import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth';
import { restablecerPasswordSlice } from './slices/restablecerPassword/restablecerPasswordSlice';
import { signupSlice } from './slices/signup';
import { userSlice } from './slices/user';
import { eventsSlice } from './slices/events';

export const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		signup: signupSlice.reducer,
		resetPass: restablecerPasswordSlice.reducer,
		user: userSlice.reducer,
		events: eventsSlice.reducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
