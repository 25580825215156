import { Card, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import MDTypography from 'components/MDTypography';
import { useState } from 'react';
import FormFieldRadius from 'components/MDFormFieldRadius';
import MDButton from 'components/MDButton';
import uploadIMG from 'assets/images/icons/uploadIMG.svg';
import { patchMyPerfilData } from 'store/slices/user';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const DatosBancariosModal = ({
	handleClose,
	actionType,
	datosBancariosToEdit,
	datosBancariosGet,
	userId,
}: any) => {
	const { id } = useSelector((state: RootState) => state.auth);
	const [banksArray, setBanksArray] = useState([
		{ id: 1, nombre: 'BBVA' },
		{ id: 2, nombre: 'Banamex' },
	]);
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { bank, titularName, accountNumber, accountClabe } = formField;
	const [fileValue, setFileValue] = useState<any>({});

	const handleFileInput = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue(files0);
		}

		console.log('files0', files0);
	};

	const submitForm = async (values: any, actions: any) => {
		console.log('SubmitFormdatos_bancarios: ', {
			banco: values.bank,
			nombre_titular: values.titularName,
			cuenta_bancaria: values.accountNumber,
			cuenta_clabe: values.accountClabe,
		});

		const patchPerfilData = patchMyPerfilData(id, {
			datos_bancarios: {
				banco: values.bank,
				nombre_titular: values.titularName,
				cuenta_bancaria: values.accountNumber,
				cuenta_clabe: values.accountClabe,
			},
		});
		patchPerfilData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('Respuesta patch perfil-200', respuesta);
					datosBancariosGet();
					toast.success(respuesta.data.mensaje);
				}
				setTimeout(() => {
					handleClose();
					actions.setSubmitting(false);
				}, 2000);
			})
			.catch((error) => {
				console.log('error', error);
				toast.error(error.message);
				actions.setSubmitting(false);
			});
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	return (
		<>
			<MDBox width={'90vw'} maxWidth={'757px'}>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox p={4} display={'flex'} flexDirection={'column'} width={'100%'}>
						<MDTypography variant={'h4'} style={{ margin: '0rem 1rem 1rem 1rem' }}>
							Editar datos bancarios
						</MDTypography>
						<Formik
							initialValues={datosBancariosToEdit}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, handleChange, isSubmitting }) => (
								<Form id={formId} autoComplete='off'>
									<MDBox style={{ margin: '0rem 0rem 1rem 1rem' }}>
										<MDTypography mt={2} mb={1} variant={'h5'}>
											Banco
										</MDTypography>
										<div className='fp-selectRadius' style={{ width: '100%' }}>
											<select
												className='fp-selectedRadius'
												name={bank.name}
												value={values.bank}
												onChange={handleChange}
												style={{ width: '110%' }}
											>
												<option value={''}>Selecciona una opción</option>

												{banksArray.map((item: any) => (
													<option key={item.id} value={item.nombre}>
														{item.nombre}
													</option>
												))}
											</select>
										</div>
									</MDBox>
									<MDBox>
										<MDTypography
											style={{ margin: '0rem 1rem 0rem 1rem' }}
											mt={2}
											variant={'h5'}
										>
											{titularName.label}
										</MDTypography>
										<MDBox style={{ margin: '0rem 0rem 0rem 1rem' }}>
											<FormFieldRadius
												type={titularName.type}
												label={titularName.label}
												name={titularName.name}
												value={values.titularName}
												onChange={handleChange}
												placeholder={titularName.label}
												error={errors.titularName && touched.titularName}
												success={values.titularName.length > 0 && !errors.titularName}
												inputProps={{ autoComplete: '' }}
												style={{ minWidth: '100%' }}
											/>
										</MDBox>
									</MDBox>
									<MDBox display={'flex'} width={'100%'}>
										<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
											<MDTypography
												style={{ margin: '0rem 1rem 0rem 1rem' }}
												mt={2}
												variant={'h5'}
											>
												{accountNumber.label}
											</MDTypography>
											<MDBox style={{ margin: '0rem 0rem 0rem 1rem' }}>
												<FormFieldRadius
													type={accountNumber.type}
													label={accountNumber.label}
													name={accountNumber.name}
													value={values.accountNumber}
													onChange={handleChange}
													placeholder={accountNumber.label}
													error={errors.accountNumber && touched.accountNumber}
													success={values.accountNumber.length > 0 && !errors.accountNumber}
													inputProps={{ autoComplete: '' }}
													style={{ minWidth: '100%' }}
												/>
											</MDBox>
										</MDBox>
										<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
											<MDTypography
												style={{ margin: '0rem 1rem 0rem 1rem' }}
												mt={2}
												variant={'h5'}
											>
												{accountClabe.label}
											</MDTypography>
											<MDBox style={{ margin: '0rem 0rem 0rem 1rem' }}>
												<FormFieldRadius
													type={accountClabe.type}
													label={accountClabe.label}
													name={accountClabe.name}
													value={values.accountClabe}
													onChange={handleChange}
													placeholder={accountClabe.label}
													error={errors.accountClabe && touched.accountClabe}
													success={values.accountClabe.length > 0 && !errors.titularName}
													inputProps={{ autoComplete: '' }}
													style={{ minWidth: '100%' }}
												/>
											</MDBox>
										</MDBox>
									</MDBox>
									<MDBox
										mt={1}
										display={'flex'}
										justifyContent={'center'}
										width={'100%'}
									>
										<MDBox
											display={'flex'}
											flexDirection={'column'}
											alignItems={'center'}
											position={'relative'}
										>
											<input
												id='imgFile2'
												className='fpInputFileImg'
												type='file'
												// name='file'
												// value={file}
												onChange={handleFileInput}
												multiple={false}
												// accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg, .pdf, .txt '
											/>
											<MDTypography style={{ fontSize: '13px' }}>
												Estado de cuenta
											</MDTypography>
											{!fileValue.name && (
												<div
													className='fpInputFileIMGContainer'
													// style={{
													// 	display: 'flex',
													// 	width: '100%',
													// 	justifyContent: 'center',
													// 	alignItems: 'center',
													// 	backgroundColor: 'teal',
													// }}
												>
													<div style={{ marginLeft: '1.9rem' }}>
														<img
															width={'100px'}
															className='fpInputFileIMG'
															src={uploadIMG}
															alt='ImagenCategoria'
														/>
													</div>
												</div>
											)}
											{fileValue.name && (
												<div
													className='fpInputFileIMGContainer'
													// style={{
													// 	display: 'flex',
													// 	width: '100%',
													// 	justifyContent: 'center',
													// 	alignItems: 'center',
													// 	backgroundColor: 'teal',
													// }}
												>
													<div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
														<span>{fileValue.name}</span>
													</div>
												</div>
											)}
										</MDBox>
									</MDBox>
									<MDBox
										mt={5}
										display={'flex'}
										flexWrap={'wrap'}
										justifyContent={'space-between'}
										width={'100%'}

										// alignItems={'center'}
									>
										<MDBox
											style={{
												minWidth: '100px',
												width: '48%',
											}}
										>
											<MDButton
												disabled={isSubmitting}
												variant='contained'
												type='submit'
												// color='error'
												// onClick={sendBanerPrincipal}
												style={{
													backgroundColor: 'var(--colorPrimary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'bold',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Guardar
											</MDButton>
										</MDBox>
										<MDBox
											style={{
												minWidth: '100px',
												width: '48%',
											}}
										>
											<MDButton
												onClick={() => handleClose()}
												type='button'
												variant='contained'
												color='primary'
												style={{
													backgroundColor: 'var(--colorSecondary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'normal',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Cancelar
											</MDButton>
										</MDBox>
									</MDBox>
								</Form>
							)}
						</Formik>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
};

export default DatosBancariosModal;
