import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import EventoProximoItem from './components/EventoProximoItem';
import pE1 from 'assets/images/EventosProximos/pE1.png';
import pE2 from 'assets/images/EventosProximos/pE2.png';
import pE3 from 'assets/images/EventosProximos/pE3.png';
import pE4 from 'assets/images/EventosProximos/pE4.png';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const EventosProximos = () => {
	const [masEventosBtn, setMasEventosBtn] = useState(true);
	//state que guarda un boolean si la pantalla es pequeña o no
	const [screenSize, setScreenSize] = useState<boolean>(false);
	//Effect para el manejo del resize de la pantalla
	useEffect(() => {
		//Funcion que retorna un boolean si la pantalla hace match con la condicion
		const isSmallScreen = (): boolean =>
			window.matchMedia('(max-width: 699px)').matches;

		//Se guarda el valor de la funcion en el state
		setScreenSize(isSmallScreen());

		// Funcion que ejecutará el evento Listener resize
		const screenResize = (): void => {
			// si la isSmallScreen es true se cambia el state a -> true
			if (isSmallScreen()) {
				setScreenSize(true);
				setMasEventosBtn(true);
			}
			// en caso contrario se cambia el state a -> false
			else {
				setScreenSize(false);
				setMasEventosBtn(false);
			}
		};
		//Evento que escucha cuando la pantalla cambia de valores
		window.addEventListener('resize', (): void => {
			screenResize();
		});

		// Se remueve el evento Listener resize
		return () => window.removeEventListener('resize', (): void => screenResize());
	}, [setScreenSize, screenSize]);
	return (
		<>
			<MDBox
				display={'flex'}
				width={'100%'}
				pt={3}
				pl={4}
				pr={4}
				justifyContent={'space-between'}
			>
				<MDTypography variant={'h4'}>
					Próximos eventos ¡Regístrate ahora!
				</MDTypography>
			</MDBox>
			<MDBox
				display={'flex'}
				justifyContent={'center'}
				flexWrap={'wrap'}
				width={'100%'}
				marginBottom={5}
			>
				<EventoProximoItem
					img={pE1}
					category={'Categoría'}
					eventName={'Nombre del evento'}
					desc={
						'Music is something that every person has his or her own specific about.'
					}
				/>
				<EventoProximoItem
					img={pE2}
					category={'Categoría'}
					eventName={'Nombre del evento'}
					desc={
						'Music is something that every person has his or her own specific about.'
					}
				/>
				{screenSize && (
					<div className='eventoProximoItem' style={{ textAlign: 'center' }}>
						<MDTypography
							component={IconButton}
							variant='button'
							// fontWeight='bold'
							onClick={() => {
								setMasEventosBtn(!masEventosBtn);
							}}
							style={{ fontSize: '1rem', color: '#EF3024' }}
						>
							{masEventosBtn ? (
								<>
									ver más eventos <ExpandMoreIcon />
								</>
							) : (
								<>
									ver menos <ExpandLessIcon />
								</>
							)}
						</MDTypography>
					</div>
				)}
				{screenSize ? (
					!masEventosBtn && (
						<>
							<EventoProximoItem
								img={pE3}
								category={'Categoría'}
								eventName={'Nombre del evento'}
								desc={
									'Music is something that every person has his or her own specific about.'
								}
							/>
							<EventoProximoItem
								img={pE4}
								category={'Categoría'}
								eventName={'Nombre del evento'}
								desc={
									'Music is something that every person has his or her own specific about.'
								}
							/>
						</>
					)
				) : (
					<>
						<EventoProximoItem
							img={pE3}
							category={'Categoría'}
							eventName={'Nombre del evento'}
							desc={
								'Music is something that every person has his or her own specific about.'
							}
						/>
						<EventoProximoItem
							img={pE4}
							category={'Categoría'}
							eventName={'Nombre del evento'}
							desc={
								'Music is something that every person has his or her own specific about.'
							}
						/>
					</>
				)}
			</MDBox>
		</>
	);
};

export default EventosProximos;
