import MDBox from 'components/MDBox';
import fpLogo from 'assets/images/fullpass.png';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Link } from 'react-router-dom';
import MDAvatar from 'components/MDAvatar';
// formik components
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import FormFieldRadius from 'components/MDFormFieldRadius';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { startLogIn } from 'store/slices/auth';
import { useEffect } from 'react';
import { cleanIsRegister } from 'store/slices/signup';

const SingIn = () => {
	const dispatch = useDispatch();
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { email, password } = formField;

	const submitForm = async (values: any, actions: any) => {
		await dispatch<any>(
			startLogIn(
				{
					email: values.email,
					password: values.password,
				},
				actions
			)
		);
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	useEffect(() => {
		dispatch<any>(cleanIsRegister());
	}, [dispatch]);
	return (
		<>
			{/* <CoverLayout image={bgImage}> */}
			<MDBox
				display={'flex'}
				alignItems={'center'}
				height={'100vh'}
				justifyContent={'center'}
				padding={5}
			>
				<MDBox width={'100%'} maxWidth={'400px'}>
					<Card>
						<Grid
							container
							direction={'column'}
							justifyContent='center'
							alignItems='center'
						>
							<MDAvatar
								variant='square'
								style={{ marginTop: '1rem', width: '9rem' }}
								src={fpLogo}
								alt='Avatar'
							/>
							<MDTypography variant='h4' fontWeight={'bold'}>
								Iniciar sesión
							</MDTypography>
							<MDTypography variant='body2' fontWeight={'light'}>
								Ingresa tus credenciales para inciar sesión
							</MDTypography>
						</Grid>
						<MDBox pt={5} pb={3} px={3}>
							<Formik
								initialValues={initialValues}
								validationSchema={currentValidation}
								onSubmit={handleSubmit}
							>
								{({ values, errors, touched, handleChange, isSubmitting }) => (
									<Form id={formId} autoComplete='off'>
										<MDBox mb={2}>
											<FormFieldRadius
												type={email.type}
												label={email.label}
												name={email.name}
												value={values.email}
												onChange={handleChange}
												placeholder={email.label}
												error={errors.email && touched.email}
												success={values.email.length > 0 && !errors.email}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
										<MDBox mb={2}>
											<FormFieldRadius
												type={password.type}
												label={password.label}
												name={password.name}
												value={values.password}
												onChange={handleChange}
												placeholder={password.label}
												error={errors.password && touched.password}
												success={values.password.length > 0 && !errors.password}
												inputProps={{ autoComplete: '' }}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
										<MDBox mt={4} mb={1}>
											<MDButton variant='gradient' color='error' fullWidth type='submit'>
												Entrar
											</MDButton>
										</MDBox>
										<MDBox
											mt={3}
											mb={1}
											textAlign='center'
											style={{ display: 'flex', flexDirection: 'column' }}
										>
											<MDTypography variant='button' color='text'>
												¿Eres nuevo en FullPass?{' '}
												<MDTypography
													disabled={isSubmitting}
													component={Link}
													to='/signup'
													variant='button'
													color='primary'
													fontWeight='medium'
													textGradient
												>
													Crear una cuenta
												</MDTypography>
											</MDTypography>
											<MDTypography
												component={Link}
												to='/resetPassword'
												variant='button'
												color='secondary'
												fontWeight='medium'
												textGradient
											>
												¿Olvidaste tu contraseña?
											</MDTypography>
										</MDBox>
									</Form>
								)}
							</Formik>
						</MDBox>
					</Card>
					<ToastContainer
						position='top-center'
						autoClose={3000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</MDBox>
			</MDBox>
			{/* </CoverLayout> */}
		</>
	);
};

export default SingIn;
