import { useState } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Images
import arrowUp from 'assets/images/icons/Icon_arrow_up.svg';
import arrowDown from 'assets/images/icons/Icon_arrow_down.svg';

import { IconButton, Modal, Tooltip } from '@mui/material';
import ActionBtn from '../actionBtn/ActionBtn';
import MDButton from 'components/MDButton';
import { patchUsuario } from 'store/slices/usuarios';
import { toast } from 'react-toastify';
import ConfirmActionUserModal from '../confirmActionModal/ConfirmActionUserModal';

function Basic({
	tittle,
	content,
	handleCloseModal,
	pendienteAprobación,
	userId,
	user,
	getUsuariosData,
	setOptionModal1,
	handleOpen1,
	setusuarioToEdit,
}: any): JSX.Element {
	const [isCardOpen, setIsCardOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpenModal(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpenModal(false);
		setOptionModal(1);
	};

	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<ConfirmActionUserModal
						handleClose={handleClose}
						user={user}
						getUsuariosData={getUsuariosData}
						actionType='Inhabilitar'
					/>
				);
			case 2:
				return (
					<ConfirmActionUserModal
						handleClose={handleClose}
						user={user}
						getUsuariosData={getUsuariosData}
						actionType='Habilitar'
					/>
				);
			case 3:
				return (
					<ConfirmActionUserModal
						handleClose={handleClose}
						user={user}
						getUsuariosData={getUsuariosData}
						actionType='Eliminar'
					/>
				);
			case 4:
				return (
					<ConfirmActionUserModal
						handleClose={handleClose}
						user={user}
						getUsuariosData={getUsuariosData}
						actionType='Eliminar'
					/>
				);
			// case 3:
			// 	return (
			// 		<DeleteVenueModal
			// 			handleClose={handleClose}
			// 			venueToDelete={venueToDelete}
			// 			getVenues={venuesGet}
			// 		/>
			// 	);

			default:
				return;
		}
	};
	const inhabilitarUsuario = () => {
		setOptionModal(1);
		handleOpen();
	};
	const habilitarUsuario = () => {
		setOptionModal(2);
		handleOpen();
	};
	const eliminarUsuario = () => {
		setOptionModal(3);
		handleOpen();
	};
	const editarUsuario = () => {
		setusuarioToEdit(user);
		setOptionModal1(2);
		handleOpen1();
	};

	const aprobarUsuario = () => {
		console.log('aprobar', userId);
		const venuesAprobar = patchUsuario(userId, {
			estatus: 'Activo',
		});
		venuesAprobar
			.then((respuesta) => {
				if (respuesta.status === 200) {
					toast.success(respuesta.data.mensaje);
					setTimeout(() => {
						getUsuariosData();
					}, 2000);
				}
			})
			.catch((error) => {
				toast.error(error.mensaje);
				console.log(error);
			});
	};

	const estatusUsuer = (estatus: string) => {
		const venuesAprobar = patchUsuario(user.id, {
			estatus: estatus,
		});
		venuesAprobar
			.then((respuesta) => {
				if (respuesta.status === 200) {
					toast.success('Usuario descartado exitosamente');
					getUsuariosData();
					setTimeout(() => {
						// handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				toast.error(error.mensaje);
				console.log(error);
				// handleClose();
			});
	};

	return (
		<MDBox>
			{pendienteAprobación && (
				<MDBox
					style={{
						height: '4rem',
						// marginTop: '-15px',
					}}
					display={'flex'}
					justifyContent={'center'}
				>
					<MDBox
						style={{
							backgroundColor: '#000000',
							color: '#ffffff',
							height: '4rem',
							width: '80%',
							borderRadius: '15px',
							// marginTop: '-15px',
						}}
					>
						<MDTypography
							p={1}
							variant={'h5'}
							textAlign='center'
							style={{ color: '#ffffff' }}
						>
							Tienes 1 usuario pendiente de aprobación
						</MDTypography>
					</MDBox>
				</MDBox>
			)}
			<Card>
				<MDBox
					variant='gradient'
					bgColor='primary'
					borderRadius='lg'
					coloredShadow='info'
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign='center'
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<MDBox display={'flex'} alignContent={'flex-end'} flexDirection={'column'}>
						<MDTypography
							ml={3}
							variant='h4'
							fontWeight='medium'
							color='white'
							textAlign='left'
							mt={1}
						>
							{tittle}
						</MDTypography>
						<MDTypography
							textAlign='left'
							ml={3}
							variant='body2'
							color='white'
							mt={1}
						>
							{user.tipo_usuario}| Usuario {user.estatus}
						</MDTypography>
					</MDBox>
					<MDBox display={'flex'}>
						{/* <Tooltip title='Editar2'>
							<MDBox
								component={IconButton}
								// onClick={editVenue}
								// variant='button'
							>
								<img width={'30px'} src={editIcon} alt='Editar' />
							</MDBox>
						</Tooltip> */}

						{/* <Tooltip title='Eliminar'>
							<MDBox
								component={IconButton}
								// onClick={deleteVenue}
								// variant='button'
							>
								<img width={'30px'} src={deleteIcon} alt='Eliminar' />
							</MDBox>
						</Tooltip> */}
						<MDBox mr={2} display={'flex'}>
							{isCardOpen ? (
								<Tooltip title='Cerrar'>
									<MDBox
										component={IconButton}
										onClick={() => setIsCardOpen(!isCardOpen)}
										// variant='button'
									>
										<img width={'30px'} src={arrowUp} alt='flecha Cerrar' />
									</MDBox>
								</Tooltip>
							) : (
								<Tooltip title='Abrir'>
									<MDBox
										component={IconButton}
										onClick={() => setIsCardOpen(!isCardOpen)}
										// variant='button'
									>
										<img width={'30px'} src={arrowDown} alt='Flecha Abrir' />
									</MDBox>
								</Tooltip>
							)}
							<ActionBtn
								habilitarUsuario={habilitarUsuario}
								inhabilitarUsuario={inhabilitarUsuario}
								eliminarUsuario={eliminarUsuario}
								editUser={editarUsuario}
								userStatus={user.estatus}
							/>
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox pt={2} pb={3} px={3}>
					{isCardOpen && (
						<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
							{content}
							{pendienteAprobación && (
								<>
									<MDBox
										mt={5}
										display={'flex'}
										flexWrap={'wrap'}
										justifyContent={'space-between'}
										width={'100%'}

										// alignItems={'center'}
									>
										<MDBox
											style={{
												minWidth: '100px',
												width: '45%',
											}}
										>
											<MDButton
												variant='contained'
												type='button'
												// color='error'
												onClick={aprobarUsuario}
												style={{
													backgroundColor: 'var(--colorPrimary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'bold',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Aprobar
											</MDButton>
										</MDBox>
										<MDBox
											style={{
												minWidth: '100px',
												width: '45%',
											}}
										>
											<MDButton
												onClick={() => estatusUsuer('Eliminado')}
												type='button'
												variant='contained'
												color='primary'
												style={{
													backgroundColor: 'var(--colorSecondary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'normal',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Descartar
											</MDButton>
										</MDBox>
									</MDBox>
								</>
							)}
						</MDBox>
					)}
				</MDBox>
			</Card>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
		</MDBox>
	);
}
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default Basic;
