import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import MDButton from 'components/MDButton';
import FormFieldRadius from 'components/MDFormFieldRadius';
import { useEffect, useState } from 'react';
import {
	getCountries,
	getCurrency,
	getLanguages,
	getPaymentMethod,
	getSettings,
	patchSettings,
} from 'store/slices/settings';
import { Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ToastContainer, toast } from 'react-toastify';
const Settings = () => {
	const { id } = useSelector((state: RootState) => state.auth);
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { country, language, Moneda, defaultPaymentMethod, commission } =
		formField;
	const [countriesArray, setCountriesArray] = useState([]);
	const [languagesArray, setLanguagesArray] = useState([]);
	const [currencyArray, setCurrencyArray] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState([]);
	const [settingsValues, setSettingsValues] = useState<any>({});
	const [gettingSetsData, setGettingSetsData] = useState(false);

	const submitForm = async (values: any, actions: any) => {
		console.log('entrando1');
		if (values.country) {
			const patchPerfilData = patchSettings(settingsValues.id, {
				id_pais: values.country,
				id_idioma: values.language,
				id_moneda: values.Moneda,
				id_motor_pago: values.defaultPaymentMethod,
				comision: values.commission,
			});
			patchPerfilData
				.then((respuesta) => {
					if (respuesta.status === 200) {
						console.log('Respuesta patch -200', respuesta);
						toast.success(respuesta.data.mensaje);
						actions.setSubmitting(false);
					}
				})
				.catch((error) => {
					console.log('error', error);
					toast.error(error.message);
					actions.setSubmitting(false);
				});
		} else {
			toast.error('El campo País es obligatorio');
			actions.setSubmitting(false);
		}
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	const countriesGET = () => {
		const contriesGet = getCountries();
		contriesGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setCountriesArray(respuesta.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setCountriesArray([]);
			});
	};
	const languagesGET = () => {
		const languagesGet = getLanguages();
		languagesGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setLanguagesArray(respuesta.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setLanguagesArray([]);
			});
	};
	const currencyGET = () => {
		const currencyGet = getCurrency();
		currencyGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setCurrencyArray(respuesta.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setCurrencyArray([]);
			});
	};
	const paymentMethodGET = () => {
		const paaymentMethodGet = getPaymentMethod();
		paaymentMethodGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setPaymentMethod(respuesta.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setPaymentMethod([]);
			});
	};
	const settingsGET = () => {
		const settingsGet = getSettings();
		settingsGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					const mxSettings = respuesta.data.filter((item: any) => item.id === 1);
					// console.log('mxSettings', mxSettings[0]);
					const initialValue = {
						id: mxSettings[0].id ? mxSettings[0].id : 0,
						country: mxSettings[0].id_pais ? mxSettings[0].id_pais : '',
						language: mxSettings[0].id_idioma ? mxSettings[0].id_idioma : '',
						Moneda: mxSettings[0].id_moneda ? mxSettings[0].id_moneda : '',
						defaultPaymentMethod: mxSettings[0].id_motor_pago
							? mxSettings[0].id_motor_pago
							: '',
						commission: mxSettings[0].comision ? mxSettings[0].comision : 0,
					};
					console.log('initialValueResponse', initialValue);
					setSettingsValues(initialValue);
					setGettingSetsData(true);
				}
			})
			.catch((error) => {
				console.log(error);
				setSettingsValues({});
			});
	};
	useEffect(() => {
		countriesGET();
		languagesGET();
		currencyGET();
		paymentMethodGET();
	}, []);

	useEffect(() => {
		settingsGET();
	}, [id, gettingSetsData]);

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox display={'flex'} width={'100%'}>
					<MDTypography
						variant={'h3'}
						// style={{ margin: '0rem 1rem 1rem 1rem' }}
					>
						Configuración
					</MDTypography>
				</MDBox>
				{(settingsValues.country ||
					settingsValues.language ||
					settingsValues.Moneda) && (
					<Formik
						initialValues={settingsValues}
						validationSchema={currentValidation}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, isSubmitting }) => (
							<Form id={formId} autoComplete='off'>
								<MDBox
									display={'flex'}
									flexDirection={'column'}
									justifyContent={'center'}

									// alignItems={'center'}
								>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<MDTypography mt={2} mb={1} variant={'h4'}>
											País
										</MDTypography>
										<div className='fp-select' style={{ width: '100%' }}>
											<select
												className='fp-selected'
												name={country.name}
												value={values.country}
												onChange={handleChange}
												style={{ width: '110%' }}
											>
												<option value={''}>Selecciona una opción</option>

												{countriesArray.map((item: any) => (
													<option key={item.id} value={item.id}>
														{item.nombre}
													</option>
												))}
											</select>
										</div>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<MDTypography mt={2} mb={1} variant={'h4'}>
											Idioma
										</MDTypography>
										<div className='fp-select' style={{ width: '100%' }}>
											<select
												className='fp-selected'
												name={language.name}
												value={values.language}
												onChange={handleChange}
												style={{ width: '110%' }}
											>
												<option value={''}>Selecciona una opción</option>

												{languagesArray.map((item: any) => (
													<option key={item.id} value={item.id}>
														{item.nombre}
													</option>
												))}
											</select>
										</div>
									</MDBox>

									{/* <MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
									<MDTypography variant={'h4'}>Idioma</MDTypography>
									<FormFieldRadius
										type={language.type}
										label={language.label}
										name={language.name}
										value={values.language}
										onChange={handleChange}
										placeholder={language.label}
										error={errors.language && touched.language}
										success={values.language.length > 0 && !errors.language}
										style={{ minWidth: '12rem' }}
									/>
								</MDBox> */}

									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<MDTypography mt={2} mb={1} variant={'h4'}>
											Modena
										</MDTypography>
										<div className='fp-select' style={{ width: '100%' }}>
											<select
												className='fp-selected'
												name={Moneda.name}
												value={values.Moneda}
												onChange={handleChange}
												style={{ width: '110%' }}
											>
												<option value={''}>Selecciona una opción</option>

												{currencyArray.map((item: any) => (
													<option key={item.id} value={item.id}>
														{item.nombre}
													</option>
												))}
											</select>
										</div>
									</MDBox>

									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<MDTypography mt={2} mb={1} variant={'h4'}>
											Motor de pago predeterminado
										</MDTypography>
										<div className='fp-select' style={{ width: '100%' }}>
											<select
												className='fp-selected'
												name={defaultPaymentMethod.name}
												value={values.defaultPaymentMethod}
												onChange={handleChange}
												style={{ width: '110%' }}
											>
												<option value={''}>Selecciona una opción</option>

												{paymentMethod.map((item: any) => (
													<option key={item.id} value={item.id}>
														{item.nombre}
													</option>
												))}
											</select>
										</div>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<MDTypography mt={2} mb={1} variant={'h4'}>
											Comisión predeterminada*
										</MDTypography>
										<div className='fp-select' style={{ width: '100%' }}>
											<input
												type='number'
												className='fp-selected'
												name={commission.name}
												value={values.commission}
												onChange={handleChange}
												style={{ width: '110%' }}
											/>
										</div>
										{/* <FormFieldRadius
										type={commission.type}
										label={commission.label}
										name={commission.name}
										value={values.commission}
										onChange={handleChange}
										placeholder={commission.label}
										error={errors.commission && touched.commission}
										success={Number(values.commission) > 0 && !errors.commission}
										inputProps={{ autoComplete: '' }}
										style={{ minWidth: '12rem' }}
									/> */}
										<MDTypography variant={'body2'} fontSize={'15px'} color={'error'}>
											*Esta comisión puede ser modificada por evento
										</MDTypography>
									</MDBox>
								</MDBox>
								<MDBox
									mt={4}
									mb={1}
									display={'flex'}
									width={'100%'}
									justifyContent={'center'}
								>
									<MDButton
										disabled={isSubmitting}
										type='submit'
										variant='gradient'
										color='error'
										size='large'
										style={{ minWidth: '12rem', width: '100%' }}
									>
										Guardar cambios
									</MDButton>
								</MDBox>
							</Form>
						)}
					</Formik>
				)}
			</MDBox>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default Settings;
