// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import { ErrorMessage } from 'formik';

// Declaring props types for FormField
interface Props {
	label: string;
	name: string;
	[key: string]: any;
}

function FormFieldRadius({ label, name, ...rest }: Props): JSX.Element {
	return (
		<MDBox mb={1.5}>
			<MDInput
				{...rest}
				name={name}
				label={label}
				fullWidth
				// style={{ border: '1px solid #000000' }}
			/>
			<MDBox mt={0.75}>
				<MDTypography
					component='div'
					variant='caption'
					color='error'
					fontWeight='regular'
				>
					<ErrorMessage name={name} />
				</MDTypography>
			</MDBox>
		</MDBox>
	);
}

export default FormFieldRadius;
