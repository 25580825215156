// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Authentication layout components
import IllustrationLayout from './components/illustration';
// formik components
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';

// Image
import bgImage from 'assets/images/illustrations/party2.jpg';
import FormFieldRadius from './components/FormField';

import { useDispatch } from 'react-redux';
import { postNewUser } from 'store/slices/signup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

function SingUp(): JSX.Element {
	const dispatch = useDispatch();
	const { isRegister } = useSelector((state: RootState) => state.signup);
	const navigate = useNavigate();
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { userName, email, password, repeatPassword, countryCode, tel, zip } =
		formField;

	const submitForm = async (values: any, actions: any) => {
		await dispatch<any>(
			postNewUser(
				{
					nombre: values.userName,
					email: values.email,
					password: values.password,
					codigo_telefono: values.countryCode,
					telefono: values.tel,
					estado: 'guerrero',
					codigo_postal: values.zip,
					tipo_usuario: 'COMPRADOR',
				},
				actions
			)
		);

		// actions.setSubmitting(false);
		// actions.resetForm();
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	//Navigate->logIn si registro es exitoso
	useEffect(() => {
		if (isRegister) {
			navigate('/login');
		}
	}, [isRegister, navigate]);
	return (
		<>
			<MDBox
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				padding={5}
			>
				<MDBox width={'90%'}>
					<IllustrationLayout
						title='Crear Cuenta Nueva'
						description='Ingresa los datos del formulario para registrarte.'
						illustration={bgImage}
					>
						<Formik
							initialValues={initialValues}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, handleChange, isSubmitting }) => (
								<Form id={formId} autoComplete='off'>
									<MDBox
										display={'flex'}
										flexWrap={'wrap'}
										justifyContent={'center'}

										// alignItems={'center'}
									>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<FormFieldRadius
												type={userName.type}
												label={userName.label}
												name={userName.name}
												value={values.userName}
												onChange={handleChange}
												placeholder={userName.label}
												error={errors.userName && touched.userName}
												success={values.userName.length > 0 && !errors.userName}
												style={{ minWidth: '12rem' }}
												autoComplete='off'
											/>
										</MDBox>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<FormFieldRadius
												type={email.type}
												label={email.label}
												name={email.name}
												value={values.email}
												onChange={handleChange}
												placeholder={email.label}
												error={errors.email && touched.email}
												success={values.email.length > 0 && !errors.email}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
									</MDBox>
									<MDBox
										display={'flex'}
										flexWrap={'wrap'}
										justifyContent={'center'}
										// mt={2}
									>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<FormFieldRadius
												type={password.type}
												label={password.label}
												name={password.name}
												value={values.password}
												onChange={handleChange}
												placeholder={password.label}
												error={errors.password && touched.password}
												success={values.password.length > 0 && !errors.password}
												inputProps={{ autoComplete: '' }}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<FormFieldRadius
												type={repeatPassword.type}
												label={repeatPassword.label}
												name={repeatPassword.name}
												value={values.repeatPassword}
												onChange={handleChange}
												placeholder={repeatPassword.label}
												error={errors.repeatPassword && touched.repeatPassword}
												success={values.repeatPassword.length > 0 && !errors.repeatPassword}
												inputProps={{ autoComplete: '' }}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
										<MDBox
											display={'flex'}
											justifyContent={'space-between'}
											style={{ margin: '0rem 1rem 1rem 1rem' }}
										>
											<FormFieldRadius
												type={countryCode.type}
												label={countryCode.label}
												name={countryCode.name}
												value={values.countryCode}
												onChange={handleChange}
												placeholder={'+52'}
												// error={errors.tel && touched.tel}
												// success={values.tel.length > 0 && !errors.tel}
												style={{ width: '3rem', marginRight: '1rem' }}
											/>
											<FormFieldRadius
												type={tel.type}
												label={tel.label}
												name={tel.name}
												value={values.tel}
												onChange={handleChange}
												placeholder={tel.label}
												error={errors.tel && touched.tel}
												success={values.tel.length > 0 && !errors.tel}
												style={{ width: '7.5rem', marginLeft: '.5rem' }}
											/>
										</MDBox>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<FormFieldRadius
												type={zip.type}
												label={zip.label}
												name={zip.name}
												value={values.zip}
												onChange={handleChange}
												placeholder={zip.label}
												error={errors.zip && touched.zip}
												success={values.zip.length > 0 && !errors.zip}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
									</MDBox>

									<MDBox mt={4} mb={1} display={'flex'} justifyContent={'center'}>
										<MDButton
											disabled={isSubmitting}
											type='submit'
											variant='gradient'
											color='error'
											size='large'
											style={{ minWidth: '12rem' }}
										>
											Crear cuenta
										</MDButton>
									</MDBox>
									<MDBox mt={3} textAlign='center'>
										<MDTypography variant='button' color='text'>
											¿Ya tienes una cuenta?{' '}
											<MDTypography
												component={Link}
												to='/login'
												variant='button'
												color='error'
												fontWeight='medium'
												textGradient
											>
												Ingresa
											</MDTypography>
										</MDTypography>
									</MDBox>
								</Form>
							)}
						</Formik>
					</IllustrationLayout>
				</MDBox>
				<ToastContainer
					position='top-center'
					autoClose={3000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</MDBox>
		</>
	);
}

export default SingUp;
