import { IconButton, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Basic from './components/basic';
import React, { useEffect, useState } from 'react';
import CategoriaModal from './components/crearCategoriaModal/CategoriaModal';
import DeleteCategoriaModal from './components/deleteCategoriaModal/DeleteCategoriaModal';
import BasicAprobacion from './components/basicAprobacion';
import { getCategorias } from 'store/slices/categories/thunks';
import { ToastContainer } from 'react-toastify';

const Categories = () => {
	const [categoriesArray, setCategoriesArray] = useState<any[]>([]);
	const [categoriesPendingArray, setCategoriesPendingArray] = useState<any[]>(
		[]
	);
	interface iCategoryToEdit {
		id: number | null;
		nombre: string;
		imagen: string;
		activo: boolean;
		Subcategorias: any[];
	}
	const categoryToEditInitialValue: iCategoryToEdit = {
		id: null,
		nombre: '',
		imagen: '',
		activo: false,
		Subcategorias: [],
	};

	const [categoryToEdit, setCategoryToEdit] = useState(
		categoryToEditInitialValue
	);
	const [categoryToDelete, setCategoryToDelete] = useState(0);
	const [openModal, setOpenModal] = React.useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpenModal(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpenModal(false);
		setOptionModal(1);

		// setComprobanteToUpdate(null);
	};

	const handleCloseModal = () => setOpenModal(false);
	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<CategoriaModal
						handleClose={handleClose}
						actionType={'Add'}
						categoryToEdit={categoryToEdit}
						categoriesGet={categoriesGet}
					/>
				);
			case 2:
				return (
					<CategoriaModal
						handleClose={handleClose}
						actionType={'Edit'}
						categoryToEdit={categoryToEdit}
						categoriesGet={categoriesGet}
					/>
				);
			case 3:
				return (
					<DeleteCategoriaModal
						handleClose={handleClose}
						categoryToDelete={categoryToDelete}
						categoriesGet={categoriesGet}
					/>
				);

			default:
				return;
		}
	};
	const newCategory = () => {
		setOptionModal(1);
		handleOpen();
	};
	const editCategory = (category: iCategoryToEdit) => {
		setCategoryToEdit(category);
		setOptionModal(2);
		handleOpen();
	};
	const deleteCategory = (categoryId: number) => {
		setCategoryToDelete(categoryId);
		setOptionModal(3);
		handleOpen();
	};

	const categoriesGet = (): void => {
		const categoriesGet = getCategorias();
		categoriesGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					const arrayPendientesdeAprobar = respuesta.data.data.filter(
						(item: any) => !item.activo
					);
					const arrayAprobados = respuesta.data.data.filter(
						(item: any) => item.activo
					);

					setCategoriesArray(arrayAprobados ? arrayAprobados : []);
					setCategoriesPendingArray(
						arrayPendientesdeAprobar ? arrayPendientesdeAprobar : []
					);
				}
			})
			.catch((error) => {
				console.log(error);
				setCategoriesArray([]);
				setCategoriesPendingArray([]);
			});
	};

	useEffect(() => {
		categoriesGet();
	}, []);

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					width={'100%'}
				>
					<MDTypography
						variant={'h3'}
						// style={{ margin: '0rem 1rem 1rem 1rem' }}
					>
						Categorías
					</MDTypography>
					<MDTypography
						component={IconButton}
						variant='body1'
						color='primary'
						fontWeight='medium'
						textGradient
						onClick={newCategory}
					>
						+ Añadir categoría
					</MDTypography>
				</MDBox>

				{categoriesPendingArray.length > 0 &&
					categoriesPendingArray.map((item: any) => (
						<MDBox mt={6} key={item.id}>
							<Basic
								deleteCategory={deleteCategory}
								editCategory={editCategory}
								tittle={item.nombre}
								pendienteAprobación={!item.activo}
								categoryId={item.id}
								categoryItem={item}
								categoriesGet={categoriesGet}
								content={
									<>
										<MDBox
											display={'flex'}
											pl={5}
											flexDirection={'column'}
											width={'100%'}
										>
											{item.Subcategorias.map((item: any) => (
												<MDTypography m={1} variant='body2' key={item.id}>
													• {item.nombre}
												</MDTypography>
											))}
										</MDBox>
									</>
								}
							/>
						</MDBox>
					))}
				{categoriesArray.length > 0 &&
					categoriesArray.map((item: any) => (
						<MDBox mt={6} key={item.id}>
							<Basic
								deleteCategory={deleteCategory}
								editCategory={editCategory}
								tittle={item.nombre}
								pendienteAprobación={!item.activo}
								categoryId={item.id}
								categoryItem={item}
								categoriesGet={categoriesGet}
								content={
									<>
										<MDBox
											display={'flex'}
											pl={5}
											flexDirection={'column'}
											width={'100%'}
										>
											{item.Subcategorias.map((item: any) => (
												<MDTypography m={1} variant='body2' key={item.id}>
													• {item.nombre}
												</MDTypography>
											))}
										</MDBox>
									</>
								}
							/>
						</MDBox>
					))}
			</MDBox>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default Categories;
