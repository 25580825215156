import checkout from './form';

const {
	formField: {
		userName,
		email,
		password,
		repeatPassword,
		countryCode,
		tel,
		// state,
	},
} = checkout;

const initialValues = {
	[userName.name]: '',
	[email.name]: '',
	[password.name]: '',
	[repeatPassword.name]: '',
	[countryCode.name]: '',
	[tel.name]: '',
	// [state.name]: '',
};

export default initialValues;
