import {
	CircularProgress,
	Divider,
	IconButton,
	Modal,
	Tooltip,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import EventosDestacados from 'pantallas/dashboard/comprador/components/Eventos destacados/EventosDestacados';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';
import Footer from 'pantallas/footer/Footer';
import TimerIcon from '@mui/icons-material/Timer';
import MDButton from 'components/MDButton';
import AddCardIcon from '@mui/icons-material/AddCard';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { numeroAMoneda } from 'helpers/aMoneda';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	cleanPurchaseSummary,
	endLoadingPurchase,
	postCompraBoletos,
	savePurchaseConfirm,
	startLoadingPurchase,
} from 'store/slices/events';
import { getMetodoPagoData } from 'store/slices/user';
import MetodoPagoBtn from './MetodoPagoBtn';
import MDInput from 'components/MDInput';
import { ToastContainer, toast } from 'react-toastify';
import MetodoPagoModal, {
	iItemToEdit,
} from 'pantallas/user/comprador/MyPerfil/metodoPagoModal/MetodoPagoModal';

const EventoConfirm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { evento, expires, reservaDetalles, cps, sumaTotal, token, purchase } =
		useSelector((state: RootState) => state.events);
	const { nombre, email, telefono, codigo_telefono } = useSelector(
		(state: RootState) => state.auth
	);

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [timerMinutes, setTimerMinutes] = useState(0);
	const [timerSeconds, setTimerSeconds] = useState(0);
	const [metodoPagoArray, setMetodoPagoArray] = useState([]);
	const [metodoPagoSelected, setMetodoPagoSelected] = useState<any>({});
	const [ccvValue, setCcvValue] = useState({ ccv: '' });

	const itemToEditInitialValues: iItemToEdit = {
		numero_tarjeta: '',
		nombre_en_tarjeta: '',
		apellido_en_tarjeta: '',
		mes_exp: '',
		ano_exp: '',
	};
	const [itemToEdit, setItemToEdit] = useState(itemToEditInitialValues);
	const showInModal = () => {
		return (
			<MetodoPagoModal
				handleClose={handleClose}
				typeModal={'Add'}
				itemToEdit={itemToEdit}
				getMetodosPago={getMetodosPago}
			/>
		);
	};
	const openAddMetodoPago = () => {
		handleOpen();
	};
	//handleInputChange
	const handleInputChangeCcv = (event: any) => {
		setCcvValue({
			...ccvValue,
			[event.target.name]: event.target.value,
		});
	};
	const onSubmit = () => {
		if (ccvValue.ccv) {
			console.log('enviando Data de compra', {
				token: token,
				cvv: ccvValue.ccv,
				metodo_id: metodoPagoSelected.id,
			});
			dispatch(startLoadingPurchase());
			const postCompraTicketsData = postCompraBoletos({
				token: token,
				cvv: ccvValue.ccv,
				metodo_id: metodoPagoSelected.id,
			});
			postCompraTicketsData
				.then((respuesta) => {
					console.log('Respuesta Compra', respuesta);
					if (respuesta.status === 200) {
						toast.success(respuesta.data.mensaje);
						console.log('Respuesta post CompraTickets::', respuesta);
						dispatch(
							savePurchaseConfirm({
								id_venta: respuesta.data.id_venta,
								evento: respuesta.data.evento,
							})
						);
						setTimeout(() => {
							dispatch(endLoadingPurchase());
							navigate(`/event/success`);
						}, 3000);
					}
				})
				.catch((error) => {
					console.log('error', error);
					toast.error(error.response.data.mensaje);
					setTimeout(() => {
						dispatch(endLoadingPurchase());
					}, 5000);
				});
		} else toast.error('CCV es requerido');
	};

	useEffect(() => {
		// Set the date we're counting down to
		const countDownDate = new Date(expires).getTime();

		// Update the count down every 1 second
		const x = setInterval(() => {
			// Get today's date and time
			const now = new Date().getTime();

			// Find the distance between now and the count down date
			const distance = countDownDate - now;

			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			setTimerMinutes(minutes);
			setTimerSeconds(seconds);

			// If the count down is finished, write some text
			if (distance < 0) {
				clearInterval(x);
				navigate(`/event/${evento.id}`);
				dispatch(cleanPurchaseSummary());
				//   document.getElementById("demo").innerHTML = "EXPIRED";
			}
		}, 1000);
	}, []);

	const getMetodosPago = () => {
		const myMetodosPagoData = getMetodoPagoData();
		myMetodosPagoData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setMetodoPagoArray(respuesta.data.data);
					// console.log('firstMEtodoPAgo', respuesta.data.data[0]);
					setMetodoPagoSelected(respuesta.data.data[0]);
				}
			})
			.catch((error) => {
				setMetodoPagoArray([]);
			});
	};
	useEffect(() => {
		getMetodosPago();
	}, []);
	// useEffect(() => {
	// window.onbeforeunload = () => {
	// 	// navigate("/");
	// 	// if (window.confirm('¿Estas seguro que desas salir?')) {
	// 	// 	console.log('EjecutandoCancelacion..');
	// 	// }
	// 	var r = window.confirm('Select an Action!');
	// 	if (r == true) {
	// 		console.log('You have selected OK!');
	// 	} else {
	// 		console.log('You have selected Cancelled!');
	// 		return;
	// 	}
	// };
	// }, []);

	window.addEventListener('beforeunload', (ev) => {
		ev.returnValue = '¿Estas seguro que desas salir?';
	});
	// window.addEventListener('onbeforeunload', (e) => {
	// 	const confirmationMessage = 'Are you sure you want to leave?';
	// 	if (!window.confirm(confirmationMessage)) {
	// 		e.preventDefault();
	// 	}
	// });
	// window.onbeforeunload = preguntarAntesDeSalir;

	// function preguntarAntesDeSalir() {
	// 	if (true) return '¿Seguro que quieres salir2?';
	// }

	return (
		<>
			<Navbar />
			<MDBox display={'flex'} width={'100%'} pt={11}>
				<MDBox display={'flex'} flexDirection={'column'} width={'100%'} p={3}>
					<MDTypography ml={2} style={{ color: 'var(--colorPrimary)' }}>
						{`inicio > Festividades > F1 Grand Prix > Detalle de compra`}
					</MDTypography>
					<MDTypography mb={2} ml={2} variant={'h3'}>
						Resumen de compra
					</MDTypography>
					<MDBox
						display={'flex'}
						justifyContent={'center'}
						flexWrap={'wrap'}
						width={'100%'}
					>
						<div className='eventConfirmContainerDesc'>
							<div className='eventCard'>
								<div className='eventCardDesc'>
									<MDBox>
										<MDTypography variant={'h4'}>
											{evento.nombre ? evento.nombre : 'Nombre evento'}
										</MDTypography>
									</MDBox>
									<MDBox mt={2}>
										<MDTypography variant={'body2'}>
											{evento.descripcion ? evento.descripcion : ''}
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'} mt={2}>
										<MDTypography variant={'h6'}>Fecha: </MDTypography>
										<MDTypography ml={1} variant={'body2'}>
											{evento.fecha ? evento.fecha : ''}
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'} mt={2}>
										<MDTypography variant={'h6'}>Lugar: </MDTypography>
										<MDTypography ml={1} variant={'body2'}>
											{evento.Venue ? evento.Venue.nombre : ''}
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'} mt={2}>
										<MDTypography variant={'h6'}>Boletos: </MDTypography>
									</MDBox>
									{reservaDetalles &&
										reservaDetalles.map((item: any, index: number) => (
											<MDBox
												key={item.id + index}
												display={'flex'}
												justifyContent={'space-between'}
												width={'100%'}
												mt={1}
											>
												<MDTypography ml={1} variant={'body2'}>
													{item.Evento_boleto.seccion}
												</MDTypography>
												<MDTypography ml={1} variant={'body2'}>
													{numeroAMoneda(item.Evento_boleto.precio)}
												</MDTypography>
											</MDBox>
										))}
									<MDBox>
										<Divider style={{ backgroundColor: '#000000' }} />
									</MDBox>
									<MDBox display={'flex'} mt={2}>
										<MDTypography variant={'h6'}>Cargo por servicio: </MDTypography>
										<MDTypography ml={1} variant={'body2'}>
											{numeroAMoneda(cps)}
										</MDTypography>
									</MDBox>
									<MDBox
										display={'flex'}
										width={'100%'}
										justifyContent={'center'}
										mt={5}
									>
										<MDTypography variant={'h7'}>
											<TimerIcon />
											Boletos reservados por: {timerMinutes}:{timerSeconds}
										</MDTypography>
									</MDBox>
								</div>
								<MDBox
									display='flex'
									justifyContent='flex-end'
									alignItems='center'
									pt={2}
									pb={2}
									pr={3}
									lineHeight={1}
									style={{
										backgroundColor: '#000000',
										borderRadius: '0px 0px 12px 12px',
									}}
								>
									<MDTypography variant={'h6'} style={{ color: '#ffffff' }}>
										Total: {numeroAMoneda(sumaTotal)}
									</MDTypography>
								</MDBox>
							</div>
						</div>
						<div className='eventConfirmContainerDesc'>
							<div className='eventCard' style={{ height: 'auto' }}>
								<div className='eventCardDesc'>
									<MDBox>
										<MDTypography variant={'h4'}>Detalle de pago</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexDirection={'column'} mt={2}>
										<MDTypography variant={'h5'}>Nombre de usuario </MDTypography>
										<MDTypography variant={'body2'}>{nombre ? nombre : ''}</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexDirection={'column'} mt={4}>
										<MDTypography variant={'h5'}>Correo electrónico </MDTypography>
										<MDTypography variant={'body2'}>{email}</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexDirection={'column'} mt={4}>
										<MDTypography variant={'h5'}>Teléfono </MDTypography>
										<MDTypography
											variant={'body2'}
										>{`${codigo_telefono} ${telefono}`}</MDTypography>
									</MDBox>
									<MDBox
										display={'flex'}
										mt={4}
										alignItems={'center'}
										justifyContent={'space-between'}
									>
										<MDBox display={'flex'} flexDirection={'column'}>
											<MDTypography variant={'h5'}>Método de pago </MDTypography>
											<MDTypography variant={'body2'}>
												Tarjeta visa terminación{' '}
												{metodoPagoSelected.numero_tarjeta &&
													metodoPagoSelected.numero_tarjeta.substr(
														metodoPagoSelected.numero_tarjeta.length - 4
													)}
											</MDTypography>
										</MDBox>
										<MDBox display={'flex'}>
											<MetodoPagoBtn
												metodosPago={metodoPagoArray}
												setMetodoPagoSelected={setMetodoPagoSelected}
											/>
											<Tooltip title='Agregar metodo pago'>
												<button
													className='inputMetodoPagoBtn'
													style={{ width: '100%', height: '100%' }}
													onClick={openAddMetodoPago}
												>
													<AddCardIcon />
												</button>
											</Tooltip>
										</MDBox>
									</MDBox>
									<MDBox display={'flex'} flexDirection={'column'} mt={4}>
										<MDTypography mb={1} variant={'h5'}>
											CCV
										</MDTypography>
										<MDInput
											type={'text'}
											label={'CCV'}
											name={'ccv'}
											value={ccvValue.ccv}
											onChange={handleInputChangeCcv}
											placeholder={'CCV'}
											// error={errors.email && touched.email}
											// success={values.email.length > 0 && !errors.email}
											style={{ maxWidth: '4.5rem' }}
										/>
									</MDBox>
									<MDBox mt={7} mb={1}>
										<MDButton
											disabled={purchase.isLoading}
											variant='gradient'
											style={{
												backgroundColor: '#EF3024',
												color: '#ffffff',
												textTransform: 'capitalize',
												fontSize: '1rem',
											}}
											fullWidth
											onClick={onSubmit}
										>
											{purchase.isLoading ? (
												<>
													<CircularProgress
														style={{ color: '#ffffff', marginRight: '5px' }}
														size={20}
													/>
													Finalizando compra...
												</>
											) : (
												<>Finalizar compra</>
											)}
										</MDButton>
									</MDBox>
									<MDBox mt={4}>
										<p
											style={{ fontSize: '15px', fontWeight: '200', textAlign: 'center' }}
										>
											Al hacer clic en “Finalizar compra” aceptas nuestros
											<span
												className='textBtn'
												style={{ fontWeight: 'bold' }}
												onClick={() => alert('to Policys')}
											>
												Términos y Condiciones, Políticas de Privacidad
											</span>{' '}
											y recibir comunicaciones de marketing.
										</p>
									</MDBox>
								</div>
							</div>
						</div>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox ml={3}>
				<EventosDestacados />
			</MDBox>
			<Footer />
			<Modal
				open={open}
				disableScrollLock={true}
				// disableEscapeKeyDown
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default EventoConfirm;
