import { axiosClient } from 'config/axiosConfig';

export const restartPassword = (email: {}) => {
	return async () => {
		axiosClient
			.post(`/usuarios/recuperacionContrasena/`, email)
			.then((respuesta) => {
				if (respuesta.status === 201) console.log(respuesta.data);
			})
			.catch((error) => console.log(error.response.data));
	};
};
