import { Card, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import { Formik, Form } from 'formik';
import initialValues from '../../schemas/initialValues';
import validations from '../../schemas/validations';
import form from '../../schemas/form';
import FormFieldRadius from 'components/MDFormFieldRadius';
import MDButton from 'components/MDButton';
import { patchVenues, postVenues } from 'store/slices/venues';
import { toast } from 'react-toastify';

const NuevoVenueModal = ({
	handleClose,
	actionType,
	venueToEdit,
	getVenues,
}: any) => {
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { name, Address, Map, Aforo } = formField;
	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};

	const initialEditValues = {
		name: venueToEdit.nombre ? venueToEdit.nombre : '',
		Address: venueToEdit.direccion ? venueToEdit.direccion : '',
		Map: venueToEdit.maps ? venueToEdit.maps : '',
		Aforo: venueToEdit.aforo ? venueToEdit.aforo : 0,
	};

	const submitForm = async (values: any, actions: any) => {
		if (actionType === 'Add') {
			console.log({
				nombre: values.name,
				direccion: values.Address,
				maps: values.Map,
				aforo: values.Aforo,
			});
			const venuesPost = postVenues({
				nombre: values.name,
				direccion: values.Address,
				maps: values.Map,
				aforo: values.Aforo,
			});
			venuesPost
				.then((respuesta) => {
					if (respuesta.status === 201) {
						toast.success(respuesta.data.mensaje);
						getVenues();
						setTimeout(() => {
							handleClose();
							actions.setSubmitting(false);
							actions.resetForm();
						}, 2000);
					}
				})
				.catch((error) => {
					console.log(error);
					toast.error(error.mensaje);
					setTimeout(() => {
						handleClose();
						actions.setSubmitting(false);
						actions.resetForm();
					}, 2000);
				});
		} else if (actionType === 'Edit') {
			const venuesAprobar = patchVenues(venueToEdit.id, {
				nombre: values.name,
				direccion: values.Address,
				maps: values.Map,
				aforo: values.Aforo,
			});
			venuesAprobar
				.then((respuesta) => {
					if (respuesta.status === 200) {
						toast.success(respuesta.data.mensaje);
						getVenues();
						setTimeout(() => {
							handleClose();
							actions.setSubmitting(false);
							actions.resetForm();
						}, 2000);
					}
				})
				.catch((error) => {
					toast.error(error.response.data.mensaje);
					console.log(error);
				});
		}
	};
	return (
		<>
			<MDBox
				// width={'100%'}
				width={'80vw'}
				maxWidth={'800px'}
				// style={{ backgroundColor: 'tomato' }}
			>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox p={4} display={'flex'} flexDirection={'column'} width={'100%'}>
						<MDTypography variant={'h4'}>
							{actionType === 'Add'
								? 'Dar de alta un venue'
								: actionType === 'Edit' && 'Editar venue'}
						</MDTypography>
						<Formik
							initialValues={
								actionType === 'Add'
									? initialValues
									: actionType === 'Edit' && initialEditValues
							}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, handleChange, isSubmitting }) => (
								<Form id={formId} autoComplete='off'>
									<MDBox
										display={'flex'}
										flexDirection={'column'}
										justifyContent={'center'}

										// alignItems={'center'}
									>
										<MDBox style={{ margin: '1rem' }}>
											<MDTypography variant={'h4'}>Nombre</MDTypography>
											<FormFieldRadius
												type={name.type}
												label={name.label}
												name={name.name}
												value={values.name}
												onChange={handleChange}
												placeholder={name.label}
												error={errors.name && touched.name}
												success={values.name.length > 0 && !errors.name}
												style={{ minWidth: '12rem', backgroundColor: 'tomato' }}
												// autoComplete='off'
											/>
										</MDBox>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<MDTypography variant={'h4'}>Dirección</MDTypography>
											<FormFieldRadius
												type={Address.type}
												label={Address.label}
												name={Address.name}
												value={values.Address}
												onChange={handleChange}
												placeholder={Address.label}
												error={errors.Address && touched.Address}
												success={values.Address.length > 0 && !errors.Address}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<MDTypography variant={'h4'}>Mapa</MDTypography>
											<FormFieldRadius
												type={Map.type}
												label={Map.label}
												name={Map.name}
												value={values.Map}
												onChange={handleChange}
												placeholder={Map.label}
												error={errors.Map && touched.Map}
												success={values.Map.length > 0 && !errors.Map}
												inputProps={{ autoComplete: '' }}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
										<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
											<MDTypography variant={'h4'}>Aforo</MDTypography>
											<FormFieldRadius
												type={Aforo.type}
												label={Aforo.label}
												name={Aforo.name}
												value={values.Aforo}
												onChange={handleChange}
												placeholder={Aforo.label}
												error={errors.Aforo && touched.Aforo}
												success={values.Aforo || (values.Aforo > 0 && !errors.Aforo)}
												inputProps={{ autoComplete: '' }}
												style={{ minWidth: '12rem' }}
											/>
										</MDBox>
									</MDBox>
									<MDBox
										mt={5}
										display={'flex'}
										flexWrap={'wrap'}
										justifyContent={'space-between'}
										width={'100%'}

										// alignItems={'center'}
									>
										<MDBox
											style={{
												minWidth: '100px',
												width: '48%',
											}}
										>
											<MDButton
												variant='contained'
												type='submit'
												// color='error'
												// onClick={sendBanerPrincipal}
												style={{
													backgroundColor: 'var(--colorPrimary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'bold',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Guardar
											</MDButton>
										</MDBox>
										<MDBox
											style={{
												minWidth: '100px',
												width: '48%',
											}}
										>
											<MDButton
												onClick={() => handleClose()}
												type='button'
												variant='contained'
												color='primary'
												style={{
													backgroundColor: 'var(--colorSecondary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'normal',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Cancelar
											</MDButton>
										</MDBox>
									</MDBox>
								</Form>
							)}
						</Formik>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
};

export default NuevoVenueModal;
