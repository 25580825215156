import MDBox from 'components/MDBox';
import EventosDestacados from 'pantallas/dashboard/comprador/components/Eventos destacados/EventosDestacados';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';
import Footer from 'pantallas/footer/Footer';
import MDTypography from 'components/MDTypography';
import EventIcon from '@mui/icons-material/Event';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Divider, Modal } from '@mui/material';
import MDButton from 'components/MDButton';
import './EventoDesc.css';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useEffect, useState } from 'react';
import SingIn from 'pantallas/dashboard/comprador/components/navbar/components/sing-in';
import SingUp from 'pantallas/dashboard/comprador/components/navbar/components/sign-up';
import ResetPassWord from 'pantallas/dashboard/comprador/components/navbar/components/reset-password';
import {
	getEventsById,
	postReservaBoletos,
	savePurchaseSummary,
} from 'store/slices/events';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { numeroAMoneda } from 'helpers/aMoneda';
import CounterTickets from './components/CounterTickets';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const EventoDesc = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const eventId = params.eventId;
	const { isAutenticated } = useSelector((state: RootState) => state.auth);
	const [isEventOK, setIsEventOK] = useState(false);

	const initialEventDesc: any = [];

	const [eventDesc, setEventDesc] = useState(initialEventDesc);
	const [totalCompraValue, setTotalCompraValue] = useState(0);
	const [carritoValue, setCarritoValue] = useState<any>([]);

	useEffect(() => {
		const getEvents = getEventsById(`${eventId}`);
		getEvents
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('descripcion del Evento::', respuesta.data.data);
					setEventDesc(respuesta.data.data);
					if (respuesta.data.data) {
						const testSections = respuesta.data.data.Evento_boletos.map(
							(item: any) => {
								return {
									id_boleto: item.id,
									cantidad: 0,
								};
							}
						);

						setCarritoValue(testSections);
					}
					setIsEventOK(true);
				}
			})
			.catch((error) => {
				if (error.response.status === 404) {
					console.log('error404- no se encontraron');
					setIsEventOK(false);
				}
			});
		setTotalCompraValue(0);
	}, [eventId]);

	//Modal proces
	const [open, setOpen] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpen(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpen(false);
		setOptionModal(1);

		// setComprobanteToUpdate(null);
	};
	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return <SingIn handleClose={handleClose} setOptionModal={setOptionModal} />;
			case 2:
				return <SingUp handleClose={handleClose} setOptionModal={setOptionModal} />;
			case 3:
				return (
					<ResetPassWord handleClose={handleClose} setOptionModal={setOptionModal} />
				);

			default:
				return;
		}
	};

	const buyTickets = () => {
		if (isAutenticated) {
			// navigate(`/event/${eventId}/confirm`);

			console.log('Enviando data::', {
				id_evento: eventDesc.id,
				boletos: carritoValue,
			});

			const postReservaTicketsData = postReservaBoletos({
				id_evento: eventDesc.id,
				boletos: carritoValue,
			});
			postReservaTicketsData
				.then((respuesta) => {
					console.log('Respuesta post perfil', respuesta);
					if (respuesta.status === 200) {
						console.log('Respuesta post REservaTickets::', respuesta);
						dispatch(
							savePurchaseSummary({
								cps: respuesta.data.cps,
								reservaDetalles: respuesta.data.data.reservaDetalles,
								evento: respuesta.data.evento,
								expires: respuesta.data.expires,
								sumaTotal: respuesta.data.sumaTotal,
								token: respuesta.data.token,
								total: respuesta.data.total,
							})
						);
						navigate(`/event/${eventId}/confirm`);
					}
				})
				.catch((error) => {
					console.log('error', error);
					if (error.response.status === 409) {
						error.response.data.notas.map((item: any) => toast.error(item.mensaje));
					}
				});
		} else handleOpen();
	};
	return (
		<>
			<Navbar />
			{isEventOK ? (
				<>
					<MDBox display={'flex'} width={'100%'} pt={11}>
						<MDBox display={'flex'} mt={2} width={'100%'}>
							<div
								className='eventDescImgContainer'
								// style={{ width: '100%', minHeight: '170px', maxHeight: '480px' }}
							>
								<img
									// src={eventDesc.imgUrl}
									src={eventDesc.Evento_imagenes[0].urlImagen}
									alt='CoverImg'
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'cover',
										// borderRadius: '.5rem',
									}}
								/>
							</div>
						</MDBox>
					</MDBox>
					<MDBox
						display={'flex'}
						justifyContent={'center'}
						flexWrap={'wrap'}
						width={'100%'}
					>
						<div className='eventDescInfoSection'>
							<MDTypography variant={'h3'} mt={2}>
								{eventDesc.nombre}
							</MDTypography>
							<MDTypography variant={'body2'} mt={2}>
								{eventDesc.descripcion}
							</MDTypography>
							<MDTypography variant={'h5'} mt={1}>
								{/* <PlaceOutlinedIcon /> km 200, México 307, 77760 Tulum, Q.R. */}
								<PlaceOutlinedIcon /> {eventDesc.Venue.nombre}
							</MDTypography>
							<MDTypography variant={'h5'} mt={1}>
								{/* <EventIcon /> 23, 24 y 25 de Marzo */}
								<EventIcon /> {eventDesc.fecha}
							</MDTypography>
							<MDTypography variant={'h5'} mt={1}>
								<AccessTimeOutlinedIcon /> {eventDesc.hora} hrs
							</MDTypography>
						</div>
						<div>
							<div className='eventDescTicketSection'>
								<MDBox display={'flex'} flexDirection='column'>
									<MDBox
										display={'flex'}
										padding={2}
										paddingLeft={4}
										style={{
											backgroundColor: '#000000',
											color: '#ffffff',
											fontWeight: 'bold',
										}}
									>
										Boletos
									</MDBox>
									<MDBox
										pt={2}
										pb={3}
										pl={4}
										pr={4}
										display={'flex'}
										flexDirection={'column'}
									>
										<MDBox display={'flex'} flexDirection={'column'}>
											{eventDesc.Evento_boletos.map((item: any) => (
												<MDBox
													key={item.id}
													display={'flex'}
													mb={2}
													justifyContent={'space-between'}
												>
													<MDBox display={'flex'} flexDirection={'column'}>
														<MDTypography variant={'h6'}>Sección</MDTypography>
														<MDTypography variant={'body2'}>{item.seccion}</MDTypography>
														<MDTypography variant={'body2'}>
															{numeroAMoneda(item.precio)}
														</MDTypography>
													</MDBox>
													<CounterTickets
														totalCompraValue={totalCompraValue}
														setTotalCompraValue={setTotalCompraValue}
														item={item}
														carritoValue={carritoValue}
														setCarritoValue={setCarritoValue}
													/>
													{/* <MDBox display={'flex'} alignItems={'center'}>
														<button className='eventDescTicketBtn'>-</button>
														<MDBox>0</MDBox>
														<button className='eventDescTicketBtn'>+</button>
													</MDBox> */}
												</MDBox>
											))}
										</MDBox>
										<MDBox>
											<Divider style={{ backgroundColor: '#ef3024' }} />
										</MDBox>
										<MDBox display={'flex'} justifyContent={'space-between'}>
											<MDBox>
												<MDBox>
													<MDTypography variant={'h5'}>Total</MDTypography>
												</MDBox>
												<MDBox>
													<MDTypography variant={'body2'}>
														Descripción de la compra
													</MDTypography>
												</MDBox>
											</MDBox>
											<MDBox display={'flex'} alignItems={'flex-end'}>
												<MDTypography variant={'h5'}>
													{numeroAMoneda(totalCompraValue)}
												</MDTypography>
											</MDBox>
										</MDBox>
										<MDBox mt={4} mb={1}>
											<MDButton
												disabled={totalCompraValue === 0}
												variant='gradient'
												style={{ backgroundColor: '#EF3024', color: '#ffffff' }}
												fullWidth
												type='submit'
												onClick={buyTickets}
											>
												Comprar boletos
											</MDButton>
										</MDBox>
									</MDBox>
								</MDBox>
							</div>
						</div>
					</MDBox>
				</>
			) : (
				<MDBox display={'flex'} width={'100%'} pt={12} pb={3}>
					<MDBox
						style={{
							width: '100%',
							// minWidth: '300px',
							// maxWidth: '400px',
							minHeight: '320px',
							margin: '5px',
						}}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<MDBox pt={0} mt={0} textAlign='center'>
							<MDTypography
								variant='h3'
								fontWeight='medium'
								style={{ color: '##B6B6B6' }}
							>
								PÁGINA NO ENCONTRADA
							</MDTypography>
							<MDTypography variant='button' color='text' fontSize='1rem'>
								volver a{' '}
								<MDTypography
									component={Link}
									to='/'
									variant='button'
									color='primary'
									fontWeight='medium'
									fontSize='1rem'
									textGradient
								>
									Inicio
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			)}
			<EventosDestacados />
			<Footer />
			<Modal
				open={open}
				disableScrollLock={true}
				disableEscapeKeyDown
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};

export default EventoDesc;
