import MDBox from 'components/MDBox';
import './EventoItem.css';

const EventoItem = ({ img, text }: any) => {
	return (
		<>
			<div className='eventoXCiudadItem'>
				<div className='categoryCard' onClick={() => alert('Ir a evento')}>
					<MDBox
						width='100%'
						maxHeight={'200px'}
						component='img'
						src={img}
						alt={text}
						loading={'lazy'}
						style={{ objectFit: 'cover' }}
					/>
					<div className='eventoXCiudadTextBar'>{text}</div>
				</div>
			</div>
		</>
	);
};

export default EventoItem;
