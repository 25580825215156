import { createSlice } from '@reduxjs/toolkit';

export interface signupState {
	isLoading: boolean;
	isRegister: boolean;
}
const initialState: signupState = {
	isLoading: false,
	isRegister: false,
};

export const signupSlice = createSlice({
	name: 'signup',
	initialState,
	reducers: {
		startLoadingSignup: (state /* action */) => {
			state.isLoading = true;
		},
		endLoadingSignup: (state /* action */) => {
			state.isLoading = false;
		},
		registering_Ok: (state /* action */) => {
			state.isRegister = true;
		},
		registering_False: (state /* action */) => {
			state.isRegister = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	startLoadingSignup,
	endLoadingSignup,
	registering_Ok,
	registering_False,
} = signupSlice.actions;
