const form = {
	formId: 'MyPerfil-form',
	formField: {
		userName: {
			name: 'userName',
			label: 'Nombre de usuario',
			type: 'text',
			errorMsg: 'Nombre de usuario es requerido.',
		},
		email: {
			name: 'email',
			label: 'Correo electrónico',
			type: 'eamil',
			errorMsg: 'Correo electrónico es requerido.',
			invalidMsg: 'El correo electrónico es invalido',
		},
		companyName: {
			name: 'companyName',
			label: 'Nombre de empresa',
			// label: 'Fecha de nacimiento',
			type: 'text',
			errorMsg: 'Nombre de empresa es requerida.',
			// invalidMsg: 'Tu fecha debe estar completa',
		},
		countryCode: {
			name: 'countryCode',
			label: '',
			type: 'text',
			errorMsg: 'Codigo de país es requerido.',
		},
		tel: {
			name: 'tel',
			label: 'Teléfono',
			type: 'number',
			errorMsg: 'Teléfono es requerido.',
			invalidMsg: 'Telefono debe tener 10 dígitos.',
		},
	},
};

export default form;
