import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import React from 'react';

const CardInfo = ({ tittle, subtittle, body }: any) => {
	return (
		<>
			<div className='cardInfoSection'>
				<MDBox>
					<MDBox mt={3}>
						<div className='cardInfoTittle'>{tittle}</div>
					</MDBox>
					<MDBox>
						<div className='cardInfoSubittle'>{subtittle}</div>
					</MDBox>
					<MDBox>
						<div className='cardInfoBody'>{body}</div>
					</MDBox>
				</MDBox>
				<MDBox
					style={{
						backgroundColor: 'var(--colorPrimary)',
						width: '100%',
						height: '12px',
					}}
				></MDBox>
			</div>
		</>
	);
};

export default CardInfo;
