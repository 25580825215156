const form = {
	formId: 'resetPassWord-form',
	formField: {
		email: {
			name: 'email',
			label: 'Correo electrónico',
			type: 'email',
			errorMsg: 'Correo electrónico es requerido.',
			invalidMsg: 'El correo electrónico es invalido',
		},
	},
};

export default form;
