import { Card, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { deleteVenuesId } from 'store/slices/venues';
import { toast } from 'react-toastify';
import { patchEventos } from 'store/slices/events';
import { useState } from 'react';

const DeleteEventoModal = ({ handleClose, eventToDelete, eventosGET }: any) => {
	const [enviandoState, setEnviandoState] = useState(false);

	const deleteEvento = () => {
		console.log(eventToDelete);

		const eventosPATCH = patchEventos(eventToDelete, {
			estatus: 'Eliminado',
		});
		setEnviandoState(true);
		eventosPATCH
			.then((respuesta) => {
				console.log('respuesta', respuesta);
				if (respuesta.status === 200) {
					toast.success('La solicitúd de eliminación se envío exitosamente.');
					setTimeout(() => {
						setEnviandoState(false);
						eventosGET();
						handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				setEnviandoState(false);
				setTimeout(() => {
					setEnviandoState(false);
					handleClose();
				}, 2000);
			});

		// const venuesDelete = deleteVenuesId(eventToDelete);
		// venuesDelete
		// 	.then((respuesta) => {
		// 		console.log(respuesta);
		// 		if (respuesta.status === 200) {
		// 			toast.success('El venue se eliminó exitosamente');
		// 			setTimeout(() => {
		// 				handleClose();
		// 				getVenues();
		// 			}, 2000);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 		toast.error(error.response.data.mensaje);
		// 		setTimeout(() => {
		// 			handleClose();
		// 		}, 2000);
		// 	});
	};
	return (
		<>
			<MDBox
				// width={'100%'}
				width={'80vw'}
				maxWidth={'400px'}
				// style={{ backgroundColor: 'tomato' }}
			>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox
						p={5}
						pt={7}
						pb={5}
						display={'flex'}
						flexDirection={'column'}
						width={'100%'}
					>
						<MDTypography textAlign={'center'} variant={'h3'}>
							Eliminar Evento
						</MDTypography>
						<MDBox mt={2}>
							<MDTypography variant={'body2'} textAlign={'center'}>
								¿Estas seguro que deseas eliminar este evento?
							</MDTypography>
						</MDBox>
						<MDBox mt={2}>
							<MDTypography variant={'body2'} textAlign={'center'}>
								Esta solicitud será enviada al administrador para su aprobación.
							</MDTypography>
						</MDBox>
						<MDBox
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							mt={4}
						>
							<MDBox
								style={{
									minWidth: '100px',
									width: '100%',
								}}
							>
								<MDButton
									variant='contained'
									type='button'
									// color='error'
									onClick={deleteEvento}
									style={{
										backgroundColor: 'var(--colorPrimary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'bold',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
									disabled={enviandoState}
								>
									{enviandoState ? 'Solicitando...' : 'Aceptar'}
								</MDButton>
							</MDBox>
							<MDBox
								mt={2}
								style={{
									minWidth: '100px',
									width: '100%',
								}}
							>
								<MDButton
									onClick={() => handleClose()}
									type='button'
									variant='contained'
									color='primary'
									style={{
										backgroundColor: 'var(--colorSecondary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'normal',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Cancelar
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
};

export default DeleteEventoModal;
