const form = {
	formId: 'DatosBancarios-form',
	formField: {
		bank: {
			name: 'bank',
			label: 'Banco',
			type: 'text',
			errorMsg: 'Banco es requerido.',
		},
		titularName: {
			name: 'titularName',
			label: 'Nombre titular de la cuenta',
			type: 'text',
			errorMsg: 'Nombre titular de la cuenta.',
		},
		accountNumber: {
			name: 'accountNumber',
			label: 'Número de cuenta',
			type: 'number',
			errorMsg: 'Número de cuenta es requerida.',
			// invalidMsg: 'Tu fecha debe estar completa',
		},
		accountClabe: {
			name: 'accountClabe',
			label: 'Cuenta CLABE',
			type: 'number',
			errorMsg: 'Cuenta CLABE es requerido.',
		},
	},
};

export default form;
