import checkout from './form';

const {
	formField: { userName, email, companyName, countryCode, tel },
} = checkout;

const initialValues = {
	[userName.name]: '',
	[email.name]: '',
	[companyName.name]: '',
	[countryCode.name]: '',
	[tel.name]: '',
};

export default initialValues;
