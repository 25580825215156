import { IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import deletIconRed from 'assets/images/icons/deletedIconRed.svg';

const DeleteSubcategoria = ({
	item,
	subcategoriaArray,
	setSubcategoriaArray,
	index,
	arrayToDelete,
	setarrayToDelete,
}: any) => {
	const handleDelete = () => {
		setSubcategoriaArray(
			subcategoriaArray.filter((itemList: any, index: any) => itemList !== item)
		);
		if (item.id) {
			setarrayToDelete([...arrayToDelete, { id: item.id }]);
		}
		// toast.success(`Eliminado!`);
	};
	return (
		<>
			<Tooltip title='Eliminar'>
				<MDBox
					component={IconButton}
					onClick={handleDelete}
					// variant='button'
				>
					<img width={'30px'} src={deletIconRed} alt='Eliminar' />
				</MDBox>
			</Tooltip>
		</>
	);
};

export default DeleteSubcategoria;
