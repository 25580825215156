import { ReactNode } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
import fullpass from 'assets/images/fullpass.svg';
import MDAvatar from 'components/MDAvatar';
import { useMaterialUIController } from 'context';
import PageLayout from '../PageLayout';

import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Declaring props types for IllustrationLayout
interface Props {
	header?: ReactNode;
	title?: string;
	description?: string;
	children: ReactNode;
	illustration?: string;
	handleClose?: any;
}

function IllustrationLayout({
	header,
	title,
	description,
	illustration,
	children,
	handleClose,
}: Props): JSX.Element {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<PageLayout background='white'>
			<Grid
				container
				sx={{
					backgroundColor: ({ palette: { background, white } }) =>
						darkMode ? background.default : white.main,
				}}
			>
				<Grid item xs={12} lg={5}>
					<MDBox
						display={{ xs: 'none', lg: 'flex' }}
						width='100%'
						height='100%'
						borderRadius='lg'

						// sx={{ backgroundImage: `url(${illustration})` }}
					>
						<img
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								// borderRadius: '.5rem',
							}}
							src={illustration}
							alt={'tags'}
							loading='lazy'
						/>
					</MDBox>
				</Grid>
				<Grid
					item
					xs={11}
					sm={8}
					md={1}
					lg={6}
					xl={7}
					sx={{ mx: 'auto' }}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								top: '0px',
								backgroundColor: '#ffffff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox display='flex' flexDirection='column' justifyContent='center'>
						<MDBox py={3} px={3} textAlign='center'>
							{!header ? (
								<>
									<MDBox mb={1} textAlign='center'>
										<Grid container justifyContent='center' alignItems='center'>
											<MDAvatar
												variant='square'
												style={{ marginTop: '2rem', width: '9rem' }}
												src={fullpass}
												alt='Avatar'
											/>
										</Grid>
										<MDTypography variant='h4' fontWeight='bold'>
											{title}
										</MDTypography>
									</MDBox>
									<MDTypography variant='body2' color='text'>
										{description}
									</MDTypography>
								</>
							) : (
								header
							)}
						</MDBox>
						<MDBox p={3}>{children}</MDBox>
					</MDBox>
				</Grid>
			</Grid>
		</PageLayout>
	);
}

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
	header: '',
	title: '',
	description: '',
	illustration: '',
};

export default IllustrationLayout;
