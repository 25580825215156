import { Dispatch } from 'redux';
import { axiosClient } from 'config/axiosConfig';
import {
	iValidationEmpresaInfo,
	password_updated,
	validation_token_success,
} from './restablecerPasswordSlice';
import { showErrorsAuth } from 'helpers/showErrorsAuth';

//Validar token recibido por mail
export const validationToken = (token: any) => {
	return async (dispatch: Dispatch) => {
		axiosClient
			.get(`confirmation/${token}`)
			.then((respuesta) => {
				console.log('ValidandoTKN:::', respuesta);
				if (respuesta.status === 200) {
					const confirmationUser: iValidationEmpresaInfo = respuesta.data.data;
					console.log(confirmationUser);
					dispatch(validation_token_success(confirmationUser));
				}
			})
			.catch((error) => {
				showErrorsAuth(error, dispatch);
			});
	};
};

//Envio de confirmacion de Contraseña o Nueva Contraseña
export const sendPassword = (tkn: string, email: string, password: string) => {
	return async (dispatch: Dispatch) => {
		axiosClient
			.patch(
				`confirmation/`,
				{
					email: email,
					password: password,
				},
				{
					headers: {
						Authorization: `${tkn}`,
					},
				}
			)
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 200) {
					console.log('Cambio de Password exitoso!::');
					setTimeout(() => {
						dispatch(password_updated());
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				showErrorsAuth(error, dispatch);
			});
	};
};
