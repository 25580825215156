import { Navigate, Outlet } from 'react-router-dom';

const RequireAdmin = () => {
	const flpssUser = localStorage.getItem('flpssTypeUser') || '';
	console.log('flpssTypeUser', flpssUser);

	if (flpssUser === 'ADMINISTRADOR') return <Outlet />;

	return <Navigate to='/' />;
};

export default RequireAdmin;
