import * as Yup from 'yup';
import checkout from './form';

const {
	formField: { bank, titularName, accountNumber, accountClabe },
} = checkout;

const validations = [
	Yup.object().shape({
		[bank.name]: Yup.string().required(bank.errorMsg),
		[titularName.name]: Yup.string().required(titularName.errorMsg),
		[accountNumber.name]: Yup.number().required(accountNumber.errorMsg),
		[accountClabe.name]: Yup.number().required(accountClabe.errorMsg),
	}),
];

export default validations;
