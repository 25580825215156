import { ReactNode } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
import fullpass from 'assets/images/fullpass.png';
import MDAvatar from 'components/MDAvatar';
import { useMaterialUIController } from 'context';
import PageLayout from '../PageLayout';

// Declaring props types for IllustrationLayout
interface Props {
	header?: ReactNode;
	title?: string;
	description?: string;
	children: ReactNode;
	illustration?: string;
}

function IllustrationLayout({
	header,
	title,
	description,
	illustration,
	children,
}: Props): JSX.Element {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<PageLayout background='white'>
			<Grid
				container
				sx={{
					backgroundColor: ({ palette: { background, white } }) =>
						darkMode ? background.default : white.main,
				}}
			>
				<Grid item xs={12} lg={5}>
					<MDBox
						display={{ xs: 'none', lg: 'flex' }}
						width='100%'
						height='100%'
						borderRadius='lg'

						// sx={{ backgroundImage: `url(${illustration})` }}
					>
						<img
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								// borderRadius: '.5rem',
							}}
							src={illustration}
							alt={'tags'}
							loading='lazy'
						/>
					</MDBox>
				</Grid>
				<Grid
					item
					xs={11}
					sm={8}
					md={1}
					lg={6}
					xl={7}
					sx={{ mx: 'auto' }}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<MDBox display='flex' flexDirection='column' justifyContent='center'>
						<MDBox py={3} px={3} textAlign='center'>
							{!header ? (
								<>
									<MDBox mb={1} textAlign='center'>
										<Grid container justifyContent='center' alignItems='center'>
											<MDAvatar
												variant='square'
												style={{ marginTop: '2rem', width: '9rem' }}
												src={fullpass}
												alt='Avatar'
											/>
										</Grid>
										<MDTypography variant='h4' fontWeight='bold'>
											{title}
										</MDTypography>
									</MDBox>
									<MDTypography variant='body2' color='text'>
										{description}
									</MDTypography>
								</>
							) : (
								header
							)}
						</MDBox>
						<MDBox p={3}>{children}</MDBox>
					</MDBox>
				</Grid>
			</Grid>
		</PageLayout>
	);
}

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
	header: '',
	title: '',
	description: '',
	illustration: '',
};

export default IllustrationLayout;
