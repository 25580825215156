import DataTable from 'components/DataTable';
import MDBox from 'components/MDBox';
import { useEffect, useState } from 'react';
import SplitButton from './SplitButton';
import { CSVLink } from 'react-csv';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import './BannerInformacionEventos.css';
import { getEvents, getInformacionDashboard } from 'store/slices/events';
const selectDataEstatus = [
	{ id: 'Activo', value: 'Activo' },
	{ id: 'Por aprobar', value: 'Por aprobar' },
	{ id: 'Inactivo', value: 'Inactivo' },
];
const initialArrayEvent: any[] = [];

const BannerInformacionEventos = () => {
	const [eventsArray, setEventsArray] = useState<any[]>([]);
	const [eventosListData, setEventosListData] = useState(initialArrayEvent);
	const [parmsEvents, setParmsEvents] = useState({
		evento_id: '',
		fechaInicio: '',
		FechaFin: '',
		estatus: '',
	});
	const { evento_id, fechaInicio, FechaFin, estatus } = parmsEvents;
	const handleInputChangeParamsEvents = (event: any) => {
		setParmsEvents({
			...parmsEvents,
			[event.target.name]: event.target.value,
		});
	};

	//Estuctura de datos para tabla
	const datosTabla = {
		columns: [
			{ Header: 'Evento', accessor: 'Evento', width: '80px', align: 'left' },
			{ Header: 'Estatus', accessor: 'Estatus', align: 'left' },
			{ Header: 'Tickets', accessor: 'Tickets', width: '80px', align: 'left' },
			{ Header: 'Vendidos', accessor: 'Vendidos', width: '400px', align: 'left' },
			{ Header: 'Detalles', accessor: 'Detalles', align: 'center' },
		],
		rows: eventosListData
			.map((item) => {
				return {
					Evento: item.evento ? item.evento : '',
					Estatus: item.estatus,
					Tickets: item.tickets,
					Vendidos: item.vendidos,
					Detalles: (
						<>
							<SplitButton eventoId={item.evento_id} />
							{/* <SplitButton
								item={item}
								handleOpen={handleOpen}
								setComprobanteToUpdate={setComprobanteToUpdate}
								setComprobantesListData={setEventosListData}
								isMyDataValid={isMyDataValid}
							/> */}
						</>
					),
				};
			})
			.reverse(),
	};

	const banerDestacadosGet = (): void => {
		const getInfoDashboard = getInformacionDashboard(
			parmsEvents.evento_id,
			parmsEvents.fechaInicio,
			parmsEvents.FechaFin,
			parmsEvents.estatus
		);
		getInfoDashboard
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					setEventosListData(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setEventosListData([]);
			});
	};
	const eventosGet = (): void => {
		const getEventos = getEvents();
		getEventos
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					setEventsArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setEventsArray([]);
			});
	};

	useEffect(() => {
		banerDestacadosGet();
	}, [parmsEvents]);
	useEffect(() => {
		eventosGet();
	}, []);

	return (
		<>
			<MDBox
				display={'flex'}
				alignItems={'center'}
				flexDirection={'column'}
				width={'100%'}
				position={'relative'}
			>
				<MDBox mt={4} display={'flex'} width={'100%'}>
					<MDTypography variant='h3'>Información eventos</MDTypography>
				</MDBox>
				<MDBox mt={4} display={'flex'} width={'100%'}>
					<div className='fpTableContainer'>
						<MDBox mt={1} display={'flex'} flexWrap={'wrap'} width={'100%'}>
							{/* Desde */}
							<MDBox
								display={'flex'}
								flexDirection={'column'}
								style={{
									minWidth: '230px',
									borderBottom: '1px solid #d9d9d9c7',
								}}
							>
								<MDTypography variant='body2'>Desde</MDTypography>

								<input
									type='date'
									className='fp-calendar'
									name='fechaInicio'
									value={fechaInicio}
									onChange={handleInputChangeParamsEvents}
								/>
							</MDBox>
							{/* Hasta */}
							<MDBox
								ml={1}
								display={'flex'}
								flexDirection={'column'}
								style={{
									minWidth: '230px',
									borderBottom: '1px solid #d9d9d9c7',
								}}
							>
								<MDTypography variant='body2'>Hasta</MDTypography>

								<input
									type='date'
									className='fp-calendar'
									name='FechaFin'
									value={FechaFin}
									onChange={handleInputChangeParamsEvents}
								/>
							</MDBox>

							{/* Evento                */}
							<MDBox
								ml={1}
								display={'flex'}
								flexDirection={'column'}
								style={{ minWidth: '230px' }}
							>
								<MDTypography variant='body2'>Evento</MDTypography>
								<div className='fp-select'>
									<select
										className='fp-selected'
										name='evento_id'
										value={evento_id}
										onChange={handleInputChangeParamsEvents}
									>
										<option value={''}>Selecciona una opción</option>
										{eventsArray.map((item: any) => (
											<option key={item.id} value={item.id}>
												{item.nombre}
											</option>
										))}
									</select>
								</div>
							</MDBox>
							{/* Estatus */}
							<MDBox
								ml={1}
								display={'flex'}
								flexDirection={'column'}
								style={{ minWidth: '230px' }}
							>
								<MDTypography variant='body2'>Estatus</MDTypography>
								<div className='fp-select'>
									<select
										className='fp-selected'
										name='estatus'
										value={estatus}
										onChange={handleInputChangeParamsEvents}
									>
										<option value={''}>Selecciona una opción</option>

										{selectDataEstatus.map((item: any) => (
											<option key={item.id} value={item.value}>
												{item.value}
											</option>
										))}
									</select>
								</div>
							</MDBox>
						</MDBox>
					</div>
				</MDBox>
				{/* <MDBox display={'flex'} style={{ width: '98%' }}>
					<DataTable table={datosTabla} canSearch={false} />
				</MDBox> */}
				<div
					style={{
						position: 'relative',
						width: '100%',
						overflow: 'hidden',
					}}
				>
					<DataTable table={datosTabla} canSearch={false} />
				</div>
				<MDBox display={'flex'} width={'100%'} justifyContent={'flex-end'}>
					<CSVLink
						data={eventosListData
							.map((d) => {
								return {
									Evento: d.evento,
									Estatus: d.estatus,
									Tickets: d.tickets,
									Vendidos: d.vendidos,
								};
							})
							.reverse()}
						filename='Comprobantes.csv'
					>
						<MDButton size='small' sx={{ marginLeft: '5px', border: '1px solid' }}>
							+ Exportar CSV
						</MDButton>
					</CSVLink>
				</MDBox>
			</MDBox>
		</>
	);
};

export default BannerInformacionEventos;
