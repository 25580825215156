// react-router-dom components
import { Link, useNavigate, useParams } from 'react-router-dom';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import fpLogo from 'assets/images/fullpass.svg';
import { useDispatch } from 'react-redux';

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// formik components
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import FormFieldRadius from 'components/MDFormFieldRadius';
import { useEffect } from 'react';
import {
	sendPassword,
	validationToken,
} from 'store/slices/restablecerPassword/thunk';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

function ConfirmPassword(): JSX.Element {
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { password, repeatPassword } = formField;

	const dispatch = useDispatch();
	// const { passworUpdated, empresatknInfo } = useSelector(
	const { passworUpdated, empresatknInfo } = useSelector(
		(state: RootState) => state.resetPass
	);
	const { id, email } = empresatknInfo;
	// const { registerState } = useSelector((state: RootStore) => state.auth);

	// const registerState = { loading: false };

	const navigate = useNavigate();
	const params = useParams();
	const tkn = params.tkn;

	const submitForm = async (values: any, actions: any) => {
		dispatch<any>(sendPassword(tkn, email, values.password));
		actions.setSubmitting(false);
		// actions.resetForm();
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};

	// Efecto que se ejecuta al cargar el componente
	useEffect(() => {
		dispatch<any>(validationToken(tkn));
	}, [dispatch, tkn]);

	//useEffect para redireccionar al login una vez se actualizo el password
	useEffect(() => {
		if (passworUpdated) {
			navigate('/');
		}
	}, [passworUpdated, navigate]);

	return (
		<MDBox
			// pt={4} pb={3}
			style={{ backgroundColor: 'blue' }}
		>
			{!!id ? (
				<Grid
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
					style={{ flexWrap: 'wrap' }}
				>
					<MDBox
						style={{
							backgroundColor: 'white',
							width: '100%',
							height: '100%',
							minHeight: '100vh',
							position: 'relative',
						}}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'flex-end'}
						flexDirection={'column'}
					>
						<Grid container justifyContent='' alignItems='center'>
							<MDAvatar
								variant='square'
								style={{
									marginTop: '1rem',
									marginLeft: '1rem',
									width: '18rem',
									height: 'auto',
								}}
								src={fpLogo}
								alt='Logo'
							/>
						</Grid>
						<MDBox
							style={{
								minWidth: '300px',
								maxWidth: '400px',
								minHeight: '520px',
								margin: '5px',
								backgroundColor: 'white',
							}}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
						>
							<MDBox pt={4} pb={3} px={3}>
								<MDBox pt={1} pb={3} textAlign='center'>
									<MDTypography color='primary' variant='h5'>
										INGRESA TU NUEVA CONTRASEÑA.
									</MDTypography>
									<MDTypography display='block' fontSize={'1.5rem'} color='secondary'>
										{email}
									</MDTypography>
								</MDBox>
								<Formik
									initialValues={initialValues}
									validationSchema={currentValidation}
									onSubmit={handleSubmit}
								>
									{({ values, errors, touched, handleChange, isSubmitting }) => (
										<Form id={formId} autoComplete='off'>
											<MDBox mb={2}>
												<FormFieldRadius
													type={password.type}
													label={password.label}
													name={password.name}
													value={values.password}
													onChange={handleChange}
													placeholder={password.label}
													error={errors.password && touched.password}
													success={values.password.length > 0 && !errors.password}
													inputProps={{ autoComplete: '' }}
													style={{ minWidth: '12rem' }}
												/>
											</MDBox>
											<MDBox mb={2}>
												<FormFieldRadius
													type={repeatPassword.type}
													label={repeatPassword.label}
													name={repeatPassword.name}
													value={values.repeatPassword}
													onChange={handleChange}
													placeholder={repeatPassword.label}
													error={errors.repeatPassword && touched.repeatPassword}
													success={
														values.repeatPassword.length > 0 && !errors.repeatPassword
													}
													inputProps={{ autoComplete: '' }}
													style={{ minWidth: '12rem' }}
												/>
											</MDBox>

											<MDBox mt={4} mb={1}>
												<MDButton
													disabled={isSubmitting}
													variant='gradient'
													color='primary'
													fullWidth
													type='submit'
												>
													Guardar cambios
												</MDButton>
												{/* {!registerState.loading ? (
													<MDButton
														disabled={isSubmitting}
														variant='gradient'
														color='primary'
														fullWidth
														type='submit'
													>
														Guardar cambios
													</MDButton>
												) : (
													<MDButton variant='gradient' color='primary' fullWidth disabled>
														Guardando...
													</MDButton>
												)} */}
											</MDBox>
										</Form>
									)}
								</Formik>
							</MDBox>
						</MDBox>
						<MDBox
							style={{
								backgroundColor: '#333333',
								width: '100%',
								height: '100%',
								padding: '1rem',
							}}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'column'}
						>
							<Grid container justifyContent='center'>
								<Grid item xs={10} lg={8}>
									<MDBox display='flex' justifyContent='center' flexWrap='wrap' mb={2}>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Company
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												About Us
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 0, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Team
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Product
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Blog
											</MDTypography>
										</MDBox>
										<MDBox>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Pricing
											</MDTypography>
										</MDBox>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDBox display='flex' justifyContent='center' mt={1} mb={2}>
										<MDBox mr={3} color='secondary'>
											<FacebookIcon fontSize='small' />
										</MDBox>
										<MDBox mr={3} color='secondary'>
											<TwitterIcon fontSize='small' />
										</MDBox>
										<MDBox mr={3} color='secondary'>
											<InstagramIcon fontSize='small' />
										</MDBox>
										<MDBox mr={3} color='secondary'>
											<PinterestIcon fontSize='small' />
										</MDBox>
										<MDBox color='secondary'>
											<LinkedInIcon fontSize='small' />
										</MDBox>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={8} sx={{ textAlign: 'center' }}>
									<MDTypography variant='body2' color='secondary'>
										Copyright &copy; 2023 fullpass.com.
									</MDTypography>
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>
				</Grid>
			) : (
				<Grid
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
					style={{ flexWrap: 'wrap' }}
				>
					<MDBox
						style={{
							backgroundColor: 'white',
							width: '100%',
							height: '100%',
							minHeight: '100vh',
							position: 'relative',
						}}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'flex-end'}
						flexDirection={'column'}
					>
						<Grid container justifyContent='' alignItems='center'>
							<MDAvatar
								variant='square'
								style={{
									marginTop: '1rem',
									marginLeft: '1rem',
									width: '18rem',
									height: 'auto',
								}}
								src={fpLogo}
								alt='Logo'
							/>
						</Grid>
						<MDBox
							style={{
								minWidth: '300px',
								maxWidth: '400px',
								minHeight: '520px',
								margin: '5px',
								backgroundColor: 'white',
							}}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
						>
							<MDBox pt={0} mt={0} textAlign='center'>
								<MDTypography
									variant='h3'
									fontWeight='medium'
									style={{ color: '##B6B6B6' }}
								>
									PÁGINA NO ENCONTRADA
								</MDTypography>
								<MDTypography variant='button' color='text' fontSize='1rem'>
									volver a{' '}
									<MDTypography
										component={Link}
										to='/login'
										variant='button'
										color='primary'
										fontWeight='medium'
										fontSize='1rem'
										textGradient
									>
										Inicio
									</MDTypography>
								</MDTypography>
							</MDBox>
						</MDBox>
						<MDBox
							style={{
								backgroundColor: '#333333',
								width: '100%',
								height: '100%',
								padding: '1rem',
							}}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'column'}
						>
							<Grid container justifyContent='center'>
								<Grid item xs={10} lg={8}>
									<MDBox display='flex' justifyContent='center' flexWrap='wrap' mb={2}>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Company
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												About Us
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 0, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Team
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Product
											</MDTypography>
										</MDBox>
										<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Blog
											</MDTypography>
										</MDBox>
										<MDBox>
											<MDTypography
												component='a'
												href='#'
												variant='body2'
												fontWeight='regular'
												color='secondary'
											>
												Pricing
											</MDTypography>
										</MDBox>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={8}>
									<MDBox display='flex' justifyContent='center' mt={1} mb={2}>
										<MDBox mr={3} color='secondary'>
											<FacebookIcon fontSize='small' />
										</MDBox>
										<MDBox mr={3} color='secondary'>
											<TwitterIcon fontSize='small' />
										</MDBox>
										<MDBox mr={3} color='secondary'>
											<InstagramIcon fontSize='small' />
										</MDBox>
										<MDBox mr={3} color='secondary'>
											<PinterestIcon fontSize='small' />
										</MDBox>
										<MDBox color='secondary'>
											<LinkedInIcon fontSize='small' />
										</MDBox>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={8} sx={{ textAlign: 'center' }}>
									<MDTypography variant='body2' color='secondary'>
										Copyright &copy; 2023 fullpass.com.
									</MDTypography>
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>
				</Grid>
				// ) : (
				// 	<MDBox pt={4} mt={5} textAlign='center'>
				// 		<MDTypography variant='h4' fontWeight='medium' color='text'>
				// 			PÁGINA NO ENCONTRADA
				// 		</MDTypography>
				// 		<MDTypography variant='button' color='text'>
				// 			volver a{' '}
				// 			<MDTypography
				// 				component={Link}
				// 				to='/login'
				// 				variant='button'
				// 				color='primary'
				// 				fontWeight='medium'
				// 				textGradient
				// 			>
				// 				Inicio
				// 			</MDTypography>
				// 		</MDTypography>
				// 	</MDBox>
			)}
		</MDBox>
	);
}

export default ConfirmPassword;
