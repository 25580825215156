import { Card, Grid, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import { deleteMetodoPago } from 'store/slices/user';
interface propsDeleteMetodoPagoModal {
	handleClose: Function;
	metodoPagoItemID: string | number;
}

const DeleteMedotoPagoModal = ({
	handleClose,
	metodoPagoItemID,
}: propsDeleteMetodoPagoModal) => {
	const dispatch = useDispatch();
	const deleteItem = () => {
		dispatch<any>(deleteMetodoPago(metodoPagoItemID, handleClose));
	};
	return (
		<>
			<MDBox
				// width={'100%'}

				position={'relative'}
				width={'90vw'}
				minWidth={'300px'}
				maxWidth={'360px'}
			>
				<Card style={{ backgroundColor: '#FDFDFD' }}>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<Grid
						container
						direction={'column'}
						justifyContent='center'
						alignItems='center'
						padding={4}
						textAlign={'center'}
					>
						<MDTypography mt={2} variant='h3' fontWeight={'bold'}>
							Eliminar método de pago
						</MDTypography>
						<MDTypography mt={2} variant='body2'>
							¿Estas seguro que deseas eliminar este método de pago?
						</MDTypography>
						<MDTypography mt={3} variant='body2'>
							Ya no podrás utilizar esta forma de pago para futuras compras a menos que
							lo des de alta de nuevo.
						</MDTypography>
						<MDBox
							style={{
								minWidth: '200px',
								width: '100%',
							}}
							mt={3}
						>
							<MDButton
								onClick={deleteItem}
								variant='contained'
								style={{
									backgroundColor: 'var(--colorPrimary)',
									width: '100%',
									textTransform: 'capitalize',
									fontWeight: '400',
									color: 'var(--backgroundBody)',
								}}
							>
								Aceptar
							</MDButton>
							<MDBox
								style={{
									minWidth: '200px',
									width: '100%',
								}}
								mt={2}
								mb={2}
							>
								<MDButton
									onClick={() => handleClose()}
									variant='contained'
									color='primary'
									style={{
										backgroundColor: 'var(--colorSecondary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: '400',
										color: 'var(--backgroundBody)',
									}}
								>
									Cancelar
								</MDButton>
							</MDBox>
						</MDBox>
					</Grid>
				</Card>
			</MDBox>
		</>
	);
};

export default DeleteMedotoPagoModal;
