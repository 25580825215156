import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SignUp from 'pantallas/autenticacion/sign-up/';
import LogIn from 'pantallas/autenticacion/sing-in';
import ResetPassword from 'pantallas/autenticacion/reset-password';
import ConfirmPassword from 'pantallas/autenticacion/confirmPassword';
import { RequireAuthToHidden } from './RequireAuthToHidden';
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { startChecking } from 'store/slices/auth';
// import { RootState } from 'store/store';
import CompradorDashboard from 'pantallas/dashboard/comprador/CompradorDashboard';
import EventoDesc from 'pantallas/eventos/EventoDesc';
import ScrollToTop from './ScrollToTop';
import { RequireAuth } from './RequireAuth';
import User from 'pantallas/user/comprador/User';
// import MySettings from 'pantallas/user/MySettings/MySettings';
import MyEvents from 'pantallas/user/comprador/MyEvents/MyEvents';
// import MyPerfil from 'pantallas/user/MyPerfil/MyPerfil';
import SearchEvents from 'pantallas/searchEvents/SearchEvents';
import EventoConfirm from 'pantallas/eventos/eventoConfirm/EventoConfirm';
import EventoSuccess from 'pantallas/eventos/eventoSuccess/EventoSuccess';
import MyEvent from 'pantallas/user/comprador/MyEvents/MyEvent/MyEvent';
import NextAndLastEvents from 'pantallas/user/comprador/MyEvents/NextAndLastEvents';
import MyPerfil from 'pantallas/user/comprador/MyPerfil/MyPerfil';
import MySettings from 'pantallas/user/comprador/MySettings/MySettings';
import Admin from 'pantallas/user/admin/Admin';
import Dashboard from 'pantallas/user/admin/dashboard/Dashboard';
import DashboardProducer from 'pantallas/user/productor/dashboard/Dashboard';
import Categories from 'pantallas/user/admin/categories/Categories';
import Venues from 'pantallas/user/admin/venues/Venues';
import Events from 'pantallas/user/admin/events/Events';
import Users from 'pantallas/user/admin/users/Users';
import Settings from 'pantallas/user/admin/settings/Settings';
import VenuesBusqueda from 'pantallas/user/admin/venues/venuesBusqueda/VenuesBusqueda';
import RequireAdmin from './RequireAdmin';
import RequireComprador from './RequireComprador';
import RequireProductor from './RequireProductor';
import Productor from 'pantallas/user/productor/Productor';
import MiPerfil from 'pantallas/user/productor/miPerfil/MiPerfil';
import MisEventos from 'pantallas/user/productor/misEventos/MisEventos';
import NewEvento from 'pantallas/user/productor/misEventos/components/NewEvento';
import EventoDescPrev from 'pantallas/eventos/EventoDescPrev';

const AppRouter = () => {
	const dispatch = useDispatch();
	// const { isLoading } = useSelector((state: RootState) => state.auth);

	//Efecto que renueva el token cada que se refesca o carga la pagina
	useEffect(() => {
		dispatch<any>(startChecking());
	}, [dispatch]);

	// if (isLoading) {
	// 	return (
	// 		<div>
	// 			<h1>Cargando...</h1>
	// 		</div>
	// 	);
	// }

	return (
		<>
			<Suspense fallback={<div>Loading...</div>}>
				<ScrollToTop />
				<Routes>
					<Route element={<RequireAuthToHidden />}>
						<Route path='/login/' element={<LogIn />} />
						<Route path='/signup/' element={<SignUp />} />
					</Route>
					{/* <Route element={<RequireAuthToHidden />}>
						<Route path='/login/' element={<LogIn />} />
					</Route> */}
					<Route element={<RequireAuth />}>
						<Route element={<RequireComprador />}>
							<Route path='/user/' element={<User />}>
								<Route index element={<MyPerfil />} />
								<Route path='myEvents/' element={<MyEvents />}>
									<Route index element={<NextAndLastEvents />} />
									<Route path=':myEventId/' element={<MyEvent />} />
								</Route>
								<Route path='settings' element={<MySettings />} />
							</Route>
						</Route>
						<Route element={<RequireAdmin />}>
							<Route path='/admin/' element={<Admin />}>
								<Route index element={<Dashboard />} />
								<Route path='dasboard/' element={<Dashboard />} />
								<Route path='categories' element={<Categories />} />
								<Route path='venues' element={<Venues />} />
								<Route path='venues/busqueda' element={<VenuesBusqueda />} />
								<Route path='events' element={<Events />} />
								<Route path='events/nuevo' element={<NewEvento />} />

								<Route path='users' element={<Users />} />
								<Route path='settings' element={<Settings />} />
							</Route>
						</Route>
						<Route element={<RequireProductor />}>
							<Route path='/producer/' element={<Productor />}>
								<Route index element={<DashboardProducer />} />
								<Route path='menu/' element={<DashboardProducer />} />
								<Route path='perfil' element={<MiPerfil />} />
								<Route path='events' element={<MisEventos />} />
								<Route
									path='events/busqueda'
									element={<h1>mis eventos busqueda- producer</h1>}
								/>
								<Route path='events/nuevo' element={<NewEvento />} />

								<Route path='users' element={<Users />} />
								<Route path='venues' element={<Venues />} />
								<Route path='venues/busqueda' element={<VenuesBusqueda />} />
								<Route path='settings' element={<MySettings />} />
							</Route>
						</Route>
						{/* <Route element={<RequireSuscriptionActive />}>
						<Route path='/' element={<Pages />}>
							{getRoutes(routes)}
							<Route path='*' element={<Navigate to='/' />} />
						</Route>
					</Route> */}
					</Route>

					{/* Rutas públicas */}
					{/* <Route path='/comprobante/pdf/:uuid' element={<ComprobantePDF />} />
				<Route path='/comprobante/xml/:uuid' element={<ComprobanteXml />} />
				<Route
					path='/comprobante/descarga/:uuid'
					element={<ComprobanteDescarga />}
				/> */}
					<Route path='/' element={<CompradorDashboard />} />
					<Route path='/resetPassword' element={<ResetPassword />} />
					<Route path='/recuperacion/:tkn' element={<ConfirmPassword />} />
					<Route path='/event/:eventId' element={<EventoDesc />} />
					<Route path='/event/prev' element={<EventoDescPrev />} />
					<Route path='/event/:eventId/confirm' element={<EventoConfirm />} />
					<Route path='/event/success' element={<EventoSuccess />} />

					<Route path='/search/' element={<SearchEvents />} />
					<Route path='/search/:searchParam' element={<SearchEvents />} />
					{/* <Route path='/payment/success' element={<PaymentSuccesful />} />
				<Route path='/payment/cancel' element={<PaymentCancel />} /> */}
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Suspense>
		</>
	);
};

export default AppRouter;
