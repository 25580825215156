import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import React, { useState } from 'react';
import EventIcon from '@mui/icons-material/Event';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { numeroAMoneda } from 'helpers/aMoneda';
import CounterTickets from './components/CounterTickets';
import { Divider } from '@mui/material';
import MDButton from 'components/MDButton';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';
import EventosDestacados from 'pantallas/dashboard/comprador/components/Eventos destacados/EventosDestacados';
import Footer from 'pantallas/footer/Footer';

const EventoDescPrev = () => {
	const [imgPortada, setImgPortada] = useState(
		'https://pixcuarhfiles.s3.amazonaws.com/fpPortada.png'
	);
	const initialEventDesc: any = [];

	const [eventDesc, setEventDesc] = useState(initialEventDesc);
	const [totalCompraValue, setTotalCompraValue] = useState(0);
	const [carritoValue, setCarritoValue] = useState<any>([]);
	return (
		<>
			<Navbar />
			<MDBox display={'flex'} flexDirection={'column'} width={'100%'} pt={11}>
				<MDBox
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					width={'100%'}
					mt={2}
					style={{
						backgroundColor: '#ef3024',
						width: '100%',
						height: '70px',
					}}
				>
					<MDTypography variant='body1' style={{ color: '#fff' }}>
						Esta es una previsualización de tu evento
					</MDTypography>
				</MDBox>
				<MDBox display={'flex'} mt={2} width={'100%'}>
					<div
						className='eventDescImgContainer'
						// style={{ width: '100%', minHeight: '170px', maxHeight: '480px' }}
					>
						<img
							// src={eventDesc.imgUrl}
							src={imgPortada}
							alt='CoverImg'
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								// borderRadius: '.5rem',
							}}
						/>
					</div>
				</MDBox>
			</MDBox>
			<MDBox
				display={'flex'}
				justifyContent={'center'}
				flexWrap={'wrap'}
				width={'100%'}
			>
				<div className='eventDescInfoSection'>
					<MDTypography variant={'h3'} mt={2}>
						{'eventDesc.nombre'}
					</MDTypography>
					<MDTypography variant={'body2'} mt={2}>
						{'eventDesc.descripcion'}
					</MDTypography>
					<MDTypography variant={'h5'} mt={1}>
						{/* <PlaceOutlinedIcon /> km 200, México 307, 77760 Tulum, Q.R. */}
						<PlaceOutlinedIcon /> {'eventDesc.Venue.nombre'}
					</MDTypography>
					<MDTypography variant={'h5'} mt={1}>
						{/* <EventIcon /> 23, 24 y 25 de Marzo */}
						<EventIcon /> {'eventDesc.fecha'}
					</MDTypography>
					<MDTypography variant={'h5'} mt={1}>
						<AccessTimeOutlinedIcon /> {'eventDesc.hora'} hrs
					</MDTypography>
				</div>
				<div>
					<div className='eventDescTicketSection'>
						<MDBox display={'flex'} flexDirection='column'>
							<MDBox
								display={'flex'}
								padding={2}
								paddingLeft={4}
								style={{
									backgroundColor: '#000000',
									color: '#ffffff',
									fontWeight: 'bold',
								}}
							>
								Boletos
							</MDBox>
							<MDBox
								pt={2}
								pb={3}
								pl={4}
								pr={4}
								display={'flex'}
								flexDirection={'column'}
							>
								<MDBox display={'flex'} flexDirection={'column'}></MDBox>
								<MDBox>
									<Divider style={{ backgroundColor: '#ef3024' }} />
								</MDBox>
								<MDBox display={'flex'} justifyContent={'space-between'}>
									<MDBox>
										<MDBox>
											<MDTypography variant={'h5'}>Total</MDTypography>
										</MDBox>
										<MDBox>
											<MDTypography variant={'body2'}>
												Descripción de la compra
											</MDTypography>
										</MDBox>
									</MDBox>
									<MDBox display={'flex'} alignItems={'flex-end'}>
										<MDTypography variant={'h5'}>
											{numeroAMoneda(totalCompraValue)}
										</MDTypography>
									</MDBox>
								</MDBox>
								<MDBox mt={4} mb={1}>
									<MDButton
										disabled={totalCompraValue === 0}
										variant='gradient'
										style={{ backgroundColor: '#EF3024', color: '#ffffff' }}
										fullWidth
										type='submit'
										onClick={() => alert('Previsualización')}
									>
										Comprar boletos
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</div>
				</div>
			</MDBox>
			<EventosDestacados />
			<Footer />
		</>
	);
};

export default EventoDescPrev;
