// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// formik components
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Images
import { Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import fpLogo from 'assets/images/fullpass.png';
import { restartPassword } from 'actions/Auth/auth';
import { useDispatch } from 'react-redux';
import FormFieldRadius from 'components/MDFormFieldRadius';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Swal from 'sweetalert2';

function SignUp(): JSX.Element {
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { email } = formField;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const submitForm = async (values: any, actions: any) => {
		dispatch<any>(restartPassword({ email: values.email }));

		// actions.resetForm();
		actions.setSubmitting(false);
		toast.info(
			`¡Si el correo existe en nuestro sistema, <br/> en unos minutos recibiras un email con las instrucciones para acceder!`
		);
		setTimeout(() => {
			navigate('/login');
		}, 5000);
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};

	return (
		<MDBox pt={4} mt={5}>
			<Grid
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				style={{ marginTop: '4.5rem', flexWrap: 'wrap' }}
			>
				<Card style={{ minWidth: '300px', maxWidth: '400px', margin: '5px' }}>
					{/* <MDBox
						variant='gradient'
						// bgColor='primary'
						borderRadius='lg'
						coloredShadow='success'
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign='center'
						style={{ backgroundColor: '#ef3024' }}
					> */}
					{/* <MDTypography variant='h4' fontWeight='medium' color='white'>
							Restablecer contraseña
						</MDTypography>
						<MDTypography display='block' variant='button' color='white'>
							Recibiras un correo con las instrucciones para restablecer tu contraseña
						</MDTypography> */}
					{/* </MDBox> */}
					<MDBox pt={4} pb={3} px={3}>
						<Grid container justifyContent='center' alignItems='center'>
							<MDAvatar
								variant='square'
								style={{ marginTop: '.5rem', width: '10rem' }}
								src={fpLogo}
								alt='Avatar'
							/>
						</Grid>
						<MDBox
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							mb={4}
							mt={2}
							// style={{ backgroundColor: 'teal' }}
						>
							<MDTypography variant='h4' fontWeight='medium' color='Black'>
								Restablecer contraseña
							</MDTypography>
							<MDTypography variant='body2' textAlign={'center'} color='Black'>
								Recibiras un correo con las instrucciones para restablecer tu contraseña
							</MDTypography>
						</MDBox>
						<Formik
							initialValues={initialValues}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, handleChange, isSubmitting }) => (
								<Form id={formId} autoComplete='off'>
									<MDBox mb={2}>
										<FormFieldRadius
											type={email.type}
											label={email.label}
											name={email.name}
											value={values.email}
											onChange={handleChange}
											placeholder={email.label}
											error={errors.email && touched.email}
											success={values.email.length > 0 && !errors.email}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>

									<MDBox mt={4} mb={1}>
										<MDButton
											variant='gradient'
											fullWidth
											type='submit'
											style={{ backgroundColor: '#EF3024', color: '#ffffff' }}
										>
											Enviar
										</MDButton>
									</MDBox>

									<MDBox mt={3} mb={1} textAlign='center'>
										<MDTypography variant='button' color='secondary'>
											¿Ya tienes una cuenta?{' '}
											<MDTypography
												component={Link}
												to='/login'
												variant='button'
												color='primary'
												fontWeight='medium'
												textGradient
											>
												Entrar
											</MDTypography>
										</MDTypography>
									</MDBox>
								</Form>
							)}
						</Formik>
					</MDBox>
				</Card>
			</Grid>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</MDBox>
	);
}

export default SignUp;
