import * as Yup from 'yup';
import checkout from './form';

const {
	formField: { userName, email, tel },
} = checkout;

const validations = [
	Yup.object().shape({
		[userName.name]: Yup.string().required(userName.errorMsg),
		[email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
		[tel.name]: Yup.number().required(tel.errorMsg).min(10, tel.invalidMsg),
	}),
];

export default validations;
