import MDBox from 'components/MDBox';
import fpLogo from 'assets/images/fullpass.svg';
import { Card, Grid, IconButton, Tooltip } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
// formik components
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import FormFieldRadius from 'components/MDFormFieldRadius';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { startLogIn } from 'store/slices/auth';
import { useEffect } from 'react';
import { cleanIsRegister } from 'store/slices/signup';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const SingIn = ({ handleClose, setOptionModal }: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isLoading, tipo_usuario } = useSelector(
		(state: RootState) => state.auth
	);
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { email, password } = formField;

	const submitForm = async (values: any, actions: any) => {
		await dispatch<any>(
			startLogIn(
				{
					email: values.email,
					password: values.password,
				},
				actions,
				handleClose
			)
		);
	};

	const handleSubmit = async (values?: any, actions?: any) => {
		submitForm(values, actions);
	};
	useEffect(() => {
		// actions.
		dispatch<any>(cleanIsRegister());
	}, [dispatch]);

	useEffect(() => {
		if (tipo_usuario === 'ADMINISTRADOR') {
			navigate('/admin');
		}
		if (tipo_usuario === 'PRODUCTOR') {
			navigate('/producer');
		}
	}, [tipo_usuario]);

	return (
		<>
			{/* <CoverLayout image={bgImage}> */}
			{/* <MDBox
				display={'flex'}
				alignItems={'center'}
				height={'100vh'}
				justifyContent={'center'}
				padding={5}
			> */}
			<MDBox
				// width={'100%'}
				width={'380px'}
				maxWidth={'400px'}
				// style={{ backgroundColor: 'tomato' }}
			>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<Grid
						container
						direction={'column'}
						justifyContent='center'
						alignItems='center'
						px={3}
					>
						<MDTypography mt={7} variant='h4' fontWeight={'bold'}>
							Iniciar sesión
						</MDTypography>
						<MDTypography variant='body2' fontWeight={'light'} textAlign={'center'}>
							Ingresa tu correo y contraseña para iniciar sesión y acceder a los
							mejores eventos.
						</MDTypography>
					</Grid>
					<MDBox pt={5} pb={3} px={3}>
						<Formik
							initialValues={initialValues}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}

							// onSubmit={(e: any, values: any) => {
							// 	handleSubmit(values);
							// }}
						>
							{({ values, errors, touched, handleChange, isSubmitting }) => (
								<Form id={formId} autoComplete='off'>
									<MDBox mb={2}>
										<FormFieldRadius
											type={email.type}
											label={email.label}
											name={email.name}
											value={values.email}
											onChange={handleChange}
											placeholder={email.label}
											error={errors.email && touched.email}
											success={values.email.length > 0 && !errors.email}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
									<MDBox mb={2}>
										<FormFieldRadius
											type={password.type}
											label={password.label}
											name={password.name}
											value={values.password}
											onChange={handleChange}
											placeholder={password.label}
											error={errors.password && touched.password}
											success={values.password.length > 0 && !errors.password}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
									{isLoading && (
										<MDBox display={'flex'} justifyContent={'center'} m={0} p={0}>
											<span
												style={{
													fontSize: '1rem',
													color: 'var(--colorVerde)',
												}}
											>
												<CircularProgress color='success' size={12} /> Ingresando...
											</span>
										</MDBox>
									)}
									<MDBox mt={4} mb={1}>
										<MDButton
											disabled={isLoading}
											variant='gradient'
											style={{
												backgroundColor: '#EF3024',
												color: '#ffffff',
												textTransform: 'capitalize',
											}}
											fullWidth
											type='submit'
										>
											Entrar
										</MDButton>
									</MDBox>
									<MDBox
										mt={3}
										mb={1}
										textAlign='center'
										style={{ display: 'flex', flexDirection: 'column' }}
									>
										<MDTypography variant='button' color='text'>
											¿Eres nuevo en FullPass?{' '}
											<MDTypography
												component={IconButton}
												variant='button'
												color='primary'
												fontWeight='medium'
												textGradient
												onClick={() => {
													setOptionModal(2);
												}}
											>
												Crear una cuenta
											</MDTypography>
										</MDTypography>
										<MDTypography
											component={IconButton}
											variant='button'
											color='secondary'
											fontWeight='medium'
											textGradient
											onClick={() => {
												setOptionModal(3);
											}}
										>
											¿Olvidaste tu contraseña?
										</MDTypography>
									</MDBox>
								</Form>
							)}
						</Formik>
					</MDBox>
				</Card>
				<ToastContainer
					position='top-center'
					autoClose={3000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</MDBox>
			{/* </MDBox> */}
			{/* </CoverLayout> */}
		</>
	);
};

export default SingIn;
