import MDTypography from 'components/MDTypography';

import MyEventsList from './MyEventsList/MyEventsList';
import { useEffect, useState } from 'react';
import { getMyEvents } from 'store/slices/events';
import moment from 'moment';
import MDBox from 'components/MDBox';

const NextAndLastEvents = () => {
	const [myEventsArray, setmyEventsArray] = useState([]);
	const [myLastEventsArray, setmyLastEventsArray] = useState([]);

	// const isDateLessToday = (date: any) => {
	// 	const hoy = moment().format('DD/MM/YYYY');
	// 	const today = moment(hoy, 'DD/MM/YYYY');
	// 	const dateToCompare = moment(date, 'DD/MM/YYYY');

	// 	const dateDiff = dateToCompare.diff(today, 'days');

	// 	if (dateDiff < 0) return true;
	// 	else return false;
	// };
	const isDateLessToday = (date: any) => {
		const hoy = moment().format('YYYY-MM-DD');
		const today = moment(hoy, 'YYYY-MM-DD');
		const dateToCompare = moment(date, 'YYYY-MM-DD');
		console.log('hoy', hoy);
		console.log('today', today);
		console.log('dateToCompare', dateToCompare);
		const dateDiff = dateToCompare.diff(today, 'days');
		console.log('diferencia de dias', dateDiff);
		if (dateDiff < 0) return true;
		else return false;
	};

	useEffect(() => {
		console.log();
		const getEvents = getMyEvents();
		getEvents
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('E.MyEvents::', respuesta.data.data);

					const nextEvents = respuesta.data.data.filter(
						(item: any) => isDateLessToday(item.Evento.fecha) === false
					);
					const lastEvents = respuesta.data.data.filter(
						(item: any) => isDateLessToday(item.Evento.fecha) === true
					);

					setmyEventsArray(nextEvents);
					setmyLastEventsArray(lastEvents);
				}
			})
			.catch((error) => {
				console.log(error);
				setmyEventsArray([]);
				setmyLastEventsArray([]);
			});
	}, []);

	return (
		<>
			<MDTypography variant={'h3'} ml={2}>
				Mis eventos
			</MDTypography>
			{myEventsArray.length <= 0 && (
				<MDBox
					display={'flex'}
					width={'100%'}
					justifyContent={'center'}
					alignItems={'center'}
					mt={5}
				>
					<MDTypography color={'secondary'} variant={'h3'}>
						Aún no tienes eventos
					</MDTypography>
				</MDBox>
			)}
			{myEventsArray.length > 0 && (
				<MyEventsList myEventsArray={myEventsArray} type={'NextEvents'} />
			)}
			{myLastEventsArray.length > 0 && (
				<MyEventsList myEventsArray={myLastEventsArray} type={'LastEvents'} />
			)}
		</>
	);
};

export default NextAndLastEvents;
