import { createSlice } from '@reduxjs/toolkit';

export interface authState {
	isLoading: boolean;
	isAutenticated: boolean;
	id: number;
	nombre: string;
	email: string;
	tipo_usuario: string;
	telefono: string;
	genero?: string;
	codigo_telefono: string;
	codigo_postal: string;
}
const initialState: authState = {
	isLoading: false,
	isAutenticated: false,
	codigo_postal: '',
	codigo_telefono: '',
	id: null,
	email: '',
	nombre: '',
	telefono: '',
	tipo_usuario: '',
	genero: '',
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		startLoadingAuth: (state /* action */) => {
			state.isLoading = true;
		},
		endLoadingAuth: (state /* action */) => {
			state.isLoading = false;
		},
		authSuccess: (state, action) => {
			state.isLoading = false;
			state.isAutenticated = true;
			state.codigo_postal = action.payload.codigo_postal;
			state.codigo_telefono = action.payload.codigo_telefono;
			state.genero = action.payload.genero;
			state.id = action.payload.id;
			state.email = action.payload.email;
			state.nombre = action.payload.nombre;
			state.telefono = action.payload.telefono;
			state.tipo_usuario = action.payload.tipo_usuario;
		},
		authLogout: (state /* action */) => {
			state.isLoading = false;
			state.isAutenticated = false;
			state.codigo_postal = initialState.codigo_postal;
			state.codigo_telefono = initialState.codigo_telefono;
			state.genero = initialState.genero;
			state.id = initialState.id;
			state.email = initialState.email;
			state.nombre = initialState.nombre;
			state.telefono = initialState.telefono;
			state.tipo_usuario = initialState.tipo_usuario;
		},
	},
});

// Action creators are generated for each case reducer function
export const { startLoadingAuth, endLoadingAuth, authSuccess, authLogout } =
	authSlice.actions;
