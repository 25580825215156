import React, { useEffect, useState } from 'react';
import Basic from '../basic';
import MDBox from 'components/MDBox';
import { toast } from 'react-toastify';
import MDButton from 'components/MDButton';
import {
	getActiveEvents,
	getBanerDestacado,
	getEvents,
	postBanerDestacado,
} from 'store/slices/events';

const BannerEventosDestacados = () => {
	const [isCardOpen, setIsCardOpen] = useState(false);
	const [eventsArray, setEventsArray] = useState([]);
	const [datesValues, setDatesValues] = useState<any>({
		baner1Date: '---/---/--',
		baner2Date: '---/---/--',
		baner3Date: '---/---/--',
		baner4Date: '---/---/--',
		baner5Date: '---/---/--',
		baner6Date: '---/---/--',
	});
	const banerListInitialState: any = {
		baner1: '',
		baner2: '',
		baner3: '',
		baner4: '',
		baner5: '',
		baner6: '',
	};
	const [banerList, setBanerList] = useState<any>(banerListInitialState);
	//handleInputChange
	const handleInputChange = (event: any) => {
		setBanerList({
			...banerList,
			[event.target.name]: event.target.value,
		});
	};
	const { baner1, baner2, baner3, baner4, baner5, baner6 } = banerList;
	//Uso de hook useForm para manejo de campos en el formulario
	// const [formValues, handleInputChange] = useForm(banerList);
	// const { baner1, baner2, baner3, baner4, baner5, baner6 } = formValues;

	const takeDate = (baner: string | number, banerDate: string) => {
		if (baner) {
			const date = eventsArray.find((element) => element.id === Number(baner));
			if (date) {
				setDatesValues({
					...datesValues,
					[banerDate]: date.fecha ? date.fecha : 'test',
				});
			}
		} else if (baner === '')
			setDatesValues({ ...datesValues, [banerDate]: '---/---/--' });
	};

	useEffect(() => {
		if (baner1) {
			if (baner1 === '100') {
				setDatesValues({ ...datesValues, baner1Date: 'Indefinido' });
			} else {
				const date = eventsArray.find((element) => element.id === Number(baner1));
				if (date) {
					setDatesValues({
						...datesValues,
						baner1Date: date.fecha ? date.fecha : 'test',
					});
				}
			}
		} else if (baner1 === '')
			setDatesValues({ ...datesValues, baner1Date: '---/---/--' });
	}, [baner1]);
	useEffect(() => {
		takeDate(baner2, 'baner2Date');
	}, [baner2]);
	useEffect(() => {
		takeDate(baner3, 'baner3Date');
	}, [baner3]);
	useEffect(() => {
		takeDate(baner4, 'baner4Date');
	}, [baner4]);
	useEffect(() => {
		takeDate(baner5, 'baner5Date');
	}, [baner5]);
	useEffect(() => {
		takeDate(baner6, 'baner6Date');
	}, [baner6]);

	const sendBanerEventosDestacados = (): void => {
		if (baner1 === '') {
			toast.error('Baner 1 no puede estár vacío');

			return;
		} else {
			const banerDestacadosPost = postBanerDestacado([
				{
					posicion: 'Banner1',
					id_evento: baner1,
				},
				{
					posicion: 'Banner2',
					id_evento: baner2,
				},
				{
					posicion: 'Banner3',
					id_evento: baner3,
				},
				{
					posicion: 'Banner4',
					id_evento: baner4,
				},
				{
					posicion: 'Banner5',
					id_evento: baner5,
				},
				{
					posicion: 'Banner6',
					id_evento: baner6,
				},
			]);
			banerDestacadosPost
				.then((respuesta) => {
					if (respuesta.status === 201) {
						toast.success(respuesta.data.mensaje);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	const cancelarBanerDestacados = (): void => {
		setIsCardOpen(false);
		banerDestacadosGet();
	};

	const banerDestacadosGet = (): void => {
		const getBanerPrinc = getBanerDestacado();
		getBanerPrinc
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('respuestabannerDestacadis123::::', respuesta.data.data);
					// setEventsArray(respuesta.data.data);
					setBanerList({
						baner1: respuesta.data.data[0]
							? respuesta.data.data[0].id_evento.toString()
							: '',
						baner2: respuesta.data.data[1]
							? respuesta.data.data[1].id_evento.toString()
							: '',
						baner3: respuesta.data.data[2]
							? respuesta.data.data[2].id_evento.toString()
							: '',
						baner4: respuesta.data.data[3]
							? respuesta.data.data[3].id_evento.toString()
							: '',
						baner5: respuesta.data.data[4]
							? respuesta.data.data[4].id_evento.toString()
							: '',
						baner6: respuesta.data.data[5]
							? respuesta.data.data[5].id_evento.toString()
							: '',
					});

					setDatesValues({
						baner1Date: respuesta.data.data[0]
							? respuesta.data.data[0].Evento.fecha
							: '',
						baner2Date: respuesta.data.data[1]
							? respuesta.data.data[1].Evento.fecha
							: '',
						baner3Date: respuesta.data.data[2]
							? respuesta.data.data[2].Evento.fecha
							: '',
						baner4Date: respuesta.data.data[3]
							? respuesta.data.data[3].Evento.fecha
							: '',
						baner5Date: respuesta.data.data[4]
							? respuesta.data.data[4].Evento.fecha
							: '',
						baner6Date: respuesta.data.data[5]
							? respuesta.data.data[5].Evento.fecha
							: '',
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	//Efecto que obtiene array de BanerEventosDestacados
	useEffect(() => {
		banerDestacadosGet();
	}, [eventsArray]);
	//Effecto que obtitne todos los eventos.
	useEffect(() => {
		// const getEventos = getEvents();
		const getEventos = getActiveEvents();
		getEventos
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setEventsArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<>
			<Basic
				tittle={'Eventos destacados'}
				isCardOpen={isCardOpen}
				setIsCardOpen={setIsCardOpen}
				content={
					<MDBox
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
						width={'100%'}
					>
						<div className='fpTableContainer'>
							<MDBox
								p={1}
								display={'flex'}
								justifyContent={'space-between'}
								style={{ backgroundColor: '#F9F9F9' }}
							>
								<MDBox
									display={'flex'}
									style={{ minWidth: '230px', backgroundColor: '#F9F9F9' }}
								>
									Posición
								</MDBox>
								<MDBox
									display={'flex'}
									style={{ minWidth: '230px', backgroundColor: '#F9F9F9' }}
								>
									Eventos
								</MDBox>
								<MDBox
									display={'flex'}
									width={'230px'}
									pl={2}
									style={{
										minWidth: '230px',
										backgroundColor: '#F9F9F9',
									}}
								>
									Fecha evento
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 1
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner1'
											value={baner1}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											<option value={100}>Default</option>
											{eventsArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{datesValues.baner1Date}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 2
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner2'
											value={baner2}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{eventsArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{datesValues.baner2Date}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 3
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner3'
											value={baner3}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{eventsArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{datesValues.baner3Date}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 4
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner4'
											value={baner4}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{eventsArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{datesValues.baner4Date}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 5
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner5'
											value={baner5}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{eventsArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{datesValues.baner5Date}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 6
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner6'
											value={baner6}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{eventsArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{datesValues.baner6Date}
								</MDBox>
							</MDBox>
						</div>
						<MDBox
							mt={5}
							display={'flex'}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
							width={'100%'}

							// alignItems={'center'}
						>
							<MDBox
								style={{
									minWidth: '100px',
									width: '48%',
								}}
							>
								<MDButton
									variant='contained'
									type='button'
									// color='error'
									onClick={sendBanerEventosDestacados}
									style={{
										backgroundColor: 'var(--colorPrimary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'bold',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Guardar
								</MDButton>
							</MDBox>
							<MDBox
								style={{
									minWidth: '100px',
									width: '48%',
								}}
							>
								<MDButton
									onClick={cancelarBanerDestacados}
									type='button'
									variant='contained'
									color='primary'
									style={{
										backgroundColor: 'var(--colorSecondary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'normal',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Cancelar
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				}
			/>
		</>
	);
};

export default BannerEventosDestacados;
