import MDBox from 'components/MDBox';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';
import Sidebar from 'pantallas/SideBar/Sidebar';
import './User.css';

import { Outlet } from 'react-router';
import Footer from 'pantallas/footer/Footer';

const User = () => {
	return (
		<>
			<Navbar />
			<MDBox
				display={'flex'}
				width={'100%'}
				minHeight={'100vh'}
				pt={11}
				style={{ backgroundColor: 'var(--backgroundBody)' }}
			>
				<div className='fpSidebarContainer'>
					<Sidebar />
				</div>

				<Outlet />
			</MDBox>
			<Footer />
		</>
	);
};

export default User;
