import { useState, useEffect, useMemo } from 'react';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 PRO React TS themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from 'context';

import AppRouter from 'routes/AppRouter';

export default function App() {
	const [controller] = useMaterialUIController();
	const { direction } = controller;
	const [rtlCache, setRtlCache] = useState(null);

	// Cache for the rtl
	useMemo(() => {
		const pluginRtl: any = rtlPlugin;
		const cacheRtl = createCache({
			key: 'rtl',
			stylisPlugins: [pluginRtl],
		});

		setRtlCache(cacheRtl);
	}, []);

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute('dir', direction);
	}, [direction]);

	return direction === 'rtl' ? (
		<CacheProvider value={rtlCache}>
			<ThemeProvider theme={themeRTL}>
				<CssBaseline />
				<AppRouter />
			</ThemeProvider>
		</CacheProvider>
	) : (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AppRouter />
		</ThemeProvider>
	);
}
