import { Icon } from '@mui/material';
import MDBox from 'components/MDBox';

const SearchBar = () => {
	return (
		<>
			<MDBox display={'flex'} width={'100%'} justifyContent={'center'} p={2}>
				<div className='navbarSearch2'>
					<MDBox display={'flex'} justifyContent={'center'}>
						<input
							type='text'
							placeholder='Buscar evento'
							className='inputTransparent2'
						/>
						<button id='inputSearchBtn'>
							<Icon>search</Icon>
						</button>
					</MDBox>
				</div>
			</MDBox>
		</>
	);
};

export default SearchBar;
