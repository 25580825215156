import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import CardInfo from './CardInfo';
import './CardInfoSection.css';
import { getInformacionDashboardConteo } from 'store/slices/events';
import { useEffect, useState } from 'react';

const CardInfoSection = () => {
	const infoDashboardInitialState = {
		eventos: 0,
		productores: 0,
		ventas: {
			anio: '',
			mes: '',
			totalVentas: 0,
		},
		venues: 0,
	};
	const [infoDashboard, setInfoDashboard] = useState(infoDashboardInitialState);
	//Effecto que obtitne todos los eventos.
	useEffect(() => {
		const getEventos = getInformacionDashboardConteo();
		getEventos
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data[0]);
					setInfoDashboard(respuesta.data.data[0] ? respuesta.data.data[0] : {});
				}
			})
			.catch((error) => {
				console.log(error);
				setInfoDashboard(infoDashboardInitialState);
			});
	}, []);
	return (
		<div
			className='cardInfoSectionContainer'
			// justifyContent={'space-between'}
			// flexWrap={'wrap'}
			// width={'100%'}
		>
			<CardInfo
				tittle={
					infoDashboard.ventas.totalVentas
						? '$' + infoDashboard.ventas.totalVentas.toLocaleString()
						: '$' + '0'
				}
				subtittle={'Ventas'}
				body={`${infoDashboard.ventas.mes}/${infoDashboard.ventas.anio}`}
			/>
			<CardInfo
				tittle={infoDashboard.eventos ? infoDashboard.eventos : '0'}
				subtittle={'Eventos'}
				body={'Activos'}
			/>
			<CardInfo
				tittle={infoDashboard.productores ? infoDashboard.productores : '0'}
				subtittle={'Productores'}
				body={'Activos'}
			/>
			<CardInfo
				tittle={infoDashboard.venues ? infoDashboard.venues : '0'}
				subtittle={'Venues'}
				body={'Activos'}
			/>

			{/* <CategoriaItem img={category1} text={'CONCIERTOS'} />
    <CategoriaItem img={category2} text={'FESTIVALES'} />
    <CategoriaItem img={category3} text={'TEATRO'} />
    <CategoriaItem img={category4} text={'STAND UP'} /> */}
		</div>
	);
};

export default CardInfoSection;
