import checkout from './form';

const {
	formField: { email },
} = checkout;

const initialValues = {
	[email.name]: '',
};

export default initialValues;
