import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import form from './schemas/form';
import validations from './schemas/validations';
import { ToastContainer, toast } from 'react-toastify';
import FormFieldRadius from 'components/MDFormFieldRadius';
import MDButton from 'components/MDButton';
import editIcon from 'assets/images/icons/EditRed.svg';
import alarmRed from 'assets/images/icons/alarmRed.svg';
import './MiPerfil.css';
import { Modal, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import DatosBancariosModal from './components/DatosBancariosModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getMyPerfilData, patchMyPerfilData } from 'store/slices/user';

const MiPerfil = () => {
	const { id } = useSelector((state: RootState) => state.auth);
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { userName, email, companyName, countryCode, tel } = formField;
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => setOpenModal(false);
	const handleOpen = () => setOpenModal(true);
	const [optionModal, setOptionModal] = useState<number>(1);
	const [gettingSetsData, setGettingSetsData] = useState(false);
	const [miPerfilData, setMiPerfilData] = useState<any>(initialValues);
	const [miBankData, setMiBankData] = useState<any>({
		bank: '',
		titularName: '',
		accountNumber: '',
		accountClabe: '',
	});

	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<DatosBancariosModal
						handleClose={handleCloseModal}
						actionType={'Add'}
						datosBancariosToEdit={miBankData}
						datosBancariosGet={getMiperfilData}
						userId={id}
					/>
				);
			case 2:
				return (
					<DatosBancariosModal
						handleClose={handleCloseModal}
						actionType={'Edit'}
						// categoryToEdit={categoryToEdit}
						// categoriesGet={categoriesGet}
					/>
				);

			default:
				return;
		}
	};
	const editDatosBancarios = () => {
		setOptionModal(1);
		handleOpen();
	};

	const submitForm = async (values: any, actions: any) => {
		const patchPerfilData = patchMyPerfilData(id, {
			nombre: values.userName,
			email: values.email,
			nombre_empresa: values.companyName,
			codigo_telefono: values.countryCode,
			telefono: values.tel,
		});
		patchPerfilData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('Respuesta patch perfil-200', respuesta);
					getMiperfilData();
					toast.success(respuesta.data.mensaje);
				}
			})
			.catch((error) => {
				console.log('error', error);
				toast.error(error.message);
			});

		actions.setSubmitting(false);
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	const getMiperfilData = () => {
		const myPerfilData = getMyPerfilData(id);
		myPerfilData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('Mi perfil data::', respuesta.data.data);
					const initialPerfiValues = {
						userName: respuesta.data.data.nombre ? respuesta.data.data.nombre : '',
						email: respuesta.data.data.email,
						companyName: respuesta.data.data.nombre_empresa
							? respuesta.data.data.nombre_empresa
							: '',
						countryCode: respuesta.data.data.codigo_telefono,
						tel: respuesta.data.data.telefono,
					};
					setMiPerfilData(initialPerfiValues);

					const intialBankValues = {
						bank: respuesta.data.data.datos_bancarios[0]
							? respuesta.data.data.datos_bancarios[0].banco
							: 'Banco',
						titularName: respuesta.data.data.datos_bancarios[0]
							? respuesta.data.data.datos_bancarios[0].nombre_titular
							: 'nombre titular',
						accountNumber: respuesta.data.data.datos_bancarios[0]
							? respuesta.data.data.datos_bancarios[0].cuenta_bancaria
							: '0000',
						accountClabe: respuesta.data.data.datos_bancarios[0]
							? respuesta.data.data.datos_bancarios[0].cuenta_clabe
							: '0000',
					};
					setMiBankData(intialBankValues);
					setGettingSetsData(true);
				}
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	useEffect(() => {
		if (id) {
			getMiperfilData();
		}
		// eslint-disable-next-line
	}, [id, gettingSetsData]);

	const getFormik = () => {
		return (
			<Formik
				initialValues={miPerfilData}
				validationSchema={currentValidation}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched, handleChange, isSubmitting }) => (
					<div className='miPerfilContent'>
						<Form
							id={formId}
							autoComplete='off'
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							<div>
								<MDBox>
									<MDTypography
										style={{ margin: '0rem 1rem 0rem 1rem' }}
										mt={2}
										variant={'h4'}
									>
										{userName.label}
									</MDTypography>
									<MDBox style={{ margin: '0rem 0rem 0rem 1rem' }}>
										<FormFieldRadius
											type={userName.type}
											label={userName.label}
											name={userName.name}
											value={values.userName}
											onChange={handleChange}
											placeholder={userName.label}
											error={errors.userName && touched.userName}
											success={values.userName.length > 0 && !errors.userName}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '100%' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox>
									<MDTypography
										style={{ margin: '0rem 1rem 0rem 1rem' }}
										mt={2}
										variant={'h4'}
									>
										{email.label}
									</MDTypography>
									<MDBox style={{ margin: '0rem 0rem 0rem 1rem' }}>
										<FormFieldRadius
											// disabled
											type={email.type}
											label={email.label}
											name={email.name}
											value={values.email}
											onChange={handleChange}
											placeholder={email.label}
											error={errors.email && touched.email}
											success={values.email.length > 0 && !errors.email}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '100%' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox>
									<MDTypography
										style={{ margin: '0rem 1rem 0rem 1rem' }}
										mt={2}
										variant={'h4'}
									>
										{companyName.label}
									</MDTypography>
									<MDBox style={{ margin: '0rem 0rem 0rem 1rem' }}>
										<FormFieldRadius
											type={companyName.type}
											label={companyName.label}
											name={companyName.name}
											value={values.companyName}
											onChange={handleChange}
											placeholder={companyName.label}
											error={errors.companyName && touched.companyName}
											success={values.companyName.length > 0 && !errors.companyName}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '100%' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox>
									<MDTypography
										style={{ margin: '0rem 1rem 0rem 1rem' }}
										mt={2}
										variant={'h4'}
									>
										{tel.label}
									</MDTypography>
									<MDBox
										display={'flex'}
										flexWrap={'wrap'}
										style={{ margin: '0rem 0rem 0rem 1rem' }}
									>
										<FormFieldRadius
											type={countryCode.type}
											label={countryCode.label}
											name={countryCode.name}
											value={values.countryCode}
											onChange={handleChange}
											placeholder={'+52'}
											// error={errors.tel && touched.tel}
											// success={values.tel.length > 0 && !errors.tel}
											style={{ width: '3rem', marginRight: '1rem' }}
										/>
										<FormFieldRadius
											type={tel.type}
											label={tel.label}
											name={tel.name}
											value={values.tel}
											onChange={handleChange}
											placeholder={tel.label}
											error={errors.tel && touched.tel}
											success={values.tel.length > 0 && !errors.tel}
											style={{ width: '9rem', marginLeft: '.5rem' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox mb={3} style={{ margin: '1.5rem 1rem 0rem 1rem' }}>
									<div className='miPerfilContentCard'>
										<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
											<MDBox display={'flex'} justifyContent={'space-between'}>
												<MDTypography variant={'h4'}>Datos bancarios</MDTypography>

												<MDButton type='button' onClick={editDatosBancarios}>
													<Tooltip title='Editar'>
														<img width={'26px'} src={editIcon} alt='Editar' />
													</Tooltip>
												</MDButton>
											</MDBox>
											<MDBox display={'flex'} mb={2}>
												<MDBox mr={1}>
													<img width={'20px'} src={alarmRed} alt='alert' />
												</MDBox>

												<MDTypography variant={'body2'} style={{ color: 'red' }}>
													Datos pendientes de aprobación
												</MDTypography>
											</MDBox>
											<MDBox mb={3} display={'flex'} width={'100%'} flexWrap={'wrap'}>
												<MDBox width={'30%'}>
													<MDTypography style={{ fontWeight: 'bold' }}>Banco</MDTypography>
												</MDBox>
												<MDBox width={'70%'}>
													<MDTypography variant={'body2'}>
														{miBankData.bank ? miBankData.bank : 'Banco'}
													</MDTypography>
												</MDBox>
											</MDBox>
											<MDBox mb={3} display={'flex'} width={'100%'} flexWrap={'wrap'}>
												<MDBox width={'30%'}>
													<MDTypography style={{ fontWeight: 'bold' }}>
														Nombre titular
													</MDTypography>
												</MDBox>
												<MDBox width={'70%'}>
													<MDTypography variant={'body2'}>
														{miBankData.titularName
															? miBankData.titularName
															: 'Nombre del titular de la cuenta'}
													</MDTypography>
												</MDBox>
											</MDBox>
											<MDBox mb={3} display={'flex'} width={'100%'} flexWrap={'wrap'}>
												<MDBox width={'30%'}>
													<MDTypography style={{ fontWeight: 'bold' }}>
														Cuenta bancaria
													</MDTypography>
												</MDBox>
												<MDBox width={'70%'}>
													<MDTypography variant={'body2'}>
														{miBankData.accountNumber ? (
															// ? miBankData.accountNumber
															<MDTypography variant={'body2'}>
																XXXX XXXX XXXX{' '}
																{miBankData.accountNumber.substr(
																	miBankData.accountNumber.length - 4
																)}
															</MDTypography>
														) : (
															'**** **** **** 000002'
														)}
													</MDTypography>
												</MDBox>
											</MDBox>
											<MDBox mb={3} display={'flex'} width={'100%'} flexWrap={'wrap'}>
												<MDBox width={'30%'}>
													<MDTypography style={{ fontWeight: 'bold' }}>
														Cuenta CLABE
													</MDTypography>
												</MDBox>
												<MDBox width={'70%'}>
													<MDTypography variant={'body2'}>
														{miBankData.accountClabe ? (
															<MDTypography variant={'body2'}>
																XXXX XXXX XXXX{' '}
																{miBankData.accountClabe.substr(
																	miBankData.accountClabe.length - 4
																)}
															</MDTypography>
														) : (
															'** **** **** **** 00000'
														)}
													</MDTypography>
												</MDBox>
											</MDBox>
										</MDBox>
									</div>
								</MDBox>
								<MDBox mb={3} style={{ margin: '2rem 1rem 0rem 1rem' }}>
									<MDButton
										disabled={isSubmitting}
										type='submit'
										variant='gradient'
										color='error'
										size='large'
										style={{
											padding: '1rem',
											width: '100%',
											textTransform: 'capitalize',
											fontSize: '18px',
										}}
									>
										Guardar
									</MDButton>
								</MDBox>
							</div>
						</Form>
					</div>
				)}
			</Formik>
		);
	};

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox display={'flex'} width={'100%'}>
					<MDTypography
						variant={'h3'}
						// style={{ margin: '0rem 1rem 1rem 1rem' }}
					>
						Mi Perfil
					</MDTypography>
				</MDBox>

				{gettingSetsData && getFormik()}
				{!gettingSetsData && getFormik()}
			</MDBox>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default MiPerfil;
