import MDButton from 'components/MDButton';
import { Icon } from '@mui/material';
import './CarouselItem.css';
import { Link } from 'react-router-dom';

interface props {
	id: number | string;
	img: any;
	tittle: string;
	venue: string;
	date: string;
}
const CarouselItem = ({ id, img, tittle, venue, date }: props) => {
	return (
		<>
			<div className='carouselContenedor'>
				<div
					style={{
						display: 'flex',
						marginBottom: '2rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							zIndex: '500',
							marginLeft: '2rem',
							alignItems: 'flex-start',
						}}
					>
						<div>
							<p className='carouselTittle'>{tittle}</p>
						</div>
						<div className='displayFlex carouselSubtittle alignItemsCenter'>
							<Icon> roomOutlinedIcon</Icon>
							<p>{venue}</p>
						</div>
						<div className='displayFlex carouselSubtittle2 alignItemsCenter'>
							<Icon>event</Icon>
							<p>{date}</p>
						</div>
						<MDButton
							// onClick={startLogOut}
							component={Link}
							to={`/event/${id}`}
							id='carouselBtn'
						>
							Comprar boletos
						</MDButton>
					</div>
				</div>
			</div>
			<div className='carouselImg'>
				<img
					src={img}
					alt='CoverImg'
					loading='lazy'
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						// borderRadius: '.5rem',
					}}
				/>
			</div>
		</>
	);
};

export default CarouselItem;
