import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { authLogout } from 'store/slices/auth';

const MetodoPagoBtn = ({ metodosPago, setMetodoPagoSelected }: any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const setMetodoPago = (item: any) => {
		handleClose();
		setMetodoPagoSelected(item);
	};

	return (
		<>
			<Tooltip title='Cambiar metodo pago'>
				<button
					onClick={handleClick}
					// className={open ? 'inputNavBarBtn inputNavBarBtnOpen  ' : 'inputNavBarBtn'}
					className='inputMetodoPagoBtn'
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: '10px',
					}}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
				>
					<ModeEditOutlineOutlinedIcon />
				</button>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{metodosPago.map((item: any) => (
					<div key={item.id}>
						<MenuItem key={item.id} onClick={() => setMetodoPago(item)}>
							<ListItemIcon>
								<CreditCardIcon fontSize='small' />
							</ListItemIcon>
							xxxx xxxx xxxx{' '}
							{item.numero_tarjeta.substr(item.numero_tarjeta.length - 4)}
						</MenuItem>
						<Divider />
					</div>
				))}
			</Menu>
		</>
	);
};

export default MetodoPagoBtn;
