// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';

// // Material Dashboard 2 PRO React TSUI Dashboard PRO helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { inputBorderColor, info, grey, transparent } = colors;
const { borderRadius } = borders;
const { size } = typography;

// types
type Types = any;

const inputOutlined: Types = {
	styleOverrides: {
		root: {
			backgroundColor: transparent.main,
			fontSize: size.sm,
			borderRadius: borderRadius.md,

			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: inputBorderColor,
			},

			'&.Mui-focused': {
				'& .MuiOutlinedInput-notchedOutline': {
					// borderColor: infoInput.main,
					borderColor: info.main,
					// borderColor: '#B6B6B6',
				},
			},
		},

		notchedOutline: {
			borderColor: inputBorderColor,
		},

		input: {
			color: grey[700],
			padding: pxToRem(12),
			backgroundColor: transparent.main,
		},

		inputSizeSmall: {
			fontSize: size.xs,
			padding: pxToRem(10),
		},

		multiline: {
			color: grey[700],
			padding: 0,
		},
	},
};

export default inputOutlined;
