import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import mailIcon from 'assets/images/icons/Icon_mailRed.svg';

import EventCard from '../../components/EventCard';
import { useEffect, useState } from 'react';
import { getEvents } from 'store/slices/events';

const EventosRecientes = () => {
	const [eventsArray, setEventsArray] = useState([]);
	useEffect(() => {
		const getEventos = getEvents();
		getEventos
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					setEventsArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<MDBox
			display={'flex'}
			alignItems={'center'}
			flexDirection={'column'}
			width={'100%'}
		>
			<MDBox mt={4} display={'flex'} width={'100%'}>
				<MDTypography variant='h3'>Eventos recientes</MDTypography>
			</MDBox>
			<MDBox
				mt={4}
				display={'flex'}
				width={'100%'}
				alignItems={'center'}
				flexDirection={'column'}
			>
				{eventsArray.map((item: any) => (
					<EventCard
						key={item.id}
						type={item.estatus}
						item={item}
						content={
							<>
								<MDBox
									display={'flex'}
									pl={5}
									flexDirection={'column'}
									width={'100%'}
									// style={{ minWidth: '600px' }}
								>
									<MDBox display={'flex'} flexDirection={'column'}>
										<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
											<MDTypography varian={'bold'} mr={1}>
												Venue:
											</MDTypography>
											<MDTypography variant={'body2'}>{item.Venue.nombre}</MDTypography>
										</MDBox>
										<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
											<MDTypography varian={'bold'} mr={1}>
												Categoría:
											</MDTypography>
											<MDTypography variant={'body2'}>
												{item.Categoria.nombre
													? item.Categoria.nombre
													: 'Nombre de la categoría'}
											</MDTypography>
										</MDBox>
										<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
											<MDTypography varian={'bold'} mr={1}>
												Administrador:
											</MDTypography>
											<MDTypography variant={'body2'}>
												Nombre de administrador, administradores
											</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
							</>
						}
					/>
				))}
				{/* <EventCard
					type={'Finalizado'}
					content={
						<>
							<MDBox
								display={'flex'}
								pl={5}
								flexDirection={'column'}
								width={'100%'}
								// style={{ minWidth: '600px' }}
							>
								<MDBox display={'flex'} flexDirection={'column'}>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Venue:
										</MDTypography>
										<MDTypography variant={'body2'}>Foro Sol</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Categoría:
										</MDTypography>
										<MDTypography variant={'body2'}> Nombre de la categoría</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Administrador:
										</MDTypography>
										<MDTypography variant={'body2'}>
											Nombre de administrador, administradores
										</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
						</>
					}
				/>
				<EventCard
					type={'Activado'}
					content={
						<>
							<MDBox
								display={'flex'}
								pl={5}
								flexDirection={'column'}
								width={'100%'}
								// style={{ minWidth: '600px' }}
							>
								<MDBox display={'flex'} flexDirection={'column'}>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Venue:
										</MDTypography>
										<MDTypography variant={'body2'}>Foro Sol</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Categoría:
										</MDTypography>
										<MDTypography variant={'body2'}> Nombre de la categoría</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Administrador:
										</MDTypography>
										<MDTypography variant={'body2'}>
											Nombre de administrador, administradores
										</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
						</>
					}
				/>
				<EventCard
					type={'Activado'}
					content={
						<>
							<MDBox
								display={'flex'}
								pl={5}
								flexDirection={'column'}
								width={'100%'}
								// style={{ minWidth: '600px' }}
							>
								<MDBox display={'flex'} flexDirection={'column'}>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Venue:
										</MDTypography>
										<MDTypography variant={'body2'}>Foro Sol</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Categoría:
										</MDTypography>
										<MDTypography variant={'body2'}> Nombre de la categoría</MDTypography>
									</MDBox>
									<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
										<MDTypography varian={'bold'} mr={1}>
											Administrador:
										</MDTypography>
										<MDTypography variant={'body2'}>
											Nombre de administrador, administradores
										</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
						</>
					}
				/> */}
			</MDBox>
		</MDBox>
	);
};

export default EventosRecientes;
