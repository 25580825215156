import { ReactNode } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import DownloadIcon from '@mui/icons-material/Download';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useNavigate } from 'react-router-dom';

// Declaring props types for BookingCard
interface Props {
	image: string;
	title: string;
	description: string;
	price: string;
	location: ReactNode;
	action?: ReactNode | boolean;
	typeEvent: string;
	[key: string]: any;
	numTickets: number;
	eventDate: string;
	purchaseId: string | number;
}

function BookingCard({
	image,
	title,
	description,
	price,
	location,
	action,
	typeEvent,
	numTickets,
	eventDate,
	purchaseId,
}: Props): JSX.Element {
	const navigate = useNavigate();
	const goMyEvent = () => {
		navigate(`/user/MyEvents/${purchaseId}`);
	};
	const goDownloadMyEvent = () => {
		navigate(`/user/MyEvents/${purchaseId}`);
	};
	return (
		<Card
			sx={{
				'&:hover .card-header': {
					transform: action && 'translate3d(0, -50px, 0)',
				},
			}}
			// style={{ boxShadow: '1px 7px 15px 1px #00000038' }}
		>
			<MDBox
				position='relative'
				borderRadius='lg'
				mt={-3}
				mx={1}
				className='card-header'
				sx={{ transition: 'transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)' }}
			>
				<MDBox
					component='img'
					src={image}
					alt={title}
					borderRadius='lg'
					// shadow='md'

					width='100%'
					height='100%'
					minHeight={'250px'}
					maxHeight={'250px'}
					position='relative'
					zIndex={1}
					style={{ objectFit: 'cover' }}
				/>
				<MDBox
					borderRadius='lg'
					shadow='md'
					width='100%'
					height='100%'
					position='absolute'
					left={0}
					top='0'
					sx={{
						backgroundImage: `url(${image})`,
						transform: 'scale(0.94)',
						filter: 'blur(12px)',
						backgroundSize: 'cover',
					}}
				/>
			</MDBox>
			<MDBox textAlign='center' pt={3} px={3} pb={2}>
				<MDBox
					display='flex'
					justifyContent='center'
					alignItems='center'
					mt={action ? -8 : -4.25}
				>
					{action}
				</MDBox>
				<MDTypography variant='h4' fontWeight='bold' sx={{ mt: 4 }}>
					{title}
				</MDTypography>
				<MDTypography variant='body1' color='text'>
					{numTickets === 1 ? `1 BOLETO` : `${numTickets} BOLETOS`}
				</MDTypography>
				<MDTypography variant='body2' color='text'>
					{eventDate} | {location}
				</MDTypography>
				<MDTypography variant='body2' color='text'>
					ID compra: #{purchaseId}
				</MDTypography>
			</MDBox>
			{typeEvent === 'NextEvents' && (
				<MDBox
					display='flex'
					justifyContent='space-between'
					// pt={1}
					// pb={1}
					px={3}
					lineHeight={1}
					style={{ backgroundColor: '#000000', borderRadius: '0px 0px 12px 12px' }}
				>
					<MDBox
						onClick={goMyEvent}
						color='white'
						display='flex'
						alignItems='center'
						pt={1}
						pb={1}
						sx={{
							'&:hover ': {
								cursor: 'pointer',
							},
						}}
					>
						<MDTypography
							variant='button'
							fontWeight='bold'
							color='white'
							sx={{ m: 0.5 }}
						>
							Ver boletos
						</MDTypography>
					</MDBox>
					<MDBox
						color='white'
						display='flex'
						alignItems='center'
						style={{
							backgroundColor: 'var(--colorPrimary)',
							height: '100%',
							borderRadius: '0px 0px 4px 4px',
						}}
						onClick={goDownloadMyEvent}
						sx={{
							'&:hover ': {
								cursor: 'pointer',
							},
						}}
					>
						<DownloadIcon color='inherit' sx={{ m: 0.5 }} />
					</MDBox>
				</MDBox>
			)}
		</Card>
	);
}

// Declaring default props for BookingCard
BookingCard.defaultProps = {
	action: false,
};

export default BookingCard;
