import { IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import CategoriaItem from './components/CategoriaItem';
import category1 from 'assets/images/categories/category1.png';
import category2 from 'assets/images/categories/category2.jpeg';
import category3 from 'assets/images/categories/category3.jpeg';
import category4 from 'assets/images/categories/category4.jpeg';
import { Link } from 'react-router-dom';

const Categorias = () => {
	return (
		<>
			<MDBox
				display={'flex'}
				width={'100%'}
				pt={3}
				pl={4}
				pr={4}
				justifyContent={'space-between'}
			>
				<MDTypography variant={'h4'}>Categorías</MDTypography>
				<MDTypography
					component={Link}
					to='/search'
					variant='button'
					fontWeight='bold'
					style={{ fontSize: '1.2rem' }}
				>
					ver todo
				</MDTypography>
			</MDBox>
			<MDBox
				display={'flex'}
				justifyContent={'center'}
				flexWrap={'wrap'}
				width={'100%'}
			>
				<CategoriaItem img={category1} text={'CONCIERTOS'} />
				<CategoriaItem img={category2} text={'FESTIVALES'} />
				<CategoriaItem img={category3} text={'TEATRO'} />
				<CategoriaItem img={category4} text={'STAND UP'} />
			</MDBox>
		</>
	);
};

export default Categorias;
