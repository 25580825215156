import * as Yup from 'yup';
import checkout from './form';

const {
	formField: { name, Address, Map, Aforo },
} = checkout;

const validations = [
	Yup.object().shape({
		[name.name]: Yup.string().required(name.errorMsg),
		[Address.name]: Yup.string().required(Address.errorMsg),
		[Map.name]: Yup.string().required(Map.errorMsg),
		[Aforo.name]: Yup.number().required(Aforo.errorMsg),
	}),
];

export default validations;
