const form = {
	formId: 'login-form',
	formField: {
		email: {
			name: 'email',
			label: 'Correo electrónico',
			type: 'email',
			errorMsg: 'Correo electrónico es requerido.',
			invalidMsg: 'El correo electrónico es invalido',
		},
		password: {
			name: 'password',
			label: 'Contraseña',
			type: 'password',
			errorMsg: 'Contraseña es requerida.',
			invalidMsg: 'Tu contraseña debe tener más de 6 caracteres.',
		},
	},
};

export default form;
