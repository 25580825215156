import { axiosClient, getToken } from 'config/axiosConfig';

export const getVenues = async () => {
	await getToken();
	return await axiosClient.get(`venues`);
};
export const postVenues = async (data: {}) => {
	await getToken();
	return await axiosClient.post(`venues`, data);
};
export const patchVenues = async (venueId: number, data: {}) => {
	await getToken();
	return await axiosClient.patch(`venues/${venueId}`, data);
};
export const deleteVenuesId = async (venueId: number) => {
	await getToken();
	return await axiosClient.delete(`venues/${venueId}`);
};

export const getVenuesByParams = async (
	aforo: string | number,
	estatus: string,
	nombre: string
) => {
	await getToken();
	return await axiosClient.get(
		`venues?aforo=${aforo}&estatus=${estatus}&nombre=${nombre}`
	);
};
