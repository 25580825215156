import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
	Button,
	ButtonGroup,
	Card,
	IconButton,
	Modal,
	Tooltip,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import { getInformacionDashboardDetalle } from 'store/slices/events';
import DataTable from 'components/DataTable';
import MDButton from 'components/MDButton';

const SplitButton = ({ eventoId }: any) => {
	const [open, setOpen] = React.useState(false);
	const [eventoDetalle, setEventoDetalle] = useState<any>({});
	const [openModal, setOpenModal] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpenModal((openModal) => !openModal);
	};
	const handleCloseModal = () => setOpenModal(false);

	const banerDestacadosGet = (): void => {
		console.log('eventoId::', eventoId);
		const getInfoDetalle = getInformacionDashboardDetalle(eventoId);
		getInfoDetalle
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 200) {
					console.log('Detalle', respuesta.data.data);
					setEventoDetalle(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setEventoDetalle({});
			});
	};
	useEffect(() => {
		if (openModal) {
			banerDestacadosGet();
		}
	}, [openModal]);
	const datosTablaBoletos = {
		columns: [
			{ Header: 'Secciones', accessor: 'Secciones', width: '80px', align: 'left' },
			{ Header: 'Tickets', accessor: 'Tickets', align: 'left' },
			{ Header: 'Vendidos', accessor: 'Vendidos', width: '80px', align: 'left' },
			{ Header: 'Comisión', accessor: 'Comision', width: '400px', align: 'left' },
			{ Header: 'Ingreso total', accessor: 'IngresoTotal', align: 'center' },
		],
		rows:
			eventoDetalle.boletos &&
			eventoDetalle.boletos
				.map((item: any) => {
					return {
						Secciones: item.seccion ? item.seccion : '',
						Tickets: item.tickets ? item.tickets : '',
						Vendidos: item.vendidos ? item.vendidos : '',
						Comision: item.comision ? item.comision : '',
						IngresoTotal: item.ingreso_total ? item.ingreso_total : '',
					};
				})
				.reverse(),
	};
	const datosTablaUsuario = {
		columns: [
			{
				Header: 'Nombre usuario',
				accessor: 'NombreUsuario',
				width: '80px',
				align: 'left',
			},
			{ Header: 'Tipo usuario', accessor: 'TipoUsuario', align: 'left' },
			{ Header: 'Vendidos', accessor: 'Vendidos', width: '80px', align: 'left' },
			{ Header: 'Comisión', accessor: 'Comision', width: '400px', align: 'left' },
			{ Header: 'Ingreso total', accessor: 'IngresoTotal', align: 'center' },
		],
		rows:
			eventoDetalle.por_usuario &&
			eventoDetalle.por_usuario
				.map((item: any) => {
					return {
						NombreUsuario: item.nombre ? item.nombre : '',
						TipoUsuario: item.tipo_usuario ? item.tipo_usuario : '',
						Vendidos: item.vendidos ? item.vendidos : '0',
						Comision: item.comision ? item.comision : '0',
						IngresoTotal: item.ingreso_total ? item.ingreso_total : '0',
					};
				})
				.reverse(),
	};

	return (
		<>
			<ButtonGroup variant='text' ref={anchorRef} aria-label='split button'>
				<Tooltip title='Detalle'>
					<MDBox
						component={IconButton}
						onClick={handleToggle}
						// variant='button'
					>
						{/* <img width={'30px'} src={arrowUp} alt='Notificaciones' /> */}
						<AddCircleIcon style={{ color: 'var(--colorPrimary)' }} />
					</MDBox>
				</Tooltip>
			</ButtonGroup>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>
					<MDBox
						// width={'100%'}
						width={'80vw'}
						maxWidth={'800px'}
						// style={{ backgroundColor: 'tomato' }}
					>
						<Card>
							<Tooltip title='Cerrar'>
								<IconButton
									onClick={() => handleCloseModal()}
									color='secondary'
									aria-label='upload picture'
									component='label'
									style={{
										position: 'absolute',
										right: '0px',
										// top: '0px',
										backgroundColor: '#fff',
										border: '1px solid #ffffff',
									}}
								>
									<CloseIcon style={{ color: '#3E3E3E' }} />
								</IconButton>
							</Tooltip>
							<MDBox
								display={'flex'}
								width={'100%'}
								flexDirection={'column'}
								pt={5}
								pb={3}
								px={3}
							>
								<MDBox display={'flex'} width={'100%'}>
									<MDTypography>
										{eventoDetalle.nombre ? eventoDetalle.nombre : 'Nombre del evento'}
									</MDTypography>
								</MDBox>
								<MDBox
									display={'flex'}
									justifyContent={'space-between'}
									flexWrap={'wrap'}
									width={'100%'}
								>
									<MDBox>
										<MDTypography>
											Venue: {eventoDetalle.venue ? eventoDetalle.venue : 'Nombre venue'}
										</MDTypography>
										<MDTypography>
											Productor:{' '}
											{eventoDetalle.productor
												? eventoDetalle.productor
												: 'Nombre productor'}
										</MDTypography>
									</MDBox>
									<MDBox
										display={'flex'}
										justifyContent={'flex-end'}
										flexDirection={'column'}
									>
										<MDBox display={'flex'} justifyContent={'flex-end'}>
											<MDTypography>Inicio venta:</MDTypography>
											<MDTypography>
												{eventoDetalle.inicio_venta
													? eventoDetalle.inicio_venta
													: '--/--/--'}
											</MDTypography>
										</MDBox>
										<MDBox display={'flex'} justifyContent={'flex-end'}>
											<MDTypography>Fin venta:</MDTypography>
											<MDTypography>
												{eventoDetalle.fin_venta ? eventoDetalle.fin_venta : '--/--/--'}
											</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
								<MDBox mt={2}>
									<MDTypography variant={'h5'}>Boletos</MDTypography>
								</MDBox>
								<MDBox style={{ width: '98%' }}>
									{datosTablaBoletos.rows && (
										<DataTable table={datosTablaBoletos} canSearch={false} />
									)}
								</MDBox>
								<MDBox display={'flex'} width={'100%'} justifyContent={'flex-end'}>
									{eventoDetalle.boletos && (
										<CSVLink
											data={
												eventoDetalle.boletos &&
												eventoDetalle.boletos
													.map((d: any) => {
														return {
															Secciones: d.seccion ? d.seccion : '',
															Tickets: d.tickets ? d.tickets : '',
															Vendidos: d.vendidos ? d.vendidos : '',
															Comision: d.comision ? d.comision : '',
															IngresoTotal: d.ingreso_total ? d.ingreso_total : '',
														};
													})
													.reverse()
											}
											filename='Detalle_Boletos.csv'
										>
											<MDButton
												size='small'
												sx={{ marginLeft: '5px', border: '1px solid' }}
											>
												+ Exportar CSV
											</MDButton>
										</CSVLink>
									)}
								</MDBox>
								<MDBox mt={1}>
									<MDTypography variant={'h5'}>Por usuario</MDTypography>
								</MDBox>
								<MDBox style={{ width: '98%' }}>
									{datosTablaUsuario.rows && (
										<DataTable table={datosTablaUsuario} canSearch={false} />
									)}
								</MDBox>
								<MDBox display={'flex'} width={'100%'} justifyContent={'flex-end'}>
									{eventoDetalle.por_usuario && (
										<CSVLink
											data={
												eventoDetalle.por_usuario &&
												eventoDetalle.por_usuario

													.map((item: any) => {
														return {
															NombreUsuario: item.nombre ? item.nombre : '',
															TipoUsuario: item.tipo_usuario ? item.tipo_usuario : '',
															Vendidos: item.vendidos ? item.vendidos : '0',
															Comision: item.comision ? item.comision : '0',
															IngresoTotal: item.ingreso_total ? item.ingreso_total : '0',
														};
													})
													.reverse()
											}
											filename='Detalle_Por_usuario.csv'
										>
											<MDButton
												size='small'
												sx={{ marginLeft: '5px', border: '1px solid' }}
											>
												+ Exportar CSV
											</MDButton>
										</CSVLink>
									)}
								</MDBox>
							</MDBox>
						</Card>
					</MDBox>
				</MDBox>
			</Modal>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default SplitButton;
