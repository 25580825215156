import { axiosClient, getToken } from 'config/axiosConfig';

export const getCountries = async () => {
	await getToken();
	return await axiosClient.get(`paises`);
};
export const getLanguages = async () => {
	await getToken();
	return await axiosClient.get(`idiomas`);
};
export const getCurrency = async () => {
	await getToken();
	return await axiosClient.get(`monedas`);
};
export const getPaymentMethod = async () => {
	await getToken();
	return await axiosClient.get(`motores_pago`);
};
export const getSettings = async () => {
	await getToken();
	return await axiosClient.get(`configuraciones`);
};
export const patchSettings = async (idConfig: number, data: {}) => {
	await getToken();
	return await axiosClient.patch(`configuraciones/${idConfig}`, data);
};
