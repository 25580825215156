import {
	Card,
	Checkbox,
	FormControlLabel,
	IconButton,
	Tooltip,
} from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';
import MDButton from 'components/MDButton';
import { patchUsuario, postUsuario } from 'store/slices/usuarios';
import { toast } from 'react-toastify';
import { getEvents } from 'store/slices/events';
import { pink } from '@mui/material/colors';
import { item } from 'examples/Sidenav/styles/sidenavItem';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const tipoUsuarioArray = [
	{ id: 1, nombre: 'ADMINISTRADOR' },
	{ id: 2, nombre: 'PRODUCTOR' },
	{ id: 3, nombre: 'USUARIO RP' },
	{ id: 4, nombre: 'CHECK IN' },
	// { id: 4, nombre: 'USUARIO TAQUILLA' },
];
const tipoUsuarioArrayProductor = [
	{ id: 3, nombre: 'USUARIO RP' },
	{ id: 4, nombre: 'CHECK IN' },
	// { id: 4, nombre: 'USUARIO TAQUILLA' },
];

const NuevoUsuarioModal = ({
	handleClose,
	actionType,
	usuarioToEdit,
	getUsuariosData,
}: any) => {
	const { tipo_usuario } = useSelector((state: RootState) => state.auth);
	const initialUsuarioModalValues = {
		id: usuarioToEdit.id ? usuarioToEdit.id : null,
		nombre: usuarioToEdit.nombre ? usuarioToEdit.nombre : '',
		email: usuarioToEdit.email ? usuarioToEdit.email : '',
		codigo_telefono: usuarioToEdit.codigo_telefono
			? usuarioToEdit.codigo_telefono
			: '',
		telefono: usuarioToEdit.telefono ? usuarioToEdit.telefono : '',
		tipo_usuario: usuarioToEdit.tipo_usuario ? usuarioToEdit.tipo_usuario : '',
	};
	const [usuarioModalValue, setUsuarioModalValue] = useState<any>(
		actionType === 'Add'
			? {
					id: null,
					nombre: '',
					email: '',
					codigo_telefono: '',
					telefono: '',
					tipo_usuario: '',
			  }
			: actionType === 'Edit' && initialUsuarioModalValues
	);
	const handleInputChangeUsuario = (event: any) => {
		setUsuarioModalValue({
			...usuarioModalValue,
			[event.target.name]: event.target.value,
		});
	};
	//Asignar Eventos Logic
	const [eventosArray, setEventosArray] = useState([]);
	//handleInputChange
	const updateState = (index: any) => (e: any) => {
		const newArray = eventosArray.map((item: any, i: any) => {
			if (index === i) {
				if (e.target.type === 'checkbox') {
					return { ...item, [e.target.name]: e.target.checked };
				} else return { ...item, [e.target.name]: e.target.value };
			} else {
				return item;
			}
		});
		setEventosArray(newArray);
	};

	const sendUsuario = () => {
		// console.log('usuarioModalValue', usuarioModalValue);
		const result = eventosArray.filter((item) => item.checked);
		console.log('usuarioARRAYEVENTOS', result);

		const data: any = {
			nombre: usuarioModalValue.nombre,
			email: usuarioModalValue.email,
			codigo_telefono: usuarioModalValue.codigo_telefono,
			telefono: usuarioModalValue.telefono,
			fecha_nacimiento: null,
			genero: null,
			password: '',
			codigo_postal: null,
			tipo_usuario: usuarioModalValue.tipo_usuario,
			nacionalidad: null,
			estado: null,
			eventos:
				usuarioModalValue.tipo_usuario === 'USUARIO RP' ||
				usuarioModalValue.tipo_usuario === 'CHECK IN'
					? result
					: [],
		};
		console.log('DATA_Enviada', data);

		if (
			usuarioModalValue.nombre &&
			usuarioModalValue.email &&
			usuarioModalValue.codigo_telefono &&
			usuarioModalValue.telefono &&
			usuarioModalValue.tipo_usuario
		) {
			if (actionType === 'Add') {
				const userPOST = postUsuario(
					// {nombre: usuarioModalValue.nombre,
					// email: usuarioModalValue.email,
					// codigo_telefono: usuarioModalValue.codigo_telefono,
					// telefono: usuarioModalValue.telefono,
					// tipo_usuario: usuarioModalValue.tipo_usuario,
					// eventos: [],}
					data
				);
				userPOST
					.then((respuesta) => {
						if (respuesta.status === 201) {
							toast.success(respuesta.data.mensaje);
							console.log('usuarioCreado:', respuesta);
							getUsuariosData();
							setTimeout(() => {
								handleClose();
							}, 2000);
						}
					})
					.catch((error) => {
						toast.error(error.response.data.mensaje);
						console.log(error);
						setTimeout(() => {
							handleClose();
						}, 2000);
					});
			} else if (actionType === 'Edit') {
				const userPatch = patchUsuario(usuarioModalValue.id, {
					nombre: usuarioModalValue.nombre,
					email: usuarioModalValue.email,
					codigo_telefono: usuarioModalValue.codigo_telefono,
					telefono: usuarioModalValue.telefono,
					tipo_usuario: usuarioModalValue.tipo_usuario,
				});
				userPatch
					.then((respuesta) => {
						if (respuesta.status === 200) {
							toast.success(respuesta.data.mensaje);
							getUsuariosData();
							setTimeout(() => {
								handleClose();
							}, 2000);
						}
					})
					.catch((error) => {
						toast.error(error.response.data.mensaje);
						console.log(error);
						setTimeout(() => {
							handleClose();
						}, 2000);
					});
			}
		} else {
			toast.error('Todos los campos son requeridos');
		}
	};
	const getEventosData = (): void => {
		const eventsGet = getEvents();
		eventsGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('eventos200', respuesta.data.data);
					const initialEventData = respuesta.data.data.map((item: any) => {
						return {
							evento_id: item.id,
							checked: false,
							nombre: item.nombre,
							comision: '',
							url_rp: '',
						};
					});
					setEventosArray(initialEventData);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		getEventosData();
	}, []);

	return (
		<>
			<MDBox
				// width={'100%'}
				width={'80vw'}
				maxWidth={'800px'}
				// style={{ backgroundColor: 'tomato' }}
			>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox p={4} display={'flex'} flexDirection={'column'} width={'100%'}>
						<MDTypography variant={'h4'}>
							{actionType === 'Add'
								? 'Dar de alta un usuario'
								: actionType === 'Edit' && 'Editar usuario'}
						</MDTypography>
						<MDTypography
							component={IconButton}
							variant='body1'
							color='primary'
							fontWeight='medium'
							textGradient
							onClick={() => alert('archivo csv')}
							textAlign={'left'}
						>
							{`+ Añadir usuarios con archivo csv`}
						</MDTypography>

						<MDBox display={'flex'} width={'100%'} flexDirection={'column'}>
							<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
								<MDTypography mt={1} variant='h6'>
									Nombre
								</MDTypography>
								<input
									type='text'
									placeholder='Nombre de usuario'
									className='inputCategoria'
									value={usuarioModalValue.nombre}
									name='nombre'
									onChange={handleInputChangeUsuario}
								/>
							</MDBox>
							<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
								<MDTypography mt={1} variant='h6'>
									Correo electrónico
								</MDTypography>
								<input
									type='text'
									placeholder='Correo electrónico'
									className='inputCategoria'
									value={usuarioModalValue.email}
									name='email'
									onChange={handleInputChangeUsuario}
								/>
							</MDBox>

							<MDBox
								display={'flex'}
								flexWrap={'wrap'}
								width={'100%'}
								justifyContent={'space-between'}
							>
								<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
									<MDBox
										display={'flex'}
										flexWrap={'wrap'}
										alignItems={'flex-end'}
										minWidth={'40%'}
									>
										<MDBox display={'flex'} flexDirection={'column'}>
											<MDTypography mt={1} variant='h6'>
												Teléfono
											</MDTypography>
											<input
												type='text'
												placeholder='Código país'
												className='inputCategoria'
												style={{ width: '7rem', marginRight: '1rem' }}
												value={usuarioModalValue.codigo_telefono}
												name='codigo_telefono'
												onChange={handleInputChangeUsuario}
											/>
										</MDBox>
										<MDBox display={'flex'} flexDirection={'column'}>
											<input
												type='number'
												placeholder='número'
												className='inputCategoria'
												style={{ width: '9.5rem' }}
												value={usuarioModalValue.telefono}
												name='telefono'
												onChange={handleInputChangeUsuario}
											/>
										</MDBox>
									</MDBox>
									<MDBox display={'flex'} flexDirection={'column'} width={'60%'}>
										<MDTypography mt={1} variant='h6'>
											Tipo usuario
										</MDTypography>
										<div className='fp-selectUser'>
											<select
												className='fp-selectedUser'
												value={usuarioModalValue.tipo_usuario}
												name='tipo_usuario'
												onChange={handleInputChangeUsuario}
											>
												<option value={''}>Selecciona una opción</option>
												{tipo_usuario === 'PRODUCTOR' &&
													tipoUsuarioArrayProductor.map((item: any) => (
														<option key={item.id} value={item.nombre}>
															{item.nombre}
														</option>
													))}
												{tipo_usuario === 'ADMINISTRADOR' &&
													tipoUsuarioArray.map((item: any) => (
														<option key={item.id} value={item.nombre}>
															{item.nombre}
														</option>
													))}
											</select>
										</div>
									</MDBox>
								</MDBox>
								{(usuarioModalValue.tipo_usuario === 'USUARIO RP' ||
									usuarioModalValue.tipo_usuario === 'CHECK IN') && (
									<MDBox
										mt={1}
										display={'flex'}
										flexDirection={'column'}
										style={{ minWidth: '230px', width: '100%' }}
									>
										<MDTypography mt={1} variant='h6'>
											Asignar eventos
										</MDTypography>
										<div className='fpTableContainer' style={{ width: '100%' }}>
											<MDBox
												mt={1}
												display={'flex'}
												width={'100%'}
												p={1}
												style={{ backgroundColor: '#F9F9F9' }}
											>
												{/* Evento */}
												<MDBox
													display={'flex'}
													flexDirection={'column'}
													style={{
														minWidth: '230px',
													}}
												>
													<MDTypography variant='body2'>Evento</MDTypography>
												</MDBox>
												{/* Comisión */}
												<MDBox
													display={'flex'}
													flexDirection={'column'}
													style={{
														minWidth: '230px',
													}}
												>
													{usuarioModalValue.tipo_usuario !== 'CHECK IN' && (
														<MDTypography variant='body2'>Comisión</MDTypography>
													)}
												</MDBox>
												{/* Url Rp */}
												<MDBox
													display={'flex'}
													flexDirection={'column'}
													style={{
														minWidth: '230px',
													}}
												>
													{usuarioModalValue.tipo_usuario !== 'CHECK IN' && (
														<MDTypography variant='body2'>URL RP</MDTypography>
													)}
												</MDBox>
											</MDBox>
											{/* Evento Item */}
											{eventosArray.map((item: any, i: any) => (
												<MDBox
													key={i}
													mt={1}
													display={'flex'}
													width={'100%'}
													p={1}
													// style={{ backgroundColor: '#F9F9F9' }}
												>
													{/* Evento */}
													<MDBox
														display={'flex'}
														flexDirection={'column'}
														style={{
															width: '230px',
															whiteSpace: 'pre-wrap',
														}}
													>
														<FormControlLabel
															label={item.nombre}
															control={
																<Checkbox
																	// checked={checked}
																	name='checked'
																	checked={item.checked}
																	// value={item.checked}
																	onChange={updateState(i)}
																	inputProps={{ 'aria-label': 'controlled' }}
																	// sx={{
																	// 	color: pink[800],
																	// 	'&.Mui-checked': {
																	// 		color: pink[600],
																	// 	},
																	// }}
																/>
															}
															style={{
																display: 'flex',
																whiteSpace: 'pre-wrap',
															}}
														/>
													</MDBox>
													{/* Comisión */}
													<MDBox
														display={'flex'}
														flexDirection={'column'}
														style={{
															minWidth: '230px',
														}}
													>
														{usuarioModalValue.tipo_usuario !== 'CHECK IN' && (
															<input
																type='number'
																placeholder='0%'
																className='inputCategoria'
																name='comision'
																value={item.comision}
																onChange={updateState(i)}
																style={{ width: '100px' }}
																disabled={!item.checked}
															/>
														)}
													</MDBox>
													{/* Url Rp */}
													<MDBox
														display={'flex'}
														flexDirection={'column'}
														style={{
															minWidth: '230px',
														}}
													>
														{usuarioModalValue.tipo_usuario !== 'CHECK IN' && (
															<input
																type='text'
																placeholder='Url de venta para evento'
																className='inputCategoria'
																name='url_rp'
																value={item.url_rp}
																onChange={updateState(i)}
																disabled
															/>
														)}
													</MDBox>
												</MDBox>
											))}
										</div>
									</MDBox>
								)}
								{/* Botones Guardar */}
								<MDBox
									mt={5}
									display={'flex'}
									flexWrap={'wrap'}
									justifyContent={'space-between'}
									width={'100%'}

									// alignItems={'center'}
								>
									<MDBox
										style={{
											minWidth: '100px',
											width: '48%',
										}}
									>
										<MDButton
											variant='contained'
											type='button'
											// color='error'
											onClick={sendUsuario}
											style={{
												backgroundColor: 'var(--colorPrimary)',
												width: '100%',
												textTransform: 'capitalize',
												fontWeight: 'bold',
												fontSize: '1rem',
												color: 'var(--backgroundBody)',
											}}
										>
											Guardar
										</MDButton>
									</MDBox>
									<MDBox
										style={{
											minWidth: '100px',
											width: '48%',
										}}
									>
										<MDButton
											onClick={handleClose}
											type='button'
											variant='contained'
											color='primary'
											style={{
												backgroundColor: 'var(--colorSecondary)',
												width: '100%',
												textTransform: 'capitalize',
												fontWeight: 'normal',
												fontSize: '1rem',
												color: 'var(--backgroundBody)',
											}}
										>
											Cancelar
										</MDButton>
									</MDBox>
								</MDBox>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
};

export default NuevoUsuarioModal;
