import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export const axiosClient = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-type': 'application/json',
	},
});
export const axiosClientFormData = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-type': 'multipart/form-data',
	},
});

export async function getToken() {
	const token = localStorage.getItem('flpss-tkn') || '';
	axiosClient.defaults.headers.common['Authorization'] = token;
	return token;
}
export async function getTokenFormData() {
	const token = localStorage.getItem('flpss-tkn') || '';
	axiosClientFormData.defaults.headers.common['Authorization'] = token;
	return token;
}
