import checkout from './form';

const {
	formField: {
		cardNumber,
		ownerName,
		ownerLastname,
		yearExpiration,
		monthExpiration,
	},
} = checkout;

const initialValues = {
	[cardNumber.name]: '',
	[ownerName.name]: '',
	[ownerLastname.name]: '',
	[monthExpiration.name]: '',
	[yearExpiration.name]: '',
};

export default initialValues;
