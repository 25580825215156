import { Dispatch } from 'redux';
import { axiosClient, getToken } from 'config/axiosConfig';
import { toast } from 'react-toastify';
import { showErrors } from 'helpers/showErrors';
import { endLoadingPut, startLoadingPut } from './userSlice';

export const getMyPerfilData = async (userId: string | number) => {
	await getToken();
	return await axiosClient.get(`/usuarios/${userId}`);
};
export const patchMyPerfilData = async (userId: string | number, data: {}) => {
	console.log('PatchUser:', userId, 'PatchData:::', data);
	await getToken();
	return await axiosClient.patch(`/usuarios/${userId}`, data);
};

export const getMetodoPagoData = async () => {
	await getToken();
	return await axiosClient.get(`/usuario/metodos-pago`);
};

export const postNewMetodoPago = (
	metodoPagoData: {},
	actions: any,
	handleClose: any,
	getMetodosPago?: any
) => {
	return async (dispatch: Dispatch, getState: any) => {
		dispatch(startLoadingPut());
		await getToken();
		await axiosClient
			.post(`/usuario/metodos-pago`, metodoPagoData)
			.then((respuesta) => {
				if (respuesta.status === 201) {
					toast.success(respuesta.data.mensaje);
					dispatch(endLoadingPut());
					getMetodosPago();
					setTimeout(() => {
						// dispatch(endLoadingSignup());
						actions.setSubmitting(false);
						actions.resetForm();
						handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				showErrors(error, dispatch, actions);
				handleClose();
			});

		// dispatch(setPokemons({ pokemons: data.results, page: page + 1 }));
	};
};
export const putMetodoPago = (
	itemId: string | number,
	metodoPagoData: {},
	actions: any,
	handleClose: any
) => {
	return async (dispatch: Dispatch, getState: any) => {
		dispatch(startLoadingPut());
		await getToken();
		await axiosClient
			.put(`/usuario/metodos-pago/${itemId}`, metodoPagoData)
			.then((respuesta) => {
				if (respuesta.status === 200) {
					toast.success(respuesta.data.mensaje);
					dispatch(endLoadingPut());
					setTimeout(() => {
						actions.setSubmitting(false);
						actions.resetForm();
						handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				showErrors(error, dispatch, actions);
				handleClose();
			});

		// dispatch(setPokemons({ pokemons: data.results, page: page + 1 }));
	};
};
export const deleteMetodoPago = (itemId: string | number, handleClose: any) => {
	return async (dispatch: Dispatch, getState: any) => {
		dispatch(startLoadingPut());
		await getToken();
		await axiosClient
			.delete(`/usuario/metodos-pago/${itemId}`)
			.then((respuesta) => {
				if (respuesta.status === 200) {
					toast.success(respuesta.data.mensaje);
					dispatch(endLoadingPut());
					setTimeout(() => {
						handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				handleClose();
			});
	};
};
