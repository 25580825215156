const form = {
	formId: 'SignUp-form',
	formField: {
		country: {
			name: 'country',
			label: 'País',
			type: 'text',
			errorMsg: 'País es requerido.',
		},
		language: {
			name: 'language',
			label: 'Idioma',
			type: 'text',
			errorMsg: 'Idioma es requerido.',
		},
		Moneda: {
			name: 'Moneda',
			label: 'Moneda',
			// label: 'Fecha de nacimiento',
			type: 'text',
			errorMsg: 'Moneda es requerida.',
			// invalidMsg: 'Tu fecha debe estar completa',
		},
	},
};

export default form;
