import { Navigate, Outlet } from 'react-router-dom';

const RequireProductor = () => {
	const flpssUser = localStorage.getItem('flpssTypeUser') || '';
	console.log('flpssTypeUser', flpssUser);
	if (flpssUser === 'PRODUCTOR') return <Outlet />;

	return <Navigate to='/' />;
};

export default RequireProductor;
