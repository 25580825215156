import { NavLink, useNavigate } from 'react-router-dom';
import WindowIcon from '@mui/icons-material/Window';
import './Sidebar.css';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import fpMiniLogo1 from 'assets/images/fpMiniLogo1.svg';
import categoryIcon from 'assets/images/icons/categoryIcon.svg';
import categoryIconActive from 'assets/images/icons/categoryIconActive.png';
import venueIcon from 'assets/images/icons/venue.svg';
import venueIconActive from 'assets/images/icons/venueActive.svg';
import userIcon from 'assets/images/icons/userIcon.svg';
import userIconActive from 'assets/images/icons/userIconActive.svg';
import settingIcon from 'assets/images/icons/settingIcon.svg';
import settingIconActive from 'assets/images/icons/settingIconActive.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Divider, IconButton } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { useDispatch } from 'react-redux';
import { authLogout } from 'store/slices/auth';

const Sidebar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isAutenticated, tipo_usuario } = useSelector(
		(state: RootState) => state.auth
	);

	//Metodo para cerrar sesion
	const startLogOut = async () => {
		// window.location.reload();
		// localStorage.clear();

		localStorage.setItem('flpss-tkn', '');
		localStorage.setItem('flpss-tkn-init-date', '');
		localStorage.setItem('flpssUser', '');
		localStorage.setItem('flpssUser', '');
		localStorage.setItem('flpssTypeUser', '');
		dispatch(authLogout());
		navigate('/');
		window.location.reload();
	};
	return (
		<>
			{tipo_usuario === 'ADMINISTRADOR' && (
				<div className='fpSidebarContent'>
					<NavLink
						to={'/admin/dasboard'}
						// className={({ isActive }) =>
						// 	isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						// }
					>
						<div className='fpSidebarTitle' style={{ minWidth: '200px' }}>
							<WindowIcon /> <p className='fpSidebarItemText'>Dashboard</p>
						</div>
					</NavLink>
					<NavLink
						to={'/admin/categories'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={categoryIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Categorías</p>
					</NavLink>
					<NavLink
						to={'/admin/venues'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={venueIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Venues</p>
					</NavLink>
					<NavLink
						to={'/admin/events'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={fpMiniLogo1} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Eventos</p>
					</NavLink>
					<NavLink
						to={'/admin/users'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={userIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Usuarios</p>
					</NavLink>
					<NavLink
						to={'/admin/settings'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={settingIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Configuración</p>
					</NavLink>
					<Divider style={{ backgroundColor: 'var(--colorTertiary)' }} />

					<MDBox
						display={'flex'}
						justifyContent={'center'}
						width={'100%'}
						// style={{ backgroundColor: 'tomato' }}
					>
						<MDTypography
							component={IconButton}
							variant='body2'
							// fontWeight='medium'

							onClick={startLogOut}
							// style={{ backgroundColor: 'tomato', with: '100%' }}
						>
							Cerrar sesión
						</MDTypography>
					</MDBox>
				</div>
			)}
			{tipo_usuario === 'COMPRADOR' && (
				<div className='fpSidebarContent'>
					<div className='fpSidebarTitle'>
						<WindowIcon /> <p className='fpSidebarItemText'>Menú</p>
					</div>
					<NavLink
						to={'/user/'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<PersonOutlineOutlinedIcon fontSize='medium' />{' '}
						<p className='fpSidebarItemText'>Mi perfil</p>
					</NavLink>
					<NavLink
						to={'/user/MyEvents'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={fpMiniLogo1} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Mis eventos</p>
					</NavLink>
					<NavLink
						to={'/user/settings'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItem fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<SettingsOutlinedIcon fontSize='medium' />{' '}
						<p className='fpSidebarItemText'>Configuración</p>
					</NavLink>
				</div>
			)}
			{tipo_usuario === 'PRODUCTOR' && (
				<div className='fpSidebarContent'>
					<NavLink
						to={'/producer/menu'}
						// className={({ isActive }) =>
						// 	isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						// }
					>
						<div className='fpSidebarTitle' style={{ minWidth: '200px' }}>
							<WindowIcon /> <p className='fpSidebarItemText'>Menú</p>
						</div>
					</NavLink>
					<NavLink
						to={'/producer/perfil'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={categoryIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Mi perfil</p>
					</NavLink>
					<NavLink
						to={'/producer/events'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={fpMiniLogo1} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Eventos</p>
					</NavLink>
					<NavLink
						to={'/producer/users'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={userIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Usuarios</p>
					</NavLink>
					<NavLink
						to={'/producer/venues'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={venueIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Venues</p>
					</NavLink>
					<NavLink
						to={'/producer/settings'}
						className={({ isActive }) =>
							isActive ? 'fpSidebarItemActive' : 'fpSidebarItem'
						}
					>
						<img width={'25px'} src={settingIcon} alt='Mis eventos' />{' '}
						<p className='fpSidebarItemText'>Configuración</p>
					</NavLink>
					<Divider style={{ backgroundColor: 'var(--colorTertiary)' }} />

					<MDBox
						display={'flex'}
						justifyContent={'center'}
						width={'100%'}
						// style={{ backgroundColor: 'tomato' }}
					>
						<MDTypography
							component={IconButton}
							variant='body2'
							// fontWeight='medium'

							onClick={startLogOut}
							// style={{ backgroundColor: 'tomato', with: '100%' }}
						>
							Cerrar sesión
						</MDTypography>
					</MDBox>
				</div>
			)}
		</>
	);
};

export default Sidebar;
