import { Card, Grid, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import { Form, Formik } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import FormFieldRadius from 'components/MDFormFieldRadius';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import { postNewMetodoPago, putMetodoPago } from 'store/slices/user';
interface propsMetodoPagoModal {
	handleClose: Function;
	typeModal: 'Add' | 'Edit';
	itemToEdit?: iItemToEdit;
	getMetodosPago?: any;
}
export interface iItemToEdit {
	id?: string | number;
	numero_tarjeta?: string;
	nombre_en_tarjeta?: string;
	apellido_en_tarjeta?: String;
	mes_exp?: string;
	ano_exp?: string;
}

const MetodoPagoModal = ({
	handleClose,
	typeModal,
	itemToEdit,
	getMetodosPago,
}: propsMetodoPagoModal) => {
	const dispatch = useDispatch();
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const {
		cardNumber,
		ownerName,
		ownerLastname,
		monthExpiration,
		yearExpiration,
	} = formField;

	const initialEditValues = {
		cardNumber: itemToEdit.numero_tarjeta ? itemToEdit.numero_tarjeta : '',
		ownerName: itemToEdit.nombre_en_tarjeta ? itemToEdit.nombre_en_tarjeta : '',
		ownerLastname: itemToEdit.apellido_en_tarjeta
			? itemToEdit.apellido_en_tarjeta
			: '',
		monthExpiration: itemToEdit.mes_exp ? itemToEdit.mes_exp : '',
		yearExpiration: itemToEdit.ano_exp ? itemToEdit.ano_exp : '',
	};

	const submitForm = async (values: any, actions: any) => {
		const dataToSend = {
			numero_tarjeta: values.cardNumber.toString(),
			nombre_en_tarjeta: values.ownerName,
			apellido_en_tarjeta: values.ownerLastname,
			mes_exp: values.monthExpiration.toString(),
			ano_exp: values.yearExpiration.toString(),
		};
		if (typeModal === 'Add') {
			await dispatch<any>(
				postNewMetodoPago(dataToSend, actions, handleClose, getMetodosPago)
			);
		} else if (typeModal === 'Edit') {
			await dispatch<any>(
				putMetodoPago(itemToEdit.id, dataToSend, actions, handleClose)
			);
		}

		actions.setSubmitting(false);
		// actions.resetForm();
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	return (
		<>
			<MDBox
				// width={'100%'}

				position={'relative'}
				width={'90vw'}
				minWidth={'300px'}
				maxWidth={'650px'}
			>
				<MDBox>
					<Card style={{ backgroundColor: '#FDFDFD' }}>
						<Tooltip title='Cerrar'>
							<IconButton
								onClick={() => handleClose()}
								color='secondary'
								aria-label='upload picture'
								component='label'
								style={{
									position: 'absolute',
									right: '0px',
									// top: '0px',
									backgroundColor: '#fff',
									border: '1px solid #ffffff',
								}}
							>
								<CloseIcon style={{ color: '#3E3E3E' }} />
							</IconButton>
						</Tooltip>
						<Grid
							container
							direction={'column'}
							justifyContent='center'
							alignItems='flex-start'
							padding={5}
						>
							<MDTypography mt={1} variant='h3' fontWeight={'bold'}>
								{typeModal === 'Add'
									? 'Agregar método de pago'
									: typeModal === 'Edit' && 'Editar método de pago'}
							</MDTypography>
							<MDBox width={'100%'}>
								<Formik
									initialValues={
										typeModal === 'Add'
											? initialValues
											: typeModal === 'Edit' && initialEditValues
									}
									validationSchema={currentValidation}
									onSubmit={handleSubmit}
									width={'100%'}
								>
									{({ values, errors, touched, handleChange, isSubmitting }) => (
										<Form id={formId} autoComplete='off'>
											<MDBox mt={2}>
												<MDTypography mt={1} mb={1} variant='h6'>
													Número de tarjeta
												</MDTypography>
												<FormFieldRadius
													type={cardNumber.type}
													label={cardNumber.label}
													name={cardNumber.name}
													value={values.cardNumber}
													onChange={handleChange}
													placeholder={'xxxx xxxx xxxx xxxx'}
													error={errors.cardNumber && touched.cardNumber}
													success={values.cardNumber.length > 0 && !errors.cardNumber}
													style={{ minWidth: '12rem' }}
													autoComplete='off'
												/>
											</MDBox>
											<MDBox
												display={'flex'}
												flexWrap={'wrap'}
												justifyContent={'space-between'}
												width={'100%'}

												// alignItems={'center'}
											>
												<MDBox
													style={{
														minWidth: '100px',
														width: '48%',
													}}
												>
													<MDTypography mt={2} mb={1} variant='h6'>
														Nombre
													</MDTypography>
													<FormFieldRadius
														type={ownerName.type}
														label={ownerName.label}
														name={ownerName.name}
														value={values.ownerName}
														onChange={handleChange}
														placeholder={ownerName.label}
														error={errors.ownerName && touched.ownerName}
														// success={values.ownerName.length > 0 && !errors.ownerName}
														// style={{ minWidth: '12rem' }}
														autoComplete='off'
													/>
												</MDBox>
												<MDBox
													style={{
														minWidth: '100px',
														width: '48%',
													}}
												>
													<MDTypography mt={2} mb={1} variant='h6'>
														Apellidos
													</MDTypography>
													<FormFieldRadius
														type={ownerLastname.type}
														label={ownerLastname.label}
														name={ownerLastname.name}
														value={values.ownerLastname}
														onChange={handleChange}
														placeholder={ownerLastname.label}
														error={errors.ownerLastname && touched.ownerLastname}
														// success={values.ownerLastname.length > 0 && !errors.ownerLastname}
														// style={{ minWidth: '12rem' }}
														autoComplete='off'
													/>
												</MDBox>
											</MDBox>
											<MDBox
												display={'flex'}
												flexWrap={'wrap'}
												justifyContent={'space-between'}
												width={'100%'}

												// alignItems={'center'}
											>
												<MDBox
													style={{
														minWidth: '100px',
														width: '45%',
													}}
													display={'flex'}
													justifyContent={'space-between'}
													flexDirection={'column'}
												>
													<MDTypography mt={2} mb={1} variant='h6'>
														Fecha expiración
													</MDTypography>
													<MDBox display={'flex'}>
														<MDBox
															style={{
																minWidth: '65px',
																width: '15%',
															}}
															mr={2}
														>
															<FormFieldRadius
																type={monthExpiration.type}
																label={monthExpiration.label}
																name={monthExpiration.name}
																value={values.monthExpiration}
																onChange={handleChange}
																placeholder={monthExpiration.label}
																error={errors.monthExpiration && touched.monthExpiration}
																success={values.ownerName.length > 0 && !errors.ownerName}
																// style={{ minWidth: '12rem' }}
																autoComplete='off'
															/>
														</MDBox>
														<MDBox
															style={{
																minWidth: '60px',
																width: '15%',
															}}
														>
															<FormFieldRadius
																type={yearExpiration.type}
																label={yearExpiration.label}
																name={yearExpiration.name}
																value={values.yearExpiration}
																onChange={handleChange}
																placeholder={yearExpiration.label}
																error={errors.yearExpiration && touched.yearExpiration}
																success={
																	values.yearExpiration.length > 0 && !errors.yearExpiration
																}
																// style={{ minWidth: '12rem' }}
																autoComplete='off'
															/>
														</MDBox>
													</MDBox>
												</MDBox>
												<MDBox
													style={{
														minWidth: '100px',
														width: '45%',
													}}
												/>
											</MDBox>
											<MDBox
												mt={3}
												display={'flex'}
												flexWrap={'wrap'}
												justifyContent={'space-between'}
												width={'100%'}

												// alignItems={'center'}
											>
												<MDBox
													style={{
														minWidth: '100px',
														width: '48%',
													}}
												>
													<MDButton
														variant='contained'
														type='submit'
														// color='error'
														style={{
															backgroundColor: 'var(--colorPrimary)',
															width: '100%',
															textTransform: 'capitalize',
															fontWeight: '400',
															color: 'var(--backgroundBody)',
														}}
													>
														Guardar
													</MDButton>
												</MDBox>
												<MDBox
													style={{
														minWidth: '100px',
														width: '48%',
													}}
												>
													<MDButton
														onClick={() => handleClose()}
														type='button'
														variant='contained'
														color='primary'
														style={{
															backgroundColor: 'var(--colorSecondary)',
															width: '100%',
															textTransform: 'capitalize',
															fontWeight: '400',
															color: 'var(--backgroundBody)',
														}}
													>
														Cancelar
													</MDButton>
												</MDBox>
											</MDBox>
										</Form>
									)}
								</Formik>
							</MDBox>
						</Grid>
					</Card>
				</MDBox>
			</MDBox>
		</>
	);
};

export default MetodoPagoModal;
