import MDBox from 'components/MDBox';
// import fpLogo from 'assets/images/fullpass.png';
import fpLogo from 'assets/images/fullpass.svg';
import fpMiniLogo from 'assets/images/fpMiniLogo.svg';
import fpNotifications from 'assets/images/notifications.svg';

import { useNavigate } from 'react-router-dom';
import { Icon, Modal, Tooltip } from '@mui/material';
import './Navbar.css';
import { useState } from 'react';
import SingIn from './components/sing-in';
import SingUp from './components/sign-up';
import ResetPassWord from './components/reset-password';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import SettingsBtn from './components/settingsBtn/SettingsBtn';
// import SingIn from 'pantallas/autenticacion/sing-in';

// Declaring prop types for DashboardNavbar
interface Props {
	absolute?: boolean;
	light?: boolean;
	isMini?: boolean;
}

const Navbar = ({ absolute, light, isMini }: Props) => {
	const { isAutenticated, tipo_usuario } = useSelector(
		(state: RootState) => state.auth
	);
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState({ search: '' });

	//handleInputChange
	const handleInputChangeSearch = (event: any) => {
		setSearchValue({
			...searchValue,
			[event.target.name]: event.target.value,
		});
	};

	const [open, setOpen] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpen(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpen(false);
		setOptionModal(1);

		// setComprobanteToUpdate(null);
	};

	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return <SingIn handleClose={handleClose} setOptionModal={setOptionModal} />;
			case 2:
				return <SingUp handleClose={handleClose} setOptionModal={setOptionModal} />;
			case 3:
				return (
					<ResetPassWord handleClose={handleClose} setOptionModal={setOptionModal} />
				);

			default:
				return;
		}
	};
	const myPerfil = () => {
		navigate('/user/');
	};
	const myEvents = () => {
		navigate('/user/MyEvents');
	};

	const goHome = () => {
		navigate('/');
	};
	const goSearch = () => {
		navigate(`/search/${searchValue.search}`);
	};
	const goDashboard = () => {
		if (tipo_usuario === 'ADMINISTRADOR') {
			navigate(`/admin`);
		} else if (tipo_usuario === 'PRODUCTOR') {
			navigate(`/producer`);
		}
	};

	return (
		<>
			{tipo_usuario === 'ADMINISTRADOR' || tipo_usuario === 'PRODUCTOR' ? (
				<MDBox
					mt={4}
					display={'flex'}
					flexWrap={'wrap'}
					width={'100%'}
					// maxWidth={'2000px'}
					justifyContent={'space-between'}
					alignItems={'center'}
					position={'absolute'}
					zIndex={'100'}
				>
					<div className='logoBtn' onClick={goHome}>
						<img className='navbarFPLogo' src={fpLogo} alt='FP-Logo' />
					</div>
					<MDBox display={'flex'} alignItems={'center'} mr={3}>
						<MDBox display={'flex'}>
							<Tooltip title='Notificaciones'>
								<button
									className='inputNavBarBtn'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									onClick={goDashboard}
								>
									<img width={'30px'} src={fpNotifications} alt='Notificaciones' />
								</button>
							</Tooltip>
							{/* <SettingsBtn openModal={handleOpen} /> */}
						</MDBox>
					</MDBox>
				</MDBox>
			) : (
				<MDBox
					mt={4}
					display={'flex'}
					flexWrap={'wrap'}
					width={'100%'}
					// maxWidth={'2000px'}
					justifyContent={'space-between'}
					alignItems={'center'}
					position={'absolute'}
					zIndex={'100'}
				>
					<div className='logoBtn' onClick={goHome}>
						<img className='navbarFPLogo' src={fpLogo} alt='FP-Logo' />
					</div>
					<MDBox display={'flex'} alignItems={'center'} mr={3}>
						<div className='navbarSearch'>
							<MDBox
								component='form'
								role='form'
								onSubmit={goSearch}
								display={'flex'}
								mr={2}
							>
								<input
									value={searchValue.search}
									name='search'
									onChange={handleInputChangeSearch}
									type='text'
									placeholder='Buscar evento'
									className='inputTransparent'
								/>
								<button id='inputSearchBtn' type='submit'>
									<Icon>search</Icon>
								</button>
							</MDBox>
						</div>
						<MDBox display={'flex'}>
							<Tooltip title={isAutenticated ? 'Mi Perfil' : 'LogIn'}>
								<button
									className='inputNavBarBtn'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										margin: '0px 8px 0px 8px',
									}}
									onClick={isAutenticated ? myPerfil : handleOpen}
								>
									<Icon>account_circle</Icon>
								</button>
							</Tooltip>

							<Tooltip title='Mis Eventos'>
								<button
									className='inputNavBarBtn'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									onClick={isAutenticated ? myEvents : handleOpen}
								>
									<img width={'30px'} src={fpMiniLogo} alt='Mis eventos' />
								</button>
							</Tooltip>
							<SettingsBtn openModal={handleOpen} />
						</MDBox>
					</MDBox>
				</MDBox>
			)}

			<Modal
				open={open}
				disableScrollLock={true}
				disableEscapeKeyDown
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default Navbar;
