import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authLogout } from 'store/slices/auth';
// import { authLogout } from '../store/slices/auth/authSlice';
export const showErrorsAuth = (error: any, dispatch?: any, actions?: any) => {
	switch (error.response.status) {
		case 400:
			console.log(error.response);
			toast.error(error.response.data);
			toast.error(error.response.data.mensaje);
			actions.setSubmitting(false);
			dispatch(authLogout());
			console.log('ejecutando dispatch');
			break;
		case 401:
			//Cerar sesión
			// toast.error(error.response.data);
			dispatch(authLogout());
			break;
		case 403:
			toast.error(error.response.data);
			dispatch(authLogout());
			break;
		case 404:
			toast.error(error.response.data);
			dispatch(authLogout());
			break;
		case 500:
			toast.warning(error.response.data);
			dispatch(authLogout());
			break;
		default:
			return console.log('error', error.response.data);
	}
};
