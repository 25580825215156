import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import EventoItem from './components/EventoItem';
import ExC1 from 'assets/images/EventosPorCiudad/ExC1.png';
import ExC2 from 'assets/images/EventosPorCiudad/ExC2.png';
import ExC3 from 'assets/images/EventosPorCiudad/ExC3.png';
import ExC4 from 'assets/images/EventosPorCiudad/ExC4.png';
import { useEffect, useState } from 'react';
import { getBanerCiudades } from 'store/slices/events';

const EventosPorCiudades = () => {
	const [citiesBanerArray, setCitiesBanerArray] = useState([]);

	const banerCiudadesGet = (): void => {
		const banerCiudadesGet = getBanerCiudades();
		banerCiudadesGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('R::BanerCiudades:', respuesta.data.data);
					// setEventsArray(respuesta.data.data);
					setCitiesBanerArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setCitiesBanerArray([]);
			});
	};

	//Efecto que obtiene array de BanerPrincipal
	useEffect(() => {
		banerCiudadesGet();
	}, []);

	return (
		<>
			<MDBox
				display={'flex'}
				width={'100%'}
				pt={3}
				pl={4}
				pr={4}
				justifyContent={'space-between'}
			>
				<MDTypography variant={'h4'}>Eventos por ciudades</MDTypography>
			</MDBox>
			<MDBox
				display={'flex'}
				justifyContent={'center'}
				flexWrap={'wrap'}
				width={'100%'}
				marginBottom={5}
			>
				{citiesBanerArray.map((item: any) => (
					<EventoItem key={item.id_ciudad} img={item.imgUrl} text={item.nombre} />
				))}
			</MDBox>
		</>
	);
};

export default EventosPorCiudades;
