/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import arrowUp from 'assets/images/icons/Icon_arrow_up.svg';
import arrowDown from 'assets/images/icons/Icon_arrow_down.svg';
import { IconButton, Tooltip } from '@mui/material';

function Basic({
	tittle,
	isCardOpen,
	setIsCardOpen,
	content,
}: any): JSX.Element {
	return (
		<div>
			<Card>
				<MDBox
					variant='gradient'
					bgColor='primary'
					borderRadius='lg'
					coloredShadow='info'
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign='center'
					display={'flex'}
					justifyContent={'space-between'}
				>
					<MDTypography ml={3} variant='h4' fontWeight='medium' color='white' mt={1}>
						{tittle}
					</MDTypography>
					<MDBox mr={2}>
						{isCardOpen ? (
							<Tooltip title='Cerrar'>
								<MDBox
									component={IconButton}
									onClick={() => setIsCardOpen(!isCardOpen)}
									// variant='button'
								>
									<img width={'30px'} src={arrowUp} alt='Notificaciones' />
								</MDBox>
							</Tooltip>
						) : (
							<Tooltip title='Abrir'>
								<MDBox
									component={IconButton}
									onClick={() => setIsCardOpen(!isCardOpen)}
									// variant='button'
								>
									<img width={'30px'} src={arrowDown} alt='Notificaciones' />
								</MDBox>
							</Tooltip>
						)}
					</MDBox>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					{isCardOpen && (
						<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
							{content}
						</MDBox>
					)}
				</MDBox>
			</Card>
		</div>
	);
}

export default Basic;
