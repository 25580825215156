import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import EventoDestacadoItem from './components/EventoDestacadoItem';

import imgPortada from 'assets/images/event2.jpg';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

import { getBanerDestacado, getEventsByTipo } from 'store/slices/events';

import './EventosDestacados.css';
import { useEffect, useState } from 'react';

const EventosDestacados = () => {
	const [eventsDestacados, setEventsDestacados] = useState([]);
	const [banerList, setBanerList] = useState<any>([]);
	const banerDestacadosGet = (): void => {
		const getBanerPrinc = getBanerDestacado();
		getBanerPrinc
			.then((respuesta) => {
				if (respuesta.status === 200) {
					// setEventsArray(respuesta.data.data);
					console.log('EventosDestacados:::', respuesta.data.data);
					setBanerList(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		banerDestacadosGet();
	}, []);

	return (
		<>
			<MDBox
				display={'flex'}
				width={'100%'}
				pt={3}
				pl={4}
				pr={4}
				justifyContent={'space-between'}
			></MDBox>
			<MDBox display={'flex'} flexDirection={'column'} width={'100%'} p={2}>
				<MDBox display={'flex'}>
					<MDTypography variant={'h4'}>Eventos destacados</MDTypography>
				</MDBox>
				<Carousel
					containerClass='carousel-container container-with-dots'
					additionalTransfrom={0}
					// arrows
					autoPlay={true}
					autoPlaySpeed={2500}
					// centerMode={true}
					customLeftArrow={
						<button className=' btnArrow btnArrowBack '>
							{/* <Icon>notifications</Icon> */}
							<ChevronLeftOutlinedIcon fontSize='large' />
						</button>
					}
					customRightArrow={
						<button className=' btnArrow btnArrowNext '>
							{/* <Icon>notifications</Icon> */}
							<NavigateNextOutlinedIcon fontSize='large' />
						</button>
					}
					className=''
					dotListClass=''
					draggable
					focusOnSelect={false}
					infinite
					itemClass=''
					keyBoardControl
					minimumTouchDrag={80}
					pauseOnHover
					renderArrowsWhenDisabled={false}
					renderButtonGroupOutside={false}
					renderDotsOutside={false}
					responsive={{
						desktop: {
							breakpoint: {
								max: 3000,
								min: 1290,
							},
							items: 3,
							partialVisibilityGutter: 40,
						},
						tablet: {
							breakpoint: {
								max: 1290,
								min: 899,
							},
							items: 2,
							partialVisibilityGutter: 30,
						},
						mobile: {
							breakpoint: {
								max: 899,
								min: 700,
							},
							items: 2,
							partialVisibilityGutter: 40,
						},
						mobilemini: {
							breakpoint: {
								max: 700,
								min: 0,
							},
							items: 1,
							partialVisibilityGutter: 40,
						},
					}}
					rewind={false}
					rewindWithAnimation={false}
					rtl={false}
					shouldResetAutoplay
					showDots={false}
					sliderClass=''
					slidesToSlide={1}
					swipeable
				>
					{banerList.map((item: any) => (
						<EventoDestacadoItem
							key={item.id && item.id}
							id={item.id_evento && item.id_evento}
							img={
								item.Evento.Evento_imagenes[0]
									? item.Evento.Evento_imagenes[0].urlImagen
									: imgPortada
							}
							tittle={item.Evento.nombre}
							desc={item.Evento.descripcion}
							price={
								item.Evento.Evento_boletos[0]
									? item.Evento.Evento_boletos[0].precio
									: 'xxx.xx'
							}
							// price={item.Evento.Evento_boletos[0].precio}
							// price={'$xxxxx USD'}
							// location={'Location'}
							location={item.Evento.Venue.nombre}
						/>
					))}
				</Carousel>
			</MDBox>
		</>
	);
};

export default EventosDestacados;
