import MDBox from 'components/MDBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CarouselItem from './carouselItem/CarouselItem';
import imgPortada from 'assets/images/event2.jpg';

import { useEffect, useState } from 'react';
import { getBanerPrincipal } from 'store/slices/events';

const CarouselPortada = () => {
	const [banerList, setBanerList] = useState<any>([]);

	const banerPrincipalGet = (): void => {
		const getBanerPrinc = getBanerPrincipal();
		getBanerPrinc
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('BannerPrincipal--', respuesta.data.data);
					setBanerList(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		banerPrincipalGet();
	}, []);

	return (
		<>
			<MDBox style={{ zIndex: '50' }}>
				{banerList.length && (
					<Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
						{banerList.map((item: any) => {
							return (
								<div key={item.id}>
									<CarouselItem
										id={item.id_evento}
										img={
											item.Evento.Evento_imagenes[0]
												? item.Evento.Evento_imagenes[0].urlImagen
												: imgPortada
										}
										// img={imgPortada}
										tittle={item.Evento.nombre}
										venue={item.Evento.Venue.nombre}
										date={item.Evento.fecha}
									/>
								</div>
							);
						})}
					</Carousel>
				)}
			</MDBox>
			{/* <MDBox
				display={{ xs: 'none', lg: 'flex' }}
				width='100%'
				height='100%'
				borderRadius='lg'

				// sx={{ backgroundImage: `url(${illustration})` }}
			>
				<img
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						// borderRadius: '.5rem',
					}}
					src={fpPortada}
					alt={'tags'}
					loading='lazy'
				/>
			</MDBox> */}
		</>
	);
};

export default CarouselPortada;
