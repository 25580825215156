import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import CardInfoSection from './components/CardInfoSection/CardInfoSection';
import BannerPrincipal from './components/BannerPrincipal/BannerPrincipal';
import BannerEventosDestacados from './components/BannerEventosDestacados/BannerEventosDestacados';
import BannerEventosPorCiudades from './components/BannerEventosPorCiudades/BannerEventosPorCiudades';
import BannerInformacionEventos from './components/BannerInformacionEventos/BannerInformacionEventos';
import { ToastContainer } from 'react-toastify';
import './Dashboard.css';
const Dashboard = () => {
	return (
		<>
			<div className='flpsDashboarContainer'>
				<MDBox
					display={'flex'}
					width={'100%'}
					maxWidth={'650px'}
					// style={{ backgroundColor: 'teal' }}
				>
					<MDTypography
						variant={'h3'}
						// style={{ margin: '0rem 1rem 1rem 1rem' }}
					>
						¡Bienvenido a Fullpass!
					</MDTypography>
				</MDBox>
				<MDBox>
					<CardInfoSection />
				</MDBox>
				<MDBox position={'relative'} display={'flex'} width={'100%'}>
					<BannerInformacionEventos />
				</MDBox>
				<MDBox mt={9}>
					<BannerPrincipal />
				</MDBox>
				<MDBox mt={9}>
					<BannerEventosDestacados />
				</MDBox>
				<MDBox mt={9}>
					<BannerEventosPorCiudades />
				</MDBox>
			</div>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default Dashboard;
