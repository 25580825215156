import { Grid, IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
	const navigate = useNavigate();
	const { tipo_usuario } = useSelector((state: RootState) => state.auth);

	const irDashboardProducer = () => {
		navigate('/producer');
	};
	const irDashboardAdmin = () => {
		navigate('/admin');
	};
	return (
		<>
			<MDBox
				style={{
					backgroundColor: '#000000',
					width: '100%',
					height: '100%',
					padding: '1rem',
				}}
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				flexDirection={'column'}
			>
				<Grid container justifyContent='center'>
					<Grid item xs={10} lg={8}>
						<MDBox display='flex' justifyContent='center' flexWrap='wrap' mb={2}>
							<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
								<MDTypography
									component='a'
									href='#'
									variant='body2'
									fontWeight='regular'
									color='secondary'
								>
									Compañia
								</MDTypography>
							</MDBox>
							<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
								<MDTypography
									component='a'
									href='#'
									variant='body2'
									fontWeight='regular'
									color='secondary'
								>
									About Us
								</MDTypography>
							</MDBox>
							<MDBox mr={{ xs: 0, lg: 3, xl: 6 }}>
								<MDTypography
									component='a'
									href='#'
									variant='body2'
									fontWeight='regular'
									color='secondary'
								>
									Equipo
								</MDTypography>
							</MDBox>
							<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
								<MDTypography
									component='a'
									href='#'
									variant='body2'
									fontWeight='regular'
									color='secondary'
								>
									Producto
								</MDTypography>
							</MDBox>
							<MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
								<MDTypography
									component='a'
									href='#'
									variant='body2'
									fontWeight='regular'
									color='secondary'
								>
									Blog
								</MDTypography>
							</MDBox>
							{tipo_usuario === 'PRODUCTOR' && (
								<MDBox>
									<MDTypography
										component={IconButton}
										variant='body2'
										fontWeight='regular'
										color='secondary'
										onClick={irDashboardProducer}
									>
										Ir a Dashboard
									</MDTypography>
								</MDBox>
							)}
							{tipo_usuario === 'ADMINISTRADOR' && (
								<MDBox>
									<MDTypography
										component={IconButton}
										variant='body2'
										fontWeight='regular'
										color='secondary'
										onClick={irDashboardAdmin}
									>
										Ir a Dashboard
									</MDTypography>
								</MDBox>
							)}
						</MDBox>
					</Grid>
					<Grid item xs={12} lg={8}>
						<MDBox display='flex' justifyContent='center' mt={1} mb={2}>
							<MDBox mr={3} color='secondary'>
								<FacebookIcon fontSize='small' />
							</MDBox>
							<MDBox mr={3} color='secondary'>
								<TwitterIcon fontSize='small' />
							</MDBox>
							<MDBox mr={3} color='secondary'>
								<InstagramIcon fontSize='small' />
							</MDBox>
							<MDBox mr={3} color='secondary'>
								<PinterestIcon fontSize='small' />
							</MDBox>
							<MDBox color='secondary'>
								<LinkedInIcon fontSize='small' />
							</MDBox>
						</MDBox>
					</Grid>
					<Grid item xs={12} lg={8} sx={{ textAlign: 'center' }}>
						<MDTypography variant='body2' color='secondary'>
							Copyright &copy; 2023 fullpass.com.
						</MDTypography>
					</Grid>
				</Grid>
			</MDBox>
		</>
	);
};

export default Footer;
