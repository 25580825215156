import { axiosClient, getToken } from 'config/axiosConfig';

export const getUsuarios = async () => {
	await getToken();
	return await axiosClient.get(`usuarios`);
};
export const postUsuario = async (data: {}) => {
	await getToken();
	return await axiosClient.post(`usuarios`, data);
};
export const patchUsuario = async (usuarioId: number, data: {}) => {
	await getToken();
	return await axiosClient.patch(`usuarios/${usuarioId}`, data);
};
export const deleteUsuario = async (usuarioId: number) => {
	await getToken();
	return await axiosClient.delete(`usuarios/${usuarioId}`);
};
