import checkout from './form';

const {
	formField: { email, password },
} = checkout;

const initialValues = {
	[email.name]: '',
	[password.name]: '',
};

export default initialValues;
