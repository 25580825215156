import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MyEventsList from './MyEventsList/MyEventsList';
import './MyEvents.css';
import { Outlet } from 'react-router-dom';

const MyEvents = () => {
	return (
		<div className='MyEventsContainer'>
			<MDBox
				width={'100%'}
				// maxWidth={'90%'}
				minHeight={'100vh'}
				padding={3}
			>
				<Outlet />
			</MDBox>
		</div>
	);
};

export default MyEvents;
