import { Dispatch } from 'redux';
import { axiosClient } from 'config/axiosConfig';
import { endLoadingAuth, authSuccess, startLoadingAuth } from './authSlice';
import { showErrors } from 'helpers/showErrors';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { showErrorsAuth } from 'helpers/showErrorsAuth';

interface authModel {
	email: string;
	password: string;
}

export const startLogIn = (
	authData: authModel,
	actions: any,
	handleClose?: any
) => {
	return async (dispatch: Dispatch, getState: any) => {
		dispatch(startLoadingAuth());

		await axiosClient
			.post(`/auth`, authData)
			.then((respuesta) => {
				if (respuesta.status === 200) {
					localStorage.setItem('flpss-tkn', respuesta.headers.token);
					//se obtiene fecha actual
					const time = moment().format('YYYY-MM-DD HH:mm:ss');
					//Se guarda el tiempo en el que se guardo el token en localStorage
					localStorage.setItem('flpss-tkn-init-date', time);
					localStorage.setItem('flpssUser', respuesta.data.data.email);
					localStorage.setItem('flpssTypeUser', respuesta.data.data.tipo_usuario);

					dispatch(authSuccess(respuesta.data.data));

					dispatch(endLoadingAuth());
					actions.setSubmitting(false);
					actions.resetForm();
					handleClose();

					// window.location.reload();
				}
			})
			.catch((error) => {
				dispatch(endLoadingAuth());
				actions.resetForm();
				showErrors(error, dispatch, actions);
			});

		// dispatch(setPokemons({ pokemons: data.results, page: page + 1 }));
	};
};

//RenovarToken
export const startChecking = () => {
	return async (dispatch: Dispatch) => {
		const token = localStorage.getItem('flpss-tkn') || '';
		axiosClient
			.get(`auth/renew`, {
				headers: {
					Authorization: `${token}`,
				},
			})
			.then((respuesta) => {
				if (respuesta.status === 200) {
					dispatch(endLoadingAuth());
					localStorage.setItem('flpss-tkn', respuesta.headers.token);
					//se obtiene fecha actual
					const time = moment().format('YYYY-MM-DD HH:mm:ss');
					//Se guarda el tiempo en el que se guardo el token en localStorage
					localStorage.setItem('flpss-tkn-init-date', time);
					localStorage.setItem('flpssUser', respuesta.data.data.email);

					dispatch(authSuccess(respuesta.data.data));
				}
			})
			.catch((error) => {
				console.log('error::', error);
				showErrorsAuth(error, dispatch);
			});
	};
};

export const logOut = () => {
	return async (dispatch: Dispatch) => {
		const token = localStorage.getItem('pxc-tkn') || '';
		axiosClient
			.get(`auth/renew`, {
				headers: {
					Authorization: `${token}`,
				},
			})
			.then((respuesta) => {
				if (respuesta.status === 201) {
					dispatch(endLoadingAuth());
					localStorage.setItem('flpss-tkn', respuesta.headers.token);
					//se obtiene fecha actual
					const time = moment().format('YYYY-MM-DD HH:mm:ss');
					//Se guarda el tiempo en el que se guardo el token en localStorage
					localStorage.setItem('flpss-tkn-init-date', time);
					localStorage.setItem('flpssUser', respuesta.data.data.email);

					dispatch(authSuccess(respuesta.data.data));
				}
			})
			.catch((error) => {
				console.log('error::', error);
				showErrorsAuth(error, dispatch);
			});
	};
};

export const restartPassword = (email: {}) => {
	return async (dispatch: Dispatch<any>) => {
		axiosClient
			.post(`/usuarios/recuperacionContrasena/`, email)
			.then((respuesta) => {
				if (respuesta.status === 200) console.log(respuesta.data);
			})
			.catch((error) => console.log(error.response.data));
	};
};
