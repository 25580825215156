import { Menu, MenuItem, Tooltip } from '@mui/material';
import dots from 'assets/images/icons/3dots.svg';
import editIcon from 'assets/images/icons/EditRed.svg';
import administrarIconRed from 'assets/images/icons/administrarIconRed.svg';
import duplicarIconRed from 'assets/images/icons/duplicarIconRed.svg';
import activarIconRed from 'assets/images/icons/activarIconRed.svg';
import deleteIcon from 'assets/images/icons/deletedIconRed.svg';
import ticketIcon from 'assets/images/icons/ticketIconRed.svg';
import ListItemIcon from '@mui/material/ListItemIcon';

import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const ActionBtn = ({
	habilitarUsuario,
	inhabilitarUsuario,
	eliminarEvento,
	duplicarEvento,
	editUser,
	type,
	item,
	activarEvento,
}: // userStatus,
any) => {
	const { tipo_usuario } = useSelector((state: RootState) => state.auth);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Tooltip title='Opciones'>
				<button
					onClick={handleClick}
					className={'textBtn'}
					style={{
						backgroundColor: 'transparent',
						border: 'none',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: '10px',
					}}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
				>
					<img width={'30px'} src={dots} alt='Flecha Abrir' />
				</button>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{type === 'Activo' && (
					<>
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={administrarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Administrar
						</MenuItem>
						<Divider />
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={duplicarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Duplicar
						</MenuItem>
					</>
				)}
				{type === 'Creado' && (
					<>
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={editIcon} alt='Editar usuario' />
							</ListItemIcon>
							Editar
						</MenuItem>
						<Divider />
						<MenuItem onClick={activarEvento}>
							<ListItemIcon>
								<img width={'22px'} src={activarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Activar
						</MenuItem>
						<Divider />
						<MenuItem onClick={duplicarEvento}>
							<ListItemIcon>
								<img width={'22px'} src={duplicarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Duplicar
						</MenuItem>
						<Divider />
						<MenuItem onClick={eliminarEvento}>
							<ListItemIcon>
								<img width={'22px'} src={deleteIcon} alt='Eliminar usuario' />
							</ListItemIcon>
							Eliminar
						</MenuItem>
					</>
				)}
				{type === 'Finalizado' && (
					<>
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={administrarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Administrar
						</MenuItem>
						<Divider />
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={duplicarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Duplicar
						</MenuItem>
						<Divider />
						<MenuItem onClick={eliminarEvento}>
							<ListItemIcon>
								<img width={'22px'} src={deleteIcon} alt='Eliminar usuario' />
							</ListItemIcon>
							Eliminar
						</MenuItem>
					</>
				)}
				{type === 'Cancelado' && (
					<>
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={administrarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Administrar
						</MenuItem>
						<Divider />
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={duplicarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Duplicar
						</MenuItem>
						<Divider />
						<MenuItem onClick={eliminarEvento}>
							<ListItemIcon>
								<img width={'22px'} src={deleteIcon} alt='Eliminar usuario' />
							</ListItemIcon>
							Eliminar
						</MenuItem>
					</>
				)}
				{type === 'Por aprobar' && tipo_usuario === 'ADMINISTRADOR' && (
					<>
						<MenuItem onClick={activarEvento}>
							<ListItemIcon>
								<img width={'22px'} src={activarIconRed} alt='Editar usuario' />
							</ListItemIcon>
							Activar
						</MenuItem>
						<Divider />
						<MenuItem onClick={() => alert('Eliminar evento')}>
							<ListItemIcon>
								<img width={'22px'} src={deleteIcon} alt='Eliminar usuario' />
							</ListItemIcon>
							Eliminar
						</MenuItem>
					</>
				)}
				{type === 'Por aprobar' && tipo_usuario === 'PRODUCTOR' && (
					<>
						<MenuItem onClick={editUser}>
							<ListItemIcon>
								<img width={'22px'} src={editIcon} alt='Editar usuario' />
							</ListItemIcon>
							Editar
						</MenuItem>
						<Divider />
						<MenuItem onClick={() => alert('Eliminar evento')}>
							<ListItemIcon>
								<img width={'22px'} src={deleteIcon} alt='Eliminar usuario' />
							</ListItemIcon>
							Eliminar
						</MenuItem>
					</>
				)}
				{/* <MenuItem onClick={editUser}>
					<ListItemIcon>
						<img width={'22px'} src={editIcon} alt='Editar usuario' />
					</ListItemIcon>
					Editar
				</MenuItem>
				<Divider />
				<MenuItem onClick={() => alert('test')}>
					<ListItemIcon>
						<img width={'22px'} src={ticketIcon} alt='Boletos vendidos' />
					</ListItemIcon>
					Boletos vendidos
				</MenuItem>
				<Divider />
				<MenuItem onClick={inhabilitarUsuario}>
					<ListItemIcon>
						<img width={'22px'} src={deshabilitarIcon} alt='Deshabilitar usuarios' />
					</ListItemIcon>
					Deshabilitar usuario
				</MenuItem>
				<Divider />
				<MenuItem onClick={eliminarUsuario}>
					<ListItemIcon>
						<img width={'22px'} src={deleteIcon} alt='Eliminar usuario' />
					</ListItemIcon>
					Eliminar
				</MenuItem> */}
			</Menu>
		</>
	);
};

export default ActionBtn;
