import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { endLoadingSignup } from '../store/slices/signup/signupSlice';
export const showErrors = (error: any, dispatch: any, actions: any) => {
	switch (error.response.status) {
		case 400:
			console.log(error.response);
			toast.error(error.response.data);
			toast.error(error.response.data.mensaje);
			dispatch(endLoadingSignup());
			actions.setSubmitting(false);
			console.log('ejecutando dispatch');
			break;
		case 401:
			//Cerar sesión
			toast.error(error.response.data);
			console.log('error', error.response.data);
			break;
		case 403:
			toast.error(error.response.data);
			break;
		case 404:
			toast.error(error.response.data);
			break;
		case 500:
			toast.warning(error.response.data);
			console.log('error', error.response.data);
			break;
		default:
			return console.log('error', error.response.data);
	}
};
