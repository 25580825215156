import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Icon } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { authLogout } from 'store/slices/auth';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
const SettingsBtn = ({ openModal }: any) => {
	const { isAutenticated } = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const goConfig = () => {
		handleClose();
		navigate('/user/settings');
	};

	//Metodo para cerrar sesion
	const startLogOut = async () => {
		// window.location.reload();
		// localStorage.clear();

		localStorage.setItem('flpss-tkn', '');
		localStorage.setItem('flpss-tkn-init-date', '');
		localStorage.setItem('flpssUser', '');
		localStorage.setItem('flpssTypeUser', '');
		dispatch(authLogout());
		navigate('/');
		window.location.reload();
	};
	return (
		<>
			<Tooltip title='Configuración'>
				<button
					onClick={isAutenticated ? handleClick : openModal}
					className={open ? 'inputNavBarBtn inputNavBarBtnOpen  ' : 'inputNavBarBtn'}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: '10px',
					}}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
				>
					<Icon>settings</Icon>
				</button>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={goConfig}>
					<ListItemIcon>
						<Settings fontSize='small' />
					</ListItemIcon>
					Configuración
				</MenuItem>
				<Divider />
				<MenuItem onClick={startLogOut}>
					<ListItemIcon>
						<Logout fontSize='small' />
					</ListItemIcon>
					Cerrar sesión.
				</MenuItem>
			</Menu>
		</>
	);
};

export default SettingsBtn;
