import { IconButton, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import lupaIcon from 'assets/images/icons/lupaIcon.svg';
import Basic from './components/basic';
import React, { useEffect, useState } from 'react';
import NuevoVenueModal from './components/NuevoVenueModal/NuevoVenueModal';
import DeleteVenueModal from './components/deleteVenueModal/DeleteVenueModal';
import { useNavigate } from 'react-router-dom';
import { getVenues } from 'store/slices/venues';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const Venues = () => {
	const { tipo_usuario } = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const [actionType, setActionType] = useState('Add');
	const [venuesArray, setVenuesArray] = useState<any[]>([]);
	const [venuesPendingArray, setVenuesPendingArray] = useState<any[]>([]);
	const [venueToDelete, setVenueToDelete] = useState(0);
	interface iVenueToEdit {
		name: string;
		Address: string;
		Map: string;
		Aforo: string | number;
	}
	const venueToEditInitialValue: iVenueToEdit = {
		name: '',
		Address: '',
		Map: '',
		Aforo: '',
	};

	const [venueToEdit, setVenueToEdit] = useState(venueToEditInitialValue);

	const [openModal, setOpenModal] = React.useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpenModal(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpenModal(false);
		setOptionModal(1);
	};
	const handleCloseModal = () => setOpenModal(false);
	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<NuevoVenueModal
						handleClose={handleClose}
						actionType={actionType}
						venueToEdit={venueToEdit}
						getVenues={venuesGet}
					/>
				);
			case 3:
				return (
					<DeleteVenueModal
						handleClose={handleClose}
						venueToDelete={venueToDelete}
						getVenues={venuesGet}
					/>
				);

			default:
				return;
		}
	};
	const newVenue = () => {
		setActionType('Add');
		setOptionModal(1);
		handleOpen();
	};
	const editVenue = (venue: iVenueToEdit) => {
		setActionType('Edit');
		setVenueToEdit(venue);
		setOptionModal(1);
		handleOpen();
	};
	const deleteVenue = (venueId: number) => {
		setVenueToDelete(venueId);
		setOptionModal(3);
		handleOpen();
	};
	const aprobarVenue = () => {
		setOptionModal(4);
		handleOpen();
	};
	const irVenueBusqueda = () => {
		if (tipo_usuario === 'ADMINISTRADOR') navigate('/admin/venues/busqueda');
		if (tipo_usuario === 'PRODUCTOR') navigate('/producer/venues/busqueda');
	};
	const venuesGet = (): void => {
		const venuessGet = getVenues();
		venuessGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					const arrayPendientesdeAprobar = respuesta.data.data.filter(
						(item: any) => item.estatus === 'Pendiente aprobación'
					);
					const arrayAprobados = respuesta.data.data.filter(
						(item: any) => item.estatus === 'Activo'
					);

					setVenuesArray(arrayAprobados ? arrayAprobados : []);
					setVenuesPendingArray(
						arrayPendientesdeAprobar ? arrayPendientesdeAprobar : []
					);
				}
			})
			.catch((error) => {
				console.log(error);
				setVenuesArray([]);
				setVenuesPendingArray([]);
			});
	};

	useEffect(() => {
		venuesGet();
	}, []);

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					width={'100%'}
				>
					<MDBox display={'flex'}>
						<MDTypography
							variant={'h3'}
							// style={{ margin: '0rem 1rem 1rem 1rem' }}
						>
							Venues
						</MDTypography>
						<MDTypography
							ml={1}
							component={IconButton}
							variant='body1'
							color='primary'
							fontWeight='medium'
							textGradient
							onClick={irVenueBusqueda}
						>
							<img src={lupaIcon} width={'25px'} alt='Buscar' />
						</MDTypography>
					</MDBox>
					<MDTypography
						component={IconButton}
						variant='body1'
						color='primary'
						fontWeight='medium'
						textGradient
						onClick={newVenue}
					>
						+ Añadir venue
					</MDTypography>
				</MDBox>
				{venuesPendingArray.length > 0 &&
					venuesPendingArray
						.map((item: any) => (
							<MDBox mt={6} key={item.id}>
								<Basic
									tittle={item.nombre}
									editVenue={editVenue}
									pendienteAprobación={true}
									deleteVenue={deleteVenue}
									handleClose={handleClose}
									venueId={item.id}
									venueToEdit={item}
									getVenues={venuesGet}
									content={
										<>
											<MDBox
												display={'flex'}
												pl={5}
												flexDirection={'column'}
												width={'100%'}
											>
												<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
													<MDTypography
														m={1}
														variant='h5'
														textAlign={'right'}
														minWidth={'100px'}
													>
														Ubicación:
													</MDTypography>
													<MDTypography m={1} variant='body2'>
														{item.direccion}
													</MDTypography>
												</MDBox>
												<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
													<MDTypography
														m={1}
														variant='h5'
														textAlign={'right'}
														minWidth={'100px'}
													>
														Mapa:
													</MDTypography>
													<MDTypography m={1} variant='body2' width={'68%'}>
														<a
															href={item.maps}
															target='_blank'
															style={{
																whiteSpace: 'pre-wrap',
																minWidth: '80px',
																width: '68%',
															}}
														>
															{item.maps}
														</a>
													</MDTypography>
												</MDBox>
												<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
													<MDTypography
														m={1}
														variant='h5'
														textAlign={'right'}
														minWidth={'100px'}
													>
														Aforo:
													</MDTypography>
													<MDTypography m={1} variant='body2'>
														{item.aforo}
													</MDTypography>
												</MDBox>
											</MDBox>
										</>
									}
								/>
							</MDBox>
						))
						.reverse()}
				{venuesArray.length > 0 &&
					venuesArray.map((item: any) => (
						<MDBox mt={6} key={item.id}>
							<Basic
								tittle={item.nombre}
								editVenue={editVenue}
								pendienteAprobación={false}
								deleteVenue={deleteVenue}
								handleClose={handleClose}
								venueId={item.id}
								venueToEdit={item}
								getVenues={venuesGet}
								content={
									<>
										<MDBox
											display={'flex'}
											pl={5}
											flexDirection={'column'}
											width={'100%'}
										>
											<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
												<MDTypography
													m={1}
													variant='h5'
													textAlign={'right'}
													minWidth={'100px'}
												>
													Ubicacion:
												</MDTypography>
												<MDTypography m={1} variant='body2'>
													{item.direccion}
												</MDTypography>
											</MDBox>
											<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
												<MDTypography
													m={1}
													variant='h5'
													textAlign={'right'}
													minWidth={'100px'}
												>
													Mapa:
												</MDTypography>
												<MDTypography m={1} variant='body2' width={'68%'}>
													<a
														href={item.maps}
														target='_blank'
														style={{
															whiteSpace: 'pre-wrap',
															minWidth: '80px',
															width: '68%',
														}}
													>
														{item.maps}
													</a>
												</MDTypography>
											</MDBox>
											<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
												<MDTypography
													m={1}
													variant='h5'
													textAlign={'right'}
													minWidth={'100px'}
												>
													Aforo:
												</MDTypography>
												<MDTypography m={1} variant='body2'>
													{item.aforo}
												</MDTypography>
											</MDBox>
										</MDBox>
									</>
								}
							/>
						</MDBox>
					))}

				{/* <MDBox mt={6}>
					<Basic
						tittle={'Nombre de venue'}
						editVenue={editVenue}
						content={
							<>
								<MDBox display={'flex'} pl={5} flexDirection={'column'} width={'100%'}>
									<MDBox display={'flex'}>
										<MDTypography
											m={1}
											variant='h5'
											textAlign={'right'}
											minWidth={'100px'}
										>
											Ubicacion:
										</MDTypography>
										<MDTypography m={1} variant='body2'>
											Viaducto Piedad y Río Churubusco s/n CD. Deportiva, Méxicom DF
											084000, Mexico
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'}>
										<MDTypography
											m={1}
											variant='h5'
											textAlign={'right'}
											minWidth={'100px'}
										>
											Mapa:
										</MDTypography>
										<MDTypography m={1} variant='body2'>
											https:/goo.gl/maps/U6MMFGMgRYmJ3aX3A
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'}>
										<MDTypography
											m={1}
											variant='h5'
											textAlign={'right'}
											minWidth={'100px'}
										>
											Aforo:
										</MDTypography>
										<MDTypography m={1} variant='body2'>
											3,000
										</MDTypography>
									</MDBox>
								</MDBox>
							</>
						}
					/>
				</MDBox> */}
			</MDBox>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default Venues;
