import * as Yup from 'yup';
import checkout from './form';

const {
	formField: { country, language, Moneda, defaultPaymentMethod, commission },
} = checkout;

const validations = [
	Yup.object().shape({
		[country.name]: Yup.string().required(country.errorMsg),
		[language.name]: Yup.string().required(language.errorMsg),
		[Moneda.name]: Yup.string().required(Moneda.errorMsg),
		[defaultPaymentMethod.name]: Yup.string().required(
			defaultPaymentMethod.errorMsg
		),
		[commission.name]: Yup.number().required(commission.errorMsg),
	}),
];

export default validations;
