import MDBox from 'components/MDBox';
import { useState } from 'react';
import { toast } from 'react-toastify';

const CounterTickets = ({
	totalCompraValue,
	setTotalCompraValue,
	item,
	carritoValue,
	setCarritoValue,
}: any) => {
	const [totalTickets, setTotalTickets] = useState(0);
	const addTicket = () => {
		if (totalTickets <= 9) {
			if (totalTickets < item.cantidad_disponible) {
				setTotalTickets(totalTickets + 1);
				setTotalCompraValue(totalCompraValue + item.precio);
				console.log('id::', item.id);

				const newValue = carritoValue.map((p: any) =>
					p.id_boleto === item.id ? { ...p, cantidad: totalTickets + 1 } : p
				);
				console.log('newValue', newValue);
				setCarritoValue(
					carritoValue.map((p: any) =>
						p.id_boleto === item.id ? { ...p, cantidad: totalTickets + 1 } : p
					)
				);
			} else
				toast.warning(
					`cantidad de boletos disponibles: ${item.cantidad_disponible}`
				);
		} else
			toast.warning('La cantidad maxima de compra son 10 boletos por sección');
	};
	const subtractTicket = () => {
		if (totalTickets >= 1) {
			setTotalTickets(totalTickets - 1);
			setTotalCompraValue(totalCompraValue - item.precio);
			setCarritoValue(
				carritoValue.map((p: any) =>
					p.id_boleto === item.id ? { ...p, cantidad: totalTickets - 1 } : p
				)
			);
		}
	};
	return (
		<>
			<MDBox display={'flex'} alignItems={'center'}>
				<button
					type='button'
					className='eventDescTicketBtn'
					onClick={subtractTicket}
				>
					-
				</button>
				<MDBox>{totalTickets}</MDBox>
				<button type='button' className='eventDescTicketBtn' onClick={addTicket}>
					+
				</button>
			</MDBox>
		</>
	);
};

export default CounterTickets;
