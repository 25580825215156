import { Link } from 'react-router-dom';
import BookingCard from './BookingCard';

const EventoDestacadoItem = ({
	id,
	img,
	tittle,
	desc,
	price,
	location,
}: any) => {
	return (
		<>
			<div className='EventoDestacadoItem'>
				<Link to={`/event/${id}`}>
					<BookingCard
						image={img}
						title={tittle}
						description={desc}
						price={price}
						location={location}
					/>
				</Link>
			</div>
		</>
	);
};

export default EventoDestacadoItem;
