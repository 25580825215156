import { Card, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import deletIconRed from 'assets/images/icons/deletedIconRed.svg';
import imgtest from 'assets/images/categories/category2.jpeg';
import uploadImagenIcon from 'assets/images/icons/uploadImagenIcon.svg';
import './CategoriaModal.css';
// Dropzone components
import Dropzone from 'dropzone';
// Dropzone styles
import 'dropzone/dist/dropzone.css';
import MDButton from 'components/MDButton';
import { useEffect, useRef, useState } from 'react';
import DeleteSubcategoria from './DeleteSubcategoria';
import { patchCategory, postCategory } from 'store/slices/categories';
import { toast } from 'react-toastify';
import MDDropzoneRoot from 'components/MDDropzone/MDDropzoneRoot';
import { useMaterialUIController } from 'context';
import { useForm } from 'hooks/useForm';

const CategoriaModal = ({
	handleClose,
	actionType,
	categoryToEdit,
	categoriesGet,
}: any) => {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const [fileValue, setFileValue] = useState<any>({});
	const [enviandoState, setEnviandoState] = useState(false);

	const handleFileInput = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue(files0);
		}

		console.log(files0);
	};

	// const dropzoneRef = useRef<HTMLFormElement | null>(null);

	// useEffect(() => {
	// 	Dropzone.autoDiscover = false;

	// 	function createDropzone() {
	// 		return new Dropzone(dropzoneRef.current, {
	// 			...{ addRemoveLinks: true, uploadMultiple: false },
	// 		});
	// 	}

	// 	function removeDropzone() {
	// 		if (Dropzone.instances.length > 0)
	// 			Dropzone.instances.forEach((dz: any) => dz.destroy());
	// 	}

	// 	console.log('Dropzone1:', createDropzone());

	// 	return () => removeDropzone();
	// }, [{ addRemoveLinks: true }]);

	const initialCategoryValues = {
		id: categoryToEdit.id ? categoryToEdit.id : 0,
		nombre: categoryToEdit.nombre ? categoryToEdit.nombre : '',
		imagen: categoryToEdit.imagen ? categoryToEdit.imagen : '',
		activo: categoryToEdit.activo ? categoryToEdit.activo : false,
	};
	const [subcategoriaArray, setSubcategoriaArray] = useState(
		actionType === 'Add'
			? [{ id: null, nombre: '', categoria_id: null, activo: null }]
			: actionType === 'Edit' && categoryToEdit.Subcategorias
	);
	const [arrayToDelete, setarrayToDelete] = useState<any[]>([]);
	// const [subcategoriaArray, setSubcategoriaArray] = useState([
	// 	{ id: null, nombre: '', categoria_id: null, activo: null },
	// ]);
	const [categoriaValue, setCategoriaValue] = useState<any>(
		actionType === 'Add'
			? {
					activo: false,
					id: null,
					imagen: '',
					nombre: '',
			  }
			: actionType === 'Edit' && initialCategoryValues
	);
	const handleInputChangeCategoria = (event: any) => {
		setCategoriaValue({
			...categoriaValue,
			[event.target.name]: event.target.value,
		});
	};

	//handleInputChange
	const updateState = (index: any) => (e: any) => {
		const newArray = subcategoriaArray.map((item: any, i: any) => {
			if (index === i) {
				return { ...item, [e.target.name]: e.target.value };
			} else {
				return item;
			}
		});
		setSubcategoriaArray(newArray);
	};
	const addSubcategoria = () => {
		setSubcategoriaArray([
			...subcategoriaArray,
			{ id: null, nombre: '', categoria_id: '', activo: false },
		]);
	};

	const sendCategoría = (e: any) => {
		e.preventDefault();
		// console.log('Dropzone2:', Dropzone.instances[0].files[0].dataURL);
		// console.log(dropzoneRef.current);
		// console.log(Dropzone.instances);
		// const formData = new FormData(e.currentTarget);
		// const file = e.target.files[0];
		console.log('File::', fileValue);
		setEnviandoState(true);

		if (categoriaValue.nombre) {
			if (actionType === 'Add') {
				const data = JSON.stringify({
					nombre: categoriaValue.nombre,
					Subcategorias: subcategoriaArray,
				});

				// console.log('blobTEst', blobTEst);
				const bodyFormData = new FormData();
				bodyFormData.append('data', data);
				bodyFormData.append('imagen', fileValue);

				console.log('EnviandobodyFormData1:', bodyFormData.getAll('data'));
				console.log('EnviandobodyFormData2:', bodyFormData.getAll('imagen'));

				const enviarCategoria = postCategory(bodyFormData);
				enviarCategoria
					.then((respuesta) => {
						console.log('post', respuesta);
						if (respuesta.status === 201) {
							console.log('post200', respuesta);
							toast.success(respuesta.data.mensaje);
							categoriesGet();
							setTimeout(() => {
								setEnviandoState(false);
								// categoriesGet();
								handleClose();
							}, 2000);
						}
					})
					.catch((error) => {
						toast.error(error.response.data.mensaje);
						console.log('patchError', error);
						categoriesGet();
						setTimeout(() => {
							setEnviandoState(false);
							handleClose();
						}, 2000);
					});
			} else if (actionType === 'Edit') {
				console.log('Array to delete::', arrayToDelete);
				const data = JSON.stringify({
					id: categoriaValue.id,
					nombre: categoriaValue.nombre,
					imagen: categoriaValue.imagen,
					activo: categoriaValue.activo,
					Subcategorias: subcategoriaArray,
					aEliminar: arrayToDelete ? arrayToDelete : null,
				});
				const bodyFormData = new FormData();
				bodyFormData.append('data', data);
				if (fileValue.name) {
					console.log('fileValueEXist::', fileValue);
					bodyFormData.append('imagen', fileValue);
				}

				console.log('ObjetoEnviadoPATCH1:::', bodyFormData.getAll('data'));
				console.log('ObjetoEnviadoPATCH2:::', bodyFormData.getAll('imagen'));

				const venuesAprobar = patchCategory(initialCategoryValues.id, bodyFormData);
				venuesAprobar
					.then((respuesta) => {
						console.log('patch', respuesta);
						if (respuesta.status === 200) {
							console.log('patch200', respuesta);
							toast.success(respuesta.data.mensaje);
							categoriesGet();
							setTimeout(() => {
								setEnviandoState(false);
								handleClose();
							}, 2000);
						}
					})
					.catch((error) => {
						toast.error(error.response.data.mensaje);
						console.log('patchError', error);
						categoriesGet();
						setTimeout(() => {
							setEnviandoState(false);
							handleClose();
						}, 2000);
					});
			}
		} else toast.error('Nombre de la categoría es obligatorio');
	};

	return (
		<>
			<MDBox
				// width={'100%'}
				width={'80vw'}
				maxWidth={'800px'}
				// style={{ backgroundColor: 'tomato' }}
			>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox
						component='form'
						role='form'
						onSubmit={sendCategoría}
						p={4}
						display={'flex'}
						flexDirection={'column'}
						width={'100%'}
					>
						<MDTypography variant={'h4'}>
							{actionType === 'Add'
								? 'Crear categoría'
								: actionType === 'Edit' && 'Editar categoría'}
						</MDTypography>
						<MDBox
							display={'flex'}
							width={'100%'}
							alignItems={'flex-end'}
							justifyContent={'space-between'}
						>
							<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
								<MDTypography mt={1} variant='h6'>
									Categoría
								</MDTypography>
								<input
									type='text'
									placeholder='Nombre de la categoría'
									className='inputCategoria'
									value={categoriaValue.nombre}
									name='nombre'
									onChange={handleInputChangeCategoria}
								/>
							</MDBox>
							{/* <MDBox
								ml={2}
								display={'flex'}
								width={'100px'}
								height={'90px'}
								justifyContent={'center'}
								alignItems={'center'}
								style={{ border: '1px solid #b6b6b6', borderRadius: '10px' }}
							> */}
							<MDBox ml={2}>
								{/* <MDDropzoneRoot
									action='/file-upload'
									ref={dropzoneRef}
									className='form-control dropzone'
									ownerState={{ darkMode }}
								> */}
								{/* <MDBox
										className='fallback'
										bgColor='transparent'
										multiple={false}
										accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg'
									> */}
								<MDBox
									display={'flex'}
									position={'relative'}
									// className='fallback'
									// bgColor='transparent'
									// multiple={false}
									// accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg'
								>
									{/* <MDBox className='' bgColor='transparent'> */}
									<input
										id='imgFile'
										className='fpInputFile'
										type='file'
										// name='file'
										// value={file}
										onChange={handleFileInput}
										multiple={false}
										accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg'
									/>
									{categoriaValue.imagen && (
										<div className='fpInputFileIMGContainer'>
											<img
												className='fpInputFileIMG'
												src={categoriaValue.imagen}
												alt='ImagenCategoria'
											/>
										</div>
									)}
								</MDBox>
								{/* </MDDropzoneRoot> */}
								{/* <img width={'40px'} src={uploadImagenIcon} alt='Mover' /> */}
							</MDBox>
						</MDBox>
						{subcategoriaArray.map((item: any, i: any) => (
							<MDBox
								key={i}
								display={'flex'}
								width={'100%'}
								alignItems={'flex-end'}
								justifyContent={'space-between'}
								pl={2}
							>
								<MDBox display={'flex'} flexDirection={'column'} width={'90%'}>
									<MDTypography mt={1} variant='h6'>
										Subcategoría
									</MDTypography>
									<input
										type='text'
										placeholder='Nombre de la categoría'
										className='inputCategoria'
										name='nombre'
										value={item.nombre}
										onChange={updateState(i)}
									/>
								</MDBox>
								<MDBox ml={2}>
									<DeleteSubcategoria
										subcategoriaArray={subcategoriaArray}
										setSubcategoriaArray={setSubcategoriaArray}
										item={item}
										index={i}
										arrayToDelete={arrayToDelete}
										setarrayToDelete={setarrayToDelete}
									/>
								</MDBox>
							</MDBox>
						))}
						<MDBox display={'flex'}>
							<MDTypography
								component={IconButton}
								variant='body1'
								color='primary'
								fontWeight='medium'
								textGradient
								onClick={addSubcategoria}
								style={{ fontSize: '15px' }}
							>
								+ Añadir subcategoría
							</MDTypography>
						</MDBox>
						<MDBox
							mt={5}
							display={'flex'}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
							width={'100%'}

							// alignItems={'center'}
						>
							<MDBox
								style={{
									minWidth: '100px',
									width: '48%',
								}}
							>
								<MDButton
									variant='contained'
									type='submit'
									// color='error'
									// onClick={sendCategoría}
									style={{
										backgroundColor: 'var(--colorPrimary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'bold',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
									disabled={enviandoState}
								>
									{enviandoState ? 'Guardando...' : 'Guardar'}
								</MDButton>
							</MDBox>
							<MDBox
								style={{
									minWidth: '100px',
									width: '48%',
								}}
							>
								<MDButton
									onClick={handleClose}
									type='button'
									variant='contained'
									color='primary'
									style={{
										backgroundColor: 'var(--colorSecondary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'normal',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Cancelar
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
};

export default CategoriaModal;
