const form = {
	formId: 'SignUp-form',
	formField: {
		userName: {
			name: 'userName',
			label: 'Nombre de Usuario',
			type: 'text',
			errorMsg: 'Nombre de usuario es requerido.',
		},
		email: {
			name: 'email',
			label: 'Correo electrónico',
			type: 'email',
			errorMsg: 'Correo electrónico es requerido.',
			invalidMsg: 'El correo electrónico es invalido',
		},
		birthdate: {
			name: 'birthdate',
			label: '',
			// label: 'Fecha de nacimiento',
			type: 'date',
			// errorMsg: 'Fecha es requerida.',
			// invalidMsg: 'Tu fecha debe estar completa',
		},
		nacionality: {
			name: 'nacionality',
			label: 'Nacionalidad',
			type: 'text',
			// errorMsg: 'Nacionalidad es requerido.',
			// invalidMsg: 'Tus contraseñas no coinciden.',
		},
		cityOfResidence: {
			name: 'cityOfResidence',
			label: 'Ciudad de residencia',
			type: 'text',
			// errorMsg: 'Nacionalidad es requerido.',
			// invalidMsg: 'Tus contraseñas no coinciden.',
		},
		gender: {
			name: 'gender',
			label: 'Género',
			type: 'text',
			// errorMsg: 'Nacionalidad es requerido.',
			// invalidMsg: 'Tus contraseñas no coinciden.',
		},
		countryCode: {
			name: 'countryCode',
			label: '',
			type: 'text',
		},
		tel: {
			name: 'tel',
			label: 'Teléfono',
			type: 'number',
			errorMsg: 'Teléfono es requerido.',
			invalidMsg: 'Telefono debe tener 10 dígitos.',
		},
	},
};

export default form;
