import MDBox from 'components/MDBox';
import Footer from 'pantallas/footer/Footer';
import CarouselPortada from './components/carouselPortada/CarouselPortada';
import Categorias from './components/categorias/Categorias';
import EventosDestacados from './components/Eventos destacados/EventosDestacados';
import EventosPorCiudades from './components/Eventos Por Ciudades/EventosPorCiudades';
import EventosProximos from './components/Eventos proximos/EventosProximos';
import Navbar from './components/navbar/Navbar';
import SearchBar from './components/searchBar/SearchBar';

const CompradorDashboard = () => {
	return (
		<>
			<Navbar />
			<MDBox
				display={'flex'}
				alignItems={'center'}
				minHeight={'100vh'}
				height={'auto'}
				flexDirection={'column'}
			>
				<CarouselPortada />
				<SearchBar />
				<Categorias />
				<EventosDestacados />
				<EventosProximos />
				<EventosPorCiudades />
				<Footer />
			</MDBox>
		</>
	);
};

export default CompradorDashboard;
