import {
	axiosClient,
	axiosClientFormData,
	getToken,
	getTokenFormData,
} from 'config/axiosConfig';

export const getEventsByTipo = async (tipo: string) => {
	await getToken();
	return await axiosClient.get(`/eventos/?tipo=${tipo}`);
};
export const getEventsById = async (id: string) => {
	await getToken();
	return await axiosClient.get(`/eventos/${id}`);
};

export const getEvents = async () => {
	await getToken();
	return await axiosClient.get(`/eventos/`);
};
export const getActiveEvents = async () => {
	await getToken();
	return await axiosClient.get(`/eventos?estatus=Activo`);
};
interface iGetEvetsByParams {
	busqueda?: string;
	categoria?: string;
	ciudad?: string;
	fecha?: string;
}
export const getEventsByParams = async ({
	busqueda,
	categoria,
	ciudad,
	fecha,
}: iGetEvetsByParams) => {
	await getToken();
	console.log(
		'Enviando',
		'Busqueda:',
		busqueda,
		'Categoria:',
		categoria,
		'Ciudad:',
		ciudad,
		'fecha:',
		fecha
	);
	return await axiosClient.get(
		`/eventos/?busqueda=${busqueda}&categoria=${categoria}&ciudad=${ciudad}&fechaInicio=${fecha}&FechaFin=${fecha}`
	);
};

export const postReservaBoletos = async (data: {}) => {
	console.log('postDataReserva:::', data);
	await getToken();
	return await axiosClient.post(`/usuario/reserva-boletos`, data);
};
export const postCompraBoletos = async (data: {}) => {
	console.log('postCompraReserva:::', data);
	await getToken();
	return await axiosClient.post(`/usuario/compra-boletos`, data);
};

export const getMyEvents = async () => {
	await getToken();
	return await axiosClient.get(`usuario/eventos/`);
};
export const getMyEventsByPurchaseId = async (ventaId: string | number) => {
	await getToken();
	return await axiosClient.get(`usuario/eventos/${ventaId}`);
};
export const getBanerPrincipal = async () => {
	await getToken();
	return await axiosClient.get(`banner-principal`);
};
export const postBanerPrincipal = async (data: any[]) => {
	await getToken();
	return await axiosClient.post(`banner-principal`, data);
};

export const getInformacionDashboardConteo = async () => {
	await getToken();
	return await axiosClient.get(`informacionDashboardConteo`);
};
export const getBanerDestacado = async () => {
	await getToken();
	return await axiosClient.get(`banner-destacado`);
};
export const postBanerDestacado = async (data: any[]) => {
	await getToken();
	return await axiosClient.post(`banner-destacado`, data);
};
export const getBanerCiudades = async () => {
	await getToken();
	return await axiosClient.get(`banner-ciudades`);
};
export const postBanerCiudades = async (data: any[]) => {
	await getToken();
	return await axiosClient.post(`banner-ciudades`, data);
};

export const getInformacionDashboard = async (
	evento_id: number | string,
	fechaInicio: string,
	FechaFin: string,
	estatus: string
) => {
	await getToken();
	return await axiosClient.get(
		`informacionDashboard?evento_id=${evento_id}&fechaInicio=${fechaInicio}&FechaFin=${FechaFin}&estatus=${estatus}`
	);
};
export const getInformacionDashboardDetalle = async (
	eventoId: number | string
) => {
	await getToken();
	return await axiosClient.get(`evento/${eventoId}/detalle`);
};

export const postEventos = async (data: {}) => {
	await getTokenFormData();
	return await axiosClientFormData.post(`eventos/`, data);
};
export const patchEventos = async (eventoId: number | string, data: {}) => {
	await getToken();
	console.log('Enviando Patch::', eventoId, data);
	return await axiosClient.patch(`eventos/${eventoId}`, data);
};

export const duplicarEventoById = async (eventoId: number | String) => {
	await getToken();
	return await axiosClient.post(`evento/duplicar/${eventoId}`);
};
export const descartarEventoById = async (eventoId: number | String) => {
	await getToken();
	return await axiosClient.patch(`eventos/${eventoId}/bitacora`);
};
