import { Card, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';
import evento1 from 'assets/images/event1.jpg';
import ActionBtn from './actionBtn/ActionBtn';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import {
	descartarEventoById,
	duplicarEventoById,
	patchEventos,
} from 'store/slices/events';
import MDButton from 'components/MDButton';
import { toast } from 'react-toastify';
import DeleteEventoModal from './deleteEventoModal/DeleteEventoModal';

const EventCard = ({ content, type, item, eventosGET }: any) => {
	const { tipo_usuario } = useSelector((state: RootState) => state.auth);

	const [enviandoState, setEnviandoState] = useState(false);
	const [enviandoDescartarState, setEnviandoDescartarState] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpenModal(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpenModal(false);
		setOptionModal(1);
	};
	const [imgPortada, setImgPortada] = useState(
		'https://pixcuarhfiles.s3.amazonaws.com/fpPortada.png'
	);

	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					// <ConfirmActionUserModal
					// 	handleClose={handleClose}
					// 	user={user}
					// 	getUsuariosData={getUsuariosData}
					// 	actionType='Inhabilitar'
					// />
					<h1>Opcion1</h1>
				);
			case 2:
				return (
					// <ConfirmActionUserModal
					// 	handleClose={handleClose}
					// 	user={user}
					// 	getUsuariosData={getUsuariosData}
					// 	actionType='Habilitar'
					// />
					<h1>Opcion2</h1>
				);
			case 3:
				return (
					<DeleteEventoModal
						handleClose={handleClose}
						eventToDelete={item.id}
						eventosGET={eventosGET}
					/>
					// <ConfirmActionUserModal
					// 	handleClose={handleClose}
					// 	user={user}
					// 	getUsuariosData={getUsuariosData}
					// 	actionType='Eliminar'
					// />
					// <h1>Opcion3</h1>
				);
			case 4:
				return (
					// <ConfirmActionUserModal
					// 	handleClose={handleClose}
					// 	user={user}
					// 	getUsuariosData={getUsuariosData}
					// 	actionType='Eliminar'
					// />
					<h1>Opcion4</h1>
				);
			// case 3:
			// 	return (
			// 		<DeleteVenueModal
			// 			handleClose={handleClose}
			// 			venueToDelete={venueToDelete}
			// 			getVenues={venuesGet}
			// 		/>
			// 	);

			default:
				return;
		}
	};
	const inhabilitarUsuario = () => {
		setOptionModal(1);
		handleOpen();
	};
	const habilitarUsuario = () => {
		setOptionModal(2);
		handleOpen();
	};
	const eliminarEvento = () => {
		setOptionModal(3);
		handleOpen();
	};
	const editarUsuario = () => {
		// setusuarioToEdit(user);
		// setOptionModal1(2);
		// handleOpen1();
	};
	const descartarEvento = () => {
		console.log('enviando..Descartar');
		setEnviandoDescartarState(true);
		const eventoDescartar = descartarEventoById(item.id);
		eventoDescartar
			.then((respuesta) => {
				console.log('respuestaDescartar:', respuesta);
				if (respuesta.status === 200) {
					toast.success('Se descartaron los cambios exitosamente');
					setTimeout(() => {
						setEnviandoDescartarState(false);
						eventosGET();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				setEnviandoDescartarState(false);
			});
	};
	const activarEvento = () => {
		// alert('accion Activar');
		console.log('enviando..Patch');
		setEnviandoState(true);
		const eventosPATCH = patchEventos(item.id, {
			estatus: 'Activo',
		});
		eventosPATCH
			.then((respuesta) => {
				console.log('respuesta', respuesta);
				if (respuesta.status === 200) {
					toast.success('La solicitúd de activación se envío exitosamente.');
					setTimeout(() => {
						setEnviandoState(false);
						eventosGET();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				setEnviandoState(false);
			});
	};
	const duplicarEvento = () => {
		// alert('accion Activar');
		console.log('enviando..Duplicar');
		setEnviandoState(true);
		const eventosDuplicar = duplicarEventoById(item.id);
		eventosDuplicar
			.then((respuesta) => {
				console.log('respuesta', respuesta);
				if (respuesta.status === 201) {
					toast.success(respuesta.data.mensaje);
					setTimeout(() => {
						setEnviandoState(false);
						eventosGET();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				setEnviandoState(false);
			});
	};

	const aprobarEvento = () => {
		console.log('enviando..Patch');
		setEnviandoState(true);
		const eventosPATCH = patchEventos(item.id, {
			validado: true,
		});
		eventosPATCH
			.then((respuesta) => {
				console.log('respuesta', respuesta);
				if (respuesta.status === 200) {
					toast.success('El evento se aprobó exitosamente');
					setTimeout(() => {
						setEnviandoState(false);
						eventosGET();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				setEnviandoState(false);
			});

		// setusuarioToEdit(user);
		// setOptionModal1(2);
		// handleOpen1();
	};

	useEffect(() => {
		const portadaImg = item.Evento_imagenes.find(
			(item: any) => item.tipo === 'BANNER'
		);
		if (portadaImg) {
			console.log('portadaImg::!', portadaImg.urlImagen);
			setImgPortada(portadaImg.urlImagen);
		} else setImgPortada('https://pixcuarhfiles.s3.amazonaws.com/fpPortada.png');
	}, [item]);

	return (
		<MDBox
			mb={6}
			position={'relative'}
			style={{ maxWidth: '900px', width: '100%' }}
		>
			{type !== 'Creado' && !item.validado && tipo_usuario === 'ADMINISTRADOR' && (
				<MDBox
					style={{
						height: '4rem',
						// marginTop: '-15px',
					}}
					display={'flex'}
					justifyContent={'center'}
				>
					<MDBox
						style={{
							backgroundColor: '#000000',
							color: '#ffffff',
							height: '4rem',
							width: '80%',
							borderRadius: '15px',
							// marginTop: '-15px',
						}}
					>
						<MDTypography
							p={1}
							variant={'h5'}
							textAlign='center'
							style={{ color: '#ffffff' }}
						>
							Tienes 1 Evento pendiente de aprobación
						</MDTypography>
					</MDBox>
				</MDBox>
			)}
			<MDBox
				position={'absolute'}
				variant='gradient'
				bgColor='primary'
				borderRadius='lg'
				coloredShadow='info'
				// mx={2}
				mt={-3}
				p={2}
				mb={1}
				display={'flex'}
				textAlign='center'
				justifyContent={'space-between'}
				width={'85%'}
				zIndex={'300'}
				style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}
			>
				<MDTypography ml={3} variant='h4' fontWeight='medium' color='white' mt={1}>
					{/* {type === 'Por aprobar' && tipo_usuario === 'PRODUCTOR' */}
					{item.estatus !== 'Creado' &&
					!item.validado &&
					tipo_usuario === 'PRODUCTOR'
						? `${item.nombre} (Pendiente de aprobación)`
						: item.nombre}
				</MDTypography>
				<ActionBtn
					habilitarUsuario={habilitarUsuario}
					inhabilitarUsuario={inhabilitarUsuario}
					eliminarEvento={eliminarEvento}
					editUser={editarUsuario}
					type={type}
					item={item}
					activarEvento={activarEvento}
					duplicarEvento={duplicarEvento}
					// userStatus={user.estatus}
				/>
			</MDBox>

			<Card style={{ overflow: 'hidden', zIndex: '200' }}>
				<MDBox style={{ height: '300px', width: '100%' }}>
					<img
						width={'100%'}
						// src={evento1}
						src={imgPortada}
						// src={
						// 	item.Evento_imagenes[0] ? item.Evento_imagenes[0].urlImagen : evento1
						// }
						alt='portada'
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
						}}
					/>
				</MDBox>
				<MDBox display={'flex'} justifyContent={'flex-end'} width={'100%'}>
					{type === 'Activo' && (
						<MDBox
							p={1}
							pl={4}
							pr={4}
							style={{
								backgroundColor: 'var(--colorPrimary)',
								borderRadius: '0px 0px 0px 10px',
							}}
						>
							<MDTypography variant='h5' style={{ color: 'var(--backgroundBody)' }}>
								Activado
							</MDTypography>
						</MDBox>
					)}
					{type === 'Creado' && (
						<MDBox
							p={1}
							pl={4}
							pr={4}
							style={{
								backgroundColor: 'var(--colorPrimary)',
								borderRadius: '0px 0px 0px 10px',
							}}
						>
							<MDTypography variant='h5' style={{ color: 'var(--backgroundBody)' }}>
								Creado
							</MDTypography>
						</MDBox>
					)}
					{type === 'Finalizado' && (
						<MDBox
							p={1}
							pl={4}
							pr={4}
							style={{
								backgroundColor: '#000000',
								borderRadius: '0px 0px 0px 10px',
							}}
						>
							<MDTypography variant='h5' style={{ color: 'var(--backgroundBody)' }}>
								Finalizado
							</MDTypography>
						</MDBox>
					)}
					{type === 'Cancelado' && (
						<MDBox
							p={1}
							pl={4}
							pr={4}
							style={{
								backgroundColor: '#000000',
								borderRadius: '0px 0px 0px 10px',
							}}
						>
							<MDTypography variant='h5' style={{ color: 'var(--backgroundBody)' }}>
								Cancelado
							</MDTypography>
						</MDBox>
					)}
					{type === 'Eliminado' && (
						<MDBox
							p={1}
							pl={4}
							pr={4}
							style={{
								backgroundColor: '#000000',
								borderRadius: '0px 0px 0px 10px',
							}}
						>
							<MDTypography variant='h5' style={{ color: 'var(--backgroundBody)' }}>
								Eliminado
							</MDTypography>
						</MDBox>
					)}
					{type === 'Por aprobar' && (
						<MDBox
							p={1}
							pl={4}
							pr={4}
							style={{
								backgroundColor: '#000000',
								borderRadius: '0px 0px 0px 10px',
							}}
						>
							<MDTypography variant='h5' style={{ color: 'var(--backgroundBody)' }}>
								Por aprobar
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					{content}
					{item.estatus !== 'Creado' &&
						!item.validado &&
						tipo_usuario === 'ADMINISTRADOR' && (
							<>
								<MDBox
									mt={5}
									display={'flex'}
									flexWrap={'wrap'}
									justifyContent={'space-between'}
									width={'100%'}

									// alignItems={'center'}
								>
									<MDBox
										style={{
											minWidth: '100px',
											width: '45%',
										}}
									>
										<MDButton
											variant='contained'
											type='button'
											// color='error'
											onClick={aprobarEvento}
											style={{
												backgroundColor: 'var(--colorPrimary)',
												width: '100%',
												textTransform: 'capitalize',
												fontWeight: 'bold',
												fontSize: '1rem',
												color: 'var(--backgroundBody)',
											}}
											disabled={enviandoState}
										>
											{enviandoState ? 'Aprobando...' : 'Aprobar'}
										</MDButton>
									</MDBox>
									<MDBox
										style={{
											minWidth: '100px',
											width: '45%',
										}}
									>
										<MDButton
											onClick={descartarEvento}
											type='button'
											variant='contained'
											color='primary'
											style={{
												backgroundColor: 'var(--colorSecondary)',
												width: '100%',
												textTransform: 'capitalize',
												fontWeight: 'normal',
												fontSize: '1rem',
												color: 'var(--backgroundBody)',
											}}
											disabled={enviandoDescartarState}
										>
											{enviandoDescartarState ? 'Descartando...' : 'Descartar'}
										</MDButton>
									</MDBox>
								</MDBox>
							</>
						)}
				</MDBox>
			</Card>
			<Modal
				open={openModal}
				onClose={handleClose}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
		</MDBox>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default EventCard;
