import { Dispatch } from 'redux';
import { axiosClient, getToken } from 'config/axiosConfig';
import {
	startLoadingSignup,
	endLoadingSignup,
	registering_Ok,
	registering_False,
} from './signupSlice';
import { showErrors } from 'helpers/showErrors';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface newUserModel {
	nombre: string;
	email: string;
	password: string;
	codigo_telefono: string;
	telefono: number;
	estado: string;
	codigo_postal?: string;
	tipo_usuario: string;
	fecha_nacimiento?: string;
	gener?: string;
}

export const postNewUser = (usuarioData: newUserModel, actions: any) => {
	return async (dispatch: Dispatch, getState: any) => {
		dispatch(startLoadingSignup());

		await axiosClient
			.post(`/usuarios`, usuarioData)
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 201) {
					toast.success(respuesta.data.mensaje);

					setTimeout(() => {
						dispatch(endLoadingSignup());
						actions.setSubmitting(false);
						actions.resetForm();
						console.log('Ejecutando setTimeout');

						dispatch(registering_Ok());
					}, 2000);
				}
			})
			.catch((error) => {
				showErrors(error, dispatch, actions);
			});

		// dispatch(setPokemons({ pokemons: data.results, page: page + 1 }));
	};
};
export const cleanIsRegister = () => {
	return async (dispatch: Dispatch, getState: any) => {
		dispatch(registering_False());
	};
};

export const getSates = async () => {
	await getToken();
	return await axiosClient.get(`/estados`);
};
