const form = {
	formId: 'confirmPassword-form',
	formField: {
		password: {
			name: 'password',
			label: 'Ingresa tu nueva contraseña',
			type: 'password',
			errorMsg: 'Contraseña es requerida.',
			invalidMsg: 'Tu contraseña debe tener más de 6 caracteres.',
		},
		repeatPassword: {
			name: 'repeatPassword',
			label: 'Confirma tu nueva contraseña',
			type: 'password',
			errorMsg: 'Confirma contraseña es requerido.',
			invalidMsg: 'Tus contraseñas no coinciden.',
		},
	},
};

export default form;
