import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EventosDestacados from 'pantallas/dashboard/comprador/components/Eventos destacados/EventosDestacados';
import Footer from 'pantallas/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const EventoSuccess = () => {
	const navigate = useNavigate();
	const { purchase } = useSelector((state: RootState) => state.events);
	const goMyEvent = () => {
		navigate(`/user/MyEvents/${purchase.id_venta}`);
	};
	const goDownloadMyEvent = () => {
		navigate(`/user/MyEvents/${purchase.id_venta}`);
	};
	return (
		<>
			<Navbar />
			<MDBox display={'flex'} width={'100%'} pt={11}>
				<MDBox display={'flex'} justifyContent={'center'} width={'100%'} p={3}>
					<MDBox
						display={'flex'}
						alignItems={'center'}
						flexDirection={'column'}
						style={{ width: '100%', maxWidth: '800px' }}
						textAlign={'center'}
					>
						<MDTypography pt={8} mb={2} ml={2} variant={'h2'}>
							¡Muchas gracias por tu compra!
						</MDTypography>
						<p>
							Tus boletos para{' '}
							<span style={{ fontWeight: 'bold' }}>
								{purchase.evento.nombre ? purchase.evento.nombre : 'Nombre del evento'}
							</span>{' '}
							el{' '}
							<span style={{ fontWeight: 'bold' }}>
								{purchase.evento.fecha ? purchase.evento.fecha : 'fecha'}
							</span>{' '}
							en{' '}
							<span style={{ fontWeight: 'bold' }}>
								{purchase.evento.Venue ? purchase.evento.Venue.nombre : 'Ubicación'}
							</span>{' '}
							han sido enviados a tu correo electrónico y están listos para
							descargarse.
						</p>
						<MDTypography pt={5} variant={'body2'}>
							ID de compra {purchase.id_venta ? purchase.id_venta : '000000'}
						</MDTypography>
						<MDButton
							// disabled={isLoading}
							variant='gradient'
							style={{
								backgroundColor: '#EF3024',
								color: '#ffffff',
								textTransform: 'capitalize',
								fontSize: '1rem',
								maxWidth: '350px',
								marginTop: '2rem',
							}}
							fullWidth
							type='submit'
							onClick={goMyEvent}
						>
							<RemoveRedEyeOutlinedIcon />{' '}
							<span style={{ marginLeft: '10px' }}> Ver boletos</span>
						</MDButton>
						<MDButton
							// disabled={isLoading}
							variant='gradient'
							style={{
								backgroundColor: '#000000',
								color: '#ffffff',
								textTransform: 'capitalize',
								fontSize: '1rem',
								maxWidth: '350px',
								marginTop: '1rem',
							}}
							fullWidth
							type='submit'
							onClick={goDownloadMyEvent}
						>
							<FileDownloadOutlinedIcon />
							<span style={{ marginLeft: '10px' }}> Descargar boletos</span>
						</MDButton>
						<MDBox mt={5} style={{ width: '100%', maxWidth: '650px' }}>
							<p style={{ fontWeight: '300' }}>
								Puedes descargarlos ahora o las veces que sean necesarias en la sección{' '}
								<span
									className='textBtn'
									style={{ color: 'var(--colorPrimary)', fontWeight: 'bold' }}
									onClick={() => navigate('/user/MyEvents')}
								>
									Mis eventos.
								</span>
							</p>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox ml={3}>
				<EventosDestacados />
			</MDBox>
			<Footer />
		</>
	);
};

export default EventoSuccess;
