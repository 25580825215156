import { Card, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { deleteUsuario, patchUsuario } from 'store/slices/usuarios';
import { toast } from 'react-toastify';

const ConfirmActionUserModal = ({
	handleClose,
	user,
	getUsuariosData,
	actionType,
}: any) => {
	const confirmAction = () => {
		switch (actionType) {
			case 'Inhabilitar':
				return estatusUsuer('Inactivo');
			case 'Habilitar':
				return estatusUsuer('Activo');
			case 'Eliminar':
				return estatusUsuer('Eliminado');

			default:
				return;
		}
	};
	const estatusUsuer = (estatus: string) => {
		const venuesAprobar = patchUsuario(user.id, {
			estatus: estatus,
		});
		venuesAprobar
			.then((respuesta) => {
				if (respuesta.status === 200) {
					toast.success(respuesta.data.mensaje);
					getUsuariosData();
					setTimeout(() => {
						handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				toast.error(error.mensaje);
				console.log(error);
				handleClose();
			});
	};
	// const deleteUsuer = () => {
	// 	const userDelete = deleteUsuario(user.id);
	// 	userDelete
	// 		.then((respuesta) => {
	// 			if (respuesta.status === 200) {
	// 				toast.success(respuesta.data.mensaje);
	// 				getUsuariosData();
	// 				setTimeout(() => {
	// 					handleClose();
	// 				}, 2000);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			toast.error(error.mensaje);
	// 			console.log(error);
	// 			handleClose();
	// 		});
	// };
	return (
		<>
			<MDBox
				// width={'100%'}
				width={'80vw'}
				maxWidth={'400px'}
				// style={{ backgroundColor: 'tomato' }}
			>
				<Card>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								right: '0px',
								// top: '0px',
								backgroundColor: '#fff',
								border: '1px solid #ffffff',
							}}
						>
							<CloseIcon style={{ color: '#3E3E3E' }} />
						</IconButton>
					</Tooltip>
					<MDBox
						p={5}
						pt={7}
						pb={5}
						display={'flex'}
						flexDirection={'column'}
						width={'100%'}
					>
						<MDTypography textAlign={'center'} variant={'h3'}>
							{actionType === 'Inhabilitar' && 'Deshabilitar Usuario'}
							{actionType === 'Habilitar' && 'Habilitar Usuario'}
							{actionType === 'Eliminar' && 'Eliminar Usuario'}
						</MDTypography>
						<MDBox mt={2}>
							<MDTypography variant={'body2'} textAlign={'center'}>
								{actionType === 'Inhabilitar' &&
									'¿Estas seguro que deseas deshabilitar este usuario?'}
								{actionType === 'Habilitar' &&
									'¿Estas seguro que deseas habilitar este usuario?'}
								{actionType === 'Eliminar' &&
									'¿Estas seguro que deseas Eliminar este usuario?'}
							</MDTypography>
						</MDBox>
						<MDBox mt={2}>
							<MDTypography variant={'body2'} textAlign={'center'}>
								{actionType === 'Inhabilitar' &&
									'El usuario ya no podrá utilizar el sistema a menos que lo habilites de nuevo.'}
								{actionType === 'Habilitar' && 'El usuario será habilitado de nuevo.'}
								{actionType === 'Eliminar' &&
									'El usuario ya no podrá utilizar el sistema a menos que lo des de alta de nuevo.'}
							</MDTypography>
						</MDBox>
						<MDBox
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							mt={4}
						>
							<MDBox
								style={{
									minWidth: '100px',
									width: '100%',
								}}
							>
								<MDButton
									variant='contained'
									type='button'
									// color='error'
									onClick={confirmAction}
									style={{
										backgroundColor: 'var(--colorPrimary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'bold',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Aceptar
								</MDButton>
							</MDBox>
							<MDBox
								mt={2}
								style={{
									minWidth: '100px',
									width: '100%',
								}}
							>
								<MDButton
									onClick={() => handleClose()}
									type='button'
									variant='contained'
									color='primary'
									style={{
										backgroundColor: 'var(--colorSecondary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'normal',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Cancelar
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</>
	);
};

export default ConfirmActionUserModal;
