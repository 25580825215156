import checkout from './form';

const {
	formField: {
		userName,
		email,
		birthdate,
		nacionality,
		cityOfResidence,
		gender,
		countryCode,
		tel,
	},
} = checkout;

const initialValues = {
	[userName.name]: '',
	[email.name]: '',
	[birthdate.name]: '',
	[nacionality.name]: '',
	[cityOfResidence.name]: '',
	[gender.name]: '',
	[countryCode.name]: '',
	[tel.name]: '',
};

export default initialValues;
