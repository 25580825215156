import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import BannerInformacionEventos from 'pantallas/user/admin/dashboard/components/BannerInformacionEventos/BannerInformacionEventos';
import CardInfoSection from 'pantallas/user/admin/dashboard/components/CardInfoSection/CardInfoSection';
import { ToastContainer } from 'react-toastify';
import EventosRecientes from './eventosRecientes/EventosRecientes';
const Dashboard = () => {
	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox
					display={'flex'}
					width={'100%'}
					maxWidth={'650px'}
					// style={{ backgroundColor: 'teal' }}
				>
					<MDTypography
						variant={'h3'}
						// style={{ margin: '0rem 1rem 1rem 1rem' }}
					>
						¡Bienvenido a Fullpass!
					</MDTypography>
				</MDBox>
				<MDBox>
					<CardInfoSection />
				</MDBox>
				<MDBox>
					<BannerInformacionEventos />
				</MDBox>
				<MDBox>
					<EventosRecientes />
				</MDBox>
			</MDBox>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default Dashboard;
