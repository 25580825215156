import checkout from './form';

const {
	formField: { country, language, Moneda },
} = checkout;

const initialValues = {
	[country.name]: '',
	[language.name]: '',
	[Moneda.name]: '',
};

export default initialValues;
