import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';
import uploadIMG from 'assets/images/icons/uploadIMG.svg';
import './NewEvento.css';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getCategorias } from 'store/slices/categories';
import { getVenues } from 'store/slices/venues';
import { postEventos } from 'store/slices/events';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';

const NewEvento = () => {
	const { id, tipo_usuario } = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const [categoriesArray, setCategoriesArray] = useState<any[]>([]);
	const [subcategoriesArray, setSubcategoriesArray] = useState<any[]>([]);
	const [venuesArray, setVenuesArray] = useState<any[]>([]);

	const [enviandoState, setEnviandoState] = useState(false);

	const [boletosArray, setBoletosArray] = useState<any>([
		{
			precio: 0,
			seccion: '',
			cantidad_total: 0,
			precio_eventero: 0,
			comision: 12,
		},
	]);

	const [formaPaEcommerce, setFormaPaEcommerce] = useState(true);
	const [formaPagoLigaPago, setFormaPagoLigaPago] = useState(false);
	const [formaPagoTransferencia, setFormaPagoTransferencia] = useState(false);
	const [formaPagoReferencia, setFormaPagoReferencia] = useState(false);
	const [formaPagoVentaTerceros, setFormaPagoRVentaTerceros] = useState(false);
	const [checkedValue, setcheckedValue] = useState({
		check: false,
	});
	const handleInputChangeChecked = (event: any) => {
		setcheckedValue({
			...checkedValue,
			[event.target.name]: event.target.checked,
		});
	};

	const [formas_pagoArray, setformas_pagoArray] = useState<any>([{ id: 1 }]);
	//handleInputChange
	const updateState = (index: any) => (e: any) => {
		const newArray = boletosArray.map((item: any, i: any) => {
			if (index === i) {
				return { ...item, [e.target.name]: e.target.value };
			} else {
				return item;
			}
		});
		setBoletosArray(newArray);
	};

	const [fileValue, setFileValue] = useState<any>({});
	const [fileValue2, setFileValue2] = useState<any>({});
	const [fileValue3, setFileValue3] = useState<any>({});
	const [fileValue4, setFileValue4] = useState<any>({});
	const [fileValue5, setFileValue5] = useState<any>({});
	const handleFileInput = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue(files0);
		}

		console.log('files0', files0);
	};
	const handleFileInput2 = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue2(files0);
		}

		console.log('files0', files0);
	};
	const handleFileInput3 = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue4(files0);
		}

		console.log('files0', files0);
	};
	const handleFileInput4 = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue5(files0);
		}

		console.log('files0', files0);
	};
	const handleFileInput5 = (e: any) => {
		// setProgress(0);
		// setSelectedFile(e.target.files[0]);
		const files0 = e.target.files[0];
		if (files0) {
			setFileValue3(files0);
		}

		console.log('files0', files0);
	};

	const newSection = () => {
		setBoletosArray([
			...boletosArray,
			{
				precio: 0,
				seccion: '',
				cantidad_total: 0,
				precio_eventero: 0,
				comision: 12,
			},
		]);
	};

	const [eventoValue, setEventoValue] = useState<any>({
		id: null,
		comision: 4.9,
		id_usuario_creacion: id,
		nombre: '',
		id_categoria: '',
		id_subcategoria: '',
		fecha: '',
		hora: '',
		id_venue: '',
		descripcion: '',
		url_facebook: '',
		url_instagram: '',
		url_twitter: 'https://www.twitter.com/ejemplo/',
		boletos: boletosArray,
		formas_pago: formas_pagoArray,
		preventa: checkedValue.check,
		id_estado: 1,
	});
	const handleInputChangeEvento = (event: any) => {
		setEventoValue({
			...eventoValue,
			[event.target.name]: event.target.value,
		});
	};

	const submitForm = () => {
		setEnviandoState(true);

		console.log('Enviando::', eventoValue);
		console.log('EnviandoBoletos::', boletosArray);
		console.log('Enviandockecket::', checkedValue);

		const data = JSON.stringify({
			id: null,
			comision: 4.9,
			id_usuario_creacion: id,
			nombre: eventoValue.nombre,
			id_categoria: Number(eventoValue.id_categoria),
			id_subcategoria: Number(eventoValue.id_subcategoria),
			fecha: eventoValue.fecha,
			hora: eventoValue.hora,
			id_venue: Number(eventoValue.id_venue),
			descripcion: eventoValue.descripcion,
			url_facebook: eventoValue.url_facebook,
			url_instagram: eventoValue.v,
			url_twitter: 'https://www.twitter.com/ejemplo/',
			boletos: boletosArray,
			formas_pago: formas_pagoArray,
			preventa: checkedValue.check,
			id_estado: 1,
		});
		console.log('EnviandoDATA::', data);
		const bodyFormData = new FormData();
		bodyFormData.append('data', data);

		if (fileValue.name) {
			console.log('fileValueEXist::', fileValue);
			bodyFormData.append('banners', fileValue);
		}
		if (fileValue2.name) {
			console.log('fileValueEXist::', fileValue2);
			bodyFormData.append('mapas', fileValue2);
		}

		const enviarCategoria = postEventos(bodyFormData);
		enviarCategoria
			.then((respuesta) => {
				console.log('post', respuesta);
				if (respuesta.status === 201) {
					console.log('post200', respuesta);
					toast.success(respuesta.data.mensaje);
					// categoriesGet();
					setTimeout(() => {
						setEnviandoState(false);
						if (tipo_usuario === 'ADMINISTRADOR') navigate('/admin/events');
						if (tipo_usuario === 'PRODUCTOR') navigate('/producer/events');
						// categoriesGet();
						// handleClose();
					}, 2000);
				}
			})
			.catch((error) => {
				toast.error(error.response.data.mensaje);
				console.log('patchError', error);
				categoriesGet();
				setTimeout(() => {
					setEnviandoState(false);
					// handleClose();
				}, 2000);
			});
	};

	const categoriesGet = (): void => {
		const categoriesGet = getCategorias();
		categoriesGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					const arrayPendientesdeAprobar = respuesta.data.data.filter(
						(item: any) => !item.activo
					);
					const arrayAprobados = respuesta.data.data.filter(
						(item: any) => item.activo
					);
					console.log('arrayAprobadosCategorias::', arrayAprobados);

					setCategoriesArray(arrayAprobados ? arrayAprobados : []);
					// setCategoriesPendingArray(
					// 	arrayPendientesdeAprobar ? arrayPendientesdeAprobar : []
					// );
				}
			})
			.catch((error) => {
				console.log(error);
				setCategoriesArray([]);
				// setCategoriesPendingArray([]);
			});
	};
	const venuesGet = (): void => {
		const venuessGet = getVenues();
		venuessGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					const arrayPendientesdeAprobar = respuesta.data.data.filter(
						(item: any) => item.estatus === 'Pendiente aprobación'
					);
					const arrayAprobados = respuesta.data.data.filter(
						(item: any) => item.estatus === 'Activo'
					);

					setVenuesArray(arrayAprobados ? arrayAprobados : []);
				}
			})
			.catch((error) => {
				console.log(error);
				setVenuesArray([]);
			});
	};
	const goEventos = () => {
		if (tipo_usuario === 'ADMINISTRADOR') navigate('/admin/events');
		if (tipo_usuario === 'PRODUCTOR') navigate('/producer/events');
	};

	useEffect(() => {
		categoriesGet();
		venuesGet();
	}, []);

	// Effecto para llenar las subcategorias
	useEffect(() => {
		if (eventoValue.id_categoria) {
			console.log('eventoValue.id_categoria', eventoValue.id_categoria);
			const subCategories = categoriesArray.find(
				(item: any) => item.id === Number(eventoValue.id_categoria)
			);

			console.log('subCategories.Subcategorias', subCategories.Subcategorias);
			setSubcategoriesArray(subCategories.Subcategorias);
		}
	}, [eventoValue.id_categoria]);
	const goPrevisualizar = () => {
		if (tipo_usuario === 'ADMINISTRADOR') navigate('/event/prev');
		if (tipo_usuario === 'PRODUCTOR') navigate('/event/prev');
	};

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox display={'flex'} width={'100%'}>
					<MDTypography
						variant={'h3'}
						// style={{ margin: '0rem 1rem 1rem 1rem' }}
					>
						Solicitud de alta de evento
					</MDTypography>
				</MDBox>
				{/* 1 */}
				<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
					<MDTypography mt={1} variant='h6'>
						Nombre del evento
					</MDTypography>
					<input
						type='text'
						placeholder='Nombre del evento'
						className='inputCategoria'
						value={eventoValue.nombre}
						name='nombre'
						onChange={handleInputChangeEvento}
					/>
				</MDBox>
				{/* 2 */}
				<MDBox display={'flex'} width={'100%'}>
					<MDBox display={'flex'} mr={1} flexDirection={'column'} width={'100%'}>
						<MDTypography
							// style={{ margin: '0rem 1rem 0rem 1rem' }}
							mt={2}
							variant={'h5'}
						>
							Categoría del evento
						</MDTypography>
						<MDBox>
							<div className='fp-selectRadius' style={{ width: '100%' }}>
								<select
									className='fp-selectedRadius'
									name={'id_categoria'}
									value={eventoValue.id_categoria}
									onChange={handleInputChangeEvento}
									style={{ width: '110%' }}
								>
									<option value={''}>Selecciona una opción</option>

									{categoriesArray.map((item: any) => (
										<option key={item.id} value={item.id}>
											{item.nombre}
										</option>
									))}
								</select>
							</div>
						</MDBox>
					</MDBox>
					{/* Subcategoria */}
					<MDBox display={'flex'} ml={1} flexDirection={'column'} width={'100%'}>
						<MDTypography mt={2} variant={'h5'}>
							Subcategoría del evento
						</MDTypography>
						<MDBox>
							<div className='fp-selectRadius' style={{ width: '100%' }}>
								<select
									className='fp-selectedRadius'
									name={'id_subcategoria'}
									value={eventoValue.id_subcategoria}
									onChange={handleInputChangeEvento}
									style={{ width: '110%' }}
								>
									<option value={''}>Selecciona una opción</option>

									{subcategoriesArray.map((item: any) => (
										<option key={item.id} value={item.id}>
											{item.nombre}
										</option>
									))}
								</select>
							</div>
						</MDBox>
					</MDBox>
				</MDBox>
				{/* 3 */}
				<MDBox display={'flex'} width={'100%'}>
					<MDBox display={'flex'} mr={1} flexDirection={'column'} width={'100%'}>
						<MDTypography
							// style={{ margin: '0rem 1rem 0rem 1rem' }}
							mt={2}
							variant={'h5'}
						>
							Fecha
						</MDTypography>
						<MDBox>
							<div
								// className='fp-selectRadius'
								style={{ width: '100%' }}
							>
								<input
									type='date'
									className='inputCategoria'
									name={'fecha'}
									value={eventoValue.fecha}
									onChange={handleInputChangeEvento}
									// style={{ width: '110%' }}
								/>
								{/* <select
									className='fp-selectedRadius'
									name={'bank.name'}
									value={'values.bank'}
									// onChange={handleChange}
									style={{ width: '110%' }}
								>
									<option value={''}>Selecciona una opción</option>

									{categoryArray.map((item: any) => (
										<option key={item.id} value={item.id}>
											{item.nombre}
										</option>
									))}
								</select> */}
							</div>
						</MDBox>
					</MDBox>
					<MDBox display={'flex'} mr={1} flexDirection={'column'} width={'100%'}>
						<MDTypography
							// style={{ margin: '0rem 1rem 0rem 1rem' }}
							mt={2}
							variant={'h5'}
						>
							Hora
						</MDTypography>
						<MDBox>
							<input
								type='time'
								placeholder='Hora'
								className='inputCategoria'
								value={eventoValue.hora}
								name='hora'
								onChange={handleInputChangeEvento}
								style={{ height: '45px' }}
							/>
						</MDBox>
					</MDBox>

					<MDBox display={'flex'} ml={1} flexDirection={'column'} width={'100%'}>
						<MDTypography mt={2} variant={'h5'}>
							Venue
						</MDTypography>
						<MDBox>
							<div className='fp-selectRadius' style={{ width: '100%' }}>
								<select
									className='fp-selectedRadius'
									name={'id_venue'}
									value={eventoValue.id_venue}
									onChange={handleInputChangeEvento}
									style={{ width: '110%' }}
								>
									<option value={''}>Selecciona una opción</option>

									{venuesArray.map((item: any) => (
										<option key={item.id} value={item.id}>
											{item.nombre}
										</option>
									))}
								</select>
							</div>
						</MDBox>
					</MDBox>
				</MDBox>
				{/* 1 */}
				<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
					<MDTypography mt={1} variant='h6'>
						Descripción del evento
					</MDTypography>
					<textarea
						placeholder='Descripción del evento'
						className='inputCategoria'
						value={eventoValue.descripcion}
						name='descripcion'
						onChange={handleInputChangeEvento}
						rows={10}
						cols={50}
					></textarea>

					{/* <input
						type='textarea'
						placeholder='Nombre de usuario'
						className='inputCategoria'
						// value={usuarioModalValue.nombre}
						name='nombre'
						// onChange={handleInputChangeUsuario}
					/> */}
				</MDBox>
				{/* 1  imagenes */}
				<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
					<MDTypography mt={1} variant='h6'>
						Imágenes del evento
					</MDTypography>
					<MDTypography mt={1} variant='body2'>
						Se recomienda subir archivos formato .jpg con medidas de 0000x0000 px y
						con un peso menor de 900kb
					</MDTypography>
					<MDBox
						display={'flex'}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
						width={'100%'}
					>
						{/* Banner */}
						<MDBox
							m={1}
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							position={'relative'}
						>
							<input
								id='imgFile2'
								className='fpInputFileImg'
								type='file'
								// name='file'
								// value={file}
								onChange={handleFileInput}
								multiple={false}
								accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg, .pdf, .webp '
							/>
							<MDTypography
								style={{
									textAlign: 'center',

									maxWidth: '130px',
									fontSize: '13px',
								}}
							>
								Banner evento*
							</MDTypography>
							{!fileValue.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginLeft: '1.9rem' }}>
										<img
											width={'100px'}
											className='fpInputFileIMG'
											src={uploadIMG}
											alt='ImagenCategoria'
										/>
									</div>
								</div>
							)}
							{fileValue.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
										<span>{fileValue.name}</span>
									</div>
								</div>
							)}
						</MDBox>
						{/* Mapa Venue */}
						<MDBox
							m={1}
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							position={'relative'}
						>
							<input
								id='imgFile2'
								className='fpInputFileImg'
								type='file'
								// name='file'
								// value={file}
								onChange={handleFileInput2}
								multiple={false}
								accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg, .pdf, .webp '
							/>
							<MDTypography
								style={{
									textAlign: 'center',
									maxWidth: '130px',
									fontSize: '13px',
								}}
							>
								Mapa venue*
							</MDTypography>
							{!fileValue2.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginLeft: '1.9rem' }}>
										<img
											width={'100px'}
											className='fpInputFileIMG'
											src={uploadIMG}
											alt='ImagenCategoria'
										/>
									</div>
								</div>
							)}
							{fileValue2.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
										<span>{fileValue2.name}</span>
									</div>
								</div>
							)}
						</MDBox>
						{/* Secciones en mapa */}
						<MDBox
							m={1}
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							position={'relative'}
						>
							<input
								disabled
								id='imgFile2'
								className='fpInputFileImg'
								type='file'
								// name='file'
								// value={file}
								onChange={handleFileInput3}
								multiple={false}
								accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg, .pdf, .webp '
							/>
							<MDTypography
								style={{
									textAlign: 'center',
									maxWidth: '130px',
									fontSize: '13px',
								}}
							>
								Secciones en mapa*
							</MDTypography>
							{!fileValue3.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginLeft: '1.9rem' }}>
										<img
											width={'100px'}
											className='fpInputFileIMG'
											src={uploadIMG}
											alt='ImagenCategoria'
										/>
									</div>
								</div>
							)}
							{fileValue3.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
										<span>{fileValue3.name}</span>
									</div>
								</div>
							)}
						</MDBox>
						{/* Hasta 5 imagenes */}
						<MDBox
							m={1}
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							position={'relative'}
						>
							<input
								disabled
								id='imgFile2'
								className='fpInputFileImg'
								type='file'
								// name='file'
								// value={file}
								onChange={handleFileInput4}
								multiple={false}
								// accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg, .pdf, .txt '
							/>
							<MDTypography
								style={{
									textAlign: 'center',
									maxWidth: '130px',
									fontSize: '13px',
								}}
							>
								Hasta 5 imágenes miniatura 500x500px
							</MDTypography>
							{!fileValue4.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginLeft: '1.9rem' }}>
										<img
											width={'100px'}
											className='fpInputFileIMG'
											src={uploadIMG}
											alt='ImagenCategoria'
										/>
									</div>
								</div>
							)}
							{fileValue4.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
										<span>{fileValue4.name}</span>
									</div>
								</div>
							)}
						</MDBox>
						{/* Hasta 10 imagenes extras */}
						<MDBox
							m={1}
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							position={'relative'}
						>
							<input
								disabled
								id='imgFile2'
								className='fpInputFileImg'
								type='file'
								// name='file'
								// value={file}
								onChange={handleFileInput5}
								multiple={false}
								// accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg, .pdf, .txt '
							/>
							<MDTypography
								style={{
									textAlign: 'center',
									maxWidth: '130px',
									fontSize: '13px',
								}}
							>
								Hasta 10 imágenes adicionales
							</MDTypography>
							{!fileValue5.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginLeft: '1.9rem' }}>
										<img
											width={'100px'}
											className='fpInputFileIMG'
											src={uploadIMG}
											alt='ImagenCategoria'
										/>
									</div>
								</div>
							)}
							{fileValue5.name && (
								<div
									className='fpInputFileIMGContainer'
									// style={{
									// 	display: 'flex',
									// 	width: '100%',
									// 	justifyContent: 'center',
									// 	alignItems: 'center',
									// 	backgroundColor: 'teal',
									// }}
								>
									<div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
										<span>{fileValue5.name}</span>
									</div>
								</div>
							)}
						</MDBox>
					</MDBox>
				</MDBox>
				{/* 1 Facebook URL*/}
				<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
					<MDTypography mt={1} variant='h6'>
						URL Facebook evento
					</MDTypography>
					<input
						type='text'
						placeholder='URL Facebook evento'
						className='inputCategoria'
						value={eventoValue.url_facebook}
						name='url_facebook'
						onChange={handleInputChangeEvento}
					/>
				</MDBox>
				{/* 1 Instagram URL*/}
				<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
					<MDTypography mt={1} variant='h6'>
						URL Instagram evento
					</MDTypography>
					<input
						type='text'
						placeholder='URL Instagram evento'
						className='inputCategoria'
						value={eventoValue.url_instagram}
						name='url_instagram'
						onChange={handleInputChangeEvento}
					/>
				</MDBox>
				{/* 1 Preventa Boletos*/}
				<MDBox
					display={'flex'}
					alignItems={'flex-end'}
					justifyContent={'space-between'}
					mt={2}
					width={'100%'}
				>
					<MDTypography mt={1} variant='h5'>
						Boletos
					</MDTypography>
					<MDBox>
						{/* <input
							type='checkbox'
							className='flpssCheckbox'
							name='check'
							checked={mychecked}
							onChange={(e) => setMyChecked(e.target.checked)}
						/> */}
						<FormControlLabel
							label={'Preventa'}
							control={
								// <input
								// 	type='checkbox'
								// 	className='flpssCheckbox'
								// 	// name='check'
								// 	checked={checked}
								// 	onChange={(e) => setChecked(e.target.checked)}
								// />
								<Checkbox
									// checked={checked}
									name='check'
									checked={checkedValue.check}
									// value={item.checked}
									onChange={handleInputChangeChecked}
									inputProps={{ 'aria-label': 'controlled' }}
									// sx={{
									// 	color: pink[800],
									// 	'&.Mui-checked': {
									// 		color: pink[600],
									// 	},
									// }}
								/>
							}
							style={{
								display: 'flex',
								whiteSpace: 'pre-wrap',
							}}
						/>
					</MDBox>
				</MDBox>
				{/* Tabla */}
				<MDBox
					mt={1}
					display={'flex'}
					flexDirection={'column'}
					style={{ minWidth: '230px', width: '100%' }}
				>
					<div className='fpTableContainer' style={{ width: '100%' }}>
						<MDBox
							mt={1}
							display={'flex'}
							width={'100%'}
							p={1}
							style={{ backgroundColor: '#F9F9F9', overflowX: 'scroll' }}
						>
							{/* Nombre de zona */}
							<MDBox
								display={'flex'}
								alignItems={'center'}
								// flexDirection={'column'}
								margin={2}
								style={{
									minWidth: '16%',
								}}
							>
								<MDTypography variant='body2'>Nombre de zona</MDTypography>
								<MDTypography
									component={IconButton}
									variant='body1'
									color='primary'
									fontWeight='medium'
									textGradient
									onClick={newSection}
								>
									+
								</MDTypography>
							</MDBox>
							{/* No. de boletos */}
							<MDBox
								display={'flex'}
								flexDirection={'column'}
								justifyContent={'center'}
								m={2}
								style={{
									minWidth: '16%',
								}}
							>
								<MDTypography variant='body2'>No. de boletos</MDTypography>
							</MDBox>

							{/* Precio eventero */}
							<MDBox
								display={'flex'}
								flexDirection={'column'}
								justifyContent={'center'}
								m={2}
								style={{
									minWidth: '16%',
								}}
							>
								<MDTypography variant='body2'>Precio eventero</MDTypography>
							</MDBox>
							{/* Más comisión */}
							<MDBox
								display={'flex'}
								flexDirection={'column'}
								justifyContent={'center'}
								m={2}
								style={{
									minWidth: '16%',
								}}
							>
								<MDTypography variant='body2'>Más comisión</MDTypography>
							</MDBox>
							{/* Precio venta */}
							<MDBox
								display={'flex'}
								flexDirection={'column'}
								justifyContent={'center'}
								m={2}
								style={{
									minWidth: '16%',
								}}
							>
								<MDTypography variant='body2'>Precio venta</MDTypography>
							</MDBox>
						</MDBox>
						{/* Secciones Edit */}
						{boletosArray.map((item: any, i: any) => {
							const comisionPorcentaje = Number(item.comision) * 0.01;
							const comisionTotal = Number(item.precio_eventero) * comisionPorcentaje;

							const precioVenta = Number(item.precio_eventero) + comisionTotal;
							item.precio = precioVenta;

							return (
								<MDBox
									key={i}
									// mt={1}
									display={'flex'}
									width={'100%'}
									p={1}
									// style={{ backgroundColor: '#F9F9F9' }}
								>
									{/* Nombre zona*/}
									<MDBox
										margin={2}
										display={'flex'}
										flexDirection={'column'}
										style={
											{
												// minWidth: '230px',
											}
										}
									>
										<input
											type='text'
											placeholder='Nombre de zona'
											className='inputCategoria'
											name='seccion'
											value={item.seccion}
											onChange={updateState(i)}
											// style={{ width: '100px' }}
											// disabled={!item.checked}
										/>
									</MDBox>
									{/* No. boletos*/}
									<MDBox
										margin={2}
										display={'flex'}
										flexDirection={'column'}
										style={
											{
												// minWidth: '230px',
											}
										}
									>
										<input
											type='number'
											placeholder='No. de boletos'
											className='inputCategoria'
											name='cantidad_total'
											value={item.cantidad_total}
											onChange={updateState(i)}
											// style={{ width: '100px' }}
											// disabled={!item.checked}
										/>
									</MDBox>
									{/* Precio eventero*/}
									<MDBox
										margin={2}
										display={'flex'}
										flexDirection={'column'}
										style={
											{
												// minWidth: '230px',
											}
										}
									>
										<input
											type='number'
											placeholder='0'
											className='inputCategoria'
											name='precio_eventero'
											value={item.precio_eventero}
											onChange={updateState(i)}
											// style={{ width: '100px' }}
											// disabled={!item.checked}
										/>
									</MDBox>
									{/* Más comisión*/}
									<MDBox
										margin={2}
										display={'flex'}
										flexDirection={'column'}
										style={
											{
												// minWidth: '230px',
											}
										}
									>
										<input
											type='number'
											placeholder='0%'
											className='inputCategoria'
											name='comision'
											value={item.comision}
											disabled={tipo_usuario === 'ADMINISTRADOR' ? false : true}
											onChange={updateState(i)}
											// style={{ width: '100px' }}
											// disabled={!item.checked}
										/>
									</MDBox>
									{/* Precio venta*/}
									<MDBox margin={2} display={'flex'} flexDirection={'column'}>
										<input
											type='number'
											placeholder='Precio venta'
											className='inputCategoria'
											name='precio'
											value={item.precio}
											disabled
											onChange={updateState(i)}
											// style={{ width: '100px' }}
											// disabled={!item.checked}
										/>
									</MDBox>
								</MDBox>
							);
						})}
					</div>
				</MDBox>
				{/* Formas de pago */}
				<MDBox
					mt={1}
					display={'flex'}
					flexDirection={'column'}
					style={{ minWidth: '230px', width: '100%' }}
				>
					<MDTypography mt={1} variant='h5'>
						Formas de pago
					</MDTypography>
					<FormControlLabel
						label={'E-commerce'}
						control={
							<input
								type='checkbox'
								className='flpssCheckbox'
								// name='check'
								checked={formaPaEcommerce}
								onChange={(e) => setFormaPaEcommerce(e.target.checked)}
							/>
						}
						style={{
							display: 'flex',
							whiteSpace: 'pre-wrap',
							marginLeft: '1rem',
							fontWeight: '200',
						}}
					/>
					<FormControlLabel
						label={'Liga de pago'}
						control={
							<input
								type='checkbox'
								className='flpssCheckbox'
								// name='check'
								checked={formaPagoLigaPago}
								onChange={(e) => setFormaPagoLigaPago(e.target.checked)}
							/>
						}
						style={{
							display: 'flex',
							whiteSpace: 'pre-wrap',
							marginLeft: '1rem',
						}}
					/>
					<FormControlLabel
						label={'Transferencias'}
						control={
							<input
								type='checkbox'
								className='flpssCheckbox'
								// name='check'
								checked={formaPagoTransferencia}
								onChange={(e) => setFormaPagoTransferencia(e.target.checked)}
							/>
						}
						style={{
							display: 'flex',
							whiteSpace: 'pre-wrap',
							marginLeft: '1rem',
						}}
					/>
					<FormControlLabel
						label={'Referencias'}
						control={
							<input
								type='checkbox'
								className='flpssCheckbox'
								// name='check'
								checked={formaPagoReferencia}
								onChange={(e) => setFormaPagoReferencia(e.target.checked)}
							/>
						}
						style={{
							display: 'flex',
							whiteSpace: 'pre-wrap',
							marginLeft: '1rem',
						}}
					/>
					<FormControlLabel
						label={'Venta terceros'}
						control={
							<input
								type='checkbox'
								className='flpssCheckbox'
								// name='check'
								checked={formaPagoVentaTerceros}
								onChange={(e) => setFormaPagoRVentaTerceros(e.target.checked)}
							/>
						}
						style={{
							display: 'flex',
							whiteSpace: 'pre-wrap',
							marginLeft: '1rem',
						}}
					/>
				</MDBox>
				{/* Botones */}
				<MDBox
					mt={5}
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					width={'100%'}

					// alignItems={'center'}
				>
					<MDBox
						style={{
							minWidth: '100px',
							width: '30%',
						}}
					>
						<MDButton
							// disabled={isSubmitting}
							disabled={enviandoState}
							variant='contained'
							type='submit'
							// color='error'
							onClick={submitForm}
							style={{
								backgroundColor: 'var(--colorPrimary)',
								width: '100%',
								textTransform: 'capitalize',
								fontWeight: 'bold',
								fontSize: '1rem',
								color: 'var(--backgroundBody)',
							}}
						>
							{enviandoState ? 'Guardando...' : 'Guardar'}
						</MDButton>
					</MDBox>
					<MDBox
						style={{
							minWidth: '100px',
							width: '30%',
						}}
					>
						<MDButton
							onClick={goEventos}
							type='button'
							variant='contained'
							color='primary'
							style={{
								backgroundColor: 'var(--colorSecondary)',
								width: '100%',
								textTransform: 'capitalize',
								fontWeight: 'normal',
								fontSize: '1rem',
								color: 'var(--backgroundBody)',
							}}
						>
							Cancelar
						</MDButton>
					</MDBox>
					<MDBox
						style={{
							minWidth: '100px',
							width: '30%',
						}}
					>
						<MDButton
							onClick={goPrevisualizar}
							type='button'
							variant='outlined'
							color='primary'
							style={{
								backgroundColor: 'transparent',
								border: '1px solid var(--colorPrimary)',
								width: '100%',
								textTransform: 'capitalize',
								fontWeight: 'normal',
								fontSize: '1rem',
								color: 'var(--colorPrimary)',
							}}
						>
							Previsualizar
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default NewEvento;
