// react-router-dom components
import { useLocation, useNavigate } from 'react-router-dom';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Authentication layout components
import IllustrationLayout from './components/illustration';
// formik components
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';

// Image
import bgImage from 'assets/images/illustrations/party2.jpg';
import FormFieldRadius from './components/FormField';

import { useDispatch } from 'react-redux';
import { postNewUser } from 'store/slices/signup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Autocomplete, IconButton } from '@mui/material';
import MDInput from 'components/MDInput';

const selectData = {
	states: [
		'Aguascalientes',
		'Baja California',
		'Baja California Sur',
		'Campeche',
		'Chiapas',
		'Chihuahua',
		'Ciudad de México',
		'Coahuila',
		'Colima',
		'Durango',
		'Estado de México',
		'Guanajuato',
		'Guerrero',
		'Hidalgo',
		'Jalisco',
		'Michoacán',
		'Morelos',
		'Nayarit',
		'Nuevo León',
		'Oaxaca',
		'Puebla',
		'Querétaro',
		'Quintana Roo',
		'San Luis Potosí',
		'Sinaloa',
		'Sonora',
		'Tabasco',
		'Tamaulipas',
		'Tlaxcala',
		'Veracruz',
		'Yucatán',
		'Zacatecas',
	],
};

function SingUp({ handleClose, setOptionModal }: any): JSX.Element {
	const dispatch = useDispatch();
	const location = useLocation();
	console.log(location);
	console.log(location.pathname);
	const { isRegister } = useSelector((state: RootState) => state.signup);
	const navigate = useNavigate();
	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { userName, email, password, repeatPassword, countryCode, tel } =
		formField;

	const [stateValue, setStateValue] = useState({ state: '' });
	// const [selectData, setSelectData] = useState({
	// 	states: [],
	// });

	const submitForm = async (values: any, actions: any) => {
		await dispatch<any>(
			postNewUser(
				{
					nombre: values.userName,
					email: values.email,
					password: values.password,
					codigo_telefono: values.countryCode,
					telefono: values.tel,
					estado: stateValue.state,
					tipo_usuario: 'COMPRADOR',
				},
				actions
			)
		);

		actions.setSubmitting(false);
		// actions.resetForm();
	};

	const handleSubmit = (values: any, actions: any) => {
		console.log('entrando');
		submitForm(values, actions);
	};
	//Navigate->logIn si registro es exitoso
	useEffect(() => {
		if (isRegister) {
			if (location.pathname === '/') {
				navigate('/');
				handleClose();
			} else {
				handleClose();
			}
		}
	}, [isRegister, navigate, handleClose]);

	// useEffect(() => {
	// 	const getStates = getSates();
	// 	getStates
	// 		.then((respuesta) => {
	// 			if (respuesta.status === 200) {
	// 				const satates = respuesta.data.reduce(
	// 					(accumulator: any, currentValue: any) => [
	// 						...accumulator,
	// 						...currentValue.nombre,
	// 					],
	// 					['Alphabet']
	// 				);
	// 				console.log('statesRsponse::::', satates);
	// 				setSelectData(satates);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// }, []);

	return (
		<>
			<MDBox
				width={'80vw'}
				maxWidth={'1300px'}
				// maxHeight={'90vh'}
				// overflow={'auto'}
				// // height={'80%'}
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				// // padding={5}
			>
				<IllustrationLayout
					title='Crear Cuenta Nueva'
					description='Ingresa los datos del formulario para registrarte.'
					illustration={bgImage}
					handleClose={handleClose}
				>
					<Formik
						initialValues={initialValues}
						validationSchema={currentValidation}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, isSubmitting }) => (
							<Form id={formId} autoComplete='off'>
								<MDBox
									display={'flex'}
									flexWrap={'wrap'}
									justifyContent={'center'}

									// alignItems={'center'}
								>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={userName.type}
											label={userName.label}
											name={userName.name}
											value={values.userName}
											onChange={handleChange}
											placeholder={userName.label}
											error={errors.userName && touched.userName}
											success={values.userName.length > 0 && !errors.userName}
											style={{ minWidth: '12rem' }}
											autoComplete='off'
										/>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={email.type}
											label={email.label}
											name={email.name}
											value={values.email}
											onChange={handleChange}
											placeholder={email.label}
											error={errors.email && touched.email}
											success={values.email.length > 0 && !errors.email}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox
									display={'flex'}
									flexWrap={'wrap'}
									justifyContent={'center'}
									// mt={2}
								>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={password.type}
											label={password.label}
											name={password.name}
											value={values.password}
											onChange={handleChange}
											placeholder={password.label}
											error={errors.password && touched.password}
											success={values.password.length > 0 && !errors.password}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={repeatPassword.type}
											label={repeatPassword.label}
											name={repeatPassword.name}
											value={values.repeatPassword}
											onChange={handleChange}
											placeholder={repeatPassword.label}
											error={errors.repeatPassword && touched.repeatPassword}
											success={values.repeatPassword.length > 0 && !errors.repeatPassword}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
									<MDBox
										display={'flex'}
										justifyContent={'space-between'}
										style={{ margin: '0rem 1rem 1rem 1rem' }}
									>
										<FormFieldRadius
											type={countryCode.type}
											label={countryCode.label}
											name={countryCode.name}
											value={values.countryCode}
											onChange={handleChange}
											placeholder={'+52'}
											// error={errors.tel && touched.tel}
											// success={values.tel.length > 0 && !errors.tel}
											style={{ width: '3rem', marginRight: '1rem' }}
										/>
										<FormFieldRadius
											type={tel.type}
											label={tel.label}
											name={tel.name}
											value={values.tel}
											onChange={handleChange}
											placeholder={tel.label}
											error={errors.tel && touched.tel}
											success={values.tel.length > 0 && !errors.tel}
											style={{ width: '7.5rem', marginLeft: '.5rem' }}
										/>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<Autocomplete
											// defaultValue='Categoria'
											options={selectData.states}
											// name={tel.name}
											value={stateValue.state}
											placeholder='Estado'
											onChange={(event, newValue) => {
												setStateValue({ state: newValue });
											}}
											renderInput={(params) => (
												<MDInput
													{...params}
													label='Estado de residencia'
													InputLabelProps={{ shrink: true }}
												/>
											)}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
								</MDBox>

								<MDBox mt={4} mb={1} display={'flex'} justifyContent={'center'}>
									<MDButton
										disabled={isSubmitting}
										type='submit'
										variant='gradient'
										color='error'
										size='large'
										style={{ minWidth: '12rem' }}
									>
										Crear cuenta
									</MDButton>
								</MDBox>
								<MDBox mt={3} textAlign='center'>
									<MDTypography variant='button' color='text'>
										¿Ya tienes una cuenta?{' '}
										<MDTypography
											disabled={isSubmitting}
											component={IconButton}
											// to='/signup'
											variant='button'
											color='primary'
											fontWeight='medium'
											textGradient
											onClick={() => {
												setOptionModal(1);
											}}
										>
											Ingresa
										</MDTypography>
									</MDTypography>
								</MDBox>
							</Form>
						)}
					</Formik>
				</IllustrationLayout>
			</MDBox>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
}

export default SingUp;
