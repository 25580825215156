import {
	axiosClient,
	axiosClientFormData,
	getToken,
	getTokenFormData,
} from 'config/axiosConfig';
export const getCategorias = async () => {
	await getToken();
	return await axiosClient.get(`categorias`);
};
export const patchCategory = async (categoryId: number, data: {}) => {
	await getTokenFormData();
	return await axiosClientFormData.patch(`categorias/${categoryId}`, data);
};
export const deleteCategoryId = async (categoryId: number) => {
	await getToken();
	return await axiosClient.delete(`categoria/${categoryId}`);
};

export const postCategory = async (data: {}) => {
	await getTokenFormData();
	return await axiosClientFormData.post(`categorias/`, data);
};
