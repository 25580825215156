import { createSlice } from '@reduxjs/toolkit';

export interface iValidationEmpresaInfo {
	id: number;
	nombre: string;
	email: string;
	codigo_postal: string;
	codigo_telefono: string;
	genero: string | null;
	telefono: string;
	tipo_usuario: string;
}
export type iRestablecerPasswordReducer = {
	empresatknInfo: iValidationEmpresaInfo;
	passworUpdated: boolean;
};
const initialState: iRestablecerPasswordReducer = {
	empresatknInfo: {
		id: null,
		nombre: '',
		email: '',
		codigo_postal: '',
		codigo_telefono: '',
		genero: '',
		telefono: '',
		tipo_usuario: '',
	},
	passworUpdated: false,
};

export const restablecerPasswordSlice = createSlice({
	name: 'restablecerPassword',
	initialState,
	reducers: {
		validation_token_success: (state, action) => {
			state.empresatknInfo = action.payload;
		},
		validation_token_clean: (state /* action*/) => {
			state.empresatknInfo = initialState.empresatknInfo;
		},
		password_updated: (state /* action*/) => {
			state.passworUpdated = true;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	validation_token_success,
	validation_token_clean,
	password_updated,
} = restablecerPasswordSlice.actions;
