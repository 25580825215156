import checkout from './form';

const {
	formField: { name, Address, Map, Aforo },
} = checkout;

const initialValues = {
	[name.name]: '',
	[Address.name]: '',
	[Map.name]: '',
	[Aforo.name]: '',
};

export default initialValues;
