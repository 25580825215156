import MDBox from 'components/MDBox';
import './EventoProximoItem.css';

import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

const EventoProximoItem = ({ img, category, eventName, desc }: any) => {
	return (
		<>
			<div className='eventoProximoItem'>
				<div className='eventoProximoCard'>
					<MDBox
						width='100%'
						component='img'
						src={img}
						alt={'title'}
						loading={'lazy'}
						style={{ objectFit: 'cover' }}
					/>
				</div>
				<MDBox display={'flex'} flexDirection={'column'}>
					<MDTypography mt={2} variant={'subtitle1'}>
						{category}
					</MDTypography>
					<MDTypography variant={'h4'}>{eventName}</MDTypography>
					<MDTypography variant={'body1'}>{desc}</MDTypography>
					<MDButton
						variant='outlined'
						color='error'
						style={{
							width: '38%',
							textTransform: 'capitalize',
							fontSize: '18px',
							fontWeight: '700',
						}}
						onClick={() => alert('registro')}
					>
						Registro
					</MDButton>
				</MDBox>
			</div>
		</>
	);
};

export default EventoProximoItem;
