const form = {
	formId: 'MetodoPago-form',
	formField: {
		cardNumber: {
			name: 'cardNumber',
			label: 'Número de tarjeta',
			type: 'number',
			errorMsg: 'Número de tarjeta es requerido.',
			invalidMsg: 'El número de tarjeta no es valido',
		},
		ownerName: {
			name: 'ownerName',
			label: 'Nombre',
			type: 'text',
			errorMsg: 'Nombre es requerido.',
			// invalidMsg: 'Tu nombre no puede ser numerico.',
		},
		ownerLastname: {
			name: 'ownerLastname',
			label: 'Apellidos',
			type: 'text',
			errorMsg: 'Apellidos es requerido.',
			// invalidMsg: 'Tus apellidos no puede ser numerico.',
		},
		monthExpiration: {
			name: 'monthExpiration',
			label: 'MM',
			type: 'number',
			errorMsg: 'MM es requerido.',
			invalidMsg: 'MM no es valido.',
		},
		yearExpiration: {
			name: 'yearExpiration',
			label: 'YY',
			type: 'number',
			errorMsg: 'YY es requerido.',
			invalidMsg: 'YY no es valido.',
		},
	},
};

export default form;
