import * as Yup from 'yup';
import checkout from './form';

const {
	formField: { country, language, Moneda },
} = checkout;

const validations = [
	Yup.object().shape({
		[country.name]: Yup.string().required(country.errorMsg),
		[language.name]: Yup.string().required(language.errorMsg),
		[Moneda.name]: Yup.string().required(Moneda.errorMsg),
	}),
];

export default validations;
