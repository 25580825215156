import MDBox from 'components/MDBox';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, IconButton, Modal, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import MDInput from 'components/MDInput';
import editIcon from 'assets/images/icons/EditRed.svg';
import NuevoVenueModal from '../components/NuevoVenueModal/NuevoVenueModal';
import DataTable from 'components/DataTable';
import { getVenues, getVenuesByParams } from 'store/slices/venues';
import { ToastContainer } from 'react-toastify';
const selectData = {
	estatus: ['Activo', 'Pendiente aprobación'],
	cities: ['Tulum', 'Punta Mita', 'Cancún', 'CDMX'],
};
const initialValue = {
	aforo: '',
	estatus: '',
	nombre: '',
};
const initialArrayVenues = [
	{
		nombre: 'Foro Sol',
		estatus: 'Pendiente de aprobacion',
		aforo: 10000,
		estado: 'Ciudad de México',
	},
	{
		nombre: 'Auditorio Nacional',
		estatus: 'Activo',
		aforo: 10000,
		estado: 'Ciudad de México',
	},
];

const VenuesBusqueda = () => {
	interface iVenueToEdit {
		name: string;
		Address: string;
		Map: string;
		Aforo: string | number;
	}
	const venueToEditInitialValue: iVenueToEdit = {
		name: '',
		Address: '',
		Map: '',
		Aforo: '',
	};

	const [venueToEdit, setVenueToEdit] = useState(venueToEditInitialValue);
	const editVenue = (venue: any) => {
		setVenueToEdit(venue);
		handleOpen();
	};
	//SEARC_BAR LOGIC
	const [filtersValues, setFiltersValues] = useState(initialValue);
	const [searchValue, setSearchValue] = useState({
		nombre: filtersValues.nombre,
	});
	const [searchValueFinal, setSearchValueFinal] = useState({
		nombre: filtersValues.nombre,
	});

	const cleanSearchValue = () => {
		setFiltersValues({ ...filtersValues, nombre: '' });
		setSearchValue({
			nombre: '',
		});
	};
	//handleInputChange
	const handleInputChangeSearch = (event: any) => {
		setSearchValue({
			...searchValue,
			[event.target.name]: event.target.value,
		});
	};
	const goSearch = (e: any) => {
		e.preventDefault();
		setSearchValueFinal({ ...searchValueFinal, nombre: searchValue.nombre });
	};
	//**AFORO LOGIC
	const [aforoValue, setAforoValue] = useState({
		aforo: filtersValues.aforo,
	});
	const handleInputChangeAforo = (event: any) => {
		setAforoValue({
			...aforoValue,
			[event.target.name]: event.target.value,
		});
	};
	const cleanAforoValue = () => {
		setFiltersValues({ ...filtersValues, aforo: '' });
		setAforoValue({
			aforo: '',
		});
	};

	//**City LOGIC
	const [estatusValue, setEstatusValue] = useState({
		estatus: filtersValues.estatus,
	});
	const handleInputChangeEstatus = (event: any) => {
		setEstatusValue({
			...estatusValue,
			[event.target.name]: event.target.value,
		});
	};

	const cleanEstatusValue = () => {
		setFiltersValues({ ...filtersValues, estatus: '' });
		setEstatusValue({
			estatus: '',
		});
	};
	//SEARCH_BAR EFFECTS
	// Efecto que se ejecuta al cargar el componente

	//SEARCH EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, nombre: searchValue.nombre });
		// eslint-disable-next-line
	}, [searchValueFinal]);
	//CATEGORY EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, aforo: aforoValue.aforo });
		// eslint-disable-next-line
	}, [aforoValue]);
	//CITY EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, estatus: estatusValue.estatus });
		// eslint-disable-next-line
	}, [estatusValue]);
	//VENUES LOGIC
	const [openModal, setOpenModal] = useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const [venuesListData, setVenuesListData] = useState(initialArrayVenues);
	const datosTabla = {
		columns: [
			{ Header: 'Venue', accessor: 'Venue', width: '80px', align: 'left' },
			{ Header: 'Estatus', accessor: 'Estatus', align: 'left' },
			{ Header: 'Aforo', accessor: 'Aforo', width: '80px', align: 'left' },
			{ Header: 'Detalles', accessor: 'Detalles', align: 'center' },
		],
		rows: venuesListData
			.map((item) => {
				return {
					Venue: item.nombre ? item.nombre : '',
					Estatus: item.estatus,
					Aforo: item.aforo,
					Detalles: (
						<>
							<Tooltip title='Editar'>
								<IconButton
									onClick={() => editVenue(item)}
									color='secondary'
									aria-label='upload picture'
									component='label'
								>
									<img width={'15px'} src={editIcon} alt='Editar' />
								</IconButton>
							</Tooltip>
							{/* <SplitButton
								item={item}
								handleOpen={handleOpen}
								setComprobanteToUpdate={setComprobanteToUpdate}
								setComprobantesListData={setEventosListData}
								isMyDataValid={isMyDataValid}
							/> */}
						</>
					),
				};
			})
			.reverse(),
	};
	const venuesGet = (): void => {
		const getVenuesbyParams = getVenuesByParams(
			filtersValues.aforo,
			filtersValues.estatus,
			filtersValues.nombre
		);
		getVenuesbyParams
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					setVenuesListData(respuesta.data.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 404) {
					console.log('error404- no se encontraron');
					setVenuesListData([]);
				}
			});
	};

	useEffect(() => {
		venuesGet();
		// eslint-disable-next-line
	}, [filtersValues]);

	return (
		<>
			<MDBox
				display={'flex'}
				width={'100%'}
				minHeight={'100vh'}
				pt={11}
				pl={3}
				pr={3}
				flexDirection={'column'}
				// style={{ backgroundColor: 'var(--backgroundBody)' }}
			>
				<MDBox
					display={'flex'}
					width='100%'
					flexWrap={'wrap'}
					mt={2}
					// style={{ backgroundColor: 'tomato' }}
				>
					<MDBox display={'flex'} width={'100%'} flexDirection={'column'}>
						<MDBox
							display={'flex'}
							flexWrap={'wrap'}
							component='form'
							role='form'
							onSubmit={goSearch}
							justifyContent={'space-between'}
						>
							<MDBox
								display={'flex'}
								minWidth={'32%'}
								p={2}
								// style={{ backgroundColor: 'tomato' }}
								// flexDirection={'column'}
							>
								<input
									value={searchValue.nombre}
									name='nombre'
									onChange={handleInputChangeSearch}
									type='text'
									placeholder='Nombre de venue'
									className='inputTransparent'
									style={{ minWidth: '100%', color: '#000' }}
								/>
								<button id='inputSearchBtn' type='submit'>
									<SearchIcon />
								</button>
							</MDBox>
							<MDBox display={'flex'} minWidth={'60%'} flexWrap={'wrap'} ml={2}>
								{/* Estatus */}
								<MDBox p={2}>
									<MDBox
										display={'flex'}
										style={{
											width: '100%',
											position: 'relative',
											height: '100%',
										}}
									>
										<Autocomplete
											// defaultValue='Categoria'

											options={selectData.estatus}
											value={estatusValue.estatus}
											placeholder='Estatus'
											onChange={(event, newValue) => {
												setEstatusValue({ ...estatusValue, estatus: newValue });
											}}
											renderInput={(params) => (
												<MDInput
													{...params}
													label='Estatus'
													InputLabelProps={{ shrink: true }}
													id='estatus'
													style={{
														background: 'transparent',
														zIndex: '300',
													}}
												/>
											)}
											style={{ minWidth: '12rem' }}
										/>
										<button
											id='inputCityButton'
											style={{
												position: 'absolute',
												backgroundColor: 'var(--colorPrimary)',
												width: '37px',
												height: '100%',
												right: '0px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												color: 'var(--backgroundPrimary)',
												borderRadius: '0px 5px 5px 0px',
												border: 'none',
											}}
										/>
									</MDBox>
								</MDBox>
								{/* AFORO */}
								<MDBox
									display={'flex'}
									flexDirection={'column'}
									p={2}
									style={{
										minWidth: '12rem',
										// background: 'tomato',
									}}
								>
									<MDBox
										display={'flex'}
										style={{
											width: '100%',
											position: 'relative',
											height: '100%',
										}}
									>
										<input
											className='inputTransparent'
											type='number'
											name={'aforo'}
											placeholder='Aforo'
											value={aforoValue.aforo}
											onChange={handleInputChangeAforo}
											style={{
												minWidth: '12rem',
												height: '100%',
												border: '1px solid #D2D6DA',
												borderRadius: '5px',
												zIndex: '300',
											}}
										/>
										<button
											id='inputCityButton'
											className='inputDateSearch'
											style={{
												position: 'absolute',
												backgroundColor: 'var(--colorPrimary)',
												width: '37px',
												height: '100%',
												right: '0px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												color: 'var(--backgroundPrimary)',
												borderRadius: '0px 5px 5px 0px',
												border: 'none',
											}}
										/>
									</MDBox>
									{/* <MDInput
									type='date'
									label=''
									name={'date'}
									value={dateValue.date}
									onChange={handleInputChangeDate}
									id={'date'}
									style={{
										minWidth: '12rem',
									}}
								/> */}
								</MDBox>
							</MDBox>
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox display={'flex'} flexWrap={'wrap'}>
					{filtersValues.nombre && (
						<div className='filterValueButton'>
							{filtersValues.nombre}
							<button className='filterValueDeleteButton' onClick={cleanSearchValue}>
								<ClearIcon />
							</button>
						</div>
					)}
					{filtersValues.estatus && (
						<div className='filterValueButton'>
							{filtersValues.estatus}
							<button className='filterValueDeleteButton' onClick={cleanEstatusValue}>
								<ClearIcon />
							</button>
						</div>
					)}
					{filtersValues.aforo && (
						<div className='filterValueButton'>
							{filtersValues.aforo}
							<button className='filterValueDeleteButton' onClick={cleanAforoValue}>
								<ClearIcon />
							</button>
						</div>
					)}
				</MDBox>
				<MDBox style={{ width: '98%' }}>
					<DataTable table={datosTabla} canSearch={false} />
				</MDBox>
			</MDBox>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>
					{
						<NuevoVenueModal
							handleClose={handleCloseModal}
							venueToEdit={venueToEdit}
							getVenues={venuesGet}
							actionType={'Edit'}
						/>
					}
				</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default VenuesBusqueda;
