import { IconButton, Modal, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import lupaIcon from 'assets/images/icons/lupaIcon.svg';
import mailIcon from 'assets/images/icons/Icon_mailRed.svg';
import phoneIcon from 'assets/images/icons/PhoneIconRed.svg';
import ticketIcon from 'assets/images/icons/ticketIconRed.svg';
import copyIcon from 'assets/images/icons/copyIconRed.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Basic from './components/basic';
import NuevoUsuarioModal from './components/nuevoUsuarioModal/NuevoUsuarioModal';
import { getUsuarios } from 'store/slices/usuarios';
import { ToastContainer } from 'react-toastify';

const Users = () => {
	interface iUsuarioToEdit {
		id: number | null;
		nombre: string;
		email: string;
		codigo_telefono: string;
		telefono: string;
		tipo_usuario: string;
	}
	const usuarioToEditInitialValue: iUsuarioToEdit = {
		id: null,
		nombre: '',
		email: '',
		codigo_telefono: '',
		telefono: '',
		tipo_usuario: '',
	};

	const [usuarioToEdit, setusuarioToEdit] = useState(usuarioToEditInitialValue);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const handleOpen = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<NuevoUsuarioModal
						handleClose={handleCloseModal}
						actionType={'Add'}
						usuarioToEdit={usuarioToEdit}
						getUsuariosData={getUsuariosData}
					/>
				);
			case 2:
				return (
					<NuevoUsuarioModal
						handleClose={handleCloseModal}
						actionType={'Edit'}
						usuarioToEdit={usuarioToEdit}
						getUsuariosData={getUsuariosData}
					/>
				);
			// case 3:
			// 	return <DeleteVenueModal handleClose={handleClose} />;

			default:
				return;
		}
	};

	const [usersArray, setUsersArray] = useState([]);
	const [usersPendingArray, setUsersPendingArray] = useState([]);

	const newUser = () => {
		setOptionModal(1);
		handleOpen();
	};
	// const editUser = () => {
	// 	setOptionModal(2);
	// 	handleOpen();
	// };

	const deleteUser = () => {
		setOptionModal(3);
		handleOpen();
	};

	const irUsuariosBusqueda = () => {
		navigate('/admin/venues/busqueda');
	};

	const getUsuariosData = (): void => {
		const usersGet = getUsuarios();
		usersGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('usuarios200', respuesta.data.data);
					setUsersArray(respuesta.data.data);
					const arrayPendientesdeAprobar = respuesta.data.data.filter(
						(item: any) => item.estatus === 'Pendiente aprobación'
					);
					const arrayAprobados = respuesta.data.data.filter(
						(item: any) => item.estatus === 'Activo' || item.estatus === 'Inactivo'
					);

					setUsersArray(arrayAprobados ? arrayAprobados : []);
					setUsersPendingArray(
						arrayPendientesdeAprobar ? arrayPendientesdeAprobar : []
					);
				}
			})
			.catch((error) => {
				console.log(error);
				setUsersArray([]);
				setUsersPendingArray([]);
			});
	};

	useEffect(() => {
		getUsuariosData();
	}, []);

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					width={'100%'}
				>
					<MDBox display={'flex'}>
						<MDTypography variant={'h3'}>Usuarios</MDTypography>
						<MDTypography
							ml={1}
							component={IconButton}
							variant='body1'
							color='primary'
							fontWeight='medium'
							textGradient
							onClick={irUsuariosBusqueda}
						>
							<img src={lupaIcon} width={'25px'} alt='Buscar' />
						</MDTypography>
					</MDBox>
					<MDTypography
						component={IconButton}
						variant='body1'
						color='primary'
						fontWeight='medium'
						textGradient
						onClick={newUser}
					>
						{`+ Dar de alta usuario (s)`}
					</MDTypography>
				</MDBox>
				{usersPendingArray.map((item: any) => (
					<MDBox mt={6} key={item.id}>
						<Basic
							tittle={item.nombre}
							pendienteAprobación={true}
							deleteVenue={deleteUser}
							handleCloseModal={handleCloseModal}
							userId={item.id}
							user={item}
							getUsuariosData={getUsuariosData}
							setOptionModal1={setOptionModal}
							handleOpen1={handleOpen}
							setusuarioToEdit={setusuarioToEdit}
							content={
								<>
									<MDBox display={'flex'} pl={5} flexDirection={'column'} width={'100%'}>
										<MDBox display={'flex'}>
											<img width={'30px'} src={mailIcon} alt='email' />
											<MDTypography m={1} variant='body2'>
												{item.email}
											</MDTypography>
										</MDBox>
										<MDBox display={'flex'}>
											<img width={'30px'} src={phoneIcon} alt='email' />
											<MDTypography m={1} variant='body2'>
												{item.codigo_telefono} {item.telefono}
											</MDTypography>
										</MDBox>
										{(item.tipo_usuario === 'USUARIO RP' ||
											item.tipo_usuario === 'CHECK IN') && (
											<MDBox display={'flex'} alignItems={'flex-start'}>
												<img width={'30px'} src={ticketIcon} alt='evento' />
												<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
													{item.evento.map((item: any) => (
														<MDBox
															display={'flex'}
															flexWrap={'wrap'}
															width={'100%'}
															key={item.id}
														>
															<MDTypography
																m={1}
																variant='h5'
																textAlign={'left'}
																minWidth={'80px'}
																width={'100%'}
																maxWidth={'200px'}
															>
																{item.Evento.nombre}:
															</MDTypography>
															<MDTypography m={1} variant='body2' width={'68%'}>
																<a
																	href={item.url_rp}
																	target='_blank'
																	style={{
																		whiteSpace: 'pre-wrap',
																		minWidth: '80px',
																		width: '65%',
																	}}
																>
																	{item.url_rp}
																</a>
															</MDTypography>
															<Tooltip title='Copiar'>
																<MDTypography
																	ml={1}
																	component={IconButton}
																	variant='body1'
																	color='primary'
																	fontWeight='medium'
																	textGradient
																	onClick={() => alert('copy')}
																>
																	<img src={copyIcon} width={'25px'} alt='Copy' />
																</MDTypography>
															</Tooltip>
														</MDBox>
													))}
												</MDBox>
											</MDBox>
										)}
									</MDBox>
								</>
							}
						/>
					</MDBox>
				))}
				{usersArray.map((item: any) => (
					<MDBox mt={6} key={item.id}>
						<Basic
							tittle={item.nombre}
							pendienteAprobación={false}
							deleteVenue={deleteUser}
							handleCloseModal={handleCloseModal}
							userId={item.id}
							user={item}
							getUsuariosData={getUsuariosData}
							setOptionModal1={setOptionModal}
							handleOpen1={handleOpen}
							setusuarioToEdit={setusuarioToEdit}
							content={
								<>
									<MDBox display={'flex'} pl={5} flexDirection={'column'} width={'100%'}>
										<MDBox display={'flex'}>
											<img width={'30px'} src={mailIcon} alt='email' />
											<MDTypography m={1} variant='body2'>
												{item.email}
											</MDTypography>
										</MDBox>
										<MDBox display={'flex'}>
											<img width={'30px'} src={phoneIcon} alt='email' />
											<MDTypography m={1} variant='body2'>
												{item.codigo_telefono} {item.telefono}
											</MDTypography>
										</MDBox>
										{(item.tipo_usuario === 'USUARIO RP' ||
											item.tipo_usuario === 'CHECK IN') && (
											<MDBox display={'flex'} alignItems={'flex-start'}>
												<img width={'30px'} src={ticketIcon} alt='evento' />
												<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
													{item.evento.map((item: any) => (
														<MDBox
															display={'flex'}
															flexWrap={'wrap'}
															width={'100%'}
															key={item.id}
														>
															<MDTypography
																m={1}
																variant='h5'
																textAlign={'left'}
																minWidth={'80px'}
																width={'100%'}
																maxWidth={'200px'}
															>
																{item.Evento.nombre}:
															</MDTypography>
															<MDTypography m={1} variant='body2' width={'68%'}>
																<a
																	href={item.url_rp}
																	target='_blank'
																	style={{
																		whiteSpace: 'pre-wrap',
																		minWidth: '80px',
																		width: '65%',
																	}}
																>
																	{item.url_rp}
																</a>
															</MDTypography>
															<Tooltip title='Copiar'>
																<MDTypography
																	ml={1}
																	component={IconButton}
																	variant='body1'
																	color='primary'
																	fontWeight='medium'
																	textGradient
																	onClick={() => alert('copy')}
																>
																	<img src={copyIcon} width={'25px'} alt='Copy' />
																</MDTypography>
															</Tooltip>
														</MDBox>
													))}
												</MDBox>
											</MDBox>
										)}
									</MDBox>
								</>
							}
						/>
					</MDBox>
				))}

				{/* <MDBox mt={6}>
					<Basic
						tittle={'Maximiliano de Hamburgo'}
						editUser={editUser}
						pendienteAprobación={false}
						deleteVenue={deleteUser}
						handleCloseModal={handleCloseModal}
						content={
							<>
								<MDBox display={'flex'} pl={5} flexDirection={'column'} width={'100%'}>
									<MDBox display={'flex'}>
										<img width={'30px'} src={mailIcon} alt='email' />
										<MDTypography m={1} variant='body2'>
											maxi1832@micorreo.com
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'}>
										<img width={'30px'} src={phoneIcon} alt='email' />
										<MDTypography m={1} variant='body2'>
											+52 333 1741 5661
										</MDTypography>
									</MDBox>
									<MDBox display={'flex'} alignItems={'flex-start'}>
										<img width={'30px'} src={ticketIcon} alt='email' />
										<MDBox display={'flex'} flexDirection={'column'}>
											<MDBox display={'flex'}>
												<MDTypography
													m={1}
													variant='h5'
													textAlign={'left'}
													minWidth={'80px'}
												>
													Zamma:
												</MDTypography>
												<MDTypography m={1} variant='body2'>
													https://fullpass.com/zamna-multi-pass-3
												</MDTypography>
												<Tooltip title='Copiar'>
													<MDTypography
														ml={1}
														component={IconButton}
														variant='body1'
														color='primary'
														fontWeight='medium'
														textGradient
														onClick={() => alert('copy')}
													>
														<img src={copyIcon} width={'25px'} alt='Buscar' />
													</MDTypography>
												</Tooltip>
											</MDBox>
											<MDBox display={'flex'}>
												<MDTypography
													m={1}
													variant='h5'
													// textAlign={'right'}
													minWidth={'80px'}
												>
													Anight with ZHU:
												</MDTypography>
												<MDTypography m={1} variant='body2'>
													https://fullpass.com/zamna-multi-pass-2
												</MDTypography>
												<Tooltip title='Copiar'>
													<MDTypography
														ml={1}
														component={IconButton}
														variant='body1'
														color='primary'
														fontWeight='medium'
														textGradient
														onClick={() => alert('copy')}
													>
														<img src={copyIcon} width={'25px'} alt='Buscar' />
													</MDTypography>
												</Tooltip>
											</MDBox>
											<MDBox display={'flex'}>
												<MDTypography
													m={1}
													variant='h5'
													// textAlign={'right'}
													minWidth={'80px'}
												>
													Renaissance:
												</MDTypography>
												<MDTypography m={1} variant='body2'>
													https://fullpass.com/renaissance
												</MDTypography>
												<Tooltip title='Copiar'>
													<MDTypography
														ml={1}
														component={IconButton}
														variant='body1'
														color='primary'
														fontWeight='medium'
														textGradient
														onClick={() => alert('copy')}
													>
														<img src={copyIcon} width={'25px'} alt='Buscar' />
													</MDTypography>
												</Tooltip>
											</MDBox>
										</MDBox>
									</MDBox>
								</MDBox>
							</>
						}
					/>
				</MDBox> */}
			</MDBox>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default Users;
