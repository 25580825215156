const form = {
	formId: 'venues-form',
	formField: {
		name: {
			name: 'name',
			label: 'Nombre',
			type: 'text',
			errorMsg: 'Nombre es requerido',
		},
		Address: {
			name: 'Address',
			label: 'Dirección',
			type: 'text',
			errorMsg: 'Dirección es requerido.',
		},
		Map: {
			name: 'Map',
			label: 'Mapa',
			// label: 'Fecha de nacimiento',
			type: 'text',
			errorMsg: 'Mapa es requerido.',
			// invalidMsg: 'Tu fecha debe estar completa',
		},
		Aforo: {
			name: 'Aforo',
			label: 'Aforo',
			// label: 'Fecha de nacimiento',
			type: 'number',
			errorMsg: 'Aforo es requerido.',
			// invalidMsg: 'Tu fecha debe estar completa',
		},
	},
};

export default form;
