import { createSlice } from '@reduxjs/toolkit';

export interface EventsState {
	cps: number;
	reservaDetalles: [];
	evento: any;
	expires: string;
	sumaTotal: number;
	token: string;
	total: number;
	purchase: {
		evento: any;
		id_venta: number;
		isLoading: boolean;
	};
}
const initialState: EventsState = {
	cps: 0,
	reservaDetalles: [],
	evento: {},
	expires: '',
	sumaTotal: 0,
	token: '',
	total: 0,
	purchase: { evento: {}, id_venta: 0, isLoading: false },
};

export const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		savePurchaseSummary: (state, action) => {
			state.cps = action.payload.cps;
			state.reservaDetalles = action.payload.reservaDetalles;
			state.evento = action.payload.evento;
			state.expires = action.payload.expires;
			state.sumaTotal = action.payload.sumaTotal;
			state.token = action.payload.token;
			state.total = action.payload.total;
		},
		cleanPurchaseSummary: (state) => {
			state.cps = initialState.cps;
			state.reservaDetalles = initialState.reservaDetalles;
			state.evento = initialState.evento;
			state.expires = initialState.expires;
			state.sumaTotal = initialState.sumaTotal;
			state.token = initialState.token;
			state.total = initialState.total;
		},
		savePurchaseConfirm: (state, action) => {
			state.purchase.id_venta = action.payload.id_venta;
			state.purchase.evento = action.payload.evento;
		},
		cleanPurchaseConfirm: (state) => {
			state.purchase.id_venta = initialState.purchase.id_venta;
			state.purchase.evento = initialState.purchase.evento;
			state.purchase.isLoading = initialState.purchase.evento;
		},
		startLoadingPurchase: (state) => {
			state.purchase.isLoading = true;
		},
		endLoadingPurchase: (state) => {
			state.purchase.isLoading = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	savePurchaseSummary,
	cleanPurchaseSummary,
	savePurchaseConfirm,
	cleanPurchaseConfirm,
	startLoadingPurchase,
	endLoadingPurchase,
} = eventsSlice.actions;
