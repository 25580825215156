import { createSlice } from '@reduxjs/toolkit';

export interface CounterState {
	userMyPerfilData: Array<any>;
	isUpdated: boolean;
}
const initialState: CounterState = {
	userMyPerfilData: [],
	isUpdated: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		startLoadingPut: (state /* action */) => {
			state.isUpdated = false;
		},
		endLoadingPut: (state /* action */) => {
			state.isUpdated = true;
		},
		myPerfilData: (state, action) => {
			state.userMyPerfilData = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { myPerfilData, startLoadingPut, endLoadingPut } =
	userSlice.actions;
