import { IconButton, Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';
import { getEvents } from 'store/slices/events';
import lupaIcon from 'assets/images/icons/lupaIcon.svg';
import EventCard from 'pantallas/user/productor/components/EventCard';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Events = () => {
	const navigate = useNavigate();
	const [actionType, setActionType] = useState('Add');
	const [openModal, setOpenModal] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(1);
	const [eventsArray, setEventsArray] = useState([]);
	const handleOpen = () => setOpenModal(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpenModal(false);
		setOptionModal(1);
	};
	const handleCloseModal = () => setOpenModal(false);

	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<h1>test</h1>
					// <NuevoVenueModal
					// 	handleClose={handleClose}
					// 	actionType={actionType}
					// 	venueToEdit={venueToEdit}
					// 	getVenues={venuesGet}
					// />
				);
			case 3:
				return (
					<h1>test2</h1>
					// <DeleteVenueModal
					// 	handleClose={handleClose}
					// 	venueToDelete={venueToDelete}
					// 	getVenues={venuesGet}
					// />
				);

			default:
				return;
		}
	};

	const newEvento = () => {
		navigate('/admin/events/nuevo');
	};
	const irEventoBusqueda = () => {
		navigate('/producer/events/busqueda');
	};
	const eventosGET = () => {
		const getEventos = getEvents();
		getEventos
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					setEventsArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		eventosGET();
	}, []);
	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				maxWidth={'1100px'}
				mt={2}
				p={2}
			>
				<MDBox
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					width={'100%'}
				>
					<MDBox display={'flex'}>
						<MDTypography
							variant={'h3'}
							// style={{ margin: '0rem 1rem 1rem 1rem' }}
						>
							Eventos
						</MDTypography>
						<MDTypography
							ml={1}
							component={IconButton}
							variant='body1'
							color='primary'
							fontWeight='medium'
							textGradient
							onClick={irEventoBusqueda}
						>
							<img src={lupaIcon} width={'25px'} alt='Buscar' />
						</MDTypography>
					</MDBox>
					<MDTypography
						component={IconButton}
						variant='body1'
						color='primary'
						fontWeight='medium'
						textGradient
						onClick={newEvento}
					>
						+ Crear nuevo evento
					</MDTypography>
				</MDBox>
				<MDBox
					mt={4}
					display={'flex'}
					width={'100%'}
					alignItems={'center'}
					flexDirection={'column'}
				>
					{eventsArray
						.map((item: any) => (
							<EventCard
								key={item.id}
								type={item.estatus}
								item={item}
								eventosGET={eventosGET}
								content={
									<MDBox
										display={'flex'}
										pl={5}
										flexDirection={'column'}
										width={'100%'}
										// style={{ minWidth: '600px' }}
									>
										<MDBox display={'flex'} flexDirection={'column'}>
											<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
												<MDTypography varian={'bold'} mr={1}>
													Venue:
												</MDTypography>
												<MDTypography variant={'body2'}>{item.Venue.nombre}</MDTypography>
											</MDBox>
											<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
												<MDTypography varian={'bold'} mr={1}>
													Categoría:
												</MDTypography>
												<MDTypography variant={'body2'}>
													{item.Categoria.nombre
														? item.Categoria.nombre
														: 'Nombre de la categoría'}
												</MDTypography>
											</MDBox>
											<MDBox display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
												<MDTypography varian={'bold'} mr={1}>
													Administrador:
												</MDTypography>
												<MDTypography variant={'body2'}>
													{item.Usuario ? item.Usuario.nombre : 'Nombre de administrador'}
												</MDTypography>
											</MDBox>
										</MDBox>
									</MDBox>
								}
							/>
						))
						.reverse()}
				</MDBox>
			</MDBox>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default Events;
