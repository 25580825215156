import MDBox from 'components/MDBox';
import './CategoriaItem.css';

const CategoriaItem = ({ img, text }: any) => {
	return (
		<>
			<div className='categoryItem'>
				<div className='categoryCard' onClick={() => alert('Ir a evento')}>
					<MDBox width='100%' component='img' src={img} alt={'title'} />
					<div className='categoryTextBar'>{text}</div>
				</div>
			</div>
		</>
	);
};

export default CategoriaItem;
