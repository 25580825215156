import * as Yup from 'yup';
import checkout from './form';
import valid from 'card-validator';

const {
	formField: {
		cardNumber,
		ownerName,
		ownerLastname,
		monthExpiration,
		yearExpiration,
	},
} = checkout;

const validations = [
	Yup.object().shape({
		[cardNumber.name]: Yup.string()
			.test(
				'test-number', // this is used internally by yup
				'Numero de tarjeta invalido', //validation message
				(value) => valid.number(value).isValid
			) // return true false based on validation
			.required(),
		[ownerName.name]: Yup.string().required(ownerName.errorMsg),
		[ownerLastname.name]: Yup.string().required(ownerLastname.errorMsg),
		[monthExpiration.name]: Yup.string()
			.test(
				'test-number', // this is used internally by yup
				monthExpiration.invalidMsg, //validation message
				(value) => valid.expirationMonth(value).isValid
			) // return true false based on validation
			.required(),
		[yearExpiration.name]: Yup.string()
			.test(
				'test-number', // this is used internally by yup
				yearExpiration.invalidMsg, //validation message
				(value) => valid.expirationYear(value).isValid
			) // return true false based on validation
			.required(),
	}),
];

export default validations;
