import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import {
	getMetodoPagoData,
	getMyPerfilData,
	patchMyPerfilData,
} from 'store/slices/user';
import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import MDButton from 'components/MDButton';
import FormFieldRadius from 'components/MDFormFieldRadius';
import { Card, Modal, IconButton, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import MetodoPagoModal, {
	iItemToEdit,
} from './metodoPagoModal/MetodoPagoModal';
import DeleteMedotoPagoModal from './deleteMedotoPagoModal/DeleteMedotoPagoModal';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MyPerfil.css';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { selectData } from 'helpers/states';
import MDInput from 'components/MDInput';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

const MyPerfil = () => {
	const { id } = useSelector((state: RootState) => state.auth);
	const { isUpdated } = useSelector((state: RootState) => state.user);

	const currentValidation = validations[0];
	const { formId, formField } = form;
	const {
		userName,
		email,
		birthdate,
		nacionality,
		cityOfResidence,
		gender,
		countryCode,
		tel,
	} = formField;

	const [perfilDataValues, setPerfilDataValues] = useState(initialValues);
	const [gettingPerfilData, setGettingPerfilData] = useState(false);
	const [stateValue, setStateValue] = useState({ state: '' });
	const [genderValue, setGenderValue] = useState({ gender: '' });

	const submitForm = async (values: any, actions: any) => {
		console.log('enviandoDATA:', values);
		console.log('userId::', id);
		const patchPerfilData = patchMyPerfilData(id, {
			nombre: values.userName,
			email: values.email,
			fecha_nacimiento: values.birthdate,
			nacionalidad: values.nacionality,
			estado: stateValue.state,
			genero: genderValue.gender,
			codigo_telefono: values.countryCode,
			telefono: values.tel,
		});
		patchPerfilData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('Respuesta patch perfil-200', respuesta);
					const initValue = {
						[userName.name]: respuesta.data.data.nombre
							? respuesta.data.data.nombre
							: '',
						[email.name]: respuesta.data.data.email ? respuesta.data.data.email : '',
						[birthdate.name]: respuesta.data.data.fecha_nacimiento
							? moment(respuesta.data.data.fecha_nacimiento)
									.add(1, 'day')
									.format('YYYY-MM-DD')
							: '',
						[nacionality.name]: respuesta.data.data.nacionalidad
							? respuesta.data.data.nacionalidad
							: '',
						[cityOfResidence.name]: respuesta.data.data.estado
							? respuesta.data.data.estado
							: '',
						[gender.name]: respuesta.data.data.genero
							? respuesta.data.data.genero
							: '',
						[countryCode.name]: respuesta.data.data.codigo_telefono
							? respuesta.data.data.codigo_telefono
							: '',
						[tel.name]: respuesta.data.data.telefono
							? respuesta.data.data.telefono
							: '',
					};
					setStateValue({
						state: respuesta.data.data.estado ? respuesta.data.data.estado : '',
					});
					setGenderValue({
						gender: respuesta.data.data.genero ? respuesta.data.data.genero : '',
					});
					setPerfilDataValues(initValue);
					toast.success(respuesta.data.mensaje);
				}
			})
			.catch((error) => {
				console.log('error', error);
				toast.error(error.message);
			});

		actions.setSubmitting(false);
		// actions.resetForm();
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};

	//MODAL LOGIC
	const [open, setOpen] = useState(false);
	const [optionModal, setOptionModal] = useState<number>(2);
	const [typeModal, setTypeModal] = useState<'Add' | 'Edit'>('Add');
	const handleOpen = () => setOpen(true);
	const handleClose = (event: object, reason: string) => {
		if (reason && reason === 'backdropClick') return;
		setOpen(false);
		setOptionModal(1);

		// setComprobanteToUpdate(null);
	};
	const [metodoPagoArray, setMetodoPagoArray] = useState([]);
	const itemToEditInitialValues: iItemToEdit = {
		numero_tarjeta: '',
		nombre_en_tarjeta: '',
		apellido_en_tarjeta: '',
		mes_exp: '',
		ano_exp: '',
	};
	const [itemToEdit, setItemToEdit] = useState(itemToEditInitialValues);
	const [metodoPagoItemID, setMetodoPagoItemID] = useState<string | number>(0);

	const openAddMetodoPago = () => {
		setOptionModal(1);
		setTypeModal('Add');
		handleOpen();
	};
	const openEditMetodoPago = (item: iItemToEdit) => {
		setItemToEdit(item);
		setOptionModal(1);
		setTypeModal('Edit');
		handleOpen();
	};
	const openDeleteMetodoPago = (ItemID: string | number) => {
		setMetodoPagoItemID(ItemID);
		setOptionModal(2);
		handleOpen();
	};

	const showInModal = () => {
		switch (optionModal) {
			case 1:
				return (
					<MetodoPagoModal
						handleClose={handleClose}
						typeModal={typeModal}
						itemToEdit={itemToEdit}
					/>
				);
			case 2:
				return (
					<DeleteMedotoPagoModal
						handleClose={handleClose}
						metodoPagoItemID={metodoPagoItemID}
					/>
				);

			default:
				return;
		}
	};

	useEffect(() => {
		if (id) {
			const myPerfilData = getMyPerfilData(id);
			myPerfilData
				.then((respuesta) => {
					if (respuesta.status === 200) {
						const initValue = {
							[userName.name]: respuesta.data.data.nombre
								? respuesta.data.data.nombre
								: '',
							[email.name]: respuesta.data.data.email ? respuesta.data.data.email : '',
							[birthdate.name]: respuesta.data.data.fecha_nacimiento
								? moment(respuesta.data.data.fecha_nacimiento)
										.add(1, 'day')
										.format('YYYY-MM-DD')
								: '',
							[nacionality.name]: respuesta.data.data.nacionalidad
								? respuesta.data.data.nacionalidad
								: '',
							[cityOfResidence.name]: respuesta.data.data.estado
								? respuesta.data.data.estado
								: '',
							[gender.name]: respuesta.data.data.genero
								? respuesta.data.data.genero
								: '',
							[countryCode.name]: respuesta.data.data.codigo_telefono
								? respuesta.data.data.codigo_telefono
								: '',
							[tel.name]: respuesta.data.data.telefono
								? respuesta.data.data.telefono
								: '',
						};
						setStateValue({
							state: respuesta.data.data.estado ? respuesta.data.data.estado : '',
						});
						setGenderValue({
							gender: respuesta.data.data.genero ? respuesta.data.data.genero : '',
						});
						setPerfilDataValues(initValue);
						setGettingPerfilData(true);
					}
				})
				.catch((error) => {
					console.log('error', error);
				});
		}
		// eslint-disable-next-line
	}, [id, gettingPerfilData]);

	const getMetodosPago = () => {
		const myMetodosPagoData = getMetodoPagoData();
		myMetodosPagoData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setMetodoPagoArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				setMetodoPagoArray([]);
			});
	};
	useEffect(() => {
		getMetodosPago();
	}, []);

	useEffect(() => {
		if (isUpdated) {
			getMetodosPago();
		}
	}, [isUpdated]);

	const getFormik = () => {
		return (
			<Formik
				initialValues={perfilDataValues}
				validationSchema={currentValidation}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched, handleChange, isSubmitting }) => (
					<Form id={formId} autoComplete='off'>
						<MDBox
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'center'}

							// alignItems={'center'}
						>
							<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Nombre de usuario</MDTypography>
								<FormFieldRadius
									type={userName.type}
									label={userName.label}
									name={userName.name}
									value={values.userName}
									onChange={handleChange}
									placeholder={userName.label}
									error={errors.userName && touched.userName}
									success={values.userName.length > 0 && !errors.userName}
									style={{ minWidth: '12rem', backgroundColor: 'tomato' }}
									// autoComplete='off'
								/>
							</MDBox>
							<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Correo electrónico</MDTypography>
								<FormFieldRadius
									type={email.type}
									label={email.label}
									name={email.name}
									value={values.email}
									onChange={handleChange}
									placeholder={email.label}
									error={errors.email && touched.email}
									success={values.email.length > 0 && !errors.email}
									style={{ minWidth: '12rem' }}
								/>
							</MDBox>
							<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Fecha de nacimiento</MDTypography>
								<FormFieldRadius
									type={birthdate.type}
									label={birthdate.label}
									name={birthdate.name}
									value={values.birthdate}
									onChange={handleChange}
									placeholder={birthdate.label}
									// error={errors.birthdate && touched.birthdate}
									// success={values.birthdate.length > 0 && !errors.birthdate}
									inputProps={{ autoComplete: '' }}
									style={{ minWidth: '12rem' }}
								/>
							</MDBox>
							<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Nacionalidad</MDTypography>
								<FormFieldRadius
									type={nacionality.type}
									label={nacionality.label}
									name={nacionality.name}
									value={values.nacionality}
									onChange={handleChange}
									placeholder={nacionality.label}
									// error={errors.nacionality && touched.nacionality}
									// success={values.nacionality.length > 0 && !errors.nacionality}
									inputProps={{ autoComplete: '' }}
									style={{ minWidth: '12rem' }}
								/>
							</MDBox>
							<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Estado de residencia</MDTypography>
								<Autocomplete
									// defaultValue='Categoria'
									options={selectData.states}
									// name={tel.name}
									value={stateValue.state}
									placeholder='Estado'
									onChange={(event, newValue) => {
										setStateValue({ state: newValue });
									}}
									renderInput={(params) => (
										<MDInput
											{...params}
											label='Estado de residencia'
											InputLabelProps={{ shrink: true }}
										/>
									)}
									style={{ minWidth: '12rem' }}
								/>
							</MDBox>
							<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Género</MDTypography>
								<Autocomplete
									// defaultValue='Categoria'
									options={selectData.genders}
									// name={tel.name}
									value={genderValue.gender}
									placeholder='Género'
									onChange={(event, newValue) => {
										setGenderValue({ gender: newValue });
									}}
									renderInput={(params) => (
										<MDInput
											{...params}
											label='Género'
											InputLabelProps={{ shrink: true }}
										/>
									)}
									style={{ minWidth: '12rem' }}
								/>
							</MDBox>
							{/* <MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
								<MDTypography variant={'h4'}>Género</MDTypography>
								<FormFieldRadius
									type={gender.type}
									label={gender.label}
									name={gender.name}
									value={values.gender}
									onChange={handleChange}
									placeholder={gender.label}
									// error={errors.gender && touched.gender}
									// success={values.gender.length > 0 && !errors.gender}
									style={{ minWidth: '12rem' }}
								/>
							</MDBox> */}
							<MDTypography variant={'h4'} style={{ margin: '0rem 1rem 1rem 1rem' }}>
								Número teléfonico
							</MDTypography>
							<MDBox
								display={'flex'}
								justifyContent={'space-between'}
								style={{ margin: '0rem 1rem 1rem 1rem' }}
							>
								<FormFieldRadius
									type={countryCode.type}
									label={countryCode.label}
									name={countryCode.name}
									value={values.countryCode}
									onChange={handleChange}
									placeholder={'+52'}
									// error={errors.tel && touched.tel}
									// success={values.tel.length > 0 && !errors.tel}
									style={{ width: '5rem', marginRight: '1rem' }}
								/>
								<MDBox display={'flex'} width={'100%'} flexDirection={'column'}>
									<FormFieldRadius
										type={tel.type}
										label={tel.label}
										name={tel.name}
										value={values.tel}
										onChange={handleChange}
										placeholder={tel.label}
										error={errors.tel && touched.tel}
										success={values.tel.length > 0 && !errors.tel}
										style={{ minWidth: '6rem', with: '100%' }}
									/>
								</MDBox>
							</MDBox>
						</MDBox>
						<MDTypography variant={'h3'} style={{ margin: '3rem 1rem 1rem 1rem' }}>
							Método de Pago
						</MDTypography>
						<div className='cardMetodoPAgoContainer'>
							<div className='cardMetodoPago60'>
								{/* CARD */}
								{metodoPagoArray.length > 0 ? (
									<MDBox
										display={'flex'}
										// flexDirection={'column'}
										alignItems={'center'}
										justifyContent={'center'}
										width={'100%'}
										height={'100%'}
									>
										<Carousel
											containerClass='carousel-container container-with-dots'
											additionalTransfrom={0}
											autoPlay={false}
											autoPlaySpeed={2500}
											// centerMode={true}
											customLeftArrow={
												<button type='button' className=' btnArrow btnArrowBack '>
													{/* <Icon>notifications</Icon> */}
													<ChevronLeftOutlinedIcon fontSize='large' />
												</button>
											}
											customRightArrow={
												<button type='button' className=' btnArrow btnArrowNext '>
													{/* <Icon>notifications</Icon> */}
													<NavigateNextOutlinedIcon fontSize='large' />
												</button>
											}
											className=''
											dotListClass=''
											draggable
											focusOnSelect={false}
											infinite
											itemClass=''
											keyBoardControl
											minimumTouchDrag={80}
											pauseOnHover
											renderArrowsWhenDisabled={false}
											renderButtonGroupOutside={false}
											renderDotsOutside={false}
											responsive={{
												desktop: {
													breakpoint: {
														max: 3000,
														min: 200,
													},
													items: 1,
												},
											}}
											rewind={false}
											rewindWithAnimation={false}
											rtl={false}
											shouldResetAutoplay
											showDots={false}
											sliderClass=''
											slidesToSlide={1}
											swipeable
										>
											{metodoPagoArray.map((item: any) => (
												<MDBox key={item.id}>
													<div
														style={{
															display: 'flex',
															width: '90%',
															minHeight: '12rem',
															position: 'relative',
															boxShadow: '1px 1px 3px 1px #a2a2a2a7',
															borderRadius: '7px',
															overflow: 'hidden',
															marginTop: '2rem',
															marginLeft: '1.2rem',
															marginBottom: '2rem',
														}}
													>
														<MDBox
															display={'flex'}
															flexDirection={'column'}
															style={{
																width: '100%',
															}}
															justifyContent={'space-between'}
														>
															<MDBox
																display={'flex'}
																flexDirection={'column'}
																height={'80%'}
																style={{ backgroundColor: '#FAFAFA' }}
															>
																<MDBox
																	display={'flex'}
																	width={'100%'}
																	height={'72%'}
																	justifyContent={'center'}
																	alignItems={'center'}
																>
																	<MDTypography variant={'h5'} mt={3}>
																		XXXX XXXX XXXX{' '}
																		{item.numero_tarjeta.substr(item.numero_tarjeta.length - 4)}
																	</MDTypography>
																</MDBox>
																<MDBox display={'flex'} width={'100%'} height={'28%'}>
																	<MDBox
																		display={'flex'}
																		justifyContent={'flex-start'}
																		ml={2}
																		width={'60%'}
																	>
																		<MDTypography variant='body2'>
																			{item.nombre_en_tarjeta} {item.apellido_en_tarjeta}
																		</MDTypography>
																	</MDBox>
																	<MDBox
																		display={'flex'}
																		justifyContent={'flex-end'}
																		pr={2}
																		width={'40%'}
																	>
																		<MDTypography variant='body2'>
																			Vence {item.mes_exp}/{item.ano_exp}
																		</MDTypography>
																	</MDBox>
																</MDBox>
															</MDBox>
															<MDBox
																display={'flex'}
																justifyContent={'space-between'}
																pl={2}
																pr={2}
																height={'20%'}
															>
																<MDBox
																	component={IconButton}
																	onClick={() => openEditMetodoPago(item)}
																	// variant='button'
																>
																	<MDTypography
																		variant={'body1'}
																		style={{ textDecoration: 'underline' }}
																	>
																		Editar
																	</MDTypography>
																</MDBox>
																<MDBox
																	component={IconButton}
																	onClick={() => openDeleteMetodoPago(item.id)}
																>
																	<MDTypography
																		variant={'body1'}
																		style={{ textDecoration: 'underline' }}
																	>
																		Eliminar
																	</MDTypography>
																</MDBox>
															</MDBox>
														</MDBox>
													</div>
												</MDBox>
											))}
										</Carousel>
									</MDBox>
								) : (
									<MDBox
										display={'flex'}
										width={'100%'}
										height={'100%'}
										justifyContent={'center'}
										alignItems={'center'}
										mt={3}
										mb={3}
									>
										<MDTypography>No se encontraron métodos de pago</MDTypography>
									</MDBox>
								)}
							</div>
							<div className='cardMetodoPago40'>
								<Card
									style={{
										display: 'flex',
										width: '100%',
										minHeight: '12rem',
										position: 'relative',
										backgroundColor: '#ffffff',
										boxShadow: '1px 1px 3px 1px #a2a2a2a7',
										borderRadius: '5px',
										marginBottom: '2rem',
									}}
								>
									<MDBox
										display={'flex'}
										width={'100%'}
										height={'100%'}
										minHeight={'100%'}
										justifyContent={'center'}
										alignItems={'center'}
										// p={1}
										textAlign={'center'}
									>
										<MDButton
											style={{ width: '100%', height: '100%' }}
											onClick={openAddMetodoPago}
										>
											<MDTypography variant={'h5'}> Agregar método de pago</MDTypography>
										</MDButton>
									</MDBox>
								</Card>
							</div>
						</div>
						<MDBox
							mt={3}
							mb={1}
							display={'flex'}
							width={'100%'}
							justifyContent={'center'}
						>
							<MDButton
								disabled={isSubmitting}
								type='submit'
								variant='gradient'
								color='error'
								size='large'
								style={{ minWidth: '11rem', width: '100%' }}
							>
								{isSubmitting ? 'Guardando...' : 'Guardar cambios'}
							</MDButton>
						</MDBox>
					</Form>
				)}
			</Formik>
		);
	};

	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				alignItems={'center'}
				mt={2}
			>
				<MDBox width={'100%'} maxWidth={'650px'} padding={2}>
					<MDTypography variant={'h3'} style={{ margin: '0rem 1rem 1rem 1rem' }}>
						Mi perfil
					</MDTypography>
					{gettingPerfilData && getFormik()}
					{!gettingPerfilData && getFormik()}
				</MDBox>
			</MDBox>
			<Modal
				open={open}
				disableScrollLock={true}
				// disableEscapeKeyDown
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<MDBox style={style}>{showInModal()}</MDBox>
			</Modal>
			<ToastContainer
				position='top-center'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	maxWidth: '1300px',
	maxHeight: '90vh',
	overflow: 'auto',
};
export default MyPerfil;
