import { useEffect, useState } from 'react';
import Basic from '../basic';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { getBanerCiudades, postBanerCiudades } from 'store/slices/events';
import { toast } from 'react-toastify';

const BannerEventosPorCiudades = () => {
	const citiesArrayInitialState = [
		{ id: 1, nombre: 'Ciudad de México', numEvents: '21' },
		{ id: 2, nombre: 'Punta Mita', numEvents: '21' },
		{ id: 3, nombre: 'Tulum', numEvents: '3' },
		{ id: 4, nombre: 'Cancun', numEvents: '1' },
	];
	const [citiesArray, setCitiesArray] = useState(citiesArrayInitialState);
	const [citiesBanerArray, setCitiesBanerArray] = useState([]);
	const [numEventsValues, setNumEventsValues] = useState({
		baner1NumEvent: '',
		baner2NumEvent: '',
		baner3NumEvent: '',
		baner4NumEvent: '',
	});
	const [isCardOpen, setIsCardOpen] = useState(false);
	const banerListInitialState = {
		baner1: '',
		baner2: '',
		baner3: '',
		baner4: '',
	};
	const [banerList, setBanerList] = useState(banerListInitialState);
	//handleInputChange
	const handleInputChange = (event: any) => {
		setBanerList({
			...banerList,
			[event.target.name]: event.target.value,
		});
	};
	const { baner1, baner2, baner3, baner4 } = banerList;

	const takeNumEvent = (baner: string | number, banerDate: string) => {
		if (citiesBanerArray.length > 0) {
			if (baner) {
				const numEvent = citiesBanerArray.find(
					(element) => element.id === Number(baner)
				);
				if (numEvent) {
					setNumEventsValues({
						...numEventsValues,
						[banerDate]: numEvent.numero_eventos ? numEvent.numero_eventos : 'n/a',
					});
				}
			} else if (baner === '')
				setNumEventsValues({ ...numEventsValues, [banerDate]: '' });
		}
	};

	useEffect(() => {
		if (baner1) {
			if (baner1 === '100') {
				setNumEventsValues({ ...numEventsValues, baner1NumEvent: 'Indefinido' });
			} else {
				const numEvent = citiesBanerArray.find(
					(element) => element.id === Number(baner1)
				);
				if (numEvent) {
					setNumEventsValues({
						...numEventsValues,
						baner1NumEvent: numEvent.numero_eventos ? numEvent.numero_eventos : 'n/a',
					});
				}
			}
		} else if (baner1 === '')
			setNumEventsValues({ ...numEventsValues, baner1NumEvent: '' });
	}, [baner1]);
	useEffect(() => {
		takeNumEvent(baner2, 'baner2NumEvent');
	}, [baner2]);
	useEffect(() => {
		takeNumEvent(baner3, 'baner3NumEvent');
	}, [baner3]);
	useEffect(() => {
		takeNumEvent(baner4, 'baner4NumEvent');
	}, [baner4]);

	const sendBanerEventosPorCiudad = () => {
		if (baner1 === '') {
			toast.error('Baner 1 no puede estár vacío');
			return;
		} else {
			const banerCiudadesPost = postBanerCiudades([
				{
					posicion: 'Ciudad1',
					id_ciudad: baner1,
				},
				{
					posicion: 'Ciudad2',
					id_ciudad: baner2,
				},
				{
					posicion: 'Ciudad3',
					id_ciudad: baner3,
				},
				{
					posicion: 'Ciudad4',
					id_ciudad: baner4,
				},
			]);
			banerCiudadesPost
				.then((respuesta) => {
					if (respuesta.status === 201) {
						toast.success(respuesta.data.mensaje);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	const cancelarBanerCiudades = (): void => {
		setIsCardOpen(false);
		banerCiudadesGet();
	};
	const banerCiudadesGet = (): void => {
		const banerCiudadesGet = getBanerCiudades();
		banerCiudadesGet
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('R::BanerCiudades:', respuesta.data.data);
					// setEventsArray(respuesta.data.data);
					setCitiesBanerArray(respuesta.data.data);
					setBanerList({
						baner1: respuesta.data.data[0]
							? respuesta.data.data[0].id_ciudad.toString()
							: '',
						baner2: respuesta.data.data[1]
							? respuesta.data.data[1].id_ciudad.toString()
							: '',
						baner3: respuesta.data.data[2]
							? respuesta.data.data[2].id_ciudad.toString()
							: '',
						baner4: respuesta.data.data[3]
							? respuesta.data.data[3].id_ciudad.toString()
							: '',
					});

					setNumEventsValues({
						baner1NumEvent: respuesta.data.data[0]
							? respuesta.data.data[0].numero_eventos
							: '',
						baner2NumEvent: respuesta.data.data[1]
							? respuesta.data.data[1].numero_eventos
							: '',
						baner3NumEvent: respuesta.data.data[2]
							? respuesta.data.data[2].numero_eventos
							: '',
						baner4NumEvent: respuesta.data.data[3]
							? respuesta.data.data[3].numero_eventos
							: '',
					});
				}
			})
			.catch((error) => {
				console.log(error);
				setCitiesBanerArray([]);
			});
	};

	//Efecto que obtiene array de BanerPrincipal
	useEffect(() => {
		banerCiudadesGet();
	}, []);
	return (
		<>
			<Basic
				tittle={'Eventos por ciudades'}
				isCardOpen={isCardOpen}
				setIsCardOpen={setIsCardOpen}
				content={
					<MDBox
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
						width={'100%'}
					>
						<div className='fpTableContainer'>
							<MDBox
								p={1}
								display={'flex'}
								justifyContent={'space-between'}
								style={{ backgroundColor: '#F9F9F9' }}
							>
								<MDBox
									display={'flex'}
									style={{ minWidth: '230px', backgroundColor: '#F9F9F9' }}
								>
									Posición
								</MDBox>
								<MDBox
									display={'flex'}
									style={{ minWidth: '230px', backgroundColor: '#F9F9F9' }}
								>
									Eventos
								</MDBox>
								<MDBox
									display={'flex'}
									width={'230px'}
									pl={2}
									style={{
										minWidth: '230px',
										backgroundColor: '#F9F9F9',
									}}
								>
									Número eventos
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 1
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner1'
											value={baner1}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											<option value={100}>Default</option>
											{citiesArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{numEventsValues.baner1NumEvent}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 2
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner2'
											value={baner2}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{citiesArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{numEventsValues.baner2NumEvent}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 3
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner3'
											value={baner3}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{citiesArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{numEventsValues.baner3NumEvent}
								</MDBox>
							</MDBox>
							<MDBox p={1} display={'flex'} justifyContent={'space-between'}>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									Baner 4
								</MDBox>
								<MDBox display={'flex'} style={{ minWidth: '230px' }}>
									<div className='fp-select'>
										<select
											className='fp-selected'
											name='baner4'
											value={baner4}
											onChange={handleInputChange}
										>
											<option value={''}>Selecciona una opción</option>
											{citiesArray.map((item: any) => (
												<option key={item.id} value={item.id}>
													{item.nombre}
												</option>
											))}
										</select>
									</div>
								</MDBox>
								<MDBox pl={2} display={'flex'} style={{ minWidth: '230px' }}>
									{numEventsValues.baner4NumEvent}
								</MDBox>
							</MDBox>
						</div>
						<MDBox
							mt={5}
							display={'flex'}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
							width={'100%'}

							// alignItems={'center'}
						>
							<MDBox
								style={{
									minWidth: '100px',
									width: '48%',
								}}
							>
								<MDButton
									variant='contained'
									type='button'
									// color='error'
									onClick={sendBanerEventosPorCiudad}
									style={{
										backgroundColor: 'var(--colorPrimary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'bold',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Guardar
								</MDButton>
							</MDBox>
							<MDBox
								style={{
									minWidth: '100px',
									width: '48%',
								}}
							>
								<MDButton
									onClick={cancelarBanerCiudades}
									type='button'
									variant='contained'
									color='primary'
									style={{
										backgroundColor: 'var(--colorSecondary)',
										width: '100%',
										textTransform: 'capitalize',
										fontWeight: 'normal',
										fontSize: '1rem',
										color: 'var(--backgroundBody)',
									}}
								>
									Cancelar
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				}
			/>
		</>
	);
};

export default BannerEventosPorCiudades;
