const form = {
	formId: 'SignUp-form',
	formField: {
		userName: {
			name: 'userName',
			label: 'Nombre de Usuario',
			type: 'text',
			errorMsg: 'Nombre de usuario es requerido.',
		},
		email: {
			name: 'email',
			label: 'Correo electrónico',
			type: 'email',
			errorMsg: 'Correo electrónico es requerido.',
			invalidMsg: 'El correo electrónico es invalido',
		},
		password: {
			name: 'password',
			label: 'Contraseña',
			type: 'password',
			errorMsg: 'Contraseña es requerida.',
			invalidMsg: 'Tu contraseña debe tener más de 6 caracteres.',
		},
		repeatPassword: {
			name: 'repeatPassword',
			label: 'Confirma contraseña',
			type: 'password',
			errorMsg: 'Confirma contraseña es requerido.',
			invalidMsg: 'Tus contraseñas no coinciden.',
		},
		countryCode: {
			name: 'countryCode',
			label: '',
			type: 'text',
		},
		tel: {
			name: 'tel',
			label: 'Teléfono',
			type: 'number',
			errorMsg: 'Teléfono es requerido.',
			invalidMsg: 'Telefono debe tener 10 dígitos.',
		},
		// state: {
		// 	name: 'state',
		// 	label: 'Estado de residencia',
		// 	type: 'select',
		// 	errorMsg: 'Estado es requerido.',
		// 	// invalidMsg: 'Código postal no es valido (ej. 70000).',
		// },
	},
};

export default form;
