import { ReactNode } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { numeroAMoneda } from 'helpers/aMoneda';

// Declaring props types for BookingCard
interface Props {
	image: string;
	title: string;
	description: string;
	price: string;
	location: ReactNode;
	action?: ReactNode | boolean;
	[key: string]: any;
}

function BookingCard({
	image,
	title,
	description,
	price,
	location,
	action,
}: Props): JSX.Element {
	return (
		<Card
			sx={{
				'&:hover .card-header': {
					transform: action && 'translate3d(0, -50px, 0)',
				},
			}}
			// style={{ boxShadow: '1px 7px 15px 1px #00000038' }}
		>
			<MDBox
				position='relative'
				borderRadius='lg'
				mt={-3}
				mx={1}
				className='card-header'
				sx={{ transition: 'transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)' }}
			>
				<MDBox
					component='img'
					src={image}
					alt={title}
					loading={'lazy'}
					borderRadius='lg'
					// shadow='md'
					width='100%'
					height='100%'
					minHeight={'250px'}
					maxHeight={'250px'}
					position='relative'
					zIndex={1}
					style={{ objectFit: 'cover' }}
				/>
				<MDBox
					borderRadius='lg'
					shadow='md'
					width='100%'
					height='100%'
					position='absolute'
					left={0}
					top='0'
					sx={{
						backgroundImage: `url(${image})`,
						transform: 'scale(0.94)',
						filter: 'blur(12px)',
						backgroundSize: 'cover',
					}}
				/>
			</MDBox>
			<MDBox
				textAlign='center'
				pt={3}
				px={3}
				height='100%'
				minHeight={'230px'}
				maxHeight={'230px'}
				overflow={'hidden'}
			>
				<MDBox
					display='flex'
					justifyContent='center'
					alignItems='center'
					mt={action ? -8 : -4.25}
				>
					{action}
				</MDBox>
				<MDTypography variant='h4' fontWeight='bold' sx={{ mt: 4 }}>
					{title}
				</MDTypography>
				<MDTypography variant='body2' color='text' sx={{ mt: 1.5, mb: 1 }}>
					{description}
				</MDTypography>
			</MDBox>
			<MDBox
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				pt={1}
				pb={1}
				px={3}
				lineHeight={1}
				style={{ backgroundColor: '#000000', borderRadius: '0px 0px 12px 12px' }}
			>
				<MDTypography variant='body2' fontWeight='bold' color='white'>
					Desde {numeroAMoneda(Number(price))}
				</MDTypography>
				<MDBox color='white' display='flex' alignItems='center'>
					<Icon color='inherit' sx={{ m: 0.5 }}>
						place
					</Icon>
					<MDTypography variant='button' fontWeight='bold' color='white'>
						{location}
					</MDTypography>
				</MDBox>
			</MDBox>
		</Card>
	);
}

// Declaring default props for BookingCard
BookingCard.defaultProps = {
	action: false,
};

export default BookingCard;
