import * as Yup from 'yup';
import checkout from './form';

const {
	formField: { password, repeatPassword },
} = checkout;

const validations = [
	Yup.object().shape({
		[password.name]: Yup.string()
			.required(password.errorMsg)
			.min(6, password.invalidMsg),
		[repeatPassword.name]: Yup.string()
			.required(repeatPassword.errorMsg)
			.oneOf([Yup.ref('password'), null], repeatPassword.invalidMsg),
	}),
];

export default validations;
