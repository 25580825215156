/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Images
import arrowUp from 'assets/images/icons/Icon_arrow_up.svg';
import arrowDown from 'assets/images/icons/Icon_arrow_down.svg';
import deleteIcon from 'assets/images/icons/deleted.svg';
import moveIcon from 'assets/images/icons/Move.svg';
import editIcon from 'assets/images/icons/Edit.svg';

import { IconButton, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import {
	deleteCategoryId,
	patchCategory,
} from 'store/slices/categories/thunks';
import { toast } from 'react-toastify';

function Basic({
	tittle,
	content,
	editCategory,
	deleteCategory,
	pendienteAprobación,
	categoryId,
	categoryItem,
	categoriesGet,
}: any): JSX.Element {
	const [isCardOpen, setIsCardOpen] = useState(false);

	const aprobarCategory = () => {
		const data = JSON.stringify({
			id: categoryId,
			nombre: categoryItem.nombre,
			activo: true,
		});
		const bodyFormData = new FormData();
		bodyFormData.append('data', data);

		console.log(bodyFormData.getAll('data'));

		const venuesAprobar = patchCategory(categoryId, bodyFormData);
		venuesAprobar
			.then((respuesta) => {
				console.log('patch', respuesta);
				if (respuesta.status === 200) {
					console.log('patch200', respuesta);
					toast.success(respuesta.data.mensaje);
					setTimeout(() => {
						categoriesGet();
					}, 2000);
				}
			})
			.catch((error) => {
				toast.error(error.response.data.mensaje);
				console.log('patchError', error);
				setTimeout(() => {
					categoriesGet();
				}, 2000);
			});
	};
	const eliminarCategory = () => {
		console.log('categoryToDelete:', categoryId);
		const categoryDelete = deleteCategoryId(categoryId);
		categoryDelete
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 200) {
					toast.success('La categoría se descartó exitosamente');
					setTimeout(() => {
						// handleClose();
						categoriesGet();
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error(error.response.data.mensaje);
				// setTimeout(() => {
				// 	// handleClose();
				// }, 2000);
			});
	};

	return (
		<MDBox>
			{pendienteAprobación && (
				<MDBox
					style={{
						height: '4rem',
						// marginTop: '-15px',
					}}
					display={'flex'}
					justifyContent={'center'}
				>
					<MDBox
						style={{
							backgroundColor: '#000000',
							color: '#ffffff',
							height: '4rem',
							width: '80%',
							borderRadius: '15px',
							// marginTop: '-15px',
						}}
					>
						<MDTypography
							p={1}
							variant={'h5'}
							textAlign='center'
							style={{ color: '#ffffff' }}
						>
							Tienes 1 categoría pendiente de aprobación
						</MDTypography>
					</MDBox>
				</MDBox>
			)}
			<Card>
				<MDBox
					variant='gradient'
					bgColor='primary'
					borderRadius='lg'
					coloredShadow='info'
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign='center'
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
				>
					<MDBox display={'flex'}>
						<MDTypography
							ml={3}
							variant='h4'
							fontWeight='medium'
							color='white'
							mt={1}
						>
							{tittle}
						</MDTypography>
					</MDBox>
					<MDBox display={'flex'}>
						<Tooltip title='Editar'>
							<MDBox
								component={IconButton}
								onClick={() => editCategory(categoryItem)}
								// variant='button'
							>
								<img width={'30px'} src={editIcon} alt='Editar' />
							</MDBox>
						</Tooltip>
						<Tooltip title='Mover'>
							<MDBox
								component={IconButton}
								onClick={() => alert('moveIcon')}
								// variant='button'
							>
								<img width={'30px'} src={moveIcon} alt='Mover' />
							</MDBox>
						</Tooltip>
						<Tooltip title='Eliminar'>
							<MDBox
								component={IconButton}
								onClick={() => deleteCategory(categoryId)}
								// variant='button'
							>
								<img width={'30px'} src={deleteIcon} alt='Eliminar' />
							</MDBox>
						</Tooltip>
						<MDBox mr={2}>
							{isCardOpen ? (
								<Tooltip title='Cerrar'>
									<MDBox
										component={IconButton}
										onClick={() => setIsCardOpen(!isCardOpen)}
										// variant='button'
									>
										<img width={'30px'} src={arrowUp} alt='flecha Cerrar' />
									</MDBox>
								</Tooltip>
							) : (
								<Tooltip title='Abrir'>
									<MDBox
										component={IconButton}
										onClick={() => setIsCardOpen(!isCardOpen)}
										// variant='button'
									>
										<img width={'30px'} src={arrowDown} alt='Flecha Abrir' />
									</MDBox>
								</Tooltip>
							)}
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox pt={2} pb={3} px={3}>
					{isCardOpen && (
						<MDBox display={'flex'} flexDirection={'column'} width={'100%'}>
							<MDBox>{content}</MDBox>
							{pendienteAprobación && (
								<>
									<MDBox
										mt={5}
										display={'flex'}
										flexWrap={'wrap'}
										justifyContent={'space-between'}
										width={'100%'}

										// alignItems={'center'}
									>
										<MDBox
											style={{
												minWidth: '100px',
												width: '45%',
											}}
										>
											<MDButton
												variant='contained'
												type='button'
												// color='error'
												onClick={aprobarCategory}
												style={{
													backgroundColor: 'var(--colorPrimary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'bold',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Aprobar
											</MDButton>
										</MDBox>
										<MDBox
											style={{
												minWidth: '100px',
												width: '45%',
											}}
										>
											<MDButton
												onClick={eliminarCategory}
												type='button'
												variant='contained'
												color='primary'
												style={{
													backgroundColor: 'var(--colorSecondary)',
													width: '100%',
													textTransform: 'capitalize',
													fontWeight: 'normal',
													fontSize: '1rem',
													color: 'var(--backgroundBody)',
												}}
											>
												Descartar
											</MDButton>
										</MDBox>
									</MDBox>
								</>
							)}
						</MDBox>
					)}
				</MDBox>
			</Card>
		</MDBox>
	);
}

export default Basic;
