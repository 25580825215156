import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { Formik, Form } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import MDButton from 'components/MDButton';
import FormFieldRadius from 'components/MDFormFieldRadius';

const MySettings = () => {
	// const dispatch = useDispatch();

	const currentValidation = validations[0];
	const { formId, formField } = form;
	const { country, language, Moneda } = formField;
	const submitForm = async (values: any, actions: any) => {
		// await dispatch<any>(
		// 	postNewUser(
		// 		{
		// 			nombre: values.userName,
		// 			email: values.email,
		// 			password: values.password,
		// 			codigo_telefono: values.countryCode,
		// 			telefono: values.tel,
		// 			codigo_postal: values.zip,
		// 			tipo_usuario: 'COMPRADOR',
		// 		},
		// 		actions
		// 	)
		// );
		// actions.setSubmitting(false);
		// actions.resetForm();
	};

	const handleSubmit = (values: any, actions: any) => {
		submitForm(values, actions);
	};
	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				alignItems={'center'}
				mt={2}
			>
				<MDBox width={'100%'} maxWidth={'650px'} minHeight={'100vh'}>
					<MDTypography variant={'h3'} mb={2}>
						Configuración
					</MDTypography>
					<Formik
						initialValues={initialValues}
						validationSchema={currentValidation}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, isSubmitting }) => (
							<Form id={formId} autoComplete='off'>
								<MDBox
									display={'flex'}
									flexDirection={'column'}
									justifyContent={'center'}

									// alignItems={'center'}
								>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={country.type}
											label={country.label}
											name={country.name}
											value={values.country}
											onChange={handleChange}
											placeholder={country.label}
											error={errors.country && touched.country}
											success={values.country.length > 0 && !errors.country}
											style={{ minWidth: '12rem', backgroundColor: 'tomato' }}
											// autoComplete='off'
										/>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={language.type}
											label={language.label}
											name={language.name}
											value={values.language}
											onChange={handleChange}
											placeholder={language.label}
											error={errors.language && touched.language}
											success={values.language.length > 0 && !errors.language}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
									<MDBox style={{ margin: '0rem 1rem 1rem 1rem' }}>
										<FormFieldRadius
											type={Moneda.type}
											label={Moneda.label}
											name={Moneda.name}
											value={values.Moneda}
											onChange={handleChange}
											placeholder={Moneda.label}
											error={errors.Moneda && touched.Moneda}
											success={values.Moneda.length > 0 && !errors.Moneda}
											inputProps={{ autoComplete: '' }}
											style={{ minWidth: '12rem' }}
										/>
									</MDBox>
								</MDBox>
								<MDBox
									mt={4}
									mb={1}
									display={'flex'}
									width={'100%'}
									justifyContent={'center'}
								>
									<MDButton
										disabled={isSubmitting}
										type='submit'
										variant='gradient'
										color='error'
										size='large'
										style={{ minWidth: '12rem', width: '100%' }}
									>
										Guardar cambios
									</MDButton>
								</MDBox>
							</Form>
						)}
					</Formik>
				</MDBox>
			</MDBox>
		</>
	);
};

export default MySettings;
