import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MyEventListItem from './components/MyEventsListItem';
import ED1 from 'assets/images/Destacados/ED1.jpeg';
import ED2 from 'assets/images/Destacados/ED2.jpeg';
import ED3 from 'assets/images/Destacados/ED3.jpeg';
import ED4 from 'assets/images/Destacados/ED4.jpeg';
import ED5 from 'assets/images/Destacados/ED5.jpeg';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import './MyEventsList.css';
import BookingCard from './components/BookingCard';

interface props {
	type: 'NextEvents' | 'LastEvents';
	myEventsArray: any[];
}

const MyEventsList = ({ type, myEventsArray }: props) => {
	// const myEventsArray = [
	// 	{ id: 'Coret', img: ED1, tittle: 'CORE TULUM' },
	// 	{ id: 'Core2', img: ED2, tittle: 'CORE2 TULUM' },
	// 	{ id: 'Core2', img: ED3, tittle: 'CORE2 TULUM' },
	// 	{ id: 'Core2', img: ED4, tittle: 'CORE2 TULUM' },
	// 	{ id: 'Core2', img: ED5, tittle: 'CORE2 TULUM' },
	// ];
	return (
		<>
			<MDBox
				display={'flex'}
				flexDirection={'column'}
				width={'100%'}
				p={2}
				// style={{ backgroundColor: 'teal' }}
			>
				<MDBox display={'flex'}>
					<MDTypography variant={'h4'}>
						{type === 'NextEvents'
							? 'Próximos eventos'
							: type === 'LastEvents' && 'Eventos pasados'}
					</MDTypography>
				</MDBox>
				<MDBox display={'flex'} position={'relative'} width={'100%'} p={1}>
					<Carousel
						containerClass='carousel-container container-with-dots'
						additionalTransfrom={0}
						// arrows
						autoPlay={false}
						autoPlaySpeed={2500}
						// centerMode={true}
						customLeftArrow={
							<button className=' btnArrow btnArrowBack '>
								{/* <Icon>notifications</Icon> */}
								<ChevronLeftOutlinedIcon fontSize='large' />
							</button>
						}
						customRightArrow={
							<button className=' btnArrow btnArrowNext '>
								{/* <Icon>notifications</Icon> */}
								<NavigateNextOutlinedIcon fontSize='large' />
							</button>
						}
						className=''
						dotListClass=''
						draggable
						focusOnSelect={false}
						infinite
						itemClass=''
						keyBoardControl
						minimumTouchDrag={80}
						pauseOnHover
						renderArrowsWhenDisabled={false}
						renderButtonGroupOutside={false}
						renderDotsOutside={false}
						responsive={{
							desktop: {
								breakpoint: {
									max: 3000,
									min: 1290,
								},
								items: 2,
								partialVisibilityGutter: 40,
							},
							tablet: {
								breakpoint: {
									max: 1290,
									min: 1010,
								},
								items: 2,
								partialVisibilityGutter: 30,
							},
							mobile: {
								breakpoint: {
									max: 1010,
									min: 700,
								},
								items: 1,
								partialVisibilityGutter: 40,
							},
							mobilemini: {
								breakpoint: {
									max: 700,
									min: 0,
								},
								items: 1,
								partialVisibilityGutter: 40,
							},
						}}
						rewind={false}
						rewindWithAnimation={false}
						rtl={false}
						shouldResetAutoplay
						showDots={false}
						sliderClass=''
						slidesToSlide={1}
						swipeable
					>
						{myEventsArray.map((item: any, index: number) => {
							// <MyEventListItem
							// 	key={item.id}
							// 	id={item.id}
							// 	img={item.Evento.imgUrl}
							// 	tittle={item.Evento.nombre}
							// 	typeEvent={type}
							// />
							const portadaImg = item.Evento.Evento_imagenes.find(
								(item: any) => item.tipo === 'BANNER'
							);
							console.log('portadaImg::', portadaImg);
							return (
								<div className='MyEventListItem'>
									{/* <Link to={`/event/${id}`}> */}
									<BookingCard
										key={item.id + index}
										image={
											portadaImg.urlImagen
												? portadaImg.urlImagen
												: 'https://pixcuarhfiles.s3.amazonaws.com/fpPortada.png'
										}
										title={item.Evento.nombre}
										description='El enigmático cantante y productor de Los Ángeles nominado al Grammy, ZHU, llega a Zamna el 7 de abril.'
										price='$459/night'
										location={item.Evento.Venue.nombre}
										typeEvent={type}
										numTickets={item.Venta_boletos_detalles.length}
										eventDate={item.Evento.fecha}
										purchaseId={item.id}
									/>
									{/* </Link> */}
								</div>
							);
						})}
					</Carousel>
				</MDBox>
			</MDBox>
		</>
	);
};

export default MyEventsList;
