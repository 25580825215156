import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Categorias from 'pantallas/dashboard/comprador/components/categorias/Categorias';
import EventosPorCiudades from 'pantallas/dashboard/comprador/components/Eventos Por Ciudades/EventosPorCiudades';
import Navbar from 'pantallas/dashboard/comprador/components/navbar/Navbar';
import Footer from 'pantallas/footer/Footer';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete } from '@mui/material';
import MDInput from './components/MDInput';
import EventoDestacadoItem from 'pantallas/dashboard/comprador/components/Eventos destacados/components/EventoDestacadoItem';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getEventsByParams } from 'store/slices/events';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import './SearchEvents.css';

const selectData = {
	category: ['Conciertos', 'Festivales', 'Teatro', 'Stand up'],
	cities: ['Tulum', 'Punta Mita', 'Cancún', 'CDMX'],
};
const initialValue = {
	search: '',
	category: '',
	date: '',
	city: '',
};
const initialEventsValues: any = [];

const SearchEvents = () => {
	const params = useParams();
	const search = params.searchParam;

	const [eventsArray, setEventsArray] = useState(initialEventsValues);

	//SEARC_BAR LOGIC
	const [filtersValues, setFiltersValues] = useState(initialValue);
	const [searchValue, setSearchValue] = useState({
		search: filtersValues.search,
	});
	const [searchValueFinal, setSearchValueFinal] = useState({
		search: filtersValues.search,
	});
	const cleanSearchValue = () => {
		setFiltersValues({ ...filtersValues, search: '' });
		setSearchValue({
			search: '',
		});
	};
	//handleInputChange
	const handleInputChangeSearch = (event: any) => {
		setSearchValue({
			...searchValue,
			[event.target.name]: event.target.value,
		});
	};
	const goSearch = (e: any) => {
		e.preventDefault();
		setSearchValueFinal({ ...searchValueFinal, search: searchValue.search });
	};
	//**CATEGORY LOGIC
	const [categoryValue, setcategoryValue] = useState({
		category: filtersValues.category,
	});
	const cleanCategoryValue = () => {
		setFiltersValues({ ...filtersValues, category: '' });
		setcategoryValue({
			category: '',
		});
	};
	//**DATE LOGIC
	const [dateValue, setDateValue] = useState({
		date: filtersValues.date,
	});
	const handleInputChangeDate = (event: any) => {
		setDateValue({
			...dateValue,
			[event.target.name]: event.target.value,
		});
	};
	const cleanDateValue = () => {
		setFiltersValues({ ...filtersValues, date: '' });
		setDateValue({
			date: '',
		});
	};
	//**City LOGIC
	const [cityValue, setCityValue] = useState({
		city: filtersValues.city,
	});
	const cleanCityValue = () => {
		setFiltersValues({ ...filtersValues, city: '' });
		setCityValue({
			city: '',
		});
	};

	//SEARCH_BAR EFFECTS
	// Efecto que se ejecuta al cargar el componente
	useEffect(() => {
		setFiltersValues({ ...filtersValues, search: search });
		setSearchValue({ ...searchValue, search: search });
		setSearchValueFinal({ ...searchValueFinal, search: search });
		// eslint-disable-next-line
	}, [search]);
	//SEARCH EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, search: searchValue.search });
		// eslint-disable-next-line
	}, [searchValueFinal]);
	//CATEGORY EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, category: categoryValue.category });
		// eslint-disable-next-line
	}, [categoryValue]);
	//DATE EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, date: dateValue.date });
		// eslint-disable-next-line
	}, [dateValue]);
	//CITY EFFECTS
	useEffect(() => {
		setFiltersValues({ ...filtersValues, city: cityValue.city });
		// eslint-disable-next-line
	}, [cityValue]);

	useEffect(() => {
		const getEventsbyParams = getEventsByParams({
			busqueda: filtersValues.search ? filtersValues.search : '',
			categoria: '',
			ciudad: '',
			// fecha: '',
			fecha: filtersValues.date
				? moment(filtersValues.date).format('DD/MM/YYYY')
				: '',
		});
		getEventsbyParams
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log(respuesta.data.data);
					setEventsArray(respuesta.data.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 404) {
					console.log('error404- no se encontraron');
					setEventsArray([]);
				}
			});
		// eslint-disable-next-line
	}, [filtersValues]);

	return (
		<>
			<Navbar />
			<MDBox
				display={'flex'}
				width={'100%'}
				minHeight={'100vh'}
				pt={11}
				pl={3}
				pr={3}
				flexDirection={'column'}
				// style={{ backgroundColor: 'var(--backgroundBody)' }}
			>
				<MDBox
					display={'flex'}
					width='100%'
					flexWrap={'wrap'}
					mt={8}
					// style={{ backgroundColor: 'tomato' }}
				>
					<MDBox display={'flex'} width={'100%'} flexDirection={'column'}>
						<MDBox
							display={'flex'}
							flexWrap={'wrap'}
							component='form'
							role='form'
							onSubmit={goSearch}
							justifyContent={'space-between'}
						>
							{/* SearchBar */}
							<MDBox
								display={'flex'}
								minWidth={'45%'}
								p={2}
								// style={{ backgroundColor: 'tomato' }}
								// flexDirection={'column'}
							>
								<input
									value={searchValue.search}
									name='search'
									onChange={handleInputChangeSearch}
									type='text'
									placeholder='Buscar evento'
									className='inputTransparent'
									style={{ minWidth: '100%', color: '#000' }}
								/>
								<button id='inputSearchBtn' type='submit'>
									<SearchIcon />
								</button>
							</MDBox>
							<MDBox
								display={'flex'}
								minWidth={'50%'}
								flexWrap={'wrap'}
								justifyContent={'space-between'}
							>
								{/* Category */}
								<MDBox p={2}>
									<MDBox
										display={'flex'}
										style={{
											width: '100%',
											position: 'relative',
											height: '100%',
										}}
									>
										<Autocomplete
											// defaultValue='Categoria'

											options={selectData.category}
											value={categoryValue.category}
											placeholder='Categoría'
											onChange={(event, newValue) => {
												setcategoryValue({ ...categoryValue, category: newValue });
											}}
											renderInput={(params) => (
												<MDInput
													{...params}
													label='Categoría'
													InputLabelProps={{ shrink: true }}
													id='category'
													style={{
														background: 'transparent',
														zIndex: '300',
													}}
												/>
											)}
											style={{ minWidth: '12rem' }}
										/>
										<button
											id='inputCityButton'
											style={{
												position: 'absolute',
												backgroundColor: 'var(--colorPrimary)',
												width: '37px',
												height: '100%',
												right: '0px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												color: 'var(--backgroundPrimary)',
												borderRadius: '0px 5px 5px 0px',
												border: 'none',
											}}
										/>
									</MDBox>
								</MDBox>
								{/* DATE */}
								<MDBox
									display={'flex'}
									flexDirection={'column'}
									p={2}
									style={{
										minWidth: '12rem',
										// background: 'tomato',
									}}
								>
									<MDBox
										display={'flex'}
										style={{
											width: '100%',
											position: 'relative',
											height: '100%',
										}}
									>
										<input
											className='inputTransparent'
											type='date'
											name={'date'}
											value={dateValue.date}
											onChange={handleInputChangeDate}
											style={{
												minWidth: '12rem',
												height: '100%',
												border: '1px solid #D2D6DA',
												borderRadius: '5px',
												zIndex: '300',
											}}
										/>
										<button
											id='inputCityButton'
											className='inputDateSearch'
											style={{
												position: 'absolute',
												backgroundColor: 'var(--colorPrimary)',
												width: '37px',
												height: '100%',
												right: '0px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												color: 'var(--backgroundPrimary)',
												borderRadius: '0px 5px 5px 0px',
												border: 'none',
											}}
										/>
									</MDBox>
									{/* <MDInput
									type='date'
									label=''
									name={'date'}
									value={dateValue.date}
									onChange={handleInputChangeDate}
									id={'date'}
									style={{
										minWidth: '12rem',
									}}
								/> */}
								</MDBox>
								{/* CITY */}
								<MDBox p={2}>
									<MDBox
										display={'flex'}
										style={{
											width: '100%',
											position: 'relative',
										}}
									>
										<Autocomplete
											id='inputCity'
											options={selectData.cities}
											value={cityValue.city}
											placeholder='Ciudad'
											onChange={(event, newValue) => {
												setCityValue({ ...cityValue, city: newValue });
											}}
											renderInput={(params) => (
												<MDInput
													{...params}
													label='Ciudad'
													InputLabelProps={{ shrink: true }}
													style={{
														background: 'transparent',
														zIndex: '300',
													}}
												/>
											)}
											style={{
												minWidth: '12rem',
											}}
										/>
										<button
											id='inputCityButton'
											style={{
												position: 'absolute',
												backgroundColor: 'var(--colorPrimary)',
												width: '37px',
												height: '100%',
												right: '0px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												color: 'var(--backgroundPrimary)',
												borderRadius: '0px 5px 5px 0px',
												border: 'none',
											}}
										/>
										{/* <KeyboardArrowDownIcon />
									</button> */}
									</MDBox>
								</MDBox>
							</MDBox>
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox display={'flex'} flexWrap={'wrap'}>
					{filtersValues.search && (
						<div className='filterValueButton'>
							{filtersValues.search}
							<button className='filterValueDeleteButton' onClick={cleanSearchValue}>
								<ClearIcon />
							</button>
						</div>
					)}
					{filtersValues.category && (
						<div className='filterValueButton'>
							{filtersValues.category}
							<button className='filterValueDeleteButton' onClick={cleanCategoryValue}>
								<ClearIcon />
							</button>
						</div>
					)}
					{filtersValues.date && (
						<div className='filterValueButton'>
							{filtersValues.date}
							<button className='filterValueDeleteButton' onClick={cleanDateValue}>
								<ClearIcon />
							</button>
						</div>
					)}
					{filtersValues.city && (
						<div className='filterValueButton'>
							{filtersValues.city}
							<button className='filterValueDeleteButton' onClick={cleanCityValue}>
								<ClearIcon />
							</button>
						</div>
					)}
				</MDBox>
				<MDBox pl={2}>
					<MDTypography
						variant={'h3'}
						style={
							eventsArray.length === 0
								? { paddingBottom: '10rem', paddingTop: '3rem' }
								: { padding: '0rem' }
						}
					>
						{eventsArray.length} Eventos encontrados
					</MDTypography>
				</MDBox>
				<MDBox display={'flex'} flexWrap={'wrap'} width={'100%'}>
					{eventsArray.map((item: any) => (
						<EventoDestacadoItem
							key={item.id}
							id={item.id}
							img={item.imgUrl}
							tittle={item.nombre}
							desc={item.descripcion}
							price={item.Evento_boletos[0] && `${item.Evento_boletos[0].precio}`}
							location={item.Venue.nombre}
						/>
					))}
					{/* <EventoDestacadoItem
						id={'f1'}
						img={ED2}
						tittle={'FORMULA 1 - GRAND PRIX'}
					/>
					<EventoDestacadoItem id={'123'} img={ED1} tittle={'PANTEÓN ROCOCO!'} />
					<EventoDestacadoItem
						id={'f1'}
						img={ED2}
						tittle={'FORMULA 1 - GRAND PRIX'}
					/> */}
				</MDBox>
				<MDBox>
					<MDTypography variant={'h4'}>
						¿No es lo que buscas? ¡Explora todas nuestras categorías!
					</MDTypography>
				</MDBox>
				<Categorias />
				<EventosPorCiudades />
				<Footer />
			</MDBox>
		</>
	);
};

export default SearchEvents;
